import axios from "axios";

let username ="gongchausa.staging.payment";
let password ="D5g#wwl%6pL@1wmdh!4k$8z^5s";
const instance = axios.create({
  // baseURL: "https://apistaging.aiprusdev.com",
  baseURL: "https://api.gongchausa.com",
  // baseURL: process.env.REACT_APP_baseUrl,
});
instance.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8";
instance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default instance;