import * as React from 'react';
import { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from "@mui/styles";
import {CircularProgress} from "@mui/material";
import {
    Container,
    Typography,
    Card,
    CardContent,
    Grid,
    TextField,
    Button,
    FormControl,
    Box,
    Backdrop,
    InputAdornment,
    IconButton
  } from "@mui/material";
  import {
    validateConfirmPassword,
    validatePassword,
  } from "../../Common/FormVailidation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ChangePassword } from '../../Api/API';
import { toast } from "react-toastify"
import { errorToastMessage } from '../../Common/utils';
import { useSelector,useDispatch } from 'react-redux';
import { setTabValue } from '../../Redux/CartSlice';

toast.configure()
const useStyles = makeStyles((theme) => ({
  errorP: {
    color: "#bf1650",
    marginLeft: "18.7rem",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },},
}));

const Security = () => {
  const defaultValues = {
    isOldPasswordRequired: true,
    newPassword: "",
    password: "",
    userId: 188749,
  };
  const { control, handleSubmit, formState, setValue } = useForm({
    defaultValues,
  });
  const { errors } = formState;
  const classes = useStyles();
  const dispatch = useDispatch()
  const [password, setPassword] = useState("");
  const userDetail = useSelector((state) => state.user.user);
  const [loading , setLoading] = useState(false);

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [valuesN, setValuesN] = React.useState( {
    password: "",
    showPassword: false,
  });
  
  const handleClickShowPasswordN = () => {
    setValuesN({ ...valuesN, showPassword: !valuesN.showPassword });
  };
  
  const handlePasswordChangeN = (prop) => (event) => {
    setValuesN({ ...valuesN, [prop]: event.target.value });
  };

  const [valuesCN, setValuesCN] = React.useState( {
    password: "",
    showPassword: false,
  });
  
  const handleClickShowPasswordCN = () => {
    setValuesCN({ ...valuesCN, showPassword: !valuesCN.showPassword });
  };
  
  const handlePasswordChangeCN = (prop) => (event) => {
    setValuesCN({ ...valuesCN, [prop]: event.target.value });
  };
  

  const onSubmit = (data) => {
    data.userId = userDetail.customer_id ? userDetail.customer_id : userDetail.person_id;
    data.password = values.password
    data.newPassword = valuesN.password
    delete data.confirmPassword;
    if (data.password != data.newPassword) {
      setLoading(true);
    ChangePassword(data)
      .then((res) => {
       
        if (res?.response_code == 1) {
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setValue("password","")
          setValue("newPassword","")
          setValue("confirmPassword","")
          setValues({
            password: "",
            showPassword: false,
          })
          setValuesN({
            password: "",
            showPassword: false,
          })
          setValuesCN({
            password: "",
            showPassword: false,
          })
          setLoading(false);
        }
        else {
          setLoading(false);
          toast.error(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
            ;
        }
      })
      .catch((err) => {
        console.log(err);
        errorToastMessage();
        setLoading(false);
      });
    }
     else 
        {
          setLoading(false);
          toast.error("Password and New Password are same.", {
            position: toast.POSITION.TOP_RIGHT,
          })
            ;
        }
        dispatch(setTabValue(2))
  }; 
  return (
    <div >
       <Backdrop
        sx={{ color: '#fff', zIndex: 1000}}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    <Container>
      <Card sx={{ borderRadius: "1rem", minHeight: "21rem", marginTop: "4vh"  }}>
        <CardContent >
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Typography marginBottom="3rem" fontWeight="600" textAlign="center">Change Password</Typography>
        <Box sx={{
          marginLeft: {xs:"0",sm : "0" , md : "0", lg:"5rem"},
          marginRight: {xs:"0",sm : "0" , md : "0", lg:"5rem"}}} 
          display="flex">
                  <Typography
                    style={{ width: "18rem" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                   Password :
                  </Typography>
                  <FormControl fullWidth>
                    <Controller
                      name="password"
                      rules={
                        ({
                          required: true,
                        },
                        {
                          validate: (value) => {
                            if (!value) {
                              return validatePassword(value);
                            } else if (value.length > 0 && value.length < 8) {
                              return validatePassword(value);
                            } else if (value.length >= 8) {
                              return value.length > 16
                                ? "More than 16 characters are not Allowed"
                                : validatePassword(value);
                            }
                          },
                        })
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="password"
                          size="small"
                          placeholder="Enter 8-16 digit password"
                          type={values.showPassword ? "text" : "password"}
                          onInput={(e) =>  setValues({
                                password: e.target.value,
                                showPassword: values.showPassword,
                              })}
                          value={values.password}
                          variant="standard"
                          fullWidth
                          InputProps={{
                            endAdornment : (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                >
                                  {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            autocomplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          inputProps={{
                            maxLength: 16,
                          }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Box>
                {errors?.password ? (
                    <Box 
                    sx={{
                      color: "#bf1650",
                      marginLeft: {lg:"18.7rem",md:"14rem",sm:"13rem"},
                      marginBottom:"1rem"
                     }}>
                    {errors.password.message}</Box>
                  ) : (
                    <p><br/></p>
                  )}
        
        <Box  sx={{
          marginLeft: {xs:"0",sm : "0" , md : "0", lg:"5rem"},
          marginRight: {xs:"0",sm : "0" , md : "0", lg:"5rem"}}}  display="flex">
                  <Typography
                    style={{ width: "18rem" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                   New Password :
                  </Typography>
                  <FormControl fullWidth>
                    <Controller
                      name="newPassword"
                      rules={
                        ({
                          required: true,
                        },
                        {
                          validate: (value) => {
                            if (!value) {
                              return validatePassword(value);
                            } 
                            else if(value == values.password) {
                              return "Password and new password are same";
                            } 
                            else if (value.length > 0 && value.length < 8) {
                              return validatePassword(value);
                            } else if (value.length >= 8) {
                              return value.length > 16
                                ? "More than 16 characters are not Allowed"
                                : validatePassword(value);
                            }
                            
                          },
                        })
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="newPassword"
                          size="small"
                          placeholder="Enter 8-16 digit password"
                          type={valuesN.showPassword ? "text" : "password"}
                          onInput={handlePasswordChangeN("password")}
                          value={valuesN.password}
                          variant="standard"
                          fullWidth
                          InputProps={{
                            endAdornment : (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPasswordN}
                                >
                                  {valuesN.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            autocomplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          inputProps={{
                            maxLength: 16,
                          }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Box>
                {errors?.newPassword ? (
                    <Box 
                     sx={{
                      color: "#bf1650",
                      marginLeft: {lg:"18.7rem",md:"14rem",sm:"13rem"},
                      marginBottom:"1rem"
                     }}
                    >{errors.newPassword.message}</Box>
                  ) : (
                    <p><br/></p>
                  )}
                <Box  sx={{
          marginLeft: {xs:"0",sm : "0" , md : "0", lg:"5rem"},
          marginRight: {xs:"0",sm : "0" , md : "0", lg:"5rem"}}}  display="flex">
                  <Typography
                    style={{ width: "18rem" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    Confirm Password :
                  </Typography>
                  <FormControl fullWidth>
                    <Controller
                      name="confirmPassword"
                      rules={
                        ({ required: true },
                        {
                          validate: (value) => {
                            if (valuesN.password) {
                              return validateConfirmPassword(value,valuesN.password);
                            } 
                            else if (!value) {
                              return "Confirm password is required"
                              }
                          },
                        })
                      }
                      render={({ field }) => (
                        <TextField
                          id="confirmPassword"
                          {...field}
                          size="small"
                          placeholder="Re-enter password"
                          type={valuesCN.showPassword ? "text" : "password"}
                          onInput={handlePasswordChangeCN("password")}
                          value={valuesCN.password}
                          variant="standard"
                          fullWidth
                          InputProps={{
                            endAdornment : (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPasswordCN}
                                >
                                  {valuesCN.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            autocomplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          inputProps={{
                            maxLength: 16,
                          }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Box>
                {errors?.confirmPassword ? (
                    <Box  sx={{
                      color: "#bf1650",
                      marginLeft: {lg:"18.7rem",md:"14rem",sm:"13rem"},
                      marginBottom:"1rem"
                     }}>
                      {errors.confirmPassword.message}
                    </Box>
                  ) : (
                    <p><br/></p>
                  )}
               
        {/* </AccordionDetails> */}
       {/* </Accordion> */}
     
      <Container sx={{ marginTop: "4rem" }}>
            <Container sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    sx={{
                      padding: "0.5rem 2rem",
                      backgroundColor: "#B7273B",
                      fontFamily: "Lusitana",
                      borderRadius: "3rem",
                    }}
                    variant="contained"
                    style={{ textTransform: "none" }}
                  >
                    Save Changes
                  </Button>
                </Container>
              </Container>
              </form>
      </CardContent>
      </Card>
    </Container>
    </div>
  );
}

export default Security;
