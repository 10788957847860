import * as React from "react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Zoom from '@mui/material/Zoom';
import {
  Container,
  Typography,
  Dialog,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../Common/FormVailidation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ChangePassword } from "../../Api/API";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import { errorToastMessage } from "../../Common/utils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
toast.configure();
const useStyles = makeStyles((theme) => ({
  paper: { borderRadius: 15 },
  errorP: {
    color: "#bf1650",
    marginLeft: "11.8rem",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom  ref={ref} {...props} timeout={500}/>;
});
const NewPassword = (props) => {
  const { showNewPass, setshowNewPass,setshowPopup ,setTitle} = props;
  const UserId = useSelector((state) => state.user.UserId);
  const defaultValues = {
    isOldPasswordRequired: false,
    newPassword: "",
    password: "",
    userId: "",
  };
  const { control, handleSubmit, formState } = useForm({
    defaultValues,
  });
  const { errors } = formState;
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const closeAd = () => {
    setshowNewPass(false);
    setshowPopup(false);
  }
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [valuesCN, setValuesCN] = React.useState( {
    password: "",
    showPassword: false,
  });
  
  const handleClickShowPasswordCN = () => {
    setValuesCN({ ...valuesCN, showPassword: !valuesCN.showPassword });
  };
  
  const handlePasswordChangeCN = (prop) => (event) => {
    setValuesCN({ ...valuesCN, [prop]: event.target.value });
  };
  const onSubmit = (data) => {
    data.newPassword = values.password
    data.userId = UserId
    delete data.confirmPassword;
    ChangePassword(data)
      .then((res) => {
        if (res?.response_code == 1) {
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          });
          setshowNewPass(false);
          setshowPopup(false);
        } else {
          toast.error(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss : false,
            autoClose : 1500,
            pauseOnHover : false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        errorToastMessage();
      });
  };
  return (
    <>
      <Dialog
        open={showNewPass}
        classes={{
          paper: classes.paper,
        }}
        onKeyDown = {(e)=>  e.key == "Escape" && closeAd() }
        TransitionComponent={Transition}
      >
        <Box textAlign="right" sx={{cursor:"pointer"}}>
          <CancelIcon
            fontSize="large"
            onClick={() => {
              setshowNewPass(false);
              setshowPopup(false);
              setTitle("")
            }}
          />
        </Box>
        <Box p={3}>
          <DialogTitle textAlign="center">
            <img src="./NewLogoG.png" alt="" width="70%" />
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box display="flex">
              <Typography
                style={{ width: "18rem" }}
                variant="subtitle1"
                gutterBottom
              >
                New Password :
              </Typography>
              <FormControl fullWidth>
                <Controller
                  name="newPassword"
                  rules={
                        ({
                          required: true,
                        },
                        {
                          validate: (value) => {
                            if (!value) {
                              return validatePassword(value);
                            } else if (value.length > 0 && value.length < 8) {
                              return validatePassword(value);
                            } else if (value.length >= 8) {
                              return value.length >= 16
                                ? "More than 16 characters are not Allowed"
                                : validatePassword(value);
                            }
                          },
                        })
                      }
                  render={({ field }) => (
                    <TextField
                          {...field}
                          id="newPassword"
                          size="small"
                          placeholder="Enter 8-16 digit password"
                          type={values.showPassword ? "text" : "password"}
                          onInput={handlePasswordChange("password")}
                          value={values.password}
                          variant="standard"
                          fullWidth
                          InputProps={{
                            endAdornment : (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                >
                                  {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            autocomplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                        />
                  )}
                  control={control}
                />
              </FormControl>
            </Box>
            {errors?.newPassword ? (
              <p className={classes.errorP}>{errors.newPassword.message}</p>
            ) : (
              <p>
                <br />
              </p>
            )}
            <Box display="flex">
              <Typography
                style={{ width: "18rem" }}
                variant="subtitle1"
                gutterBottom
              >
                Confirm Password :
              </Typography>
              <FormControl fullWidth>
                    <Controller
                      name="confirmPassword"
                      rules={
                        ({ required: true },
                        {
                          validate: (value) => {
                            if (values.password) {
                              return validateConfirmPassword(value,values.password);
                            } 
                            else if (!value) {
                              return "Confirm password is required"
                              }
                          },
                        })
                      }
                      render={({ field }) => (
                        <TextField
                          id="confirmPassword"
                          {...field}
                          size="small"
                          placeholder="Re-enter password"
                          type={valuesCN.showPassword ? "text" : "password"}
                          onInput={handlePasswordChangeCN("password")}
                          value={valuesCN.password}
                          variant="standard"
                          InputProps={{
                            endAdornment : (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPasswordCN}
                                >
                                  {valuesCN.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            autocomplete: "new-password",
                            form: {
                              autocomplete: "off",
                            },
                          }}
                          fullWidth
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
            </Box>
            {errors?.confirmPassword ? (
              <p className={classes.errorP}>{errors.confirmPassword.message}</p>
            ) : (
              <p>
                <br />
              </p>
            )}

            <Container sx={{ marginTop: "7rem" }}>
              <Container sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="submit"
                  sx={{
                    padding: "0.5rem 2rem",
                    backgroundColor: "#B7273B",
                    fontFamily: "Lusitana",
                    borderRadius: "3rem",
                  }}
                  variant="contained"
                  style={{ textTransform: "none" }}
                >
                  Save Changes
                </Button>
              </Container>
            </Container>
          </form>
        </Box>
      </Dialog>
    </>
  );
};

export default NewPassword;
