import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "@mui/material";
import {CircularProgress} from "@mui/material";
import Zoom from '@mui/material/Zoom';
import {
  Dialog,
  Box,
  Button,
  DialogTitle,
  Backdrop,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { deleteAccount, Log_in } from "../../Api/API";
import CancelIcon from "@mui/icons-material/Cancel";
import { errorToastMessage } from "../../Common/utils";
import { setTabValue } from "../../Redux/CartSlice";
toast.configure();
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: { borderRadius: 15 },
  noBorder: {
    border: "none",
  },
  btnLogin: {
    padding: "0.2rem 5rem",
    backgroundColor: "#B7273B",
    margin: "auto",
    fontFamily: "Lusitana",
    textTransform: "none",
  },
  btnNew: {
    padding: "0.2rem 0.5rem",
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    textDecoration: "none",
    color: "white",
    textTransform: "none",
  },
  error: {
    color: "#bf1650",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom  ref={ref} {...props} timeout={500}/>;
});
const  DeleteAccount = (props) => {
  const {  showPopup,  setshowPopup } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading , setLoading] = useState(false);
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.user);
  const closeAd = () =>  setshowPopup(false);
  const handleRemoveAccount = ( ) => {
    dispatch(setTabValue(2))
    setLoading(true);
    deleteAccount("" , userDetail?.email).then((res)=>
    {
      setLoading(false);
      localStorage.clear();
      navigate("/");
      window.location.reload();
    }
    ).catch((e)=>{
      console.log(e);
      setLoading(false);
      errorToastMessage();
    })
  }
  return (
    <>
     {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>:
    <Dialog
      open={showPopup}
      classes={{
        paper: classes.paper,
      }}
      onKeyDown = {(e)=>  e.key == "Escape" && setshowPopup(false) }
      TransitionComponent={Transition}
      disableRestoreFocus
    >
    <Box textAlign="right" sx={{cursor:"pointer"}}>
          <CancelIcon
            fontSize="large"
            onClick={() => {
              setshowPopup(false)
            }}
          />
        </Box>
      <Box p={3}>
        <DialogTitle textAlign="center">
          <img src="./NewLogoG.png" alt="" width="70%" />
        </DialogTitle>
        <Typography textAlign="center" variant="h5">
        Are you sure you want to delete this account?
        </Typography>

        <Container sx={{ marginTop: "2rem" }}>
            <Container sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    sx={{
                      padding: "0.5rem 2rem",
                      backgroundColor: "#B7273B",
                      fontFamily: "Lusitana",
                      borderRadius: "3rem",
                    }}
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={handleRemoveAccount}
                  >
                    Yes
                  </Button>
                  <Button
                    sx={{
                      marginLeft:"0.5rem",
                      padding: "0.5rem 2rem",
                      backgroundColor: "#B7273B",
                      fontFamily: "Lusitana",
                      borderRadius: "3rem",
                    }}
                    variant="contained"
                    style={{ textTransform: "none" }}
                    onClick={closeAd}
                  >
                    No
                  </Button>
                </Container>
              </Container>
      </Box>
    </Dialog>
}
    </>
  );
};

export default  DeleteAccount;
