import React, { useEffect } from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCard from "../Setting.js/AddCard";
import GiftCard from "../Payment/GiftCard";
import EditIcon from "@mui/icons-material/Edit";
import { handlePlaceOrderWithTime } from "../../Common/utils";
import { setAutoDiscountDetail, setDefaultCardd } from "../../Redux/UserSlice";
import {
  checkUser,
  getCustomizationOption,
  getStoreStartendTime,
  getSavedCardDetail,
  getGivexCardDetail,
  getBalance,
  getGivexDetails,
  makeDefaultCard,
  cardPointePayment,
  ApplyAutomaticCoupon,
} from "../../Api/API";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Avatar, Backdrop, Badge, Card, CircularProgress } from "@mui/material";
import { setPlacedOrder, setTabValue } from "../../Redux/CartSlice";
import {
  setCheckoutFlag,
  setFiveStarIndex,
  setGivexDetail,
  setRemoveReward,
  setIsCheckout,
} from "../../Redux/UserSlice";
import { setGivexPaymentDetail } from "../../Redux/StoreSlice";
import { setFiveStarResponse } from "../../Redux/UserSlice";
import { setFiveStarRewardDetail } from "../../Redux/UserSlice";
import {
  AmPmFormat,
  errorToastMessage,
  handleStoreStartEndTime,
  MenuProps,
  TwentyFourHourFormat,
} from "../../Common/utils";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Divider,
  TextField,
  InputLabel,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import cx from "clsx";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { givexCardPayment } from "../../Api/API";
import Popup from "../Categories/CategoryPopUp";
import Login from "../Login/Login";
import Guest from "../Login/Guest";
import {
  cart,
  setCartData,
  setCartDetailes,
  setCartItemIndexs,
  setCustomizeOptions,
  setMenuServingDetails,
  setOpenPopUps,
  setOrderTotalPrice,
  setPaymentData,
  setPickUpLaterTime,
  setSelectedCupSizes,
  setTitles,
  setEditTitles,
} from "../../Redux/CartSlice";
import { setUpdateCart } from "../../Redux/CartSlice";
import RewardPopUp from "./RewardPopUp";
import {
  ApplyCoupon,
  getTaxes,
  cardPointePaymentByProfileId,
} from "../../Api/API";
import { ClassNames } from "@emotion/react";
import { setDiscountDetail } from "../../Redux/UserSlice";
import { borderRadius, fontSize } from "@mui/system";
import { numberWithCommas } from "../../Common/utils";
import AlertPopup from "../../Common/AlertPopup";
import ButtonLoader from "../../Common/ButtonLoader";
import axios from "axios";
toast.configure();
const useStyle = makeStyles((theme) => ({
  root: {
    paddingTop: "5rem",
  },
  noBorder: {
    border: "none",
  },
  bill: {
    paddingTop: "13vh",
  },
  billOrientation: {
    display: "flex",
    justifyContent: "space-between",
  },
  location: { display: "flex", marginTop: "1rem", marginBottom: "1.4rem" },
  promo: {
    display: "flex",
    marginTop: "2rem",
    justifyContent: "space-between",
  },
  divider: {
    backgroundColor: "#d9e2ee",
    marginBottom: "0.8rem",
    // marginTop:"1rem"
    // backgroundColor: "#E5E5E5",
    // margin: '0 20px',
  },
  btnLogin: {
    borderRadius: 20,
    padding: "0.2rem 3rem",
    backgroundColor: "brown",
    color: "white",
    textTransform: "none",
  },
  btnLoginBack: {
    // borderRadius: 20,
    padding: "0.2rem 1rem",
    backgroundColor: "brown",
    color: "white",
    textTransform: "none",
    marginTop: "1rem",
    marginBottom: "0.2rem",
  },
  btnLoginChekout: {
    borderRadius: 20,
    padding: "0.2rem 6rem",
    backgroundColor: "brown",
    textTransform: "none",
    marginTop: "2rem",
  },
  buttons: { display: "flex", justifyContent: "center", marginTop: "2rem" },
  guest: {
    display: "flex",
    justifyContent: "center",
    marginTop: "0.5rem",
    cursor: "pointer",
    transition: "transform 0.10s ease-in-out",
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  error: {
    color: "#bf1650",
    textAlign: "center",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  errorS: {
    color: "green",
    textAlign: "center",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  name: {
    fontWeight: 600,
    color: "#122740",
    fontFamily: "Barlow, san-serif",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  caption: {
    fontSize: "0.875rem",
    color: "#758392",
    marginTop: -4,
  },

  qty: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const PersonItem = ({
  item,
  listKey,
  setDisc,
  setPromoApply,
  setPromoApplyError,
  setPromoCode,
  setTotalFivestar,
}) => {
  let navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [remove, setRemove] = useState(false);
  const nearestStore = useSelector((state) => state.store.nearestStore);
  const [customizeOption, setCustomizeOption] = useState();
  const [catCustomize, setCatCustomize] = useState([]);
  const [counter, setCounter] = useState(item?.quantity);
  const userDetail = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [displayTitle, setDisplayTitle] = useState("");
  const classes = useStyle();
  const dispatch = useDispatch();
  const promoCode = useSelector((state) => state.user.discountDetail);
  const fiveStarDiscount = useSelector((state) => state.user.fiveStarResponse);
  const cartItem = useSelector((state) => state.cart.cart);

  const index = cartItem?.findIndex(
    (element) =>
      element.id === item?.id && element?.categoryId === item?.categoryId
  );
  let cartDetail = {
    id: item?.id,
    categoryId: item?.categoryId,
    categoryName: item?.categoryName,
    customizationOpt: item?.customizationOpt,
    quantity: Number(counter),
    price: item?.price,
    image: item?.image,
    title: item?.title,
    totalPrice: item?.price * counter,
    btnModifierId: item?.btnModifierId,
    btnModifier: item?.btnModifier,
    selectedOptions: item?.selectedOptions,
    menuServingDetail: item?.menuServingDetail,
    selectedCupSize: item?.selectedCupSize,
    orderItemPrice: item?.orderItemPrice,
    max_calories: 0,
    min_calories: 0,
    itemAliasName: item?.itemAliasName,
  };
  const handleCustomizationCondition = () => {
    if (!promoCode && !fiveStarDiscount) {
      handleCustomization();
    } else {
      setRemove(true);
      setTitle("removeDiscount");
      setDisplayTitle("Editing your cart will remove the discount applied");
    }
  };

  useEffect(() => {
    if (!remove && title == "removeDiscount") {
      handleCustomization();
    }
  }, [remove]);

  const handleCustomization = () => {
    setLoading(true);
    let data = {
      customerId: userDetail?.customer_id
        ? userDetail?.customer_id
        : userDetail?.person_id,
      menuId: item?.id ? item?.id : 0,
      storeId: nearestStore?.id ? nearestStore?.id : 1,
      appType: "web",
    };
    getCustomizationOption(data)
      .then((res) => {
        dispatch(setCustomizeOptions(res?.result[0][0]?.customizationOpt));
        cartDetail.max_calories = res?.result[0][0]?.max_calories;
        cartDetail.itemAliasName = res?.result[0][0]?.itemAliasName;
        cartDetail.min_calories = res?.result[0][0]?.min_calories;
        cartDetail.is_favourite = res?.result[0][0].is_favourite;
        cartDetail.menuServingDetail = res?.result[0][0]?.menu_serving_detail;
        // setCustomizeOption(res?.result[0][0]?.customizationOpt);
        setLoading(false);
        // setOpenPopup(true);
        dispatch(setOpenPopUps(true));
        dispatch(setTitles("editFromCart"));
        dispatch(setEditTitles("FromCart"));
        dispatch(setCartDetailes(cartDetail));
        dispatch(setSelectedCupSizes(""));
        dispatch(setMenuServingDetails(cartDetail?.menuServingDetail));
        dispatch(setCartItemIndexs(listKey));
        navigate(`/customization`);
      })
      .catch((error) => {
        setLoading(false);
        errorToastMessage();
      });
  };

  useEffect(() => {
    if (counter === 0) {
      const updatedCart = [...cartItem];
      updatedCart.splice(listKey, 1);
      dispatch(setUpdateCart(updatedCart));
    } else {
      const item = cartDetail;
      const updatedCart = [...cartItem];
      updatedCart.splice(listKey, 1, item);
      dispatch(setUpdateCart(updatedCart));
    }
  }, [counter]);
  useEffect(() => {
    setCounter(item?.quantity);
    dispatch(setRemoveReward(""));
  }, [cartItem]);

  const handleRemove = () => {
    // dispatch(setRemoveReward(""))
    setRemove(true);
    setTitle("removeItem");
    setDisplayTitle("Are you sure want to remove this item from your cart?");
  };

  const handleRemoveCartItem = () => {
    setRemove(true);
    setTitle("removeItem");
    setDisplayTitle("Are you sure want to remove this item from your cart?");
  };
  // let String = "";
  // item?.selectedOptions?.map((elm) =>
  // String += elm?.modifier_group_name+"-" + elm.option?.map((item)=> item.modifier_name) + `\n`)
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: 1000 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container>
        <Grid item md={3} xs={4} sm={3} lg={3}>
          <div>
            <Badge
              badgeContent="X"
              color="error"
              onClick={handleRemoveCartItem}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{ cursor: "pointer" }}
            >
              <Box
                component="img"
                sx={{
                  height: "6rem",
                  cursor: "default",
                  maxWidth: { lg: "7rem", md: "7rem", sm: "9rem", xs: "6rem" },
                }}
                alt="menuImage"
                src={item?.image ? item?.image : "./logos.png"}
                onClick={"disable"}
              />
              {/* <Avatar
                  alt="menuImage"
                  src={item?.image}
                  sx={{ height: "6rem"}}
                  variant="rounded"
                /> */}
            </Badge>
          </div>
        </Grid>
        <Grid
          item
          md={7}
          xs={5}
          sm={7}
          lg={7}
          display="flex"
          direction="column"
          justifyContent="center"
        >
          <Typography variant="subtitle" color="#4D4D4D" fontWeight="600">
            {item.title}
          </Typography>
          <div style={{ display: "flex", color: "#4D4D4D" }}>
            <Typography style={{ marginRight: "-.37rem" }}>
              {item?.selectedCupSize?.length > 0 ? "" : " "}
            </Typography>
            <Typography style={{ marginLeft: "0.3rem" }}>
              {item?.selectedCupSize[0]?.serving_name}
            </Typography>
          </div>
          <Typography variant="subtitle2" style={{ color: "#797979" }}>
            {item?.selectedOptions?.map(
              (elm, index) =>
                (elm?.option?.length > 0
                  ? elm?.option?.map((item) => {
                      return (
                        " " +
                        item?.modifier_name +
                        (item?.quantity > 1
                          ? " (" + item?.quantity + ") "
                          : " ")
                      );
                    })
                  : " ") +
                (item?.selectedOptions?.length - 1 != index ? "| " : " ")
            )}
          </Typography>
        </Grid>
        <Grid
          item
          md={2}
          xs={3}
          sm={2}
          lg={2}
          display="flex"
          direction="column"
          justifyContent="center"
          alignItems="center"
          // marginLeft="20px"
        >
          <div className="btn-group" role="group" aria-label="First group">
            <button
              type="button"
              style={{
                borderTopLeftRadius: "1rem",
                borderBottomLeftRadius: "1rem",
              }}
              disabled={counter <= 0}
              onClick={() => {
                if (counter === 1) {
                  handleRemove();
                } else {
                  setCounter(counter - 1);
                }
              }}
              className="btn btn-outline-dark btn-sm"
            >
              -
            </button>
            <button type="button" className="btn btn-outline-dark btn-sm">
              {counter}
            </button>
            <button
              type="button"
              style={{
                borderTopRightRadius: "1rem",
                borderBottomRightRadius: "1rem",
              }}
              onClick={() => {
                setCounter(counter + 1);
              }}
              className="btn btn-outline-dark btn-sm"
            >
              +
            </button>
          </div>
          <div style={{ paddingTop: "0.15rem" }}>
            {/* {item?.customizationOpt?.length > 0 && ( */}
            <button
              onClick={handleCustomizationCondition}
              type="button"
              className="btn btn-dark"
              style={{
                padding: "0.1rem 0.75rem",
                backgroundColor: "#FFFFFF",
                color: "#C60734",
                fontSize: ".8rem",
                borderRadius: ".5rem",
              }}
              variant={"contained"}
            >
              Edit
            </button>
            {/* )} */}
          </div>
          <div style={{ paddingTop: "0.15rem" }}>{`$${numberWithCommas(
            Number(item?.orderItemPrice * item?.quantity).toFixed(2)
          )}`}</div>
        </Grid>
      </Grid>
      <div>
        <AlertPopup
          open={remove}
          setOpen={setRemove}
          setCounter={title == "removeDiscount" ? setTitle : setCounter}
          setState={setDisc}
          setCupSizeState={setPromoApply}
          setFavState={setPromoCode}
          setCurrentLocation={setPromoApplyError}
          setTotalFivestar={setTotalFivestar}
          title={title}
          buttonType1={true}
          buttonType2={true}
          displayTitle={displayTitle}
        />
      </div>
    </>
  );
};

const Cart = () => {
  const classes = useStyle();
  const [time, setTime] = React.useState();
  const [totalFivestar, setTotalFivestar] = React.useState();
  const [fivestarDiscount, setfivestarDiscount] = useState(totalFivestar);
  const [removeFivestarReward, setRemoveFiveStarRewards] = useState();
  const handleChange = (event) => {
    setTime(event.target.value);
  };
  let navigate = useNavigate();
  const tax = useSelector((state) => state.cart.taxes);
  const taxes = tax?.tax_rate;
  const webFee = tax?.web_fee;
  const serviceType = tax?.Service_fee;
  const [remove, setRemove] = useState(false);
  const cartItem = useSelector((state) => state.cart.cart);
  const cartData = useSelector((state) => state.cart.cartData)
  const fiveStarResponse = useSelector((state) => state.user.fiveStarResponse);
  const dispatch = useDispatch();
  const orderTotalPrice = useSelector((state) => state.cart.orderTotalPrice);
  const pickUpLaterTime = useSelector((state) => state.cart.pickUpLaterTime);
  const isLogin = useSelector((state) => state.user.isLogin);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const user = useSelector((state) => state.user.user);
  const storeName = useSelector((state) => state.store.nearestStore);
  const [Iframe, setIframe] = useState(false);
  const [savedCards, setSavedCard] = useState([]);
  const [savedGivxCards, setGivxSavedCard] = useState(false);
  const [savedGiftCards, setSavedGiftCard] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const userDetail = useSelector((state) => state.user.user);
  const defaultCard = useSelector((state) => state.user.defaultCardd);
  // const [defaultCard, setDefaultCard] = useState();
  const [GiftCardSelect, setGiftCardSelect] = useState(false);
  const [GIFTCard, setGIFTCard] = useState();
  const [showCard, setshowCard] = useState(false);
  const [showText, setShowText] = useState(false);
  const [page, setpage] = useState(false);
  const [remainingPayment, setRemainingPayment] = useState();
  const [giftCardAmount, setGiftCardAmount] = useState(0);
  const [giftCard, setGiftCard] = useState(false);
  const [givexCardDetails, setGivexCardDetails] = useState(true);
  const [boolBalanceFetched, setBoolBalanceFetched] = useState(false);
  const [title, setTitle] = useState("");
  const [cardDataSave, setCartDataSave] = useState();
  const [pickUpLaterPopup, setPickUpLaterPopup] = useState(false);
  const [checkOutbuttonDisabled, setChecoutButtonDisabled] = useState(false);
  const [combineDiscount, setCombineDiscount] = useState("");
  const [combineDiscount1, setCombineDiscount1] = useState("");
  const [showcombineDiscountPopup, setShowcombineDiscountPopup] =
    useState(false);

    const [showCombinePopUp, setShowCombinePopUp] = useState(false);
  const [giftCardPaymentDetail, setGiftCardPaymentDetail] = useState({
    cardNumber: "",
    payment: "",
    requestId: "",
  });
  const [availableBalance, setAvailableBalance] = useState();
  const [givexCardExist, setGivexCardExist] = useState();
  const [timerange, setTimeRange] = useState([
    "00:00",
    "00:15",
    "00:30",
    "00:45",
    "01:00",
    "01:15",
    "01:30",
    "01:45",
    "02:00",
    "02:15",
    "02:30",
    "02:45",
    "03:00",
    "03:15",
    "03:30",
    "03:45",
    "04:00",
    "04:15",
    "04:30",
    "04:45",
    "05:00",
    "05:15",
    "05:30",
    "05:45",
    "06:00",
    "06:15",
    "06:30",
    "06:45",
    "07:00",
    "07:15",
    "07:30",
    "07:45",
    "08:00",
    "08:15",
    "08:30",
    "08:45",
    "09:00",
    "09:15",
    "09:30",
    "09:45",
    "10:00",
    "10:15",
    "10:30",
    "10:45",
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
    "12:30",
    "12:45",
    "13:00",
    "13:15",
    "13:30",
    "13:45",
    "14:00",
    "14:15",
    "14:30",
    "14:45",
    "15:00",
    "15:15",
    "15:30",
    "15:45",
    "16:00",
    "16:15",
    "16:30",
    "16:45",
    "17:00",
    "17:15",
    "17:30",
    "17:45",
    "18:00",
    "18:15",
    "18:30",
    "18:45",
    "19:00",
    "19:15",
    "19:30",
    "19:45",
    "20:00",
    "20:15",
    "20:30",
    "20:45",
    "21:00",
    "21:15",
    "21:30",
    "21:45",
    "22:00",
    "22:15",
    "22:30",
    "22:45",
    "23:00",
    "23:15",
    "23:30",
    "23:45",
    "24:00",
  ]);
  const [pickUpTime, setPickUpTime] = useState([]);
  useEffect(() => {
    if (storeName?.id) {
      getStoreStartendTime(storeName?.id)
        .then((res) => {
          let picupLater = handleStoreStartEndTime(res, storeName?.time_zone);
          // setPicupLater(status);
          // setPicupLater(handleStoreStartEndTime(res));
          const d = new Date();
          let timeZoneDate = new Date().toLocaleString("en-US", {
            timeZone: storeName?.time_zone,
          });
          let timeZoneDateHours =
            new Date(timeZoneDate)?.getHours() > 9
              ? new Date(timeZoneDate)?.getHours()
              : "0" + new Date(timeZoneDate)?.getHours();
          let timeZoneDateMinutes =
            new Date(timeZoneDate)?.getMinutes() > 9
              ? new Date(timeZoneDate)?.getMinutes()
              : "0" + new Date(timeZoneDate)?.getMinutes();
          let timeZoneDateMonth = new Date(timeZoneDate)?.getMonth();
          let timeZoneDateFullYear = new Date(timeZoneDate)?.getFullYear();
          let timeZoneDateDay = new Date(timeZoneDate)?.getDate();
          let timeZoneWeekDay = new Date(timeZoneDate)?.getDay();
          // let minTime = new Date().toTimeString().slice(0, 5);
          let minTime = timeZoneDateHours + ":" + timeZoneDateMinutes;
          let initialTime = picupLater?.status[timeZoneWeekDay]?.Start?.slice(
            0,
            5
          );
          let maxTime = picupLater?.status[timeZoneWeekDay]?.Close?.slice(0, 5);
          let range = timerange?.filter(
            (item) =>
              item > minTime &&
              item < maxTime &&
              new Date(
                timeZoneDateFullYear,
                timeZoneDateMonth,
                timeZoneDateDay,
                Number(maxTime?.slice(0, 2)),
                Number(maxTime?.slice(3))
              ) -
                new Date(
                  timeZoneDateFullYear,
                  timeZoneDateMonth,
                  timeZoneDateDay,
                  Number(item?.slice(0, 2)),
                  Number(item?.slice(3))
                ) >=
                900000 &&
              new Date(
                timeZoneDateFullYear,
                timeZoneDateMonth,
                timeZoneDateDay,
                Number(item?.slice(0, 2)),
                Number(item?.slice(3))
              ) -
                new Date(
                  timeZoneDateFullYear,
                  timeZoneDateMonth,
                  timeZoneDateDay,
                  timeZoneDateHours,
                  timeZoneDateMinutes
                ) >=
                900000 * 2 &&
              new Date(
                timeZoneDateFullYear,
                timeZoneDateMonth,
                timeZoneDateDay,
                Number(item?.slice(0, 2)),
                Number(item?.slice(3))
              ) -
                new Date(
                  timeZoneDateFullYear,
                  timeZoneDateMonth,
                  timeZoneDateDay,
                  Number(initialTime?.slice(0, 2)),
                  Number(initialTime?.slice(3))
                ) >=
                900000 * 3
          );

          let updatedRange = range?.map((time) => {
            return AmPmFormat(time);
          });
          setPickUpTime(updatedRange);
        })
        .catch((e) => {
          errorToastMessage();
        });
    }
  }, [remove]);
  const isGivexData = useSelector((state) => state.user.givexDetail);
  const location = useLocation();
  const newClasses = useStyle();
  const [showAd, setShowAd] = useState(false);

  const [showGuest, setshowGuest] = useState(false);
  const [showRewards, setshowRewards] = useState(false);
  const [picupDate, setPickupDate] = useState();
  const [pickupType, setPickupType] = useState("asap");
  const fiveStarRewardDetail = useSelector(
    (state) => state.user.fiveStarRewardDetail
  );
  const fiveStarIndex = useSelector((state) => state.user.fiveStarIndex);
  const [promoCode, setPromoCode] = useState("");
  const [promoApply, setPromoApply] = useState();
  const [promoApplyError, setPromoApplyError] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [disc, setDisc] = useState(0);
  const [autoDisc, setAutoDisc] = useState(0);
  const [autoPromoApply, setAutoPromoApply] = useState();
  const [loader, setLoader] = useState(false);
  const [geenCheckBox, setGreenCheckbox] = useState(false);

  const [selectedGiftCard, setSelectedGiftCard] = useState(false);
  let totalItem = cartItem?.map((elm) => {
    return elm?.quantity;
  });
  let totalCartItem = totalItem?.reduce((a, b) => a + b, 0);

  let AllItemPrice = 0;
  if (cartItem?.length) {
    AllItemPrice = cartItem
      .map((item) => item?.orderItemPrice * item?.quantity)
      .reduce((previousValue, currentValue) => previousValue + currentValue);
  }

  let taxValue =
    totalFivestar && promoApply
      ? Number(
          Number(
            (taxes *
              (AllItemPrice - (totalFivestar + Number(disc.toFixed(2))+Number(autoDisc.toFixed(2))) > 0
                ? AllItemPrice - (totalFivestar + Number(disc.toFixed(2)+Number(autoDisc.toFixed(2))))
                : 0.0)) /
              100 
          ).toFixed(2)
        )
      : promoApply
      ? Number(
          Number(
            (taxes *
              (AllItemPrice - (Number(disc.toFixed(2))+Number(autoDisc.toFixed(2))) > 0
                ? AllItemPrice - (Number(disc.toFixed(2))+Number(autoDisc.toFixed(2)))
                : 0.0)) /
              100 
          ).toFixed(2)
        )
      : totalFivestar
      ? Number(
          Number(
            (taxes *
              (AllItemPrice - (totalFivestar+Number(autoDisc.toFixed(2))) > 0
                ? AllItemPrice - (totalFivestar+Number(autoDisc.toFixed(2)))
                : 0.0)) /
              100 
          ).toFixed(2)
        )
      : Number(Number((taxes * (AllItemPrice-Number(autoDisc.toFixed(2)))) / 100).toFixed(2));

  let totalAmount =
    totalFivestar && promoApply
      ? Number(
          Number(
            (AllItemPrice - (totalFivestar + Number(disc.toFixed(2))+Number(autoDisc.toFixed(2))) > 0
              ? AllItemPrice - (totalFivestar + Number(disc.toFixed(2))+Number(autoDisc.toFixed(2)))
              : 0.0) + taxValue
          ).toFixed(2)
        )
      : promoApply
      ? Number(
          Number(
            AllItemPrice - (Number(disc.toFixed(2))+Number(autoDisc.toFixed(2))) > 0
              ? AllItemPrice - (Number(disc.toFixed(2))+Number(autoDisc.toFixed(2))) + taxValue
              : 0.0
          ).toFixed(2)
        )
      : totalFivestar
      ? Number(
          Number(
            AllItemPrice - (totalFivestar+Number(autoDisc.toFixed(2))) > 0
              ? AllItemPrice - (totalFivestar+Number(autoDisc.toFixed(2))) + taxValue
              : 0.0
          ).toFixed(2)
        )
      : Number(Number(AllItemPrice-Number(autoDisc.toFixed(2))+ taxValue).toFixed(2));
  let discountTotal =
    totalFivestar && promoApply
      ? Number(totalFivestar + (Number(autoDisc?.toFixed(2))+Number(disc?.toFixed(2)))).toFixed(2)
      : promoApply
      ? Number(autoDisc?.toFixed(2))+Number(disc?.toFixed(2))
      : totalFivestar
      ? Number(totalFivestar)+Number(autoDisc.toFixed(2))
      : 0.0+Number(autoDisc).toFixed(2);
  const handlePriceCalculation = (sortedCart, promoApply) => {
    let selectedItemPrice = 0;
    let drinkLimit = promoApply?.disArr?.discount_drink_limit;
    for (let i = 0; i < sortedCart.length && drinkLimit > 0; i++) {
      if (sortedCart[i].quantity > drinkLimit) {
        selectedItemPrice =
          selectedItemPrice + sortedCart[i].orderItemPrice * drinkLimit;
        break;
      } else if (sortedCart[i].quantity <= drinkLimit) {
        drinkLimit = drinkLimit - sortedCart[i].quantity;
        selectedItemPrice =
          selectedItemPrice +
          sortedCart[i].orderItemPrice * sortedCart[i].quantity;
      }
    }
    return selectedItemPrice;
  };
  const handleMenuCheck = (
    selectedServingItem,
    promoApply,
    totalCartItem,
    disc,
    response
  ) => {
    if (!promoApply?.disArr?.menuId || promoApply?.disArr?.menuId == "All") {
      if (promoApply?.disArr?.quantity <= totalCartItem) {
        if (promoApply?.disArr?.discountType == "Percentage") {
          let selectedItemPrice = handlePriceCalculation(
            selectedServingItem,
            promoApply
          );

          disc = (selectedItemPrice * disc.toFixed(2)) / 100;
          if (disc <= AllItemPrice - (totalFivestar ? totalFivestar : 0)) {
            setPromoApplyError("Promo Code applied succesfully");
            dispatch(setDiscountDetail(response));
            return disc;
          } else {
            setPromoApplyError("Promo Code applied succesfully");
            return disc;
          }
        } else if (promoApply?.disArr?.discountType === "Amount") {
          if (promoApply?.disArr?.discount_drink_limit <= totalCartItem) {
            disc = disc * promoApply?.disArr?.discount_drink_limit;

            if (disc <= AllItemPrice - (totalFivestar ? totalFivestar : 0)) {
              setPromoApplyError("Promo Code applied succesfully");
              dispatch(setDiscountDetail(response));
              return disc;
            } else {
              setPromoApplyError("Promo Code applied succesfully");
              return disc;
            }
          } else {
            disc = disc * totalCartItem;
            if (disc <= AllItemPrice - (totalFivestar ? totalFivestar : 0)) {
              setPromoApplyError("Promo Code applied succesfully");
              dispatch(setDiscountDetail(response));
              return disc;
            } else {
              setPromoApplyError("Promo Code applied succesfully");
              return disc;
            }
          }
        }
      } else {
        setPromoApplyError("Please add more drinks to get discount");
        disc = 0;
        return disc;
      }
    } else {
      let promoMenus = promoApply?.disArr?.menuId.split(",");
      let selectedMenuItem = promoMenus
        .map((menu) =>
          selectedServingItem.filter((e) => Number(e.id) == Number(menu))
        )
        .flat(Infinity);
      let menuQuantity = selectedMenuItem?.map((elm) => {
        return elm?.quantity;
      });
      let totalCartItemBYmenu = menuQuantity?.reduce((a, b) => a + b, 0);
      if (totalCartItemBYmenu != 0) {
        if (promoApply?.disArr?.quantity <= totalCartItemBYmenu) {
          if (promoApply?.disArr?.discountType == "Percentage") {
            let selectedItemPrice = handlePriceCalculation(
              selectedMenuItem,
              promoApply
            );

            disc = (selectedItemPrice * disc.toFixed(2)) / 100;
            if (disc <= AllItemPrice - (totalFivestar ? totalFivestar : 0)) {
              setPromoApplyError("Promo Code applied succesfully");
              dispatch(setDiscountDetail(response));
              return disc;
            } else {
              setPromoApplyError("Promo Code applied succesfully");
              return disc;
            }
          } else if (promoApply?.disArr?.discountType === "Amount") {
            if (
              promoApply?.disArr?.discount_drink_limit <= totalCartItemBYmenu
            ) {
              disc = disc * promoApply?.disArr?.discount_drink_limit;
              if (disc <= AllItemPrice - (totalFivestar ? totalFivestar : 0)) {
                setPromoApplyError("Promo Code applied succesfully");
                dispatch(setDiscountDetail(response));
                return disc;
              } else {
                setPromoApplyError("Promo Code applied succesfully");
                return disc;
              }
            } else {
              disc = disc * totalCartItemBYmenu;
              if (disc <= AllItemPrice - (totalFivestar ? totalFivestar : 0)) {
                setPromoApplyError("Promo Code applied succesfully");
                dispatch(setDiscountDetail(response));
                return disc;
              } else {
                setPromoApplyError("Promo Code applied succesfully");
                return disc;
              }
            }
          }
        } else {
          setPromoApplyError("Please add more drinks to get discount");
          disc = 0;
          return disc;
        }
      } else {
        setPromoApplyError("Promo code is not applicable for selected drinks");
        disc = 0;
        return disc;
      }
    }
  };

  const handlePromocodeDiscount = (promoApply, response) => {
    let disc = promoApply?.disArr?.discountRate;

    if (promoApply?.disArr?.discountOn == true) {
      if (promoApply?.disArr?.discountType === "Percentage") {
        let sortedCart = [...cartItem];
        sortedCart.sort((a, b) => a.orderItemPrice - b.orderItemPrice);
        let selectedItemPrice = handlePriceCalculation(sortedCart, promoApply);

        disc = (selectedItemPrice * disc.toFixed(2)) / 100;
        if (disc <= AllItemPrice - (totalFivestar ? totalFivestar : 0)) {
          setPromoApplyError("Promo Code applied succesfully");
          dispatch(setDiscountDetail(response));
          return disc;
        } else {
          setPromoApplyError("Promo Code applied succesfully");
          return disc;
        }
      } else if (promoApply?.disArr?.discountType === "Amount") {
        if (promoApply?.disArr?.discount_drink_limit <= totalCartItem) {
          disc = disc * promoApply?.disArr?.discount_drink_limit;

          if (disc <= AllItemPrice - (totalFivestar ? totalFivestar : 0)) {
            setPromoApplyError("Promo Code applied succesfully");
            dispatch(setDiscountDetail(response));
            return disc;
          } else {
            setPromoApplyError("Promo Code applied succesfully");
            return disc;
          }
        } else {
          disc = disc * totalCartItem;

          if (disc <= AllItemPrice - (totalFivestar ? totalFivestar : 0)) {
            setPromoApplyError("Promo Code applied succesfully");
            dispatch(setDiscountDetail(response));
            return disc;
          } else {
            setPromoApplyError("Promo Code applied succesfully");
            return disc;
          }
        }
      }
    } else {
      let sortedCart = [...cartItem];
      sortedCart.sort((a, b) => a.orderItemPrice - b.orderItemPrice);
      if (promoApply?.disArr?.servingSizesId) {
        let promoServings = promoApply?.disArr?.servingSizesId.split(",");
        let selectedServingItem = promoServings
          .map((serving) =>
            sortedCart.filter(
              (e) =>
                Number(e.selectedCupSize[0].serving_actual_id) ==
                Number(serving)
            )
          )
          .flat(Infinity);

        if (
          !promoApply?.disArr?.categoryId ||
          promoApply?.disArr?.categoryId == "All"
        ) {
          return handleMenuCheck(
            selectedServingItem,
            promoApply,
            totalCartItem,
            disc,
            response
          );
        } else {
          let promoCategory = promoApply?.disArr?.categoryId.split(",");
          let selectedCategoryItems = promoCategory
            .map((category) =>
              selectedServingItem.filter(
                (e) => Number(e.categoryId) == Number(category)
              )
            )
            .flat(Infinity);
          let categoryQuantity = selectedCategoryItems?.map((elm) => {
            return elm?.quantity;
          });
          let totalCartItemByQuantity = categoryQuantity?.reduce(
            (a, b) => a + b,
            0
          );
          return handleMenuCheck(
            selectedCategoryItems,
            promoApply,
            totalCartItemByQuantity,
            disc,
            response
          );
        }
      }
    }
  };

  useEffect(() => {
    let pickupDate;
    if (pickupType == "asap") {
      pickupDate = new Date().toISOString();
    }
    let data = {
      subTotal: AllItemPrice,
      taxRate: taxes,
      webFee: webFee,
      totalDiscount:
        totalFivestar && promoApply
          ? Number(totalFivestar + Number(disc.toFixed(2))).toFixed(2)
          : promoApply
          ? Number(disc.toFixed(2)).toFixed(2)
          : totalFivestar
          ? Number(totalFivestar).toFixed(2)
          : 0.0,
      taxValue: taxValue,
      webFeeValue: 0,

      total: totalAmount,
      promoCode: promoCode ? promoCode : "",
      pickupType: pickupType ? pickupType : "",
      pickupDate: pickupDate ? pickupDate : "",
    };
    dispatch(setCartData(data));
  }, [totalCartItem, AllItemPrice]);

  const handleCreditCardPayment = (data) => {
    setOpen(true);
    if(data.profileId){
      cardPointePaymentByProfileId(data)
      .then((res) => {
        setshowCard(false);
        // setCreditPopup(false);
        if (res.responseCode == 0) {
          let paymentDetails = {
            amount: "0",
            amt: "0",
            auth_code: "",
            calculationAmt: "",
            card_last_no: 0,
            givex_code: 0,
            givex_num: 0,
            givex_trans_num: "",
            method: res?.response?.paymentMethod
              ? res?.response?.paymentMethod
              : "credit_card",
            payId: res?.response?.savedCardId ? res?.response?.savedCardId : 0,
            payType: res?.response?.paymentMethod
              ? res?.response?.paymentMethod
              : "credit_card",
            paymentMethod: res?.response?.paymentMethod
              ? res?.response?.paymentMethod
              : "credit_card",
            trans_num: 0,
          };
          let checkoutPaymentMethodArray = [];
          checkoutPaymentMethodArray.push(paymentDetails);

          let cardRefund = {
            type: "credit_card",
            requestId: data?.requestId,
            transactionRefNumber: res?.response?.paymentRefId,
            storeId: storeName?.id,
          };
          dispatch(setGivexPaymentDetail(cardRefund));
          setOpen(false);
          dispatch(setPaymentData(checkoutPaymentMethodArray));

          let path = `/payment/success?deviceType=web&paymentMethod1=${
            res?.response?.paymentMethod
              ? res?.response?.paymentMethod
              : "credit_card"
          }&payId1=${
            res?.response?.savedCardId ? res?.response?.savedCardId : 0
          }`;
          navigate(path);

          dispatch(setCheckoutFlag(true));
          dispatch(setIsCheckout(true));
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Duplicate Request Id"
        ) {
          setOpen(false);
          toast.error("Server error ,Please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          window.location.reload();
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Invalid card"
        ) {
          setOpen(false);
          toast.error("Please check your Card Number", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "The Store does not accept this card"
        ) {
          setOpen(false);
          toast.error("oops something went wrong please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Expired card"
        ) {
          setOpen(false);
          toast.error("Your card has been expired", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Service not allowed"
        ) {
          setOpen(false);
          toast.error("Your card Service not allowed", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        } else {
          setOpen(false);
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        }
      })
      .catch((err) => {
        setOpen(false);
        console.log(err.message);
        if (err) {
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        }
      });
    }else{
      setOpen(false)
     let cardData = {
        accountNumber: cardDataSave.account,
        amount: data.amount,
        cardExpiry: cardDataSave.cardExpiry,
        clientId: data.clientId,
        currency: data.currency,
        customerIdentifier: cardDataSave.customerName,
        customerName: cardDataSave.customerName,
        cvv: data.cvv,
        description:cardDataSave.customerName,
        deviceInfo: "",
        method: data.method,
        requestId: data.requestId,
        saveCard: 0,
        surcharge: 0,
        zipCode: data.zipCode,
        storeId: storeName?.id ? storeName?.id : 1,
      }
//         axios.post('https://api-staging-orders.gongchausa.com/gongcha/cardPointe/makePayment',cardData
//     , {
//     auth: {
//       username: "gongchausa.staging.payment",
//       password: "D5g#wwl%6pL@1wmdh!4k8z^5s",
//     },
//   }
// )
      axios.post('https://api.gongchausa.com/gongcha/cardPointe/makePayment',cardData, {
        auth: {
          username: "gongchausa.prod.payment",
          password: "D5g#fvl%6pL@1wdddh!4k8z^5s",
        },
      })
      .then((res) => {
      let  ress =res?.data
        setshowCard(false);
        // setCreditPopup(false);
        if (ress?.responseCode === 0) {
          let paymentDetails = {
            amount: "0",
            amt: "0",
            auth_code: "",
            calculationAmt: "",
            card_last_no: 0,
            givex_code: 0,
            givex_num: 0,
            givex_trans_num: "",
            method: ress?.response?.paymentMethod
              ? ress?.response?.paymentMethod
              : "credit_card",
            payId: ress?.response?.savedCardId ? ress?.response?.savedCardId : 0,
            payType: ress?.response?.paymentMethod
              ? ress?.response?.paymentMethod
              : "credit_card",
            paymentMethod: ress?.response?.paymentMethod
              ? ress?.response?.paymentMethod
              : "credit_card",
            trans_num: 0,
          };
          let checkoutPaymentMethodArray = [];
          checkoutPaymentMethodArray.push(paymentDetails);

          let cardRefund = {
            type: "credit_card",
            requestId: data?.requestId,
            transactionRefNumber: ress?.response?.paymentRefId,
            storeId: storeName?.id,
          };
          dispatch(setGivexPaymentDetail(cardRefund));
          setOpen(false);
          dispatch(setPaymentData(checkoutPaymentMethodArray));

          let path = `/payment/success?deviceType=web&paymentMethod1=${
            ress?.response?.paymentMethod
              ? ress?.response?.paymentMethod
              : "credit_card"
          }&payId1=${
            ress?.response?.savedCardId ? ress?.response?.savedCardId : 0
          }`;
          navigate(path);

          dispatch(setCheckoutFlag(true));
          dispatch(setIsCheckout(true));
        } else if (
          ress?.responseCode === 1 &&
          ress?.responseMessage === "Duplicate Request Id"
        ) {
          setOpen(false);
          toast.error("Server error ,Please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
          window.location.reload();
        } else if (
          ress?.responseCode === 1 &&
          ress?.responseMessage === "Invalid card"
        ) {
          setOpen(false);
          toast.error("Please check your Card Number", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        } else if (
          ress?.responseCode === 1 &&
          ress?.responseMessage === "The Store does not accept this card"
        ) {
          setOpen(false);
          toast.error("oops something went wrong please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        } else if (
          ress?.responseCode === 1 &&
          ress?.responseMessage === "Expired card"
        ) {
          setOpen(false);
          toast.error("Your card has been expired", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        } else if (
          ress?.responseCode === 1 &&
          ress?.responseMessage === "Service not allowed"
        ) {
          setOpen(false);
          toast.error("Your card Service not allowed", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        } else {
          setOpen(false);
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        }
      })
      .catch((err) => {
        setOpen(false);
        console.log(err.message);
        if (err) {
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        }
      });
    }
   
  };

  const handleGivexPayment = (givexData) => {
    setOpen(true);
    givexCardPayment(givexData)
      .then((res) => {
        if (res.responseCode == 0 && res.responseMessage == "Payment Success") {
          dispatch(setPlacedOrder(""));
          let paymentDetails = {
            amount: "0",
            amt: "0",
            auth_code: "",
            calculationAmt: "",
            card_last_no: 0,
            givex_code: 0,
            givex_num: 0,
            givex_trans_num: "",
            method: "gift_card",
            payId: res?.response?.savedId,
            payType: res?.response?.paymentMethod,
            paymentMethod: "gift_card",
            trans_num: 0,
          };
          let checkoutPaymentMethodArray = [];
          checkoutPaymentMethodArray.push(paymentDetails);
          let givexDetailForRefund = {
            type: "givex",
            cardNumber: giftCardPaymentDetail.cardNumber,
            clientId: "AIPRUS",
            description: "demo payment",
            originalRequestId: givexData?.requestId,
            requestId: givexData?.requestId,
            transactionRefNumber: res?.response?.transactionRefNumber,
            storeId: storeName?.id,
          };
          dispatch(setGivexPaymentDetail(givexDetailForRefund));
          setOpen(false);
          dispatch(setPaymentData(checkoutPaymentMethodArray));
          dispatch(setCheckoutFlag(true));
          dispatch(setIsCheckout(true));
          let path = `/payment/success?deviceType=web&paymentMethod2=${res?.response?.paymentMethod}&payId2=${res?.response?.savedId}`;
          navigate(path);
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Duplicate Request Id"
        ) {
          setOpen(false);
          toast.error("please refresh your page", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Cert not exist"
        ) {
          setOpen(false);
          toast.error("Please check your card number again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Invalid user ID/pswd"
        ) {
          setOpen(false);
          toast.error("oops something went wrong please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Duplicate Request Id"
        ) {
          setOpen(false);
          toast.error("oops something went wrong please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          window.location.reload();
        } else {
          setOpen(false);
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        }
      })
      .catch((err) => {
        setOpen(false);
        console.log(err.message);
      });
  };

  const handleChangeCard = (event) => {
    let newDate = new Date();
    let givexData = {
      amount: 0,
      cardNumber: event.target.value,
      clientId: "AIPRUS",
      customerIdentifier: user?.phone_number
        ? user?.phone_number
        : user?.email
        ? user?.email
        : "Guest@gmail.com",
      customerName: user?.first_name
        ? user?.first_name + " " + user?.last_name
        : "Guest",
      description: "demo payment",
      requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))
        ?.toString()
        ?.replace(/[T.Z:_-]/g, ""),
      storeId: storeName?.id ? storeName?.id : 0,
      surcharge: 0,
    };

    if (event.target.value.length > 8 || event.target.value == "giftcard") {
      setSelectedGiftCard(true);
      getBalance(givexData).then((ress) => {
        // setSelectedGiftCard(true)
        if (
          ress.responseCode === 0 &&
          ress.responseMessage === "Get Balance Successfully"
        ) {
          setAvailableBalance(ress?.response?.balance);
        }
      });
      setSavedGiftCard(event.target.value);
      setGiftCardSelect(true);
      dispatch(setDefaultCardd());

      setGIFTCard("giftcard");
      setGiftCardAmount(totalAmount);
      setGiftCardPaymentDetail({
        cardNumber: event.target.value,
        payment: totalAmount,
        requestId: (
          newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
        )
          ?.toString()
          ?.replace(/[T.Z:_-]/g, ""),
      });
      setShowText(false);
    } else if (
      event.target.value != "giftcard" &&
      event.target.value.length == 4
    ) {
      setShowText(false);

      let selectedCard = event.target.value;
      let card = savedCards.filter((e) => e.lastFourCardDigit == selectedCard);
      makeDefaultCard(
        card[0]?.customerId,
        card[0]?.cardType,
        card[0]?.multiUseToken
      )
        .then((response) => {
          dispatch(setDefaultCardd(event.target.value));
        })
        .catch((error) => {
          console.log(error.message);
        });
      setSelectedGiftCard(false);
    }
    // else if (event.target.value == "giftcard") {
    //   setGIFTCard("giftcard");
    //   setShowText(false);
    // }
  };
  const routeChange = () => {
    let pickupDate;
    let formattedPickUpLaterDate;
    let weekDayArray = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    let weekDay;
    if (pickupType == "asap") {
      dispatch(setPickUpLaterTime(""));
      pickupDate = new Date().toISOString();
    }
    if (pickupType == "later") {
      dispatch(setPickUpLaterTime(time));
      let y = time.slice(time.length - 2, time.length);
      let newdate = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: storeName?.time_zone,
        })
      );
      let date =
        new Date(newdate)?.getDate().toString().length > 1
          ? new Date(newdate)?.getDate()
          : "0" + new Date(newdate)?.getDate();
      let month =
        new Date(newdate)?.getMonth().toString().length > 1
          ? new Date(newdate)?.getMonth() + 1
          : "0" + (new Date(newdate)?.getMonth() + 1);
      let year = new Date(newdate)?.getFullYear();
      let second =
        new Date(newdate)?.getSeconds().toString().length > 1
          ? new Date(newdate)?.getSeconds()
          : "0" + new Date(newdate)?.getSeconds();
      let day = new Date(newdate)?.getDay();
      weekDay = weekDayArray[day];
      let formattedDate;
      let modifiedTime = time;
      //  `${year}-${month}-${date}T`;
      if (y == "AM") {
        if (time?.length != 8) {
          modifiedTime = "0" + time;
        }
        formattedDate = `${modifiedTime.slice(0, 2)}:${modifiedTime.slice(
          modifiedTime.length - 5,
          modifiedTime.length - 3
        )}:${second}:000Z`;
        newdate.setHours(Number(modifiedTime.slice(0, 2)));
        newdate.setMinutes(
          Number(
            modifiedTime.slice(modifiedTime.length - 5, modifiedTime.length - 2)
          )
        );
        pickupDate = newdate.toISOString();
        formattedPickUpLaterDate = formattedDate;
      } else {
        let Time;
        if (time?.length != 8) {
          modifiedTime = "0" + time;
        }
        Time =
          Number(modifiedTime.slice(0, 2)) + 12 == 24
            ? 12
            : Number(modifiedTime.slice(0, 2)) + 12;
        newdate.setHours(Time);
        newdate.setMinutes(
          modifiedTime.slice(modifiedTime.length - 5, modifiedTime.length - 2)
        );
        formattedDate = `${Time}:${modifiedTime.slice(
          modifiedTime.length - 5,
          modifiedTime.length - 3
        )}:${second}:000Z`;
        pickupDate = newdate.toISOString();
        formattedPickUpLaterDate = formattedDate;
      }
    }
    let data = {
      subTotal: AllItemPrice,
      taxRate: taxes,
      webFee: webFee,
      totalDiscount:
        totalFivestar && promoApply
          ? Number(totalFivestar + Number(disc.toFixed(2))).toFixed(2)
          : promoApply
          ? Number(disc.toFixed(2)).toFixed(2)
          : totalFivestar
          ? Number(totalFivestar).toFixed(2)
          : 0.0,
      promoDiscount: promoApply ? Number(disc.toFixed(2)).toString(): Number(autoDisc.toFixed(2)).toString(),
      taxValue: taxValue,
      webFeeValue: 0,
      total: totalAmount,
      promoCode: promoCode ? promoCode : "",
      pickupType: pickupType ? pickupType : "",
      pickupDate: pickupDate ? pickupDate : "",
      formattedDate: formattedPickUpLaterDate,
      weekDay: weekDay,
    };
    let paymentDetails = [
      {
        amount: "0",
        amt: "0",
        auth_code: "",
        calculationAmt: "",
        card_last_no: 0,
        givex_code: 0,
        givex_num: 0,
        givex_trans_num: "",
        method: "Loyalty",
        payId: -1,
        payType: "zero_payment",
        paymentMethod: "zero_payment",
        trans_num: 0,
      },
    ];
    if (data.total == 0) {
      dispatch(setOrderTotalPrice(0));
      data.taxValue = 0;
      data.webFeeValue = 0;
      dispatch(setPaymentData(paymentDetails));
      dispatch(setCartData(data));
      dispatch(setCheckoutFlag(true));
      dispatch(setIsCheckout(true));
      let path = "/checkout";
      navigate({
        pathname: path,
      });
    } else if (GIFTCard && giftCardAmount != 0 && selectedGiftCard == true) {
      dispatch(setCartData(data));
      let newDate = new Date();
      let amount = Number(totalAmount).toFixed(2);
      let givexData = {
        amount: amount ? amount : Number(isGivexData?.payment),
        cardNumber: giftCardPaymentDetail.cardNumber,
        clientId: "AIPRUS",
        customerIdentifier: userDetail?.phone_number
          ? userDetail?.phone_number
          : userDetail?.phone_no
          ? userDetail?.phone_no
          : "Guest@gmail.com",
        customerName: userDetail?.first_name
          ? userDetail?.first_name + " " + userDetail?.last_name
          : "Guest",
        description: "demo payment",
        requestId: (
          newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
        )
          ?.toString()
          ?.replace(/[T.Z:_-]/g, ""),
        storeId: storeName?.id ? storeName?.id : 0,
        surcharge: 0,
      };
      handleGivexPayment(givexData);
      // let path = `/payment`;
      // let search = `?cardDetail=${
      //   giftCardPaymentDetail.cardNumber
      // }&cardType=gift&amount=${Number(totalAmount).toFixed(2)}&deviceType=web`;
      // navigate({
      //   pathname: path,
      //   search: search,
      // });
      dispatch(setOrderTotalPrice(Number(totalAmount).toFixed(2)));
    } else if (defaultCard && selectedGiftCard != true) {
      dispatch(setCartData(data));
      let newDate = new Date();
      let amount = Number(totalAmount).toFixed(2);
      let creditPayment = {
        profileId: cardDataSave?.multiUseToken
          ? cardDataSave?.multiUseToken
          : cardDataSave?.profileId,
        amount: amount,
        clientId: "AIPRUS",
        currency: "USD",
        customerIdentifier: userDetail?.phone_number
          ? userDetail?.phone_number
          : userDetail?.phone_no
          ? userDetail?.phone_no
          : "Guest@gmail.com",
        customerName: cardDataSave?.customerName
          ? cardDataSave?.customerName
          : "Guest",
        description: userDetail?.first_name + " " + userDetail?.last_name?userDetail?.last_name:"",
        method: "card",
        requestId: (
          newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
        )
          ?.toString()
          ?.replace(/[T.Z:_-]/g, ""),
        surcharge: 0,
        storeId: storeName?.id ? storeName?.id : 1,
        zipCode: cardDataSave?.zip,
        cvv: cardDataSave?.cvv ? cardDataSave?.cvv : cardDataSave?.cvv,
      };
      handleCreditCardPayment(creditPayment);
      // let path = `/payment`;
      // let search = `?cardDetail=${defaultCard}&cardType=credit&amount=${Number(
      //   totalAmount
      // ).toFixed(2)}&deviceType=web`;
      // navigate({
      //   pathname: path,
      //   search: search,
      // });
      dispatch(setOrderTotalPrice(Number(totalAmount).toFixed(2)));
    }
    // else {
    //   dispatch(setCartData(data));
    //       // setIframe(true)
    //   let path = `/payment`;
    //   let search = `?amount=${Number(totalAmount).toFixed(2)}&deviceType=web`;
    //   navigate({
    //     pathname: path,
    //     search: search,
    //   });
    // }
  };
  // let AllItemPrice = 0;
  // if (cartItem?.length) {
  //   AllItemPrice = cartItem
  //     .map((item) => item?.orderItemPrice * item?.quantity)
  //     .reduce((previousValue, currentValue) => previousValue + currentValue);
  // }
  let orderDetailArray = cartItem.map((e, index) => {
      
    let selectedMenuServingDetail = [{

      created_at: e?.selectedCupSize[0]?.created_at,
      price: e?.selectedCupSize[0]?.price?.toString(),
      serving_actual_id: e?.selectedCupSize[0]?.serving_actual_id?.toString(),
      serving_description: e?.selectedCupSize[0]?.serving_description,
      serving_icon: e?.selectedCupSize[0]?.serving_icon,
      serving_id: e?.selectedCupSize[0]?.serving_id?.toString(),
      serving_name: e?.selectedCupSize[0]?.serving_name,
      status: e?.selectedCupSize[0]?.status,
      updated_at: e?.selectedCupSize[0]?.updated_at
    }]
    //  if(e.selectedOptions.length){
    // selectedOptions
    //  e.selectedOptions.map((element)=>element.isSelected = false)
    //  }
    let options = e.selectedCupSize.map((element) => {
      let detail = {
        modifier_group_name: "Cup Size",
        option: [{
          id: element.serving_actual_id?.toString(),
          isSelected: false,
          modifier_id: "",
          modifier_name: element.serving_name,
          modifier_prefix_overide_mode: "No",
          modifier_prefix_overideMode: "",
          modifier_prefix_price: 0,
          modifier_prefixname: "",
          modifier_prefix_name:0,
          modifier_price: element.price?.toString(),
          prefix_name: "",
          price: element.price?.toString(),
          quantity: 1,
          selected_modifier_serving: {
            price: "0",
            serving_name: "",
          },
          serving_id: element.serving_actual_id?.toString(),
          serving_name: element.serving_name,
          serving_price: element.price?.toString(),
          sub_modifier_id: 0,
          title: "",
        }],
        required: true,
        selection_time: "Single",
        title: "Cup Size",
      }
      return detail
    }
    )
    let obj = Object.assign({}, e)
    obj.selectedOptions = [...options, ...e.selectedOptions]
    e = obj
   
    let detail = {
      apply_option: "-1",
      category_id: e?.categoryId?.toString(),
      category_name: e?.categoryName,
      costToDisplay: e?.orderItemPrice?.toString(),
      customLoyaltyId: "",
      custom_loyalty_id: "",
      customizationOpt: e.selectedOptions,
      discountId: "-1",
      discount_price: "0",
      discount_rate: "0",
      discount_title: "",
      discount_type: "0",
      fivestar_discount: fiveStarResponse ? fiveStarResponse?.order_details[0]?.orderDetails[index]?.fivestar_discount : "",
      fivestar_discount_id: fiveStarResponse ? fiveStarResponse?.order_details[0]?.orderDetails[index]?.fivestar_discount_id : "",
      gift_card_no: "",
      gift_card_type: "",
      id: 1372,
      is_giftcard: "",
      itemAliasName: e?.itemAliasName,
      itemCustomizedPrice: e?.orderItemPrice?.toString() ? e?.orderItemPrice?.toString() : "0",
      loyaltyDetails: "New Order",
      loyalty_id: "",
      loyalty_points: "0",
      loyalty_value: "0",
      menu_id: e.id?.toString(),
      menu_image: e?.image,
      menu_name: e?.title,
      originalCost: e?.orderItemPrice?.toString() ? e?.orderItemPrice?.toString() : "0" ,
      quantity: e.quantity,
      selected_menu_serving_detail: selectedMenuServingDetail,
      serving_id: e.selectedCupSize[0].serving_actual_id?.toString(),
      serving_name: e.selectedCupSize[0].serving_name,
      serving_price: e.selectedCupSize[0].price?.toString()
    }
    return detail
  })
  let placeOrderRequest = {
    "customer_address": "",
    "customer_email": userDetail ? userDetail?.email : "",
     "customer_id": userDetail?.person_id ? userDetail?.person_id?.toString() : userDetail?.customer_id?.toString(),
    "customer_mobile_no": userDetail?.phone_no ? userDetail?.phone_no : userDetail?.phone_number,
    "customer_name": userDetail?.first_name ? `${userDetail?.first_name} ${userDetail?.last_name ? userDetail?.last_name : ""}` : userDetail?.username,
    "employee_id": 1,
    "employee_name": "",
    "givex_code": "",
    "givex_num": "",
    "is_emv": 0,
    "loyalty_point": userDetail?.loyalty_point ? userDetail?.loyalty_point : 0,
    "loyalty_request": {
      "checkin": "",
      "phone": "",
      "points": [

      ],
      "rewards": [

      ]
    },
    "loyalty_reward_json": [
      {
        "reward_label": fiveStarRewardDetail ? fiveStarRewardDetail?.perk : "",
        "reward_points": fiveStarRewardDetail ? fiveStarRewardDetail?.point_cost : 0,
        "reward_uid": fiveStarRewardDetail ? fiveStarRewardDetail?.uid : "",
      }
    ],
    "loyalty_value": 0,
    "order_details": [
      {
        "discount": {
          "id": -1,
          "type": "",
          "discount": 0
        },
        "discount_total_price": "0.0",
        "loyalty_point": 0,
        "loyalty_value": 0,
        "orderDetails": orderDetailArray,
        "pickup_date": cartData.pickupDate,
        "pickup_type": cartData.pickupType,
        "store_id": storeName?.id?.toString(),
        "subTotal": AllItemPrice,
        "tax": cartData?.taxRate?.toString(),
        "taxValue": taxValue?taxValue.toString():"0",
        "serviceFeeValue":cartData?.webFeeValue?.toString(),
        "timezone": -330,
        "total": cartData?.total,
        "total_fivestar_discount": fiveStarResponse ? fiveStarResponse?.order_details[0]?.total_fivestar_discount : 0
      }
    ],
    "order_status": "pending",
    "order_total_price": totalAmount,
    "order_type": "website",
    "payment_method":[],
    "store_current_time": new Date()?.toISOString(),
    "store_id": storeName?.id?.toString(),
    "store_name": storeName?.store_name,
    "timezone": -330,
    "user_id": userDetail?.person_id ? (userDetail?.person_id)?.toString() : (userDetail?.customer_id)?.toString(),
    "formattedPickUpLaterDate": cartData?.formattedDate,
    "weekDay": cartData?.weekDay
  };

  let data = {
    couponCode: promoCode,
    deviceType: 0,
    storeId: storeName?.id,
    userId: user?.person_id ? user?.person_id : user?.customer_id,
    placeOrderRequest:placeOrderRequest,
    fiveStarDiscount: fiveStarResponse ? fiveStarResponse?.order_details[0]?.total_fivestar_discount : 0
    // storeId: 2,
    // userId: 950,
  };
  let autoDiscountData = {
    deviceType: 0,
    storeId: storeName?.id,
    userId: user?.person_id ? user?.person_id : user?.customer_id,
    placeOrderRequest:placeOrderRequest,
    fiveStarDiscount: fiveStarResponse ? fiveStarResponse?.order_details[0]?.total_fivestar_discount : 0
    // storeId: 2,
    // userId: 950,
  };
  useEffect(()=>{
    if(cartItem.length>0&&Object.keys(fiveStarRewardDetail).length === 0){
      ApplyAutomaticCoupon(autoDiscountData).then((res)=>{
        if (res?.message == "Promo Code applied successfully") {
          let discount = res?.discount?res?.discount:0
          setAutoDisc(discount)
          dispatch(setAutoDiscountDetail(res?.result));
          console.log(res?.result[0].disArr.combineDiscount,"showcombineDiscountPopup")
          if(res?.result[0].disArr.combineDiscount){
             setShowcombineDiscountPopup(true)
          }else{
            setShowcombineDiscountPopup(false)
          }
        }else{
          setAutoDisc(0)
          dispatch(setAutoDiscountDetail(""));
        }
      })
    }
  },[cartItem])

  const applyPromo = (fromPage) => {
    setButtonLoader(true);

    if (
      !promoApply &&
      storeName?.id &&
      (user?.customer_id || user?.person_id)
    ) {
      ApplyCoupon(data)
        .then((res) => {
          if (res?.message == "All Fields are required") {
            setPromoApplyError("Promo Code Required");
            setCombineDiscount("");
            setButtonLoader(false);
          } else if (res?.message != "Promo Code applied successfully") {
            setPromoApplyError(res?.message);
            setCombineDiscount("");
            setButtonLoader(false);
          } else if (res.message == "Promo Code applied successfully") {
            setButtonLoader(false);
            if(autoDisc===0){
              dispatch(setDiscountDetail(res?.result));
              if (res?.result[0].disArr.combineDiscount) {
                setCombineDiscount("true");
              } else {
                setCombineDiscount("false");
                setPromoApplyError("Reward Applied!combined discount disable");
              }
              if (
                Object.keys(fiveStarRewardDetail).length > 0 &&
                fromPage != "from AlertPopup" &&
                !res?.result[0].disArr.combineDiscount
              ) {
                setPromoApplyError(
                  "Can not combine this promotions in same order"
                );
              } else {
                let discount = res?.discount?res?.discount:0
                if (discount == 0) {
                } else {               
                  setPromoApply(res?.result[0]);
                  setDisc(discount);
                  setPromoApplyError("Promo Code applied successfully");
                }
              }
            }else{
              setPromoApplyError("Can not combine this promotions");
            }
            
          }
        })
        .catch((err) => {
          setButtonLoader(false);
          errorToastMessage();
        });
    } else if (!(user?.customer_id || user?.person_id)) {
      setPromoApply();
      setButtonLoader(false);
      dispatch(setDiscountDetail(""));
      setPromoApplyError("Please login to apply promo");
      setPromoCode("");
      setCombineDiscount("");
    } else if (promoApply) {
      dispatch(setRemoveReward(""));
      setPromoApply();
      setButtonLoader(false);
      dispatch(setDiscountDetail(""));
      setPromoApplyError();
      setPromoCode("");
      setDisc(0);
      setTotalFivestar(0);
      setfivestarDiscount(0);
      dispatch(setFiveStarIndex(Number.MAX_VALUE));
      dispatch(setFiveStarRewardDetail(""));
      dispatch(setFiveStarResponse(""));
      setCombineDiscount("");
    }
  };

  const handleBothCheckOut = () => {
    // clearInterval(myInterval);
    if (pickupType == "asap") {
      // setShowAd(true);
      return routeChange();
    } else {
      if (time) {
        let timeZoneDate = new Date().toLocaleString("en-US", {
          timeZone: storeName?.time_zone,
        });
        let timeZoneDateHours =
          new Date(timeZoneDate)?.getHours() > 9
            ? new Date(timeZoneDate)?.getHours()
            : "0" + new Date(timeZoneDate)?.getHours();
        let timeZoneDateMinutes =
          new Date(timeZoneDate)?.getMinutes() > 9
            ? new Date(timeZoneDate)?.getMinutes()
            : "0" + new Date(timeZoneDate)?.getMinutes();
        let timeZoneDateMonth = new Date(timeZoneDate)?.getMonth();
        let timeZoneDateFullYear = new Date(timeZoneDate)?.getFullYear();
        let timeZoneDateDay = new Date(timeZoneDate)?.getDate();
        let modifiedTime = time;
        if (time?.length != 8) {
          modifiedTime = "0" + time;
        }
        let selectedPickUpLaterHour = TwentyFourHourFormat(modifiedTime);
        let splitHourAndMinute = selectedPickUpLaterHour?.split(":");
        let selectedSplitHour = splitHourAndMinute[0];
        let selectedSplitMinute = splitHourAndMinute[1];

        let differenceInOrderTimining =
          new Date(
            timeZoneDateFullYear,
            timeZoneDateMonth,
            timeZoneDateDay,
            Number(selectedSplitHour),
            Number(selectedSplitMinute)
          ) -
          new Date(
            timeZoneDateFullYear,
            timeZoneDateMonth,
            timeZoneDateDay,
            timeZoneDateHours,
            timeZoneDateMinutes
          );

        if (differenceInOrderTimining < 900000) {
          setRemove(true);
        } else {
          setShowAd(true);
          routeChange();
        }
      } else {
        toast.error("Please Choose PickUp Time", {
          position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss: false,
          autoClose: 1500,
          pauseOnHover: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      }
    }
  };

  const handleCheckOut = (e) => {
    // e.target.disabled = true;
      setChecoutButtonDisabled(true);
    // if (e.target.disabled) {
    // } else {
    //   setChecoutButtonDisabled(false);
    // }

    if (savedCards.length == 0) {
      setShowText(true);
    }
    if (availableBalance && availableBalance < giftCardAmount) {
      toast.error("Available balance is low", {
        position: toast.POSITION.TOP_RIGHT,
        pauseOnFocusLoss: false,
        autoClose: 1500,
        pauseOnHover: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      });
    }
    // clearInterval(myInterval)
    else {
      setLoader(true);

      getStoreStartendTime(storeName?.id).then((res) => {
        let storeStatus = handleStoreStartEndTime(res, storeName?.time_zone);
        let timeRange = storeStatus?.timeRange;
        let storeActiveStatus = storeStatus?.storeActiveStatus
          ? storeStatus?.storeActiveStatus
          : "Active";
        let statusOnMobile = storeStatus?.statusOnMobile
          ? storeStatus?.statusOnMobile
          : "Active";
        if (
          storeStatus?.status[storeStatus?.timeZoneWeekDay].storeDayStatus ==
            false &&
          storeStatus?.status[storeStatus?.timeZoneWeekDay]?.onLineStatus ==
            "on" &&
          timeRange === true &&
          storeActiveStatus === "Active" &&
          statusOnMobile === "Active"
        ) {
          if (isGuestLogin) {
            setOpen(false);
            handleBothCheckOut();
          } else {
            checkUser(user?.phone_no ? user?.phone_no : user?.phone_number, 91)
              .then((res) => {
                if (res.response_code === "1" && res.result.otp === false) {
                  handleBothCheckOut();
                  setLoader(false);
                } else {
                  localStorage.clear();
                  navigate("/");
                  window.location.reload();
                  setLoader(false);
                }
              })
              .catch((e) => {
                toast.error("Oops something went wrong", {
                  position: toast.POSITION.TOP_RIGHT,
                  pauseOnFocusLoss: false,
                  autoClose: 1500,
                  pauseOnHover: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  draggable: true,
                });
                setLoader(false);
              });
          }
        } else {
          setLoader(false);
          toast.warn("This store is closed. Please select another store", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1000,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });

          //   navigate("/");
          //  window.location.reload();
        }
      });
    }
  };

  let TotalPricee =
    Number(Number(AllItemPrice).toFixed(2)) +
    Number(Number((taxes * AllItemPrice) / 100).toFixed(2));

  useEffect(() => {
    setTotalFivestar(0);
    setfivestarDiscount(0);
  }, [cartItem]);

  useEffect(() => {
    setTotalFivestar(0);
    setfivestarDiscount(0);
    dispatch(setFiveStarIndex(Number.MAX_VALUE));
    dispatch(setFiveStarRewardDetail(""));
    dispatch(setFiveStarResponse(""));
    dispatch(setDiscountDetail(""));
    setDisc(0);
    setPromoApply();
    setPromoApplyError("");
    setPromoCode("");
  }, [cartItem?.length, totalCartItem]);

  const handleRemoveFiveStarLoyalty = () => {
    // setRemoveFiveStarRewards(true) ;
    dispatch(setRemoveReward(""));
    setTotalFivestar(0);
    setfivestarDiscount(0);
    dispatch(setFiveStarIndex(Number.MAX_VALUE));
    dispatch(setFiveStarRewardDetail(""));
    dispatch(setFiveStarResponse(""));
  };
  let personId = user?.person_id ? user?.person_id : user?.customer_id;
  useEffect(() => {
    if (user?.person_id || user?.customer_id) {
      setLoading(true);
      getSavedCardDetail(user?.person_id ? user?.person_id : user?.customer_id)
        .then((res) => {
          if (res.responseCode === 1) {
            res.object.map((e) => {
              let imageUrl;
              switch (e.cardType.toLowerCase()) {
                case "visa":
                  imageUrl = "./visa.png";
                  break;
                case "mc":
                  imageUrl = "./mastercard.png";
                  break;
                case "mastercard":
                  imageUrl = "./mastercard.png";
                  break;
                case "amex":
                  imageUrl = "./Amex.png";
                  break;
                case "american-express":
                  imageUrl = "./Amex.png";
                  break;
                case "maestro":
                  imageUrl = "./maestro.png";
                  break;
                case "dinersclub":
                  imageUrl = "./dinersclub.png";
                  break;
                case "discover":
                  imageUrl = "./discover.png";
                  break;
                case "jcb":
                  imageUrl = "./jcb.png";
                  break;
                default:
                  imageUrl = "./creditcard.png";
              }
              return (e.imageUrl = imageUrl);
            });
            setSavedCard(res.object);
            let card = res.object.filter(
              (e) => e.lastFourCardDigit == defaultCard
            )[0];
            setCartDataSave(card);
            if (res.object.length > 0 && !defaultCard) {
              dispatch(
                setDefaultCardd(
                  res.object.filter((e) => e.isDefault == true)[0]
                    ?.lastFourCardDigit
                    ? res.object.filter((e) => e.isDefault == true)[0]
                        .lastFourCardDigit
                    : res.object[0].lastFourCardDigit
                )
              );
            }
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorToastMessage();
        });
      getGivexDetails(storeName?.id)
        .then((res) => {
          setGivexCardExist(res?.givexExist);
          if (res?.givexExist == true) {
            getGivexCardDetail(
              user?.person_id ? user?.person_id : user?.customer_id
            ).then((res) => {
              res = res.map((object) => {
                return { ...object, imageUrl: "./giftcard.jpg" };
              });

              if (savedGivxCards == false) {
                setSavedCard((e) => [...e, ...res]);
              }

              // setSavedGiftCard(res[0]?.givexCardNumber);
              setLoading(false);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          errorToastMessage();
        });
    }
    //showCard is Rmoved from dependency
  }, [isLogin, personId, givexCardDetails, storeName?.id, defaultCard]);

  useEffect(() => {
    if (savedCards?.length == 1 && savedCards[0]?.givexCardNumber) {
      setGiftCardSelect(true);
    }
  }, [savedCards]);

  const handleSplitPaymentChange = (event) => {
    if (
      pickUpLaterTime &&
      !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)
    ) {
      setPickUpLaterPopup(true);
    } else {
      dispatch(setOrderTotalPrice(totalAmount));
      setGiftCard(true);
      setGiftCardSelect(true);
    }
  };
  useEffect(() => {
    dispatch(setGivexDetail(giftCardPaymentDetail));
  }, [giftCardPaymentDetail]);

  const handleGreenCheckboxOnOpen = () => {
    setGreenCheckbox(true);
  };
  const handleGreenCheckboxOnClose = () => {
    setGreenCheckbox(false);
  };

  return (
    <>
      <div>
        <div>
          {cartItem.length === 0 ? (
            <div style={{ textAlign: "center", marginTop: "15rem" }}>
              <Typography variant="h5" fontWeight="600">
                Your cart is empty{" "}
              </Typography>
              <Button
                className={newClasses.btnLogin}
                variant="contained"
                onClick={() => {
                  dispatch(setTabValue(2));
                  let path = `/`;
                  navigate(path);
                }}
              >
                Add Items
              </Button>
            </div>
          ) : (
            <>
              <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={open}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Container>
                <div
                  style={{
                    display: "flex",
                    padding: "0.7rem 0rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(setTabValue(2));
                    let path = `/`;
                    navigate(path);
                  }}
                >
                  <ArrowBackIosIcon style={{ fill: "#C60734" }} />
                  <Typography sx={{ color: "#C60734" }} variant="h5">
                    Cart
                  </Typography>
                </div>
                <Typography variant="subtitle1">
                  {`${totalCartItem}  ${totalCartItem == 1 ? "Item" : "Items"}`}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                    paddingBottom: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      width: { lg: "50%", md: "50%", sm: "100%", xs: "100%" },
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "1rem",
                    }}
                  >
                    <div>
                      <Card
                        sx={{
                          borderRadius: "10px",
                          height: { lg: "16rem", md: "16rem" },
                          // height: { lg: "27rem", md: "27rem" },
                          overflowY: "auto",
                          boxShadow: "0 6px 20px 0 #dbdbe8",
                        }}
                      >
                        <Box sx={{ padding: "1rem 0rem" }}>
                          <Container>
                            {cartItem.map((item, index) => {
                              return (
                                <>
                                  <PersonItem
                                    key={index}
                                    item={item}
                                    listKey={index}
                                    setDisc={setDisc}
                                    setPromoApply={setPromoApply}
                                    setPromoApplyError={setPromoApplyError}
                                    setPromoCode={setPromoCode}
                                    setTotalFivestar={setTotalFivestar}
                                  />
                                  <Divider
                                    variant={"middle"}
                                    className={newClasses.divider}
                                  />
                                </>
                              );
                            })}
                          </Container>
                        </Box>
                      </Card>
                    </div>
                    <Box
                      sx={{
                        marginTop: "1rem",
                        marginBottom: {
                          lg: "0rem",
                          md: "0rem",
                          sm: "1.3rem",
                          xs: "1.3rem",
                        },
                      }}
                    >
                      <Card
                        sx={{
                          borderRadius: "10px",
                          boxShadow: "0 6px 20px 0 #dbdbe8",
                          // maxHeight : { lg: "7rem", md: "7rem" },
                          height: { lg: "14rem", md: "14rem" },
                          // overflowY: "auto",
                        }}
                      >
                        <Container>
                          <div
                            style={{
                              display: "flex",
                              marginTop: "1rem",
                              // alignItems: "center",
                              justifyContent: "space-around",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  value={promoCode}
                                  onChange={(e) => {
                                    setPromoCode(e.target.value);
                                  }}
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.noBorder,
                                    },
                                    readOnly: promoApply ? true : false,
                                  }}
                                  variant="outlined"
                                  size="small"
                                  label="Enter Code Here"
                                  onInput={(e) => {
                                    // if(e.target.value.length == 0 )
                                    // {
                                    // setPromoApplyError("");
                                    // }
                                  }}
                                  onKeyPress={(e) => {
                                    e.key == "Enter" && applyPromo();
                                  }}
                                  sx={{
                                    backgroundColor: "#EEEEEE",
                                    borderRadius: "5px",
                                  }}
                                />
                                {/* <div className={promoApplyError == "Promo Code applied succesfully" ? newClasses.errorS : newClasses.error}>
                            {promoApplyError ?promoApplyError : <br />}
                          </div> */}

                                {/* </div> */}
                                <Button
                                  style={{
                                    backgroundColor: "white",
                                    color: "#C60734",
                                    padding: "0.5rem 0rem",
                                    margin: "0rem 0.5rem",
                                    border: "1px solid #C60734",
                                    borderRadius: "5px",
                                    textTransform: "none",
                                  }}
                                  variant="contained"
                                  onClick={applyPromo}
                                >
                                  {buttonLoader ? (
                                    <ButtonLoader props={"4rem"} />
                                  ) : promoApply ? (
                                    "Remove"
                                  ) : (
                                    "Apply"
                                  )}
                                </Button>
                              </div>
                              <div
                                className={
                                  promoApplyError ==
                                  "Promo Code applied successfully"
                                    ? newClasses.errorS
                                    : newClasses.error
                                }
                              >
                                {promoApplyError ? promoApplyError : <br />}
                              </div>
                            </div>
                          </div>
                          <Divider />
                          {/* </div> */}
                        </Container>
                        {isLogin === false ? (
                          <div
                            style={{
                              marginTop: "2rem",
                              marginBottom: "1.5rem",
                            }}
                          >
                            <Divider>Log in to see your Rewards</Divider>
                          </div>
                        ) : (
                          <>
                            {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "1rem",
                            }}
                          > */}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "1rem",
                              }}
                            >
                              <RewardPopUp
                                showRewards={showRewards}
                                setshowRewards={setshowRewards}
                                setTotalFivestar={setTotalFivestar}
                                totalFivestar={totalFivestar}
                                disc={disc}
                                fivestarDiscount={fivestarDiscount}
                                setfivestarDiscount={setfivestarDiscount}
                                combineDiscount={combineDiscount}
                                showcombineDiscountPopup={
                                  showcombineDiscountPopup
                                }
                                promoApply={promoApply}
                                setShowCombinePopUp={setShowCombinePopUp}
                              ></RewardPopUp>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "0.5rem",
                                marginBottom: "0.6rem",
                              }}
                            >
                              <div style={{ color: "green" }}>
                                {fiveStarIndex == Number.MAX_VALUE ? (
                                  <br />
                                ) : (
                                  fiveStarRewardDetail?.perk + " applied!"
                                )}
                              </div>
                              {fiveStarIndex != Number.MAX_VALUE ? (
                                <CancelSharpIcon
                                  fontSize="small"
                                  style={{
                                    fill: "#C30E2F",
                                    marginLeft: "0.1rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleRemoveFiveStarLoyalty}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )}
                      </Card>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: { lg: "50%", md: "50%", sm: "100%", xs: "100%" },
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: "10px",
                        // minHeight: { lg: "27.3rem", md: "27.3rem" },
                        height: { lg: "31rem", md: "31rem" },
                        overflowY: "auto",
                        boxShadow: "0 6px 20px 0 #dbdbe8",
                      }}
                    >
                      <Container>
                        <Box
                          sx={{
                            paddingBottom: "3rem",
                            paddingTop: { lg: "5vh", sm: "1rem", xs: "1rem" },
                            marginTop: { lg: "-.9rem", sm: "0rem", xs: "0rem" },
                          }}
                          // className={newClasses.bill}
                        >
                          <div className={newClasses.billOrientation}>
                            <Typography variant="subtitle1" gutterBottom>
                              Sub-Total:
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                            >{`$${numberWithCommas(
                              Number(AllItemPrice).toFixed(2)
                            )}`}</Typography>
                          </div>
                          <div className={newClasses.billOrientation}>
                            <Typography variant="subtitle1" gutterBottom>
                              Discounts:
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                              {`-$${
                                discountTotal > 0
                                  ? discountTotal <= AllItemPrice
                                    ? Number(discountTotal).toFixed(2)
                                    : Number(AllItemPrice).toFixed(2)
                                  : "0.00"
                              }`}
                            </Typography>
                          </div>

                          <div className={newClasses.billOrientation}>
                          
                              <Typography variant="subtitle1" gutterBottom>
                                Tax:
                              </Typography>
                            
                            <Typography variant="subtitle1" gutterBottom>
                              <Typography variant="subtitle1" gutterBottom>
                                {`$${
                                  taxValue > 0
                                    ? numberWithCommas(
                                        Number(taxValue).toFixed(2)
                                      )
                                    : "0.00"
                                }`}
                              </Typography>
                            </Typography>
                          </div>

                          <Divider sx={{ margin: "0px 0px 8px" }} />
                          <div className={newClasses.billOrientation}>
                            <Typography variant="subtitle1" gutterBottom>
                              Total:
                            </Typography>

                            <Typography variant="subtitle1" gutterBottom>
                              {`$${
                                totalAmount > 0
                                  ? numberWithCommas(
                                      Number(totalAmount).toFixed(2)
                                    )
                                  : "0.00"
                              }`}
                            </Typography>
                          </div>
                          <Divider />

                          <Box
                            sx={{
                              display: {
                                lg: "flex",
                                md: "flex",
                                sm: "flex",
                                xs: "flex",
                              },
                              flexDirection: { xs: "row" },
                              alignItems: { xs: "end" },
                            }}
                          >
                            <div>
                              <FormControl>
                                <RadioGroup
                                  sx={{ display: { xs: "none", sm: "block" } }}
                                  defaultValue="asap"
                                  row
                                >
                                  <FormControlLabel
                                    value="asap"
                                    onChange={(e) => {
                                      setPickupType(e.target.value);
                                      setPickupDate(new Date().toISOString());
                                    }}
                                    control={<Radio />}
                                    label="Pickup Now"
                                  />
                                  {pickUpTime?.length > 0 && (
                                    <FormControlLabel
                                      value="later"
                                      onChange={(e) => {
                                        setPickupType(e.target.value);
                                      }}
                                      control={<Radio />}
                                      label="Pickup Later"
                                    />
                                  )}
                                </RadioGroup>
                                <RadioGroup
                                  sx={{ display: { xs: "flex", sm: "none" } }}
                                  defaultValue="asap"
                                >
                                  <FormControlLabel
                                    value="asap"
                                    onChange={(e) => {
                                      setPickupType(e.target.value);
                                      setPickupDate(new Date().toISOString());
                                    }}
                                    control={<Radio />}
                                    label="Pickup Now"
                                  />
                                  {pickUpTime?.length > 0 && (
                                    <FormControlLabel
                                      value="later"
                                      onChange={(e) => {
                                        setPickupType(e.target.value);
                                      }}
                                      control={<Radio />}
                                      label="Pickup Later"
                                    />
                                  )}
                                </RadioGroup>
                              </FormControl>
                            </div>
                            <div>
                              {pickupType == "later" &&
                              pickUpTime?.length > 0 ? (
                                <>
                                  <FormControl
                                    fullWidth
                                    sx={{ marginTop: "1rem" }}
                                  >
                                    <InputLabel>Time</InputLabel>
                                    <Select
                                      MenuProps={MenuProps}
                                      value={
                                        time
                                          ? time
                                          : new Date()
                                              .toTimeString()
                                              .slice(0, 5)
                                      }
                                      variant="outlined"
                                      onChange={handleChange}
                                      label="Time"
                                      defaultOpen={true}
                                      size="small"
                                      sx={{
                                        minWidth: {
                                          lg: "8rem",
                                          md: "8rem",
                                          sm: "7rem",
                                          xs: "6rem",
                                        },
                                      }}
                                    >
                                      <MenuItem value={""} disabled>
                                        {"Select time"}
                                      </MenuItem>
                                      {pickUpTime.map((item, index) => {
                                        return (
                                          <MenuItem value={item} key={index}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </Box>
                          <div className={newClasses.location}>
                            <LocationOnIcon style={{ color: "#C60734" }} />
                            <div variant="subtitle1">
                              {`${
                                storeName.address
                                  ? storeName.address.replace("~", " ")
                                  : ""
                              } ${storeName.zip_code}`}
                            </div>
                          </div>
                          {isLogin === false && isGuestLogin === false ? (
                            <>
                              <div className={newClasses.buttons}>
                                <Button
                                  className={newClasses.btnLogin}
                                  variant="contained"
                                  onClick={() => {
                                    setShowAd(true);
                                  }}
                                >
                                  Log in and Checkout
                                </Button>
                              </div>
                              <div className={newClasses.guest}>
                                <div
                                  onClick={() => {
                                    setshowGuest(true);
                                  }}
                                  variant="subtitle1"
                                >
                                  Continue as Guest <ArrowForwardIcon />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {savedCards.length > 0 &&
                                (defaultCard || GiftCardSelect) && (
                                  <FormControl fullWidth>
                                    <InputLabel style={{ marginTop: "0.3rem" }}>
                                      Select Card
                                    </InputLabel>
                                    <Select
                                      defaultValue={defaultCard}
                                      label="Select Card "
                                      //  size="small"
                                      style={{ marginTop: "0.3rem" }}
                                      // MenuProps={MenuProps}
                                      // open={page}

                                      onChange={handleChangeCard}
                                      onClose={handleGreenCheckboxOnClose}
                                      onOpen={handleGreenCheckboxOnOpen}
                                    >
                                      {savedCards.map((cardDetail, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={
                                              cardDetail.lastFourCardDigit
                                                ? cardDetail.lastFourCardDigit
                                                : cardDetail.givexCardNumber
                                            }
                                          >
                                            <div
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Box
                                                style={{
                                                  display: "flex",
                                                }}
                                              >
                                                <div
                                                  style={{ width: "2.5rem" }}
                                                >
                                                  <Box
                                                    component="img"
                                                    sx={{
                                                      width: "3rem",
                                                      marginTop: "0.2rem",
                                                    }}
                                                    src={cardDetail.imageUrl}
                                                  />
                                                </div>
                                                <Typography
                                                  variant="subtitle1"
                                                  marginLeft="1.6rem"
                                                  marginTop="0.3rem"
                                                >
                                                  {cardDetail.lastFourCardDigit
                                                    ? ` ****${cardDetail.lastFourCardDigit} `
                                                    : ` ****${cardDetail.givexCardNumber.substring(
                                                        17,
                                                        21
                                                      )} `}{" "}
                                                </Typography>
                                              </Box>
                                              {cardDetail.isDefault == true &&
                                                geenCheckBox == true && (
                                                  <CheckCircleRoundedIcon
                                                    sx={{
                                                      color: "green",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                )}
                                            </div>
                                            {/* } */}
                                          </MenuItem>
                                        );
                                      })}
                                      <MenuItem>
                                        <Box
                                          onClick={() => {
                                            setshowCard(true);
                                            // setpage(true)
                                          }}
                                          sx={{
                                            display: "flex",
                                            marginBottom: "0.5rem",
                                            marginTop: "0.5rem",
                                            width: "100%",
                                          }}
                                        >
                                          <AddCircleOutlineIcon />
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              width: "100%",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              marginLeft="0.5rem"
                                            >
                                              Credit or Debit card
                                            </Typography>
                                          </div>
                                        </Box>
                                      </MenuItem>
                                      {givexCardExist == true && (
                                        <MenuItem>
                                          <Box
                                            onClick={handleSplitPaymentChange}
                                            sx={{
                                              display: "flex",
                                              marginBottom: "0.5rem",
                                              marginTop: "0.5rem",
                                              width: "100%",
                                            }}
                                          >
                                            <AddCircleOutlineIcon />
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                              }}
                                            >
                                              <Typography
                                                variant="subtitle1"
                                                marginLeft="0.5rem"
                                              >
                                                Gift card
                                              </Typography>
                                            </div>
                                          </Box>
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                  //value={"giftcard"}
                                )}
                              {savedCards.length == 0 && (
                                <FormControl fullWidth>
                                  <InputLabel style={{ marginTop: "0.3rem" }}>
                                    Select Card
                                  </InputLabel>
                                  <Select
                                    label="Select Card "
                                    //  size="small"
                                    style={{ marginTop: "0.3rem" }}
                                    //  MenuProps={MenuProps}
                                    onChange={handleChangeCard}
                                    //  onClose={}
                                  >
                                    <MenuItem>
                                      <Box
                                        onClick={() => {
                                          setshowCard(true);
                                          // setpage(true)
                                        }}
                                        sx={{
                                          display: "flex",
                                          marginBottom: "0.5rem",
                                          marginTop: "0.5rem",
                                          width: "100%",
                                        }}
                                      >
                                        <AddCircleOutlineIcon />
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            marginLeft="0.5rem"
                                          >
                                            Credit or Debit card
                                          </Typography>
                                        </div>
                                      </Box>
                                    </MenuItem>
                                    {givexCardExist == true && (
                                      <MenuItem>
                                        <Box
                                          onClick={handleSplitPaymentChange}
                                          sx={{
                                            display: "flex",
                                            marginBottom: "0.5rem",
                                            marginTop: "0.5rem",
                                            width: "100%",
                                          }}
                                        >
                                          <AddCircleOutlineIcon />
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              width: "100%",
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              marginLeft="0.5rem"
                                            >
                                              Gift card
                                            </Typography>
                                          </div>
                                        </Box>
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              )}
                              {showText ? (
                                <Typography className={newClasses.error}>
                                  Please select card to checkout
                                </Typography>
                              ) : (
                                <p>
                                  <br />
                                </p>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "1rem",
                                }}
                              >
                                <Button
                                  // className={newClasses.btnLoginChekout}
                                  style={{
                                    borderRadius: 20,
                                    padding: "0.2rem 3rem",
                                    backgroundColor: checkOutbuttonDisabled
                                      ? "gray"
                                      : "#C60734",
                                    color: "white",
                                    textTransform: "none",
                                  }}
                                  variant="contained"
                                  onClick={(e) => {
                                    if(checkOutbuttonDisabled === false){ 
                                      handleCheckOut(e)
                                      }
                                  }}
                                >
                                  Checkout
                                </Button>
                              </div>
                            </>
                          )}
                        </Box>
                      </Container>
                    </Card>
                  </Box>
                </Box>
                {/* </Grid> */}
              </Container>
            </>
          )}
        </div>
      </div>
      <Login showAd={showAd} setShowAd={setShowAd}></Login>
      <Guest showGuest={showGuest} setshowGuest={setshowGuest}></Guest>
      <GiftCard
        setpage={setpage}
        giftCard={giftCard}
        setGiftCard={setGiftCard}
        setGivexCardDetails={setGivexCardDetails}
        payment={totalAmount}
        setRemainingPayment={setRemainingPayment}
        setGiftCardAmount={setGiftCardAmount}
        giftCardPaymentDetail={giftCardPaymentDetail}
        setGiftCardPaymentDetail={setGiftCardPaymentDetail}
        boolBalanceFetched={boolBalanceFetched}
        setBoolBalanceFetched={setBoolBalanceFetched}
        title={title}
        savedCards={savedCards}
        storeMobileId={storeName?.id}
        customerId={user?.customer_id ? user?.customer_id : user?.person_id}
        customerPhone={user?.phone_number ? user?.phone_number : user.phone_no}
        customerName={user?.first_name}
      />
      {showCard && (
        <AddCard
          setGreenCheckbox={setGreenCheckbox}
          setGiftCardSelect={setGiftCardSelect}
          showCard={showCard}
          addCardFromCart="card Added from Cart"
          setshowCard={setshowCard}
          setGivxSavedCard={setGivxSavedCard}
          page="Save"
          setpage={setpage}
          setSavedCard={setSavedCard}
          setCartDataSave={setCartDataSave}
          setShowText={setShowText}
          setChecoutButtonDisabled={setChecoutButtonDisabled}
        ></AddCard>
      )}
      <AlertPopup
        open={remove}
        setOpen={setRemove}
        title={"pickUpLater"}
        buttonType1={true}
        buttonType2={false}
        displayTitle={
          "The pickup time that you selected is no longer available. Please select another time slot."
        }
      />
      <AlertPopup
        open={pickUpLaterPopup}
        setOpen={setPickUpLaterPopup}
        title={"paymentCondition"}
        buttonType1={true}
        buttonType2={false}
        displayTitle={
          "The pickup time that you selected is no longer available. Please select another time slot."
        }
      />
      {/* <AlertPopup
        open={showcombineDiscountPopup1}
        setOpen={setShowcombineDiscountPopup1}
        title={"combineDiscountPopup1"}
        buttonType1={true}
        buttonType2={true}
        displayTitle={
          "Promotion Applied!, want to remove couponcode and apply reward"
        }
        // rewardObject={rewardObject}
        // handleFivestarReward={handleFivestarReward}
        handleRemoveFiveStarLoyalty={handleRemoveFiveStarLoyalty}
        applyPromo={applyPromo}
        setCombineDiscount={setCombineDiscount}
        setPromoCode={setPromoCode}
        setCombineDiscount1={setCombineDiscount1}
      /> */}
    </>
  );
};

export default Cart;
