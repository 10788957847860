import React from "react";
import { lighten } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Toolbar } from "@mui/material";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import clsx from "clsx";

const useToolbarStyles = makeStyles((theme) => ({
//   root: {
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//   },
//   highlight:
//     theme.palette.type === "light"
//       ? {
//           color: theme.palette.secondary.main,
//           backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.secondary.dark,
//         },
  title: {
    flex: "1 1 100%",
  },
}));

const TableToolBar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, addUserHandler, deleteUserHandler } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} rows selected
          </Typography>
          <IconButton aria-label="delete" onClick={deleteUserHandler}>
            <DeleteIcon />
          </IconButton>
        </>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
        ></Typography>
      )}
    </Toolbar>
  );
};

export default TableToolBar;
