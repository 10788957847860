import React, { useEffect } from "react";
import { useState } from "react";
import Loader from "../../Common/Loader";
import New from "./New";
import { useNavigate } from "react-router-dom";
import { getAdvertismentDetail, GetAllCategories, GetAllMenu, getCustomizationOption } from "../../Api/API";
import { errorToastMessage } from "../../Common/utils";
import { Typography, Grid, Backdrop, CircularProgress, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setCartDetailes, setCustomizeOptions, setMenuServingDetails, setSelectedCupSizes, setOpenPopUps, setTitles,setEditTitles, setAdvCheck, setValueSet, checkAdv, setCheckAdv, setTabValue } from "../../Redux/CartSlice";
import { toast } from "react-toastify";
const Feature = ({ setValue, setState, state }) => {
  const [loading, setLoading] = useState(false);
  const [deal, setDeal] = useState();
  const [adv, setAdv] = useState();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const storeName = useSelector((state) => state.store.nearestStore);
  const userDetail = useSelector((state) => state.user.user);
  useEffect(() => {
    setLoading(true);
    getAdvertismentDetail(2, storeName ? storeName?.id : "").then((result) => {
      setDeal(result.data);
      // console.log(result);
      setLoading(false);

    }).catch((err) => {
      errorToastMessage();
      setLoading(false);
      console.log(err)
    })
  }, [storeName])
  useEffect(() => {
    const advIndex = deal?.indexOf(adv);
    if (advIndex >= 0) {
      // navigate("/customization")
      let catData = {
        storeId: storeName?.id,
        appType: "web",
      }
      if (adv?.buttonType == "Order Now") {
        if (storeName?.id) {
          setLoading(true)
          GetAllCategories(catData).then((response) => {
            let categoryExist = response?.result?.filter((element) => Number(element?.category_id) == Number(adv?.categoryId))
            if (categoryExist?.length > 0 && adv?.menuId == null) {
              // setValue(2)
              dispatch(setValueSet(response?.result.indexOf(categoryExist[0])))
              navigate(`/menu`)
              window.scrollTo(0, 0);

            }
            else if (categoryExist?.length > 0) {
              let menuItem = {
                appType: "web",
                categoryId: adv?.categoryId,
                customerId: 0,
                requestDate: "",
                storeId: storeName?.id,
              }
              setLoading(true)
              GetAllMenu(menuItem).then((responses) => {
                let menuExist = responses?.result.filter((element) => Number(element?.id) == Number(adv?.menuId));
                if (menuExist?.length > 0) {
                  // setMenuDetail(menuExist)
                  let data = {
                    customerId: userDetail?.customer_id
                      ? userDetail?.customer_id
                      : userDetail?.person_id,
                    menuId: adv?.menuId,
                    storeId: storeName?.id ? storeName?.id : 1,
                    appType: "web",
                  };
                  setLoading(true)
                  getCustomizationOption(data)
                    .then((res) => {
                      setLoading(false)

                      let cartDetail = {
                        id: menuExist[0]?.id,
                        price: menuExist[0]?.price,
                        image: menuExist[0]?.image,
                        title: menuExist[0]?.title,
                        quantity: 1,
                        categoryId: res?.result[0][0].category_id,
                        selectedCupSize: [],
                        customizationOpt: res?.result[0][0].customizationOpt,
                        categoryName: res?.result[0][0].category_name,
                        is_favourite: res?.result[0][0].is_favourite,
                        // totalPrice: props?.price * counter,
                        btnModifierId: [],
                        btnModifier: [],
                        custom: [],

                        menuServingDetail: res?.result[0][0].menu_serving_detail,
                        // orderItemPrice: fav ? favItemsDetail?.order_item_price : "",
                        // selectedOptions: fav ? favItemsDetail?.selectedOption : [],
                        cold: res?.result[0][0].caffeine,
                        caffine: res?.result[0][0].cold,
                        max_calories: res?.result[0][0]?.max_calories,
                        min_calories: res?.result[0][0]?.min_calories,
                      }
                      cartDetail.selectedCupSize =
                        [res?.result[0][0]?.menu_serving_detail[0]];
                        cartDetail.description = res?.result[0][0]?.description;
                      dispatch(setSelectedCupSizes(
                        cartDetail?.selectedCupSize?.length > 1
                          ? cartDetail?.selectedCupSize?.filter((e) => e.tier_id > 0)
                          : cartDetail?.selectedCupSize
                      ));
                      // console.log(cartDetail,"#1111")
                      let menuServingDetail = res?.result[0][0]?.menu_serving_detail;
                      dispatch(setCustomizeOptions(res?.result[0][0]?.customizationOpt))
                      dispatch(setCartDetailes(cartDetail))
                      dispatch(setMenuServingDetails(menuServingDetail))
                      dispatch(setOpenPopUps(true))
                      dispatch(setTitles(""))
                      dispatch(setEditTitles(""))
                      dispatch(setAdvCheck(true))
                      dispatch(setTabValue(0));
                      navigate(`/customization`)
                    })
                }
                else {
                  toast.error(`This menu item is not available for selected store`, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setLoading(false);
                }
              })
            }
            else {
              toast.error(`This category is not available for selected store`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false);
            }
          })
        }
      }
    }
    // setAdvCheck(advIndex)
    // console.log(advIndex, 9999)
  }, [storeName])
  return (
    <>
      {loading ? <Backdrop
        sx={{ color: '#fff', zIndex: 1000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> :
        <Box
          sx={{
            paddingBottom: { lg: "3vh", xs: "15vh" },
            paddingTop: "5vh",
          }}
        >
          <Grid container spacing={5} >
            {deal?.map((e) => {
              return (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <New
                    deals={e}
                    setValue={setValue}
                    setState={setState}
                    state={state}
                    setAdv={setAdv}
                    adv={adv}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      }
    </>
  );
};

export default Feature;
