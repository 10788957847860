import React from "react";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
  render() {
    const { children, triggerLogin, triggerLogout, ...props } = this.props;
    const style={border:"none",background:'transparent'}
    return (
      <button onClick={triggerLogin} style={style} {...props}>
        {children}
      </button>
    );
  }
}

export default SocialLogin(SocialButton);