import React, { useEffect } from "react";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Sidebar from "../Sidebar/Sidebar";
import Storebar from "./Storebar";
import { Badge, Typography , Backdrop } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "../../../pages/Login/Login";
import { useState } from "react";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLocationPopup } from "../../../Redux/StoreSlice";
import { CircularProgress } from "@mui/material";
import { getStoreStartendTime } from "../../../Api/API";
import AlertPopup from "../../AlertPopup";
import { setUserFiveStarData } from "../../../Redux/UserSlice";
import { setIsUserFiveStarData,setRemoveReward } from "../../../Redux/UserSlice";
import { setTabValue } from "../../../Redux/CartSlice";
const HeaderBar = (props) => {
  const { state , setState, storeLoading, setStoreLoading} = props
  let noOptions = props.noOptions
  let url = props.url
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAd, setShowAd] = useState(false);
  const [storeStartEnd, setStoreStartEnd] = useState("");
  const cartItem = useSelector((state) => state.cart.cart);
  const [title, setTitle] = useState("");
  const isLogin = useSelector((state) => state.user.isLogin);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const [storeClose, setStoreClose] = useState(false);
  const nearestStore = useSelector((state) => state.store.nearestStore);
  const [loading , setLoading] = useState(false);
  const [refreshTax, setRefreshTax] = useState(false)
const [displayTitle , setDisplayTitle] = useState("")


  const handleCart = () => {
    dispatch(setTabValue(0)) 
    if(refreshTax==false){
      setRefreshTax(true)
    }else{
      setRefreshTax(false)
    }
    // dispatch(setRemoveReward("")) 
    if (nearestStore.store_name == null || nearestStore.store_name == "") {
        setStoreClose(true)
    }
    else
    {
    setLoading(true)
    let status = [];
    let day = {};
    let timeZoneDate = new Date().toLocaleString("en-US", {timeZone: nearestStore?.time_zone});
  let timeZoneDateHours = new Date(timeZoneDate)?.getHours()
  let timeZoneDateMinutes = new Date(timeZoneDate)?.getMinutes()
  let timeZoneDateDay = new Date(timeZoneDate)?.getDay()
    getStoreStartendTime(nearestStore?.id)
      .then((res) => {
        setLoading(false)
        setStoreStartEnd(res.result);
        status = [
          {
            Start: res.result[0]?.sun_s,
            Close: res.result[0]?.sun_e,
            storeDayStatus: res.result[0].sun_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.mon_s,
            Close: res.result[0]?.mon_e,
            storeDayStatus: res.result[0]?.mon_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.tue_s,
            Close: res.result[0]?.tue_e,
            storeDayStatus: res.result[0]?.tue_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.wed_s,
            Close: res.result[0]?.wed_e,
            storeDayStatus: res.result[0]?.wed_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.thu_s,
            Close: res.result[0]?.thu_e,
            storeDayStatus: res.result[0]?.thu_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.fri_s,
            Close: res.result[0]?.fri_e,
            storeDayStatus: res.result[0]?.fri_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.sat_s,
            Close: res.result[0]?.sat_e,
            storeDayStatus: res.result[0]?.sat_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
        ];
        day = status[timeZoneDateDay];
        let currentTime = 
        (
          (timeZoneDateHours < 10 ? "0" + timeZoneDateHours : timeZoneDateHours) +
          ":" +
          (timeZoneDateMinutes >= 10
            ? timeZoneDateMinutes
            : "0" + timeZoneDateMinutes)
        )?.toString();
        let time =
          currentTime >= day?.Start?.slice(0, 5) &&
          day?.Close?.slice(0, 5) >= currentTime;
        // console.log(
        //   currentTime,
        //   day?.Start?.slice(0, 5),
        //   day?.Close?.slice(0, 5)
        // );
        // console.log(currentTime >= day?.Start?.slice(0, 5));
        // console.log(day?.Close?.slice(0, 5) >= currentTime);
        // console.log(day,"manan")
        if (day?.storeDayStatus == false &&
          day?.onLineStatus == "on" &&
          time == true) {
            let path = "/cart";
            navigate(path);
            setLoading(false)
          // setStoreClose(true);
          // setTitle("storeUnavailable");
          // setDisplayTitle("This store is closed. Please select another store")
        } else {
          if (day?.storeDayStatus == false && day.onLineStatus == "off") {
            setTitle("webNOtPossible");
            setLoading(false)
                      setStoreClose(true);
            setDisplayTitle("Online order is not available for selected store please choose another store")
          } else if(day?.storeDayStatus == true){
            setTitle("selectStore");
            setStoreClose(true);
            setLoading(false)
            setDisplayTitle("This store is closed. Please select another store")
          }
          else if(day?.storeDayStatus == false && time == false){
            setTitle("selectStore");
            setStoreClose(true);
            setLoading(false)
            setDisplayTitle("This store is closed. Please select another store")
          }
        }
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)});

    }
  }

  let totalItem = cartItem?.map((elm)=>{
    return (
    elm?.quantity
  )})
  let totalCartItem = totalItem?.reduce((a, b) => a + b, 0);

    // storeDetail[0]?.online_order_status == "on" && storeDetail[0]?.closed?.close[new Date()?.getDay()] == false && storeDetail[0]?.open_hrs?.openHour[new Date().getDay()]?.splice(0,5)<=currentTime?.toString() && storeDetail[0]?.close_hrs?.closeHour[new Date()?.getDay()]?.splice(0,5)>= currentTime?.toString()
    //   if (nearestStore?.address == null || nearestStore?.address == "") {
    //     dispatch(setLocationPopup(true));
    //   }
    //   else
    //  {
    //   let path ='/cart'
    //   navigate(path)
    //  }
   
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <nav
        className="navbar navbar-expand navbar-light bg-white border-bottom px-lg-5"
        style={{
          paddingTop:"2.5rem",
          paddingBottom:"2rem",
          // position: "sticky",
          // top: 0
         }}
      >  
        <div className="container-fluid">
          {/* <div className="collapse navbar-collapse " id="navbarSupportedContent"> */}
          <Box
            sx={{ display: { xs: noOptions ? "Block" : "none", sm: "block" } }}
          >
            <ul className="navbar-nav">
              <NavLink
                className="nav-link active"
                exact
                to={!noOptions ? "/"  : "/payment" + url}
                aria-current="page"
                onClick={()=>dispatch(setTabValue(2))}
              >
                <img src="./NewLogo.png" alt="" height={35} />
              </NavLink>
            </ul>
          </Box>
          <Box
            sx={{ display: { xs: noOptions ? "none" : "block", sm: "none" } }}
          >
            <ul className="navbar-nav">
              <NavLink
                className="nav-link active"
                exact
                to="/"
                aria-current="page"
                onClick={()=>dispatch(setTabValue(2))}
              >
              {/* <Typography fontWeight="bold">Gong Cha</Typography> */}
                <img src="./NewLogo.png" alt="" width={100}  />
              </NavLink>
            </ul>
          </Box>
          <form className="d-flex" variant="outlined">
            {!noOptions ? <Storebar   setState={setState} state ={state} refreshTax={refreshTax} storeLoading={storeLoading} setStoreLoading={setStoreLoading} /> : ""}
            {!noOptions ? (
              <>
                <ul className="navbar-nav me-auto mb-0 mb-lg-0">
                  <li className="nav-item">
                    {isLogin || isGuestLogin  ? (
                      ""
                    ) : (
                      <NavLink
                        className="nav-link active"
                        aria-current="page"
                        exact
                        to="/"
                        onClick={() => {
                          dispatch(setTabValue(2)) 
                          setShowAd(true);
                          dispatch(setIsUserFiveStarData(false))
                          dispatch(setUserFiveStarData([]))
                        }}
                      >
                        <AccountCircleIcon sx={{marginLeft:"1.5rem"}}/>
                        <Typography
                          sx={{ display: { xs: "none", sm: "block" },marginLeft:"1.2rem" }}
                          variant="subtitle2"
                        >
                          Log in
                        </Typography>
                      </NavLink>
                    )}
                  </li>
                  <li className="nav-item">
                    <div
                      style={{ padding: "8px",cursor: "pointer",marginLeft:".5rem"}}
                      onClick={handleCart}
                    >
                      <Badge badgeContent={totalCartItem} color="primary">
                        <ShoppingCartOutlinedIcon sx={{marginLeft: (isLogin || isGuestLogin)  ? "0.5rem" : 0}}/>
                      </Badge>
                      <Typography
                        sx={{ display: { xs: "none", sm: "block" },marginLeft:(isLogin || isGuestLogin)  ? ".5rem" : 0  }}
                        variant="subtitle2"
                      >
                        Cart
                      </Typography>
                    </div>
                  </li>
                </ul>

                <Sidebar />
              </>
            ) : (
              ""
            )}
          </form>
        </div>
        <Login showAd={showAd} setShowAd={setShowAd}></Login>
        <AlertPopup
          open={storeClose}
          setOpen={setStoreClose}
          title={nearestStore ? title : "selectStore"}
          setState={setState} 
          state ={state}
          displayTitle={
            nearestStore?
            displayTitle
            :"Please select a store before continuing to the cart."
          }
          buttonType1 = {true}
        />
      </nav>
    </>
  );
};

export default HeaderBar;
