import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { Button,  List } from "@mui/material";
import React from "react";
import Zoom from '@mui/material/Zoom';
import { Avatar } from '@mui/material'
  import { makeStyles } from "@mui/styles";
  const useStyles = makeStyles ({
    item: {
      zIndex: 1, 
      transition: '0.4s',
      '&:hover': {
        backgroundColor: '#E7E7E7',
        boxShadow: '20px 12px 64px 0 #bcc3d6',
    },
  }
  });
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom  ref={ref} {...props} timeout={500}/>;
  });
const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "fr",
    name: "Francias",
    country_code: "fr",
  },
  {
    code: "de",
    name: "Deutsch",
    country_code: "de",
  },
  {
    code: "zh-cn",
    name: "汉语",
    country_code: "cn",
  },
];
function LangPopup(props) {
  const { openbutton, setopenbutton } = props;
  const styles=useStyles()

  return (
    <>
      <Dialog
        open={openbutton}
        onClose={() => setopenbutton(false)}
        PaperProps={{
          sx: {
            borderRadius: 6,
            // width:"20vw"
          },
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle 
          id="dialog-title"
          style={{ color: "#f5f5f5", backgroundColor: "#C30E2F" }}
        >
          Change Language
        </DialogTitle>
        <DialogContent textAlign="center" >
            <List >
            {languages.map(({ code, name, country_code }) => (
              <ListItemButton className={styles.item} >
                 <ListItem   key={country_code}>
                     <Avatar src={`https://flagcdn.com/${country_code}.svg`} sx={{marginRight:"2rem"}}     
                           >
                 </Avatar>
                     <ListItemText>{name}</ListItemText>
                    
              </ListItem>
              </ListItemButton>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LangPopup;
