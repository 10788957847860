import React, { useEffect } from "react";
import { Button, Card,CardActionArea, CardMedia, Grid, Typography, Box,Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AlertPopup from "../../Common/AlertPopup";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetAllCategories, GetAllMenu, getCustomizationOption } from "../../Api/API"
import { setCartDetailes, setCustomizeOptions, setMenuServingDetails, setSelectedCupSizes, setOpenPopUps, setTitles,setEditTitles, setAdvCheck, setValueSet, setTabValue } from "../../Redux/CartSlice";
import {  useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonLoader from "../../Common/ButtonLoader";
import { Container } from "@mui/system";
const useStyles = makeStyles((theme) => ({
 
}));


const New = ({deals,setValue, setState,setAdv,adv}) =>{
   const store = useSelector((state) => state.store.nearestStore);
   const userDetail = useSelector((state) => state.user.user);
   const [openAlert, setOpenAlert] = useState(false);
   const [view, setView] = useState(false);
   const [menuDetail, setMenuDetail] = useState([]);
   const [loading, setLoading] = useState(false)
   let navigate = useNavigate();
   const dispatch = useDispatch();
 

  


  const handleOrder = () =>{
   let catData = {
      storeId : store?.id,
      appType : "web",
    }
    if(deals?.buttonType == "Order Now"){
      // console.log(deals,"sobhit")
      setAdv(deals)
      if(store?.id){
    setLoading(true)
    GetAllCategories(catData).then((res)=>{
      let categoryExist = res?.result?.filter((element)=>Number(element?.category_id) == Number(deals?.categoryId))
      if (categoryExist?.length > 0 && deals?.menuId == null){
        // setValue(2)
        dispatch(setValueSet(res?.result.indexOf(categoryExist[0])))
        dispatch(setTabValue(0));
        navigate(`/menu`)
        window.scrollTo(0, 0);  
      }
      else if (categoryExist?.length > 0){
        let menuItem =  {
          appType: "web",
          categoryId: deals?.categoryId,
          customerId: 0,
          requestDate: "",
          storeId: store?.id,
        }
        setLoading(true)
        GetAllMenu(menuItem).then((res)=>{
          let menuExist =  res?.result.filter((element)=> Number(element?.id) == Number(deals?.menuId));
          if (menuExist?.length > 0 )
          {
            // setMenuDetail(menuExist)
            let data = {
              customerId: userDetail?.customer_id
                ? userDetail?.customer_id
                : userDetail?.person_id,
              menuId: deals?.menuId,
              storeId: store?.id ? store?.id : 1,
              appType: "web",
            };
            setLoading(true)
            getCustomizationOption(data)
              .then((res) => {
                
                setLoading(false)
                let cartDetail = {
                  id: menuExist[0]?.id,
                  price: menuExist[0]?.price,
                  image: menuExist[0]?.image,
                  title: menuExist[0]?.title,
                  categoryId: res?.result[0][0].category_id,
                  quantity: 1,
                  selectedCupSize: [],
                  is_favourite: res?.result[0][0].is_favourite,
                  customizationOpt: res?.result[0][0].customizationOpt,
                        categoryName: categoryExist[0]?.category_name,
                        // totalPrice: props?.price * counter,
                        btnModifierId: [],
                        btnModifier: [],
                        custom: [],

                        menuServingDetail: res?.result[0][0].menu_serving_detail,
                        // orderItemPrice: fav ? favItemsDetail?.order_item_price : "",
                        // selectedOptions: fav ? favItemsDetail?.selectedOption : [],
                        cold: res?.result[0][0]?.caffeine,
                        caffine: res?.result[0][0]?.cold,
                        max_calories: res?.result[0][0]?.max_calories,
                        min_calories: res?.result[0][0]?.min_calories,
                }
                cartDetail.selectedCupSize = 
                [res?.result[0][0]?.menu_serving_detail[0]];
                cartDetail.description = res?.result[0][0]?.description;
                dispatch(setSelectedCupSizes(
                cartDetail?.selectedCupSize?.length > 1
                  ? cartDetail?.selectedCupSize?.filter((e) => e.tier_id > 0)
                  : cartDetail?.selectedCupSize
              ));
                let menuServingDetail = res?.result[0][0]?.menu_serving_detail;
              dispatch(setCustomizeOptions(res?.result[0][0]?.customizationOpt))
              dispatch(setCartDetailes(cartDetail))
              dispatch(setMenuServingDetails(menuServingDetail))
              dispatch(setOpenPopUps(true))
              dispatch(setTitles(""))
              dispatch(setEditTitles(""))
              dispatch(setAdvCheck(true))
              dispatch(setTabValue(0));
              navigate(`/customization`)
          })
        }
        else{
          toast.error(`This menu item is not available for selected store`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
       } )
        }
      else{
        toast.error(`This category is not available for selected store`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    })
    }
    else{
      // setOpenAlert(true)
      setState(true)
     }
  }
    else if(deals?.buttonType == "View Details"){
     setView(!view);
    }
    }
    const handleView = () =>{
     setView(false);
    }
  
  const classes = useStyles();
    return(
      <>
   
    
    <Box  sx={{justifyContent:"center",alignItems:"center", display:"flex", flexDirection:{lg:"row",md:"row",sm:"row",xs:"column",}}}>
     
      <Box 
        component="img"
        sx={{  
        objectFit: "contain",
          width:{lg:"23rem",md:"20rem",sm:"20rem",xs:"18rem"},
           height:{lg:"23rem",md:"20rem",sm:"20rem",xs:"18rem"},
        }}
       
        alt="advImage"
        src={deals?.adImage}
      />
     
      <Box sx={{ textAlign:"center", backgroundColor:deals?.backgroundColor, height:{lg:"23rem",md:"20rem",sm:"20rem",xs:"18rem"},  width:{lg:"23rem",md:"20rem",sm:"20rem",xs:"18rem"},overflowY: "auto", display:"flex", alignItems:"center", flexWrap:"wrap" }} >
      <Container style={{margin:"2rem 0 2rem 0", }}>
      <Typography variant="h6" sx={{color: deals?.headerTextColor, fontWeight:"bold",lineHeight:"1.6rem" }} >{deals?.headerText}</Typography>
      <Typography variant="subtitle1" sx={{color: deals?.descriptionTextColor,marginTop:"1rem"}}>{deals?.descriptionText}</Typography>
      <div style={{textAlign:"center"}}>
      {view && <Typography variant="subtitle2" sx={{color: deals?.descriptionTextColor}}>{deals?.details.split("\n").map(line=><div key={line}>{line}</div>)}</Typography>}
      {view &&
      <Button style={{color: deals?.buttonTextColor, backgroundColor: deals?.buttonColor, borderRadius:"1rem", padding:"0.3rem 2rem", marginTop:"1.5rem",borderColor : deals?.outlinedTextColor, borderWidth:"2px",borderStyle:"solid"   }}
      onClick={handleView}
      >Hide Details</Button>}
      {!view &&
      <Button style={{color: deals?.buttonTextColor, backgroundColor: deals?.buttonColor, borderRadius:"1rem", padding:"0.3rem 2rem", marginTop:"1.5rem",borderColor : deals?.outlinedTextColor, borderWidth:"2px",borderStyle:"solid"  }}
      onClick={handleOrder}
      >{loading ? <ButtonLoader props={"4.5rem"} /> : deals?.buttonType }</Button>}</div>
      
      </Container>
      </Box>
    </Box>
    <AlertPopup
        open={openAlert}
        setOpen={setOpenAlert}
        setState={setState}
        title={"noStoreBestSeller"}
        displayTitle={"Please select store to order from here"}
        buttonType1={true}
        buttonType2={true}
      />
   </>
  );
}
export default New;