import React, { useState } from "react";
import Alert from '@mui/material/Alert';
import {
  Dialog, Box, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import Zoom from '@mui/material/Zoom';
import { useDispatch } from "react-redux";
import { setPopUpTimmer } from "../../../Redux/UserSlice";
import { setPopUpTime } from "../../../Redux/StoreSlice";
toast.configure();
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: { borderRadius: 15 },
  noBorder: {
    border: "none",
  },
  btnLogin: {
    padding: "0.2rem 5rem",
    backgroundColor: "#B7273B",
    margin: "auto",
    fontFamily: "Lusitana",
    textTransform: "none",
  },
  btnNew: {
    padding: "0.2rem 0.5rem",
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    textDecoration: "none",
    color: "white",
    textTransform: "none",
  },
  error: {
    color: "#bf1650",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom  ref={ref} {...props} timeout={500}/>;
});
const AdvertismentPopup = (props) => {
  const dispatch = useDispatch()
  const [alert,setAlert] = useState(false);
  const {showAdvertismentPopup,setShowAdvertismentPopup,advertismentData} = props;
  const classes = useStyles();
  console.log(advertismentData);
  const closeAd = () => {
    setShowAdvertismentPopup(false);
  } 

  const handleCopy = () =>{
    if(advertismentData[0]?.clickable)
    {
      setAlert(true);
      setTimeout(()=>{
        setAlert(false);
        setShowAdvertismentPopup(false);
      },1000)
      navigator.clipboard.writeText(advertismentData[0]?.discountDetails?.couponCode);
    }
    
  }

  return (
    <>
      <Dialog
        open={showAdvertismentPopup}
        // onClose={closeAd}
        classes={{
          paper: classes.paper,
        }}
        TransitionComponent={Transition}
        onKeyDown = {(e)=>  e.key == "Escape" && setShowAdvertismentPopup(false) }
        sx={{cursor:"pointer"}}
      >
        {alert &&<Alert severity="success" color="info">
          Coupon code is copied
        </Alert>}
         {advertismentData && <Box textAlign="right">
        <CancelIcon
            fontSize="large"
           
            onClick={() => {
              setShowAdvertismentPopup(false);
              dispatch(setPopUpTime(Date.now()))
              dispatch(setPopUpTimmer(false))
            }}
          /> 
        
        
        </Box>}
        <img height="450vh" minWidth="450vw" src={advertismentData[0]?.adImage} alt=""  onClick={handleCopy} />
      </Dialog>
    </>
  );
};

export default AdvertismentPopup;
