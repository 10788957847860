import {
  CardHeader,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Button,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Divider, Box, Slider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StoreIcon from "@mui/icons-material/Store";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CallIcon from '@mui/icons-material/Call';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { getAllOrdersByCustomer, getAllOrderByCustomersec } from "../../Api/API";
import { useState } from "react";
import { useEffect } from "react";
import { Avatar } from "@mui/material";
import { uiDate,pickupTime,AmPmFormat } from "../../Common/utils";
import { errorToastMessage } from "../../Common/utils";
import { useDispatch } from "react-redux";
import { setClickActiveOrders,setCheckActive } from '../../Redux/UserSlice'
import { setTabValue } from "../../Redux/CartSlice";
// import "./OrderHistory.css"
const useStyle = makeStyles((theme) => ({
  location: { display: "flex" },
  orderStatus: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#E7E7E7",
    cursor: "pointer",
  },
  previousOrderSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#E5E6ED",
    cursor: "pointer",
  },
  btnLogin: {
    borderRadius: 20,
    padding: "0.2rem 3rem",
    backgroundColor: "brown",
    color: "white",
    textTransform: "none",
  },
  root: {
    "& .Mui-disabled": {
      color: "green",
    },
  },
}));

const OrderItem = ({ orderDetail }) => {

  console.log(orderDetail,"orderDetail45");
  return (
    <>
      <Grid container>
        <Grid item xs={3} md={2}>
          <Box
            component="img"
            sx={{
              // height: "4rem"
              height: 70,
            }}
            alt="menuImage"
            src={orderDetail?.menu_image}
          />
        </Grid>
        <Grid
          item
          xs={7}
          md={8}
          display="flex"
          direction="column"
          justifyContent="center"
        >
          <Typography fontWeight="600">{orderDetail?.menu_name}</Typography>
          <Typography variant="body2">
            {orderDetail?.customizationOpt?.map(
              (elm, index) =>
                (elm?.option?.length > 0
                  ? elm?.option?.map((item) => {
                    return (
                      " " +
                      item?.modifier_name +
                      (item?.quantity > 1
                        ? " (" + item?.quantity + ") "
                        : " ")
                    );
                  })
                  : " ") +
                (orderDetail?.order_details?.length - 1 != index ? "| " : " ")
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
          display="flex"
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Typography variant="subtitle">
            x {orderDetail?.quantity_purchased}
          </Typography>
          <Typography
            style={{ fontWeight: "bold", fontSize: "small" }}
            gutterBottom
          >{`$${Number(orderDetail?.item_customized_price).toFixed(
            2
          )}`}</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "0.5rem" }} />
    </>
  );
};

const OrderHistory = () => {
  const classes = useStyle();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [previousLoading, setPreviousLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState();
  const [clickActive, setClickActive] = useState();
  const [savedCards, setSavedCard] = useState([]);
  const [active, setActive] = useState(false);
  const [activeP, setActiveP] = useState(false);
  const [orderStatus, setOrderStatus] = useState(0);
  const [pastOrderDetail, setPastOrderDetail] = useState();
  const storeName = useSelector((state) => state.store.nearestStore);
  const userDetail = useSelector((state) => state.user.user);
  const activeOrder = useSelector((state) => state.user.clickActiveOrders);
  const checkActive = useSelector((state) => state.user.checkActive);
  // const [checkActive , setCheckActive ] = useState(false)
  const dispatch = useDispatch();

  const marks = [
    {
      value: 0,
    },
    {
      value: 50,
    },
    {
      value: 100,
    },
  ];

  let data = {
    orderType: "app",
    storeId: storeName?.id,
    // storeId: 2,
    type: "",
    userId: userDetail?.person_id
      ? userDetail?.person_id
      : userDetail?.customer_id,
    // userId: 86079
  };
  const handleOrderHistory = ()=>{
    data.type = "live";
    setLoading(true);
    setPreviousLoading(true);
    getAllOrderByCustomersec(data, 0, 5, "created_at")
      .then((res) => {
        setLoading(false);
        res.result = res.result.map((e, index) => {
          return (e.status = false), res.result[index];
        });
        setOrderDetail(res?.result);         
        res?.result.map((e)=>{
          let imageUrl 
          switch (e.card_type.toLowerCase()) {
            case "visa":
                imageUrl = './visa.png';
                break;
            case "mc":
                imageUrl = "./mastercard.png";
                break;
                case "mastercard":
                  imageUrl = "./mastercard.png";
                  break;
            case "amex":
                imageUrl = "./Amex.png";
                break;
            case "american-express":
                  imageUrl = "./Amex.png";
                  break;
            case "maestro":
                imageUrl = "./maestro.png";
                break;
            case "dinersclub":
                imageUrl = "./dinersclub.png";
                break;
            case "discover":
                imageUrl = "./discover.png";
                break;
            case "jcb":
                imageUrl = "./jcb.png";
                break;
            case  "gift_card":
                imageUrl = "./giftcard.jpg";
                break;
                default:
                  imageUrl = "";
        }
        return e.imageUrl = imageUrl
        })
        setSavedCard(res.result)
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        console.log("error 1111111111")
        errorToastMessage();
      });
    data.type = "past";
    getAllOrderByCustomersec(data, 0, 5, "created_at")
      .then((res) => {
        setPreviousLoading(false);
        setPastOrderDetail(res?.result);
        console.log(res?.result,"result333")
        res?.result.map((e)=>{

          let imageUrl 
          switch (e.card_type?.toLowerCase()) {
            case "visa":
                imageUrl = './visa.png';
                break;
            case "mc":
                imageUrl = "./mastercard.png";
                break;
                case "mastercard":
                  imageUrl = "./mastercard.png";
                  break;
            case "amex":
                imageUrl = "./Amex.png";
                break;
                case "american-express":
                  imageUrl = "./Amex.png";
                  break;
            case "maestro":
                imageUrl = "./maestro.png";
                break;
            case "dinersclub":
                imageUrl = "./dinersclub.png";
                break;
            case "discover":
                imageUrl = "./discover.png";
                break;
            case "jcb":
                imageUrl = "./jcb.png";
                break;
                case  "gift_card":
                  imageUrl = "./giftcard.jpg";
                  break;
                  default:
                    imageUrl = "";

        }
        return e.imageUrl = imageUrl
        })
      }).catch((err) => {
        console.log(err);
        setLoading(false);
        console.log("error 22222222222")
        errorToastMessage();
      });
  }
  useEffect(() => {
    handleOrderHistory()
  }, []);

  // const myinterval = setInterval(function () {
  //   // window.location.reload(OrderHistory);
  //   handleOrderHistory()
  // }, 120000);
  // clearInterval(myinterval);
  const handleActiveOrder = (e) => {
    // let orderAStatus = orderDetail?.filter(
    //   (element) => element?.status == true
    // );
    // console.log(orderAStatus, "lks");
    // orderAStatus?.map((elm) => {
    //   if (
    //     elm?.status == true &&
    //     e?.custom_order_number != elm?.custom_order_number
    //   ) {
    //     e.status = false;
    //   }
    // });
    // if (e?.status == false) {
    //   e.status = true;
    // } else {
    //   e.status = false;
    // }
    if(checkActive==false){
      dispatch(setCheckActive(true))
    }else{
      dispatch(setCheckActive(false))
    }
   
    setClickActive(e);
    // console.log(e);
    // setActive(!active)
    // dispatch(setClickActive(false));
  };
  // if (orderDetail && orderDetail?.length > 0) {
  //   setClickActive(orderDetail[0]);
  // }
// console.log(checkActive,"ssssss")
  const handlePreviousOrder = (e) => {
    // console.log(e?.id,'preivious event')
    // console.log(pastOrderDetail,"past order details")
    // if (e?.status == false) {
    //   e.status = true;
    // } else {
    //   e.status = false;
    // }
    if(checkActive==false){
      dispatch(setCheckActive(true))
    }else{
      dispatch(setCheckActive(false))
    }
   
    setClickActive(e);
    
    // setActiveP(!activeP)
  };
  useEffect(() => {
    if (activeOrder == true && orderDetail && orderDetail?.length) {
      // handleActiveOrder();
      setClickActive(orderDetail[0]);
    }
  }, [orderDetail]);

  function valuetext(value) {
    let label = value == 0 ? "pending" : value == 50 ? "preparing" : "ready";
    return label;
  }
  let newDate = new Date().toISOString();
  // console.log(activeOrder,"checkActive",checkActive,555)
  return (
    <div
      style={{
        paddingBottom: "1rem",
      }}
    >
      {orderDetail?.length == 0 && pastOrderDetail?.length == 0 ? (
        <div style={{ textAlign: "center", marginTop: "15rem" }}>
          <Typography variant="h5" fontWeight="600">
            No order available
          </Typography>
          <Typography>Please add some items from menu</Typography>
          <Button
            className={classes.btnLogin}
            variant="contained"
            onClick={() => {
              let path = `/`;
              navigate(path);
            }}
          >
            Explore Menu
          </Button>
        </div>
      ) : (
        <Container>
          <div
            style={{
              display: "flex",
              padding: "0.7rem 0rem",
              marginBottom: "1rem",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(setTabValue(2))
              let path = `/`;
              navigate(path);
            }}
          >
            <ArrowBackIosIcon style={{ fill: "#C60734" }} />
            <Typography sx={{ color: "#C60734" }} variant="h5">
              Order History
            </Typography>
          </div>

          <Grid container spacing={2}>
            <Grid item md={12} xs={12} sm={12} lg={12}>
              <Typography sx={{ fontWeight: "600" }} variant="subtitle1">
                Active Orders
              </Typography>
              {/* <Card sx={{ borderRadius: "1rem" }}> */}
              {loading ? <LinearProgress color="warning" /> : ""}
              {orderDetail?.length == 0 ? (
                <Typography
                  variant="subtitle1"
                  sx={{
                    textAlign: "center",
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    color: "#868686",
                    backgroundColor: "#E7E7E7",
                  }}
                >
                  No Active Orders Available
                </Typography>
              ) : (
                <div
                // style={{ overflowY: "auto", height: "25vh" }}
                >
                  {orderDetail?.map((e, index) => {
                    return (
                      <>
                        <Card
                         onClick={() => {
                                    handleActiveOrder(e);
                                    dispatch(setClickActiveOrders(false));
                                    
                                    (checkActive==true || activeOrder?.activeOrder) &&
                                clickActive?.custom_order_number ==
                                e?.custom_order_number ? dispatch(setCheckActive(false)):dispatch(setCheckActive(true))
                                  }}
                          sx={{
                            cursor:"pointer",
                            borderRadius: "0.5rem",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <div
                            className={classes.spaceBetween}
                            style={{
                              backgroundColor: "#D9D9D9",
                              padding: "0.3rem 0.7rem",
                            }}
                          >
                            <Typography gutterBottom fontWeight="bold">
                              {uiDate(e?.pickup_date)}
                            </Typography>
                            <Typography gutterBottom fontWeight="bold">
                              # {e?.custom_order_number}
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "space-around",
                              padding: "1rem 0.5rem",
                            }}
                          >
                            <Box sx={{width:{lg:"70%", md:"70%", sm:"90%", xs:"90%"}, marginLeft:{lg:"9rem", md:"8rem", sm:"2rem", xs:"0"}}}>
                              <div style={{ fontWeight: "bold" }}>
                                {e?.ordermenu?.length}{" "}
                                {e?.ordermenu?.length > 1 ? "items" : "item"}
                              </div>
                              <Container>
                                {e?.ordermenu?.map((elm) => {
                                  return (
                                    <Typography>{elm?.menu_name}</Typography>
                                  );
                                })}
                              </Container>
                              <div className={classes.location}>
                                <LocationOnIcon />
                                <Typography fontWeight="bold">
                               { <a  target="blank" 
               href={`https://maps.google.com?q=${e.latitude},${e.longitude}`}>{`${(e.store_address).replace("~"," ")} ${e.zipCode}`}</a>  }
                                </Typography>
                              </div>
                              {e?.mobile_number && (
                                <div className={classes.location}>
                                  <CallIcon style={{ fill: "black" }} />
                                  <a href={`tel:${e?.mobile_number}`}> {
                                    `${e?.mobile_number}`
                                  }</a>
                                </div>
                              )}
                            </Box>
                            {/* <Tooltip
                              title="Click to hide the Order details"
                              style={{ cursor: "pointer" }}
                            > */}
                              {(checkActive==true || activeOrder?.activeOrder) &&
                                clickActive?.custom_order_number ==
                                e?.custom_order_number ? (
                                <KeyboardArrowUpIcon
                                sx={{cursor: "pointer"}}
                                  onClick={() => {
                                    handleActiveOrder(e);
                                    dispatch(setClickActiveOrders(false));
                                    
                                  }}
                                />
                              ) : (

                             
                                <KeyboardArrowDownIcon
                                sx={{cursor: "pointer"}}
                                  onClick={() => {
                                    handleActiveOrder(e);
                                    dispatch(setClickActiveOrders(false));
                                    dispatch(setCheckActive(true))
                                  }}
                                />
                            
                              )}
                              {/* </Tooltip> */}
                          </div>
                          <Divider />
                          <Box
                            sx={{
                              padding: {
                                lg: "0.7rem 0.5rem",
                                xs: "0.7rem 0.5rem",
                              },
                              display: "flex",
                            }}
                          >
                            <AccessTimeIcon style={{ fill: "#C60734" }} />
                            {(e?.order_status) && e?.order_status == "pending" && 
                             (e?.pickup_type) && 
                             e?.pickup_type =="asap" &&(
                              <Typography color="#C60734">

                                Your order will be accepted soon

                              </Typography>
                            )}
                            {(e?.order_status) && e?.order_status == "preparing" && (
                              <Typography color="#C60734">

                                Your order will be ready in {e?.timer} min

                              </Typography>
                            )}
                            {(e?.order_status) && e?.order_status == "ready" && (
                              <Typography color="#C60734">

                                Your order is ready

                              </Typography>
                            )}
                            {((e?.order_status) && e?.order_status == "pending") && 
                            (e?.pickup_type) && 
                            e?.pickup_type =="later" &&
                              (
                              <Typography color="#C60734">

                                  Your order is scheduled for pick up at {AmPmFormat(pickupTime(e?.schedule_time))}


                              </Typography>
                            )}
                             {((e?.order_status) && e?.order_status == "new") && 
                            (e?.pickup_type) && 
                            e?.pickup_type =="later" &&
                              (
                              <Typography color="#C60734">

                                Your order has been accepted and will be ready by {AmPmFormat(pickupTime(e?.schedule_time))}


                              </Typography>
                            )}
                          </Box>
                        </Card>
                        {(checkActive==true || activeOrder) &&
                          clickActive?.custom_order_number ==
                          e?.custom_order_number && (
                            <Card
                              sx={{
                                borderRadius: "0.5rem",
                                marginBottom: "2rem",
                              }}
                            >
                              <Container>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "1rem",
                                  }}
                                >
                                  <Typography gutterBottom>
                                    Total Items: {e?.ordermenu?.length}
                                  </Typography>
                                  <Typography gutterBottom>
                                    Order No: {e?.custom_order_number}
                                  </Typography>
                                </Box>
                                <Divider />
                                {e?.ordermenu?.map((elm) => {
                                  return <OrderItem orderDetail={elm} />;
                                })}
                                {/* <Divider style={{ marginTop: "1vh", marginBottom: "1vh" }} /> */}
                                <Grid container justifyContent="flex-end">
                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        Sub-Total:
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >{`$
                                      ${Number(e?.subtotal).toFixed(2)
                                          ? Number(e?.subtotal).toFixed(2)
                                          : 0.0
                                        }`}</Typography>
                                    </Box>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        Tax:
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >{`$${Number(e?.tax_value).toFixed(2)
                                          ? Number(e?.tax_value).toFixed(2)
                                          : 0.0
                                        }`}</Typography>
                                    </Box>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        Discount:
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >{`-$${e?.discount_total_price &&
                                          e?.loyalty_value
                                          ? Number(
                                            e?.discount_total_price +
                                            e?.loyalty_value
                                          ).toFixed(2)
                                          : e?.discount_total_price
                                            ? Number(
                                              e?.discount_total_price
                                            ).toFixed(2)
                                            : e?.loyalty_value
                                              ? Number(e?.loyalty_value).toFixed(2)
                                              : "0.00"
                                        }`}</Typography>
                                    </Box>
                                    <Divider
                                      style={{
                                        marginTop: "1vh",
                                        marginBottom: "1vh",
                                      }}
                                    />
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        Total:
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >{`$${Number(e?.order_total_price).toFixed(2)
                                          ? Number(
                                            e?.order_total_price
                                          ).toFixed(2)
                                          : 0.0
                                        }`}</Typography>
                                    </Box>
                                    <Divider
                                      style={{
                                        marginTop: "1vh",
                                        marginBottom: "1vh",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                {/* {savedCards.map((cardDetail) => {
                                     return (
                                      <>
                                <Box component="img" sx={{ height: "100%", width: "100%" }} src={cardDetail.imageUrl} />
                                </>
                              )})} */}
                              <div style ={{display:"flex" , flexDirection : "row" , justifyContent : "left" , alignItems : "center",marginBottom:"0rem"}}>
                             
                              <Box component="img" sx={{width:"3.3rem", marginTop:"0.2rem",verticalAlign: "middle"  }} src={e?.imageUrl} />
                          { (e?.card_last_no) ? 
                                <Typography sx={{pl:"1rem"}} >{` ****${e?.card_last_no} `}</Typography>
                                : ""}
                              </div>
                            
                                <Typography
                                  style={{
                                    marginTop: "1rem",
                                    marginBottom: "2rem",
                                  }}
                                >{`You earned ${e?.accumulated_points} points on this order`}</Typography>
                           
                              </Container>
                            </Card>
                          )}
                        {/* </Container> */}
                      </>
                    );
                  })}
                </div>
              )}
              <Typography
                sx={{ fontWeight: "600", marginTop: "1.6rem" }}
                variant="subtitle1"
              >
                Previous Orders
              </Typography>
              {previousLoading ? <LinearProgress color="warning" /> : ""}
              {pastOrderDetail?.length == 0 ? (
                <Typography
                  variant="subtitle1"
                  sx={{
                    textAlign: "center",
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    color: "#868686",
                    backgroundColor: "#E7E7E7",
                  }}
                >
                  No Previous Orders Available
                </Typography>
              ) : (
                <div>
                  {pastOrderDetail?.map((e) => {
                    return (
                      <>
                        <Card
                         onClick={() => {
                          handleActiveOrder(e);
                                    dispatch(setClickActiveOrders(false));
                                    (checkActive==true || activeOrder?.activeOrder) &&
                                clickActive?.custom_order_number ==
                                e?.custom_order_number ? dispatch(setCheckActive(false)):dispatch(setCheckActive(true))
                                  }}
                          sx={{
                            // background:
                            //   e?.custom_order_number ==
                            //   clickActive?.custom_order_number
                            //     ? "#FDF4F4"
                            //     : "#F9F9F9",
                            cursor:"pointer",
                            borderRadius: "0.5rem",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <div
                            className={classes.spaceBetween}
                            style={{
                              backgroundColor: "#D9D9D9",
                              padding: "0.3rem 0.7rem",
                            }}
                          >
                            <Typography gutterBottom fontWeight="bold">
                              {uiDate(
                                e?.pickup_date ? e?.pickup_date : newDate
                              )}
                            </Typography>
                            <Typography gutterBottom fontWeight="bold">
                              # {e?.custom_order_number}
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "space-around",
                              padding: "1rem 0.5rem",
                            }}
                          >
                             <Box sx={{width:{lg:"70%", md:"70%", sm:"90%", xs:"90%"}, marginLeft:{lg:"9rem", md:"8rem", sm:"2rem", xs:"0"}}}>
                              <div style={{ fontWeight: "bold" }}>
                                {e?.ordermenu?.length}{" "}
                                {e?.ordermenu?.length > 1 ? "items" : "item"}
                              </div>
                              <Container>
                                {e?.ordermenu?.map((elm) => {
                                  return (
                                    <Typography>{elm?.menu_name}</Typography>
                                  );
                                })}
                              </Container>
                              <div className={classes.location}>
                                <LocationOnIcon />
                                <Typography fontWeight="bold">
                                { <a  target="blank" 
               href={`https://maps.google.com?q=${e?.latitude},${e?.longitude}`}>{`${(e.store_address)?.replace("~"," ")} ${e?.zipCode}`}</a>  }
                                </Typography>
                              </div>
                              {e?.mobile_number && (
                                <div className={classes.location}>
                                  <CallIcon style={{ fill: "black" }} />
                                  <a href={`tel:${e?.mobile_number}`}> {
                                    `${e?.mobile_number}`
                                  }</a>
                                </div>
                              )}
                            </Box>
                            {/* <Tooltip
                              // title="Click to hide the Order details"
                              style={{ cursor: "pointer" }}
                            > */}
                              {checkActive == true &&
                                clickActive?.custom_order_number ==
                                e?.custom_order_number ? (
                                <KeyboardArrowUpIcon
                                sx={{cursor: "pointer"}}
                                  onClick={() => {
                                    handlePreviousOrder(e);
                                  }}
                                />
                              ) : 
                              (
                                <KeyboardArrowDownIcon
                                sx={{cursor: "pointer"}}  
                                  onClick={() => {
                                    handlePreviousOrder(e);
                                    dispatch(setCheckActive(true))
                                  }}
                                />
                              )
                              }
                            {/* </Tooltip> */}
                          </div>
                          <Divider style={{ marginBottom: "1rem" }} />
                        </Card>
                        {checkActive == true &&
                          clickActive?.custom_order_number ==
                          e?.custom_order_number && (
                            <Card
                              sx={{
                                borderRadius: "0.5rem",
                                marginBottom: "2rem",
                              }}
                            >
                              <Container>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      marginTop: "1rem",
                                      marginBottom: "0.5rem",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Status :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      width: "9rem",
                                      paddingTop: "0.3rem",
                                      paddingBottom: "0.3rem",
                                      textAlign: "center",
                                      color: "white",
                                      borderRadius: "0.5rem",
                                      marginTop: "1rem",
                                      marginBottom: "0.5rem",
                                      fontWeight: "600",
                                      backgroundColor:
                                        clickActive?.order_status == "completed"
                                          ? "green"
                                          : "red",
                                    }}
                                  >
                                    {clickActive?.order_status?.toUpperCase()}{" "}
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "1rem",
                                  }}
                                >
                                  <Typography gutterBottom>
                                    Total Items: {e?.ordermenu?.length}
                                  </Typography>
                                  <Typography gutterBottom>
                                    Order No: {e?.custom_order_number}
                                  </Typography>
                                </Box>
                                <Divider />
                                {e?.ordermenu[0]?.orderDetails?.map((elm) => {
                                  return <OrderItem orderDetail={elm} />;
                                })}
                                {/* <Divider style={{ marginTop: "1vh", marginBottom: "1vh" }} /> */}
                                <Grid container justifyContent="flex-end">
                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        Sub-Total:
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >{`$${Number(e?.subtotal).toFixed(2)
                                          ? Number(e?.subtotal).toFixed(2)
                                          : 0.0
                                        }`}</Typography>
                                    </Box>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        Tax:
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >{`$${Number(e?.tax_value).toFixed(2)
                                          ? Number(e?.tax_value).toFixed(2)
                                          : 0.0
                                        }`}</Typography>
                                    </Box>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        Discount:
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >{`-$${e?.discount_total_price &&
                                          e?.loyalty_value
                                          ? Number(
                                            e?.discount_total_price +
                                            e?.loyalty_value
                                          ).toFixed(2)
                                          : e?.discount_total_price
                                            ? Number(
                                              e?.discount_total_price
                                            ).toFixed(2)
                                            : e?.loyalty_value
                                              ? Number(e?.loyalty_value).toFixed(2)
                                              : "0.00"
                                        }`}</Typography>
                                    </Box>
                                    <Divider
                                      style={{
                                        marginTop: "1vh",
                                        marginBottom: "1vh",
                                      }}
                                    />
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        Total:
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >{`$${Number(e?.order_total_price).toFixed(2)
                                          ? Number(
                                            e?.order_total_price
                                          ).toFixed(2)
                                          : 0.0
                                        }`}</Typography>
                                    </Box>
                                    <Divider
                                      style={{
                                        marginTop: "1vh",
                                        marginBottom: "1vh",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                <div style ={{display:"flex" , flexDirection : "row" , justifyContent : "left" , alignItems : "center",marginBottom:"-3rem"}}>
                             
                             <Box component="img" sx={{width:"3.3rem", marginTop:"0.2rem",verticalAlign: "middle"  }} src={e?.imageUrl} />
                         
                             { (e?.card_last_no) ? 
                                <Typography sx={{pl:"1rem"}} >{` ****${e?.card_last_no} `}</Typography>
                                : ""}
                             </div>
                                <Typography
                                  style={{
                                    marginTop: "4rem",
                                    marginBottom: "2rem",
                                  }}
                                >{`You earned ${e?.accumulated_points} points on this order`}</Typography>                              
                              </Container>
                            </Card>
                          )}
                      </>
                    );
                  })}
                </div>
              )}
              {/* </Container> */}
              {/* </Card> */}
            </Grid>
          </Grid>
          {/* </Card> */}
        </Container>
      )}
    </div>
  );
};


export default OrderHistory;
