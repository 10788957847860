import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate, useLocation } from "react-router-dom";
import { setTabValue, setUpdateCart } from '../Redux/CartSlice';
import { useDispatch,useSelector } from 'react-redux';
import {setNearestStore } from '../Redux/StoreSlice';
import { setStoreCoordinate } from '../Redux/StoreSlice';
import { makeStyles } from "@mui/styles";

import { Box, Typography } from '@mui/material';
import { setStoreData } from '../Redux/StoreSlice';
import Slide from '@mui/material/Slide';
import { setDiscountDetail, setFiveStarIndex, setFiveStarResponse, setFiveStarRewardDetail } from '../Redux/UserSlice';

const useStyles = makeStyles((theme) => ({
  paper: { borderRadius: 15 },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});

const AlertPopup = (props) => {
  const deny = useSelector((state) => state.store.deny);
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation()
  const { open, setOpen, title, handleReorder, store,setState, setCounter, displayTitle,setTotalFivestar , buttonType1, buttonType2, setCupSizeState, setFavState,changedServing , setCurrentLocation,rewardObject,handleFivestarReward,applyPromo,handleRemoveFiveStarLoyalty,setCombineDiscount,setPromoCode,setCombineDiscount1} = props;
  let navigate = useNavigate();

  const handleDisagree = () => {
    if (title == "previousOrder") {
      setOpen(false);
      setState(false)
    }
    else if(title == "pickUpLater")
    {
      setOpen(false);
    }
    else if(title == "noStoreBestSeller")
    {
      setOpen(false);  
    }
    else if(title == "removeDiscount")
    {
      setOpen(false);
      setCounter("")
    }
    else if (title == "paymentCondition")
    {
      setOpen(false);
      navigate("/cart")
    } 
    else if(title == "selectStore")
    {
      dispatch(setTabValue(2))
      setOpen(false);  
      setState(true)

    }
    else if(title == "combineDiscountPopup")
    {
      setOpen(false);
    }
    else if(title == "combineDiscountPopup1")
    {
      setPromoCode("")
      setOpen(false);
    }
    else {
      console.log(title)
      setOpen(false);
    }


    // if(title == "pickUpLater")
    // {
    //   setOpen(false);
    // }
  };
  const permissionToRemove = {
    "permissions": ["geolocation"]
  }
  
  async function remove() {
    console.log("removing");
    const testResult2 = await window.permissions.contains(permissionToRemove);
    //  const removed = await browser.permissions.remove(permissionToRemove);
    console.log(testResult2);
  }
  


  const handleAgree = () => {
    if (title == "logout") {
      // remove();
      setOpen(false);
      localStorage.clear();
      navigate("/")
      window.location.reload();
    }
    else if (title == "storeChange") {
      setOpen(false);
      dispatch(setUpdateCart([]));
      dispatch(setNearestStore(store))
      dispatch(setStoreCoordinate({
        lat: Number(store?.latitude),
        lng: Number(store?.longitude)
      }))
      if (location.pathname == "/customization"){
        navigate(`/`)
      }
      setState(false);
    }
    else if (title == "removeItem") {
      setCounter(0);
      setOpen(false);
    }
    else if (title == "previousOrder") {
      setOpen(false)
      // handleReorder()
    }
    else if (title == "locationAccess") {
      setOpen(false)
      dispatch(setState(true))
    }
    else if (title == "noStoreBestSeller") {
      setOpen(false)
      setState(true)   
    }
    else if (title == "removeStore") {
      setCurrentLocation(true);
      setOpen(false)
      dispatch(setNearestStore(""));
      dispatch(setUpdateCart([]))
    }
    else if (title == "removefavoritesItem") {
      setOpen(false)
      setFavState(true);
    }
    else if(title == "noReorder"){
      setOpen(false)
      setState(null)
    }
    else if(title == "customization"){
      setOpen(false)
      setCounter(true)
      setState(changedServing.servingDetail)
      setCupSizeState(changedServing.cupSize)
    }
    else if(title == "pickUpLater")
    {
      setOpen(false);
    }
    else if (title == "combineDiscountPopup") {
      applyPromo()
      handleFivestarReward(rewardObject.perk,rewardObject.elm,rewardObject.index)
      setCombineDiscount("");
      setOpen(false)
    }
    else if (title == "combineDiscountPopup1") {
      handleRemoveFiveStarLoyalty()
      applyPromo("from AlertPopup")
      setOpen(false)
    }
    else if(title == "removeDiscount")
    {
      setOpen(false);
      dispatch(setFiveStarIndex(Number.MAX_VALUE));
      dispatch(setFiveStarRewardDetail(""));
      dispatch(setFiveStarResponse(""));
      dispatch(setDiscountDetail(""))
      setState(0)
      setCurrentLocation("")
      setCupSizeState()
      setFavState("")
      setTotalFivestar(0)
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
        TransitionComponent={Transition}
      >
        <Box p={1} sx={{ background: "#E7E7E7" }}>
          <DialogTitle textAlign="center">
            <img src="./NewLogoG.png" alt="" width="50%" />
          </DialogTitle>
          <Typography sx={{ paddingX: "1rem" }} textAlign="center">
            {displayTitle}
          </Typography>
          <DialogActions>
            <div>
              {buttonType1 && <Button onClick={handleDisagree}>{(title == "storeUnavailable" || title == "selectStore" || title == "pickUpLater" || title == "paymentCondition") ? "OK" : "Cancel"}</Button>}
              {buttonType2 && <Button onClick={handleAgree} autoFocus> Continue </Button>}
            </div>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
export default AlertPopup;
