import React from "react";
import { Box, Container, Divider, Tab, Tabs } from "@mui/material";
import Personal from "../../../pages/Setting.js/Personal";
import Security from "../../../pages/Setting.js/Security";
import CardDetail from "../../../pages/Setting.js/CardDetail";
import { useSelector } from "react-redux";
const SettingTabBar = () => {
  const userGoogleData = useSelector((state) => state.user.userGoogleData);
  const [value, setValue] = React.useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <>
    <div style={{ backgroundColor: "white" }}>
      <Container maxWidth="xl" >
        <Box sx ={{ margin: {xs : "0px" , lg :"0 0 0 11vw"} }}>
          <Tabs
           allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            textColor="none"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { background: "#C30E2F" } }}
          >
            <Tab
              label="Personal"
              className="text-capitalize"
              value={1}
              style={{ fontFamily: "Lusitana", fontSize: 17 }}
            />
             {userGoogleData?.email ? "" :
            <Tab
              label="Security"
              className="text-capitalize"
              value={2}
              style={{ fontFamily: "Lusitana", fontSize: 17 }}
            />}
             <Tab
              label="Add/Remove Cards"
              className="text-capitalize"
              value={3}
              style={{ fontFamily: "Lusitana", fontSize: 17 }}
            />
          </Tabs>
        </Box>
      </Container>
      <Divider />
    </div>
        {value === 1 && <Personal />}
        {value === 2 && <Security />}
        {value === 3 && <CardDetail />}
    </>
  );
};
export default SettingTabBar;
 