import React from 'react'
import { Card, CardHeader, Typography } from '@mui/material'
import { Grid } from '@mui/material'
import { Box } from '@mui/material'
import { Avatar } from '@mui/material'
import { Divider } from '@mui/material'
import './Checkout.css'

const CheckoutItem = ({Detail}) => {
    return (
        <>
            <Grid container>
                <Grid item md={3} xs={3} sm={3} lg={3} style={{marginTop:"0.5rem", marginBottom:"0.5rem"}} >
                    {/* <CardHeader
                        avatar={
                            <Avatar src={Detail?.menu_image} sx={{ height: 55 }} variant="rounded" />
                        }
                    >
                    </CardHeader> */}
                    <Box
                        component="img"
                        sx={{
                        height: "6rem" 
                        }}
                        alt="menuImage"
                        src={Detail?.menu_image}
                    />
                </Grid>
                <Grid item md={7} xs={7} sm={7} lg={7} 
                 display='flex'
                    direction="column"
                    justifyContent="center"
                >
                    <Typography variant='subtitle' fontWeight="600">{Detail?.menu_name}</Typography>
                    <Typography>
                    {Detail?.customizationOpt?.map((elm,index) => 
                (elm?.option?.length > 0 ? elm?.option?.map((item) => {
                    
                    return (" "+item?.modifier_name + (item?.quantity>1 ? " (" + item?.quantity + ") ": ""))}) : "") +
                ((Detail?.customizationOpt?.length - 1) != index ? " | " : " ")
                    ) }</Typography>
                </Grid>
                <Grid item
                    md={2} xs={2} sm={2} lg={2}
                    display='flex'
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    // marginLeft="20px"
                >
                    <Typography variant='subtitle'>Qty: {Detail?.quantity}</Typography>
                    <Typography style={{ fontWeight: 'bold', fontSize: 'small' }} gutterBottom>{`$${Number(Detail?.costToDisplay ? Detail?.costToDisplay : 0).toFixed(2)}`}</Typography>
                </Grid>
            </Grid>
            <Divider />
        </>
    )
}

export default CheckoutItem