import React, { useEffect } from "react";
import { forwardRef , useImperativeHandle } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import StorefrontIcon from '@mui/icons-material/Storefront';
import InfoIcon from '@mui/icons-material/Info';
import EastIcon from '@mui/icons-material/East';
import {
  setActiveMarker,
  setMarkerSize,
  setNearestStore,
  setStoreCoordinate,
  setStoreData,
} from "../../../Redux/StoreSlice";
import { detectMobileDevice } from "../../utils";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { getStoreStartendTime } from "../../../Api/API";
import { useState } from "react";
import { handleStoreList } from "../../utils";
import AlertPopup from "../../AlertPopup";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Satellite } from "@mui/icons-material";
import Loader from "../../Loader";
import ButtonLoader from "../../ButtonLoader";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import CallIcon from '@mui/icons-material/Call';
import { AmPmFormat } from "../../utils";
import { defaultEqualityCheck } from "reselect";
import { setTabValue } from "../../../Redux/CartSlice";
const useStyles = makeStyles(() => ({
  root: {
    // height: "100%",
    margin: "3% 0%",
    transition: "0.3s",
    position: "relative",
    cursor: "pointer",
    "&:before": {
      transition: "0.2s",
      position: "absolute",
      width: "100%",
      // height: "100%",
      content: '""',
      display: "block",
      backgroundColor: "#d9daf1",
      borderRadius: "1rem",
      zIndex: 0,
      bottom: 0,
    },
    "&:hover": {
      "&:before": {
        bottom: -6,
      },
      "& $card": {
        boxShadow: "-12px 12px 64px 0 #bcc3d6",
      },
    },
  },
  card: {
    zIndex: 1,
    position: "relative",
    borderRadius: "1rem",
    boxShadow: "0 6px 20px 0 #dbdbe8",
    border:"1px solid #C60734",
    backgroundColor: "#fff",
    // transition: "0.4s",
    height: "100%",
    padding: "1rem 0.5rem 1rem 0.5rem",
    // paddingLeft: "1rem",
    transition: "transform 0.15s ease-in-out",
    // "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  selectedCard: {
    zIndex: 1,
    position: "relative",
    borderRadius: "1rem",
    border:"1px solid #C60734",
    boxShadow: "0 6px 20px 0 #dbdbe8",
    backgroundColor: "#E7E7E7",
    // transition: "0.4s",
    height: "100%",
    padding: "1rem 0.5rem 1rem 0.5rem",
    // paddingLeft: "1rem",
    transition: "transform 0.15s ease-in-out",
    // "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  logo: {
    width: 48,
    height: 100,
    borderRadius: "0.75rem",
    marginRight: "1rem",
  },
  avatar: {
    fontFamily: "Ubuntu",
    fontSize: "0.875rem",
    backgroundColor: "#6d7efc",
  },
  join: {
    background: "linear-gradient(to top, #638ef0, #82e7fe)",
    "& > *": {
      textTransform: "none !important",
    },
  },
}));

const CustomCard = ({
  thumbnail,
  title,
  subtitle,
  distance,
  item,
  setState,
  loadingState,
  activeMarkerName,
  activeMarkerId,
  setActiveMarkerName,
  setActiveMarkerId,
  selectedMarker
  // joined = false,
}) => {
  const storeName = useSelector((state) => state.store.nearestStore);
  const dispatch = useDispatch();
  const styles = useStyles();
  let navigate = useNavigate();
  const location = useLocation()
  const storeData = useSelector((state) => state.store.storeData);
  const activeMarker = useSelector((state)=>state.store.activeMarker);
  const [loading, setLoading] = useState(loadingState);
  const [showResults, setShowResults] = React.useState(false);
  const [opens, setOpens] = useState(false);
  const [open, setOpen] = useState(false);
  const [select, setSelect] = useState(false);
  const [tempStoreStatus, setTempStoreStatus] = useState([]);
  const [tempDay, setTempDay] = useState({});
  const [storeStatus, setStoreStatus] = useState([]);
  const [day, setDay] = useState({});
  const [timeRange, setTimeRange] = useState();
  const [timeTempRange, setTimeTempRange] = useState();
  const cartDetail = useSelector((state) => state.cart.cart);
  const [displayTitle, setDisplayTitle] = useState("");
  const [storeStartEndTime, setStoreStartEndTime] = useState(false);
  const [storeDetail, setStoreDetail] = useState(false);
  let date = new Date();
  let timeZoneDate = new Date().toLocaleString("en-US", {
    timeZone: item?.time_zone,
  });
  let timeZoneDateHours = new Date(timeZoneDate)?.getHours();
  let timeZoneDateMinutes = new Date(timeZoneDate)?.getMinutes();
  let timeZoneDateDay = new Date(timeZoneDate)?.getDay();
  let timeDifference = date.getHours() - timeZoneDateHours

  
  useEffect(() => {
    if (item != "" && item != null) {
      setLoading(true);
      const fetchStoreData = async () => {
     await getStoreStartendTime(item?.id).then((res) => {
        setLoading(false);
        setStoreStartEndTime(res?.result[0]);
        let status = [
          {
            Start: res.result[0]?.sun_s,
            Close: res.result[0]?.sun_e,
            storeDayStatus: res.result[0].sun_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.mon_s,
            Close: res.result[0]?.mon_e,
            storeDayStatus: res.result[0]?.mon_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.tue_s,
            Close: res.result[0]?.tue_e,
            storeDayStatus: res.result[0]?.tue_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.wed_s,
            Close: res.result[0]?.wed_e,
            storeDayStatus: res.result[0]?.wed_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.thu_s,
            Close: res.result[0]?.thu_e,
            storeDayStatus: res.result[0]?.thu_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.fri_s,
            Close: res.result[0]?.fri_e,
            storeDayStatus: res.result[0]?.fri_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
          {
            Start: res.result[0]?.sat_s,
            Close: res.result[0]?.sat_e,
            storeDayStatus: res.result[0]?.sat_c,
            onLineStatus: res.result[0]?.online_order_status,
          },
        ];
        setTempStoreStatus(status);
        // console.log(item, status);
        let currentTime = (
          (timeZoneDateHours < 10
            ? "0" + timeZoneDateHours
            : timeZoneDateHours) +
          ":" +
          (timeZoneDateMinutes >= 10
            ? timeZoneDateMinutes
            : "0" + timeZoneDateMinutes)
        )?.toString();

        let boolTime =
          currentTime >= status[timeZoneDateDay]?.Start?.slice(0, 5) &&
          status[timeZoneDateDay]?.Close?.slice(0, 5) >= currentTime;
        setTimeTempRange(boolTime);
        setTempDay(status[timeZoneDateDay]);
      });
    }
    fetchStoreData()
    } else {
      setLoading(false);
    }
  }, [item]);
  useEffect(() => {
    if (tempStoreStatus?.length > 0 && tempDay?.Start) {
      setStoreStatus(tempStoreStatus);
      setDay(tempDay);
      setTimeRange(timeTempRange);
    }
  }, [tempDay]);
  const onClick = (item) => {

    dispatch(setMarkerSize({ width: 35, height: 52 }));
    dispatch(setActiveMarker(item.id));
    setActiveMarkerName(item.store_name);
    setActiveMarkerId(item.id)
    if (
      day?.storeDayStatus == false &&
      day?.onLineStatus == "on" &&
      timeRange == true
    ) {
      if (item.store_name == storeName.store_name) {
      //  let storeList =  handleStoreList(storeData, item);
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        setState(false);
        // setStateS(false);
        // dispatch(setActiveMarker("out"))
      } else if (!storeName) {
        // let storeList =  handleStoreList(storeData, item);
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        // setState(false);
        // setOpen(false);
        // dispatch(setActiveMarker("out"))
        if (location.pathname == "/customization"||location.pathname == "/menu"){
          dispatch(setTabValue(2))
          navigate(`/`)
        }
      } else if (
        item.storeName !== storeName.store_name &&
        cartDetail.length == 0
      ) {

        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        // setState(false);
        // dispatch(setActiveMarker("out"))
        // setStateS(false);
        // setOpen(true);
        if (location.pathname == "/customization"||location.pathname == "/menu"){
          dispatch(setTabValue(2))
          navigate(`/`)
        }
      } else {
        setOpen(true);
      }
    } else {
      if (
        day?.storeDayStatus == true &&
        day?.onLineStatus == "on" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store"
        );
      } else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus !== "on" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "Online ordering is not available for this store. Please Select another store"
        );
      } else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus == "on" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus == "off" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }
      else if (
        day?.storeDayStatus == true &&
        day?.onLineStatus == "off" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }
      else if (
        day?.storeDayStatus == true &&
        day?.onLineStatus == "off" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }
       else {
        // let storeList =  handleStoreList(storeData, item);
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
      }
    }
  };

useEffect(()=>{
  // onClick(item)
  if(selectedMarker.id==item.id){

  dispatch(setMarkerSize({ width: 35, height: 52 }));
    dispatch(setActiveMarker(item.id));
    setActiveMarkerName(item.store_name);
    setActiveMarkerId(item.id)
    if (
      day?.storeDayStatus == false &&
      day?.onLineStatus == "on" &&
      timeRange == true
    ) {
      if (item.store_name == storeName.store_name) {
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        setState(false);
        // setStateS(false);
        dispatch(setActiveMarker("out"))
      } else if (!storeName) {
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        // setState(false);
        // setOpen(false);
        dispatch(setActiveMarker("out"))
        if (location.pathname == "/customization"||location.pathname == "/menu"){
          dispatch(setTabValue(2))
          navigate(`/`)
        }
      } else if (
        item.storeName !== storeName.store_name &&
        cartDetail.length == 0
      ) {
        // let storeList =  handleStoreList(storeData, item);
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        dispatch(
          setStoreCoordinate({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          })
        );
        // setState(false);
        dispatch(setActiveMarker("out"))
        // setStateS(false);
        // setOpen(true);
        if (location.pathname == "/customization"||location.pathname == "/menu"){
          dispatch(setTabValue(2))
          navigate(`/`)
        }
      } else {
        setOpen(true);
      }
    } else {
      if (
        day?.storeDayStatus == true &&
        day?.onLineStatus == "on" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store"
        );
      } else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus !== "on" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "Online ordering is not available for this store. Please Select another store"
        );
      } else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus == "on" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }else if (
        day?.storeDayStatus == false &&
        day?.onLineStatus == "off" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      } else if (
        day?.storeDayStatus == true &&
        day?.onLineStatus == "off" &&
        timeRange == false
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }
      else if (
        day?.storeDayStatus == true &&
        day?.onLineStatus == "off" &&
        timeRange == true
      ) {
        setOpens(true);
        setDisplayTitle(
          "This store is currently closed.  Please select another store."
        );
      }
       else {
        // let storeList =  handleStoreList(storeData, item);
        dispatch(setStoreData(storeData));
        dispatch(setNearestStore(item));
        // dispatch(
        //   setStoreCoordinate({
        //     lat: Number(item?.latitude),
        //     lng: Number(item?.longitude),
        //   })
        // );
      }
    }
  }
},[selectedMarker])

  const handleStoreDetail = () => {
    if(storeDetail == false){
      setStoreDetail(true)
    }
    else{setStoreDetail(false)}
  }
  
 
  // const showInMapClicked = () => {
  //   window.open("https://maps.google.com?q="+23.9888+","+76.99 );
  // };
  return (
    <>
      {loading ? (
        <div className={styles.root}>
          <div style={{ display: "flex", padding: "1rem" }}>
            <CircularProgress />
          </div>
        </div>
      ) : (
       
          <div
            className={styles.root}
            onClick={
                () => {
                    // dispatch(setMarkerSize({ width: 55, height: 81 }));
                    // dispatch(setActiveMarker(item.id));
                    // setActiveMarkerCordinates({lat:Number(item.latitude),lng:Number(item.longitude)})
                    // dispatch(setStoreCoordinate({lat:Number(item.latitude),lng:Number(item.longitude)}))
                    // setActiveMarkerName(item.store_name);
                    // setActiveMarkerId(item.id)
                    // if(activeMarker == item.id || activeMarker== item.store_name){
                      onClick(item)
                    // }        
                   
                  }
            }
           
          >
            <div
              className={
                item?.id != storeName?.id ? ((activeMarkerId == item.id && activeMarkerName==item.store_name)? styles.selectedCard:styles.card) : styles.selectedCard
              }
            >
              <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <div >
                  <div style={{ fontSize: "0.9rem" }}>
                    {title}
                    {item?.id == storeName?.id &&
                    day?.storeDayStatus == false &&
                    day?.onLineStatus == "on" ? (
                      <CheckCircleRoundedIcon 
                        sx={{ color: "green", marginLeft: "1rem" ,marginTop:"-0.6rem"}}
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <div style={{  fontSize: "0.9rem" }}>
                  <b>{subtitle}</b>
                  </div>
                  <div>
                  <b>{(item.address).replace("~"," ")}{item.zip_code}</b>
                  </div>
                  <span style={{ fontSize: "0.9rem" }}>
                    <>Distance</>
                    {" :"}
                    {` ${distance}`}{" "}
                  </span>
                  </div>
                 {/* { (activeMarker == item.id || activeMarker== item.store_name)|| */}
                 {(activeMarkerId == item.id && activeMarkerName==item.store_name)||(item?.id == storeName?.id &&
                    day?.storeDayStatus == false &&
                    day?.onLineStatus == "on" )?
                  <ArrowForwardIosIcon onClick={() => onClick(item)} fontSize="large" /> :""}
                  </div>
                  <div style={{display:"flex"}}>
                  {item && day.Start && (
                    <div>
                      {day?.storeDayStatus == true ? (
                        <>
                        <b>
                          {""}
                          {"Store closed"}
                        </b>
                        <InfoIcon fontSize="small" style={{fill:"#C60734", marginLeft:"0.5rem",marginTop:"-0.6rem"}}
                     onClick={(e)=> {
                      handleStoreDetail()
                      e.stopPropagation()
                      }
                     }
                   />
                   </>
                      ) : day?.storeDayStatus == false ? (
                        <div>
                         
                          {timeRange ? (
                           
                            `Closes at ${AmPmFormat(day?.Close?.slice(0, 5))}`
                          ) : (
                              (timeZoneDateHours < 10 ?"0" + timeZoneDateHours : timeZoneDateHours)  +
                              ":" +
                              (timeZoneDateMinutes < 10 ? "0" + timeZoneDateMinutes : timeZoneDateMinutes )
                            ).toString() < day?.Start?.slice(0, 5) ? (
                            "Store will open at " +
                            ( AmPmFormat(day?.Start?.slice(0, 5)))
                           
                          ) : (
                            <b>
                              {""}
                              {"Store closed"}
                            </b>
                          )}
                          <InfoIcon fontSize="small" style={{fill:"#C60734", marginLeft:"0.5rem",marginTop:"-0.6rem"}}
                     onClick={(e)=> {
                      handleStoreDetail()
                      e.stopPropagation()
                      }
                     }
                   />
                        </div>
                      ) : (
                        ""
                      )}
                      {day?.onLineStatus == "off" &&
                      day?.storeDayStatus == false &&
                      timeRange == true ? (
                        <Box sx={{ display: "flex" }}>
                          {" "}
                          <CancelIcon sx={{color: "yellow" }} />{" "}
                          <div>Web order currently unavailable</div>
                        </Box>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                 
                    </div>
                    
                    {storeDetail && 
                 
                      <div>
                      <EastIcon fontSize="small" style={{fill:"#C60734",marginTop:"-0.5rem"}} />
                      <a  target="blank" href={`https://maps.google.com?q=${item.latitude},${item.longitude}`}> 
                      <a  target="blank" href={`https://maps.apple.com/maps?q=${item.latitude},${item.longitude}`}>
  {/* <a  target="blank" href='https://maps.apple.com/maps?q=addressgoeshere'> */}
  Directions</a></a>
                        {item?.mobile_no && (
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <CallIcon fontSize="small" style={{fill:"#C60734"}} />
                            <a href={`tel:${item?.mobile_no}`}>{item?.mobile_no}</a>
                          </div>
                        )}
                        <Typography fontWeight="bold"  style={{textDecoration:"underline"}} 
                        sx={{marginLeft:{lg:"29%",md:"29%",sm:"23%",xs:"17%"}}}>Store Hours</Typography>
                        <Grid container spacing={2} sx={{justifyContent:"center",marginLeft:{lg:"2.6%",md:"14%",sm:"7%",xs:"1%"}}}>
                          <Grid item lg={4} xs={4} justifyContent="left" >
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>Monday</Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>Tuesday</Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>Wednesday</Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>Thursday</Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>Friday</Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>Saturday</Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>Sunday</Typography>
                          </Grid>
                          <Grid item lg={6} xs={8}>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>
                            {" "}
                            {AmPmFormat(storeStartEndTime?.mon_s?.slice(0, 5))} {AmPmFormat(storeStartEndTime?.mon_s?.slice(0, 5)).length==8 ? "-" : `\u00A0-`} {" "}
                            {AmPmFormat(storeStartEndTime?.mon_e?.slice(0, 5))}
                          </Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>
                            {" "}
                            {AmPmFormat(storeStartEndTime?.tue_s?.slice(0, 5))} {AmPmFormat(storeStartEndTime?.tue_s?.slice(0, 5))?.length==8 ? "-" : `\u00A0-`}  {" "}
                            {AmPmFormat(storeStartEndTime?.tue_e?.slice(0, 5))}
                          </Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>
                            {" "}
                            {AmPmFormat(storeStartEndTime?.wed_s?.slice(0, 5))} {AmPmFormat(storeStartEndTime?.wed_s?.slice(0, 5))?.length==8 ? "-" :`\u00A0-`}  {" "}
                            {AmPmFormat(storeStartEndTime?.wed_e?.slice(0, 5))}
                          </Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>
                            {" "}
                            {AmPmFormat(storeStartEndTime?.thu_s?.slice(0, 5))} {AmPmFormat(storeStartEndTime?.thu_s?.slice(0, 5))?.length==8 ? "-": `\u00A0-` } {" "}
                            {AmPmFormat(storeStartEndTime?.thu_e?.slice(0, 5))}
                          </Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>
                            {" "}
                            {AmPmFormat(storeStartEndTime?.fri_s?.slice(0, 5))} {AmPmFormat(storeStartEndTime?.fri_s?.slice(0, 5))?.length==8 ? "-" : `\u00A0-`}  {" "}
                            {AmPmFormat(storeStartEndTime?.fri_e?.slice(0, 5))}
                          </Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>
                            {" "}
                            {AmPmFormat(storeStartEndTime?.sat_s?.slice(0, 5))} {AmPmFormat(storeStartEndTime?.sat_s?.slice(0, 5))?.length==8 ? "-" : `\u00A0-`}  {" "}
                            {AmPmFormat(storeStartEndTime?.sat_e?.slice(0, 5))}
                          </Typography>
                          <Typography sx={{fontSize:{xs:"0.8rem",md:""}}}>
                            {" "}
                            {AmPmFormat(storeStartEndTime?.sun_s?.slice(0, 5))} {AmPmFormat(storeStartEndTime?.sun_s?.slice(0, 5))?.length==8 ? "-" : `\u00A0-`}  {" "}
                            {AmPmFormat(storeStartEndTime?.sun_e?.slice(0, 5))}
                          </Typography>
                          </Grid>
                        </Grid>
                        </div>
                        }
                        
                
              <Divider  sx={{ bgcolor: "#C60734", margin:"0.5rem 0rem" }}/>
              { item?.driveThru == true && (
              <div style={{display:"flex", justifyContent:"space-around"}}>
              <div style={{display:"flex"}}>
              <StorefrontIcon style={{fill:"#C60734"}} />
              <Typography sx={{pl:1}} >In Store</Typography>
              </div>
              <div style={{display:"flex"}}>
                <DriveEtaIcon style={{fill:"#C60734"}}/>
                <Typography  sx={{pl:1}}>Drive Thru</Typography>
              </div>
              </div>
            )}
            { item?.driveThru == false && (
            <div style={{display:"flex",justifyContent:"center"}}>
              <StorefrontIcon style={{fill:"#C60734"}} />
              {/* <div style={{marginRight:"1rem"}}> */}
              <Typography sx={{pl:1}}>In Store</Typography>
              {/* </div> */}
              </div>
            )}
            </div>
          </div>
        
      )}
     
      {opens && (
        <AlertPopup
          open={opens}
          setOpen={setOpens}
          title={"storeUnavailable"}
          buttonType1={true}
          displayTitle={displayTitle}
        />
      )}
      {open && (
        <AlertPopup
          open={open}
          setOpen={setOpen}
          title={"storeChange"}
          buttonType1={true}
          buttonType2={true}
          displayTitle={"Changing store will empty the cart items"}
          store={item}
          setState={setState}
        />
      )}
    </>
  );
};

export const StoreDistance = React.memo(function TeamCard({ setState ,selectedMarker, indexArray ,activeMarkerName,activeMarkerId,setActiveMarkerName,setActiveMarkerId ,setActiveMarkerCordinates}) {
  const stores = useSelector((state) => state.store.storeData);
  const [dayStatus, setDayStatus] = useState([]);
  const [storeNotAvailable, setStoreNotAvailable] = useState(false);

  useEffect(() => {
    if (stores.length == 0) {
      setStoreNotAvailable(true);
    } else {
      // console.log("pvr");
      setStoreNotAvailable(false);
    }
    // },5000)
  });
  
  return (
    <>
      {/* {stores && stores.length > 0 && (indexArray == (stores.length || stores.length - 1 )) && ( */}
        {stores && stores.length > 0 &&  (
        stores.map((item, index) => {
        
        
          return (
            <CustomCard
            activeMarkerId={activeMarkerId}
            activeMarkerName={activeMarkerName}
            setActiveMarkerId={setActiveMarkerId}
            setActiveMarkerName={setActiveMarkerName}
            selectedMarker={selectedMarker}
              key={index}
              thumbnail={"./logos.png"}
              title={item.store_name}
              // subtitle= {
             
              // <a  target="blank" 
              //  href={`https://maps.google.com?q=${item.latitude},${item.longitude}`}
              //  >
              //  Directions</a>
              //  }
              // title={<b>{item.store_name}</b>}
              // subtitle= {}
              distance={`${item.distance} miles (${item.duration} min ride)`}
              item={item}
              setState={setState}
              loadingState={true}
            id={index}
            /> 
          );
        })
      )  }
    </>
  );
});
export default StoreDistance;
