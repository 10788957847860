import "./Payment.css";
import React, { useEffect } from "react";
import { useState } from "react";
import { Grid, Radio } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import CardMedia from '@mui/material/CardMedia';
import succGif from "./succGif.gif"
import load from "./load.gif"

import failedPay from "./failedPay.png";
import AddCard from '../Setting.js/AddCard';
import { setDefaultCardd, setIsCheckout } from "../../Redux/UserSlice";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';


// import {PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {
  Card,
  Container,
  Typography,
  Box,
  Button,

} from "@mui/material";
import {
  cardPointePaymentByProfileId,
  checkUser,
  creditCardRefundPayment,
  getBalance,
  getSavedCardDetail,
  getGivexCardDetail,
  getGivexDetails,
  getStoreStartendTime,
  givexCardPayment,
  makeDefaultCard,
} from "../../Api/API";
import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setOrderTotalPrice, setPaymentData, setPlacedOrder } from "../../Redux/CartSlice";
import { toast } from "react-toastify";
import { setCheckoutFlag } from "../../Redux/UserSlice";
import AddIcon from '@mui/icons-material/Add';
import {
  validateCardExpiry,
  validateCardHolderName,
  validateCreditCardNumber,
} from "../../Common/FormVailidation";
import { errorToastMessage, handlePlaceOrderWithTime, handleStoreStartEndTime, MenuProps, numberWithCommas, TwentyFourHourFormat } from "../../Common/utils";
import GiftCard from "./GiftCard";
import { setGivexPaymentDetail } from "../../Redux/StoreSlice";
import CreditCardPayment from "./CreditCardPayment";
import AlertPopup from "../../Common/AlertPopup";
import { Handshake } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  btnFinalPay: {
    padding: "0.5rem 3rem",
    fontSize: "1.1rem",
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    borderRadius: "1rem",
    textTransform: "none",
    lineHeight: "1.3rem",
  },
  payButton: {
    display: "flex",
    marginTop: "1rem",
    marginBottom: "1rem",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  btn: {
    borderRadius: "0.4rem",
    boxShadow: "0 6px 20px 0 #dbdbe8",
    backgroundColor: "#fff",
    transition: "0.4s",
    padding: "0.7rem 3rem ",
    textTransform: "none",
  },
  spacing: {
    marginBottom: "0rem",
  },
  root: {
    "& .Mui-disabled": {
      background: "gray",
      color: "white",
    },
  },
  new: {
    display: "flex",
    padding: "0.5rem",

    cursor: "pointer",
    "&:hover": { backgroundColor: "#f2f2f2", transform: "scale(1.1)" },
  },
  addButton: {
    cursor: "pointer",
    ".info-slide:hover": {
      transform: "scale3d(1.1, 1.1, 1)",
    },
  },
  btnLoginBack: {
    // borderRadius: 20,
    padding: "0.2rem 1rem",
    backgroundColor: "brown",
    color: "white",
    textTransform: "none",
    marginTop: "1rem",
    marginBottom: "0.2rem",
  },
  error: {
    color: "#bf1650",
    // marginLeft: "9.7rem",
    // marginBottom : '0rem',
    display: "flex",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  cardStyling: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": { backgroundColor: "#f2f2f2", transform: "scale(1.1)" },
  },
}));

const Payment = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let location = useLocation();
  let search = useLocation().search;
  const navigate = useNavigate();
  const defaultCard = useSelector((state)=>state.user.defaultCardd);
  const [paymentTitle, setPaymentTitle] = useState("");
  const [saveCardDetail, setSaveCardDetail] = useState([]);
  const [givexCardExist, setGivexCardExist] = useState();
  const [creditPopup, setCreditPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCard, setshowCard] = useState(false);

  const [giftCardPaymentDetail, setGiftCardPaymentDetail] = useState({
    cardNumber: "",
    payment: "",
    requestId: ""
  })
  // const [checked, setChecked] = React.useState(false);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const isGivexData = useSelector((state) => state.user.givexDetail);
  const [boolBalanceFetched, setBoolBalanceFetched] = useState(false);
  const [givexCardNumber, setGiveExCardNumber] = useState();
  const amount = new URLSearchParams(search).get("amount");
  const cartCardDetail = new URLSearchParams(search).get("cardDetail");

  const cardType = new URLSearchParams(search).get("cardType");
  const [remainingPayment, setRemainingPayment] = useState(amount);
  const [giftCardAmount, setGiftCardAmount] = useState(0);
  const [givexCardDetails, setGivexCardDetails] = useState(true)
  const [giveExCardPassword, setGiveExCardPassword] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [savedCards, setSavedCard] = useState([]);
  const [deviceType, setDeviceType] = useState(
    new URLSearchParams(search).get("deviceType")
  );
  const storeMobileId = new URLSearchParams(search).get("storeId")
  const [singleCardSelected, setSingleCardSelected] = useState(defaultCard)
  const storeName = useSelector((state) => state.store.nearestStore);
  let customerId = new URLSearchParams(search).get("customerId");
  let customerPhone = new URLSearchParams(search).get("phoneNumber");
  let customerName = new URLSearchParams(search).get("name");
  const userDetail = useSelector((state) => state.user.user);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [page, setpage] = useState("")
  const [open, setOpen] = React.useState(false);
  let month = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  let year = [];
  let date = new Date();
  let thisYear = date.getFullYear();
  for (let i = 0; i <= 20; i++) {
    year.push(thisYear);
    thisYear = thisYear + 1;
  }
  const defaultValues = {
    accountNumber: "",
    cardNumber: "",
    amount: 0,
    cardExpiry: "",
    clientId: "AIPRUS",
    currency: "USD",
    customerIdentifier: "amish.dsy@gmail.com",
    customerName: "",
    password: "",
    description: "Demo payment",
    method: "card",
    requestId: "",
    saveCard: 0,
    surcharge: 0,
  };
  const {
    control,
    handleSubmit,
    formState,
    setValue,
    reset,
    getValues,
    trigger,
  } = useForm({
    defaultValues,
  });
  const { errors } = formState;
  const [expanded, setExpanded] = React.useState("panel2");
  // const [defaultCard, setDefaultCard] = useState()
  
  const [addedCard, setAddedCard] = useState([]);
  const [availableBalance, setAvailableBalance] = useState("");
  const [expiryDate, setExpiryDate] = useState({ month: month[0], year: year[0] })
  const pickUpLaterTime = useSelector((state) => state.cart.pickUpLaterTime);
  const [pickUpLaterPopup, setPickUpLaterPopup] = useState(false);
  const handleChange = (event) => {
    let multiuseToken  = event.multiUseToken;
    let cardType = event.cardType;
    dispatch(setDefaultCardd(event.givexCardNumber ? event.givexCardNumber.substring(17,21) :event.lastFourCardDigit))
    setSingleCardSelected(event.givexCardNumber ? event.givexCardNumber.substring(17,21) :event.lastFourCardDigit)
   
    if(event.givexCardNumber){
      if (deviceType == "mobile") {
        let newDate = new Date();
        let givexData = {
          amount: amount,
          cardNumber: event.givexCardNumber,
          clientId: "AIPRUS",
          customerIdentifier: customerPhone?customerPhone : "Guest@gmail.com",
          customerName: customerName?customerName : "Guest",
          description: "demo payment",
          requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))?.toString()?.replace(/[T.Z:_-]/g, ""),
          storeId: storeMobileId?storeMobileId:0,
          surcharge: 0,
        }
        getBalance(givexData)
        .then((ress) => {
          if (ress.responseCode === 0 && ress.responseMessage === "Get Balance Successfully") {
            setAvailableBalance(ress?.response?.balance);
            if( Number(ress?.response?.balance) < Number(amount))
            {
              toast.error(`Insufficient giftcard available balance`, {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              }); 
            }else{
              let path = `/payment`;
              let search = `?amount=${Number(amount).toFixed(2)}&deviceType=${deviceType}&customerId=${customerId}&name=${customerName}&phoneNumber=${customerPhone}&storeId=${storeMobileId}&CardAdded=success&paymentWith=${!event?.givexCardNumber ? "CreditCard" :"GiftCard"}&cardNumber=${event.givexCardNumber ? event.givexCardNumber:event.lastFourCardDigit}&cardImage=${event.imageUrl}&giftCardBalance=${ress?.response?.balance}`;
              navigate({
                pathname: path,
                search: search,
              });
            }     
          }
        })
      
      }
    } else if (event.lastFourCardDigit.length == "4") {
      makeDefaultCard(customerId, cardType, multiuseToken)
        .then((response) => {
          console.log(response.message);

          if (deviceType == "mobile" && response.message == "success") {
            let path = `/payment`;
            let search = `?amount=${Number(amount).toFixed(
              2
            )}&deviceType=${deviceType}&customerId=${customerId}&name=${customerName}&phoneNumber=${customerPhone}&storeId=${storeMobileId}&CardAdded=success&paymentWith=${
              !event?.givexCardNumber ? "CreditCard" : "GiftCard"
            }&cardNumber=${
              event.givexCardNumber
                ? event.givexCardNumber
                : event.lastFourCardDigit
            }&cardImage=${event.imageUrl}&fromScreen="addCard"`;
            navigate({
              pathname: path,
              search: search,
            });
          }
        }).catch((error)=>{
             console.log(error.message);
        })
      }
  
      
      // console.log(event?.lastFourCardDigit , "card");
      // console.log(event.givexCardNumber?event.givexCardNumber.substring(17,21) : "" , "givex");
      // console.log((event?.lastFourCardDigit ? event.lastFourCardDigit : "") || (event.givexCardNumber?event.givexCardNumber.substring(17,21) : "") ==
      // defaultCard );
    
  };
  // console.log( defaultCard , "harshit")
  // const handleAccordian = (event, type) => {
  //   dispatch(setOrderTotalPrice(amount));
  //   if (type == "givex") {
  //     setValue("method", "Gift Card");
  //     setValue("accountNumber", "");
  //     setValue("cardExpiry", "");
  //     setValue("customerName", "");
  //     setButtonDisabled(false);
  //     setUserCardDetails({
  //       name: "",
  //       cardNumber: "",
  //       expiryDate: "",
  //     });
  //   } else if (type == "creditCard") {
  //     dispatch(setOrderTotalPrice(amount));
  //     setValue("method", "card");
  //     setValue("cardNumber", "");
  //     setButtonDisabled(false);
  //     setValue("password", "");
  //   }
  // };
  // const handleDateChange = (event, type) => {
  //   console.log(event, type)
  //   if (type == "month") {
  //     // setExpiryDate({month : event.target.value ,...rest})
  //     expiryDate.month = event.target.value
  //     setExpiryDate(expiryDate)
  //   }
  //   else if (type == "year") {
  //     expiryDate.year = event.target.value
  //     setExpiryDate(expiryDate)
  //   }
  // }
  useEffect(() => {
    if (!showCard) {
      setpage("");
    }
  }, [showCard]);

  const handleGivexPayment = (givexData) => {
    getBalance(givexData)
    .then((ress) => {
      if (ress.responseCode === 0 && ress.responseMessage === "Get Balance Successfully") {
        setAvailableBalance(ress?.response?.balance);
        if( Number(ress?.response?.balance) < Number(amount)){
          setOpen(false)
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
          toast.error(`Insufficient giftcard available balance`, {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }); 
        }else{
          setOpen(true);
          givexCardPayment(givexData)
            .then((res) => {
              console.log(res);
              if (
                res.responseCode == 0 &&
                res.responseMessage == "Payment Success"
              ) {
                console.log(res);
                dispatch(setPlacedOrder(""));

                let paymentDetails = {
                  amount: "0",
                  amt: "0",
                  auth_code: "",
                  calculationAmt: "",
                  card_last_no: 0,
                  givex_code: 0,
                  givex_num: 0,
                  givex_trans_num: "",
                  method: "gift_card",
                  payId: res?.response?.savedId,
                  payType: res?.response?.paymentMethod,
                  paymentMethod: "gift_card",
                  trans_num: 0,
                };
                let checkoutPaymentMethodArray = [];
                checkoutPaymentMethodArray.push(paymentDetails);

                let givexDetailForRefund = {
                  type: "givex",
                  cardNumber: givexCardNumber,
                  clientId: "AIPRUS",
                  description: "demo payment",
                  originalRequestId: givexData?.requestId,
                  requestId: givexData?.requestId,
                  transactionRefNumber: res?.response?.transactionRefNumber,
                  storeId: storeName?.id,
                };
                dispatch(setGivexPaymentDetail(givexDetailForRefund));
                setOpen(false);
                dispatch(setPaymentData(checkoutPaymentMethodArray));
                dispatch(setCheckoutFlag(true));
                dispatch(setIsCheckout(true));
                let path = `/payment/success?deviceType=${deviceType}&paymentMethod2=${res?.response?.paymentMethod}&payId2=${res?.response?.savedId}`;
                navigate(path);
              } else if (
                res.responseCode == 1 &&
                res.responseMessage == "Duplicate Request Id"
              ) {
                setOpen(false);
                toast.error("please refresh your page", {
                  position: toast.POSITION.TOP_RIGHT,
                  pauseOnFocusLoss: false,
                  autoClose: 1500,
                  pauseOnHover: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                });
              } else if (
                res.responseCode == 1 &&
                res.responseMessage == "Cert not exist"
              ) {
                setOpen(false);
                toast.error("Please check your card number again", {
                  position: toast.POSITION.TOP_RIGHT,
                  pauseOnFocusLoss: false,
                  autoClose: 1500,
                  pauseOnHover: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                });
              } else if (
                res.responseCode == 1 &&
                res.responseMessage == "Invalid user ID/pswd"
              ) {
                setOpen(false);
                toast.error("oops something went wrong please try again", {
                  position: toast.POSITION.TOP_RIGHT,
                  pauseOnFocusLoss: false,
                  autoClose: 1500,
                  pauseOnHover: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                });
              } else if (
                res.responseCode == 1 &&
                res.responseMessage == "Duplicate Request Id"
              ) {
                setOpen(false);
                toast.error("oops something went wrong please try again", {
                  position: toast.POSITION.TOP_RIGHT,
                  pauseOnFocusLoss: false,
                  autoClose: 1500,
                  pauseOnHover: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                });
                window.location.reload();
              } else {
                setOpen(false);
                let path = `/payment/failure?deviceType=${deviceType}`;
                navigate(path);
              }
            })
            .catch((err) => {
              setOpen(false);
              console.log(err.message);
            });
        }
      }
    })

  }


  const handleCreditCardPayment = (data) => {
    setOpen(true)
    cardPointePaymentByProfileId(data)
      .then((res) => {

        console.log(res.responseCode, "harshit_payment")
        setshowCard(false);
        // setCreditPopup(false);
        if (res.responseCode == 0) {
          let paymentDetails = {
            amount: "0",
            amt: "0",
            auth_code: "",
            calculationAmt: "",
            card_last_no: 0,
            givex_code: 0,
            givex_num: 0,
            givex_trans_num: "",
            method: res?.response?.paymentMethod ? res?.response?.paymentMethod : "credit_card",
            payId: res?.response?.savedCardId ? res?.response?.savedCardId : 0,
            payType: res?.response?.paymentMethod ? res?.response?.paymentMethod : "credit_card",
            paymentMethod: res?.response?.paymentMethod ? res?.response?.paymentMethod : "credit_card",
            trans_num: 0
          }
          let checkoutPaymentMethodArray = [];
          checkoutPaymentMethodArray.push(paymentDetails);

          let cardRefund = {
            type: "credit_card",
            requestId: data?.requestId,
            transactionRefNumber: res?.response?.paymentRefId,
            storeId: storeName?.id
          }
          dispatch(setGivexPaymentDetail(cardRefund));
          setOpen(false)
          dispatch(setPaymentData(checkoutPaymentMethodArray))

          let path = `/payment/success?deviceType=${deviceType}&paymentMethod1=${
            res?.response?.paymentMethod
              ? res?.response?.paymentMethod
              : "credit_card"
          }&payId1=${
            res?.response?.savedCardId ? res?.response?.savedCardId : 0
          }&transactionRefNumber=${res?.response?.paymentRefId}&requestId=${
            data?.requestId
          }`;
          navigate(path);
          dispatch(setCheckoutFlag(true));
          dispatch(setIsCheckout(true));
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Duplicate Request Id"
        ) {
          setOpen(false)
          toast.error("Server error ,Please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          window.location.reload();
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Invalid card"
        ) {
          setOpen(false)
          toast.error("Please check your Card Number", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
        else if (res.responseCode == 1 &&
          res.responseMessage == "The Store does not accept this card") {
          setOpen(false)
          toast.error("oops something went wrong please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
        else if (res.responseCode == 1 &&
          res.responseMessage == "Expired card") {
          setOpen(false)
          toast.error("Your card has been expired", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
        else if (res.responseCode == 1 &&
          res.responseMessage == "Service not allowed") {
          setOpen(false)
          toast.error("Your card Service not allowed", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
        else {
          setOpen(false)
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
      })
      .catch((err) => {
        setOpen(false)
        console.log(err.message);
        if (err) {
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
      });

  }
  const handleCreditCardRefund = (creditCardDetail) => {
    creditCardRefundPayment(creditCardDetail).then((res) => {
      if (res?.responseCode == 0) {
        toast.error(
          "Oops Something Went Wrong ,Your refund has been initiated",
          {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    }).catch((e) => console.log(e))
  }

  const handleSplitPaymentMethod = (creditPayment) => {
    setOpen(true);
    cardPointePaymentByProfileId(creditPayment)
      .then((res) => {
        if (res.responseCode == 0) {
          let refundArray = [];
          let newDate = new Date()
          let givexData = {
            amount: Number(giftCardPaymentDetail?.payment),
            cardNumber: giftCardPaymentDetail?.cardNumber,
            clientId: "AIPRUS",
            customerIdentifier: customerPhone ? customerPhone : userDetail?.phone_number ? userDetail?.phone_number : userDetail?.phone_no ? userDetail?.phone_no : "Guest@gmail.com",
            customerName: customerName ? customerName : userDetail?.first_name ? (userDetail?.first_name + " " + userDetail?.last_name) : "Guest",
            description: "demo payment",
            requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))?.toString()?.replace(/[T.Z:_-]/g, ""),
            storeId: storeMobileId ? storeMobileId : storeName?.id ? storeName?.id : 0,
            surcharge: 0,
          };
          let checkoutPaymentMethodArray = [];
          let paymentDetails = {
            "amount": "0",
            "amt": "0",
            "auth_code": "",
            "calculationAmt": "",
            "card_last_no": 0,
            "givex_code": 0,
            "givex_num": 0,
            "givex_trans_num": "",
            "method": res?.response?.paymentMethod ? res?.response?.paymentMethod : "credit_card",
            "payId": res?.response?.savedCardId ? res?.response?.savedCardId : 0,
            "payType": res?.response?.paymentMethod ? res?.response?.paymentMethod : "credit_card",
            "paymentMethod": res?.response?.paymentMethod ? res?.response?.paymentMethod : "credit_card",
            "trans_num": 0
          }

          let cardRefund = {
            type: "credit_card",
            requestId: creditPayment?.requestId,
            transactionRefNumber: res?.response?.paymentRefId,
            storeId: storeName?.id
          }
          refundArray.push(cardRefund);
          checkoutPaymentMethodArray.push(paymentDetails);
          givexCardPayment(givexData).then((resp) => {
            if (
              res.responseCode == 0 &&
              res.responseMessage == "Payment Success"
            ) {
              paymentDetails = {
                "amount": "0",
                "amt": "0",
                "auth_code": "",
                "calculationAmt": "",
                "card_last_no": 0,
                "givex_code": 0,
                "givex_num": 0,
                "givex_trans_num": "",
                "method": "gift_card",
                "payId": resp?.response?.savedId,
                "payType": resp?.response?.paymentMethod,
                "paymentMethod": "gift_card",
                "trans_num": 0
              }

              let givexDetailForRefund = {
                type: "givex",
                cardNumber: givexCardNumber,
                clientId: "AIPRUS",
                description: "demo payment",
                originalRequestId: givexData?.requestId,
                requestId: givexData?.requestId,
                transactionRefNumber: resp?.response?.transactionRefNumber,
                storeId: storeName?.id
              }
              refundArray.push(givexDetailForRefund);
              dispatch(setGivexPaymentDetail(refundArray));
              checkoutPaymentMethodArray.push(paymentDetails);
              dispatch(setPaymentData(checkoutPaymentMethodArray));
              setOpen(false);
              let path = `/payment/success?deviceType=${deviceType}&paymentMethod1=${res?.response?.paymentMethod ? res?.response?.paymentMethod : "credit_card"}&payId1=${res?.response?.savedCardId ? res?.response?.savedCardId : 0}&paymentMethod2=${resp?.response?.paymentMethod}
              &payId2=${resp?.response?.savedId}&transactionRefNumber=${
                resp?.response?.transactionRefNumber
              }&requestId=${givexData?.requestId}`;
              navigate(path);
              dispatch(setCheckoutFlag(true));
              dispatch(setIsCheckout(true));
            }
            else if (
              res.responseCode == 1 &&
              res.responseMessage == "Cert not exist"

            ) {
              setOpen(false);
              setshowCard(false)
              toast.error("Please check your card number again", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
              handleCreditCardRefund(cardRefund)
            } else if (
              res.responseCode == 1 &&
              res.responseMessage == "Invalid user ID/pswd"
            ) {
              setOpen(false);
              setshowCard(false)
              toast.error("oops something went wrong please try again", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
              handleCreditCardRefund(cardRefund)
            } else if (
              res.responseCode == 1 &&
              res.responseMessage == "Duplicate Request Id"
            ) {
              setOpen(false);
              setshowCard(false)
              toast.error(
                "oops something went wrong please try again",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  pauseOnFocusLoss: false,
                  autoClose: 1500,
                  pauseOnHover: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                }
              );
              handleCreditCardRefund(cardRefund)
              window.location.reload();
            } else {
              setOpen(false);
              setshowCard(false)
              handleCreditCardRefund(cardRefund)
              let path = `/payment/failure?deviceType=${deviceType}`;
              navigate(path);
            }
          })
            .catch((err) => {
              setOpen(false);
              setshowCard(false)
              console.log(err.message);
              if (err) {
                let path = `/payment/failure?deviceType=${deviceType}`;
                navigate(path);
              }
              handleCreditCardRefund(cardRefund)
            })
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Duplicate Request Id"
        ) {
          setOpen(false)
          toast.error("Server error ,Please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          window.location.reload();
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Invalid card"
        ) {
          setOpen(false)
          toast.error("Please check your Card Number", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
        else if (res.responseCode == 1 &&
          res.responseMessage == "The Store does not accept this card") {
          setOpen(false)
          toast.error("oops something went wrong please try again", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
        else if (res.responseCode == 1 &&
          res.responseMessage == "Expired card") {
          setOpen(false)
          toast.error("Your card has been expired", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
        else if (res.responseCode == 1 &&
          res.responseMessage == "Service not allowed") {
          setOpen(false)
          toast.error("Your card Service not allowed", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
        else {
          setOpen(false)
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
      })
      .catch((err) => {
        setOpen(false)
        console.log(err.message);
        if (err) {
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
      });
  }

  useEffect(() => {
    if ((storeMobileId || storeName) && (userDetail?.person_id || userDetail?.customer_id || customerId) && !showCard) {
      setOpen(true);
      getSavedCardDetail(customerId ? customerId : userDetail?.customer_id ? userDetail?.customer_id : userDetail?.person_id)
        .then((res) => {
          if (res.responseCode === 1) {
            res.object.map((e) => {
              let imageUrl
              switch (e.cardType.toLowerCase()) {
                case "visa":
                  imageUrl = './visa.png';
                  break;
                case "mc":
                  imageUrl = "./mastercard1.png";
                  break;
                case "mastercard":
                  imageUrl = "./mastercard1.png";
                  break;
                case "amex":
                  imageUrl = "./Amex.png";
                  break;
                  case "american-express":
                    imageUrl = "./Amex.png";
                    break;
                case "maestro":
                  imageUrl = "./maestro.png";
                  break;
                case "dinersclub":
                  imageUrl = "./dinersclub.png";
                  break;
                case "discover":
                  imageUrl = "./discover.png";
                  break;
                case "jcb":
                  imageUrl = "./jcb1.png";
                  break;
                default:
                  imageUrl = "./creditcard.png";

              }
              return e.imageUrl = imageUrl
            })
            setSavedCard(res.object)
            let card = res.object.filter((e) => e.lastFourCardDigit == cartCardDetail)[0]
            if (res.object.length > 0 && (giftCardAmount < amount || giftCardAmount == 0) && !defaultCard) {
              dispatch(setDefaultCardd(res.object.filter((e) => e.isDefault == true)[0]?.lastFourCardDigit ? res.object.filter((e) => e.isDefault == true)[0].lastFourCardDigit : res.object[0].lastFourCardDigit))
            }
            setOpen(false);
            let newDate = new Date()
            // let card = savedCards.filter((e) => e.lastFourCardDigit == cartCardDetail)[0]
            console.log(card, savedCards, defaultCard, "card")
            let creditPayment = {
              profileId: card?.multiUseToken ? card?.multiUseToken : card?.profileId,
              amount: amount ? amount : Number(remainingPayment),
              clientId: "AIPRUS",
              currency: "USD",
              customerIdentifier: customerPhone ? customerPhone : userDetail?.phone_number ? userDetail?.phone_number : userDetail?.phone_no ? userDetail?.phone_no : "Guest@gmail.com",
              customerName: customerName ? customerName : card?.customerName ? card?.customerName : "Guest",
              description: customerName ? customerName : userDetail?.first_name + " " + userDetail?.last_name,
              method: "card",
              requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))?.toString()?.replace(/[T.Z:_-]/g, ""),
              surcharge: 0,
              storeId: storeMobileId ? storeMobileId : storeName?.id ? storeName?.id : 1,
              zipCode: card?.zip,
              cvv: card?.cvv ? card?.cvv : card?.cvv,
            }
            if (cardType == "credit" && cartCardDetail) {
              handleCreditCardPayment(creditPayment)
            }
          }
          if(deviceType == "mobile"){
          getGivexDetails(storeMobileId)
          .then((res) => {
            setGivexCardExist(res?.givexExist)
        if(res?.givexExist == true){
          getGivexCardDetail(customerId ? customerId : userDetail?.customer_id ? userDetail?.customer_id : userDetail?.person_id)
          .then((res) => {
            res =  res.map(object => {
              return {...object, imageUrl: "./giftcard.jpg"};
            });
            // res[0].imageUrl = "./giftcard.jpg";
            setSavedCard((e) => [...e, ...res]);
            // setSavedGiftCard(res[0]?.givexCardNumber);
            setLoading(false);
          })}
        })
      }
        })

        .catch((err) => {
          console.log(err);
          setOpen(false);
          // errorToastMessage();
        });
    }

  }, [showCard,givexCardDetails]);
  useEffect(() => {
    let newDate = new Date()
    let givexData = {
      amount: amount ? amount : Number(isGivexData?.payment),
      cardNumber: cardType == "gift" ? cartCardDetail : isGivexData?.cardNumber,
      clientId: "AIPRUS",
      customerIdentifier: customerPhone ? customerPhone : userDetail?.phone_number ? userDetail?.phone_number : userDetail?.phone_no ? userDetail?.phone_no : "Guest@gmail.com",
      customerName: customerName ? customerName : userDetail?.first_name ? (userDetail?.first_name + " " + userDetail?.last_name) : "Guest",
      description: "demo payment",
      requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))?.toString()?.replace(/[T.Z:_-]/g, ""),
      storeId: storeMobileId ? storeMobileId : storeName?.id ? storeName?.id : 0,
      surcharge: 0,
    };
    if (cardType == "gift" && cartCardDetail) {
      handleGivexPayment(givexData)
    }
  }, [])

  const onSubmit = (data) => {
    console.log(customerId, customerName, customerPhone, deviceType, amount, storeMobileId)
    if (deviceType && deviceType == "mobile" && storeMobileId && customerId && customerName && customerPhone && amount) {
      setOpen(true)
      getStoreStartendTime(deviceType == "web" ? storeName?.id : storeMobileId).then((res) => {
        let storeStatus = handleStoreStartEndTime(res, storeName?.time_zone);
        let timeRange = storeStatus?.timeRange;
        let storeActiveStatus = storeStatus?.storeActiveStatus ? storeStatus?.storeActiveStatus : "Active";
        let statusOnMobile = storeStatus?.statusOnMobile ? storeStatus?.statusOnMobile : "Active";

        if (storeStatus?.status[storeStatus?.timeZoneWeekDay].storeDayStatus == false &&
          storeStatus?.status[storeStatus?.timeZoneWeekDay]?.onLineStatus == "on" &&
          timeRange == true && storeActiveStatus == "Active" && statusOnMobile == "Active") {
          if (isGuestLogin) {
            handlePayments(data)
          } else {
            checkUser(customerPhone ? customerPhone : userDetail?.phone_no ? userDetail?.phone_no : userDetail?.phone_number, 91)
              .then((res) => {
                if (res.response_code == 1 && res.result.otp == false) {

                  handlePayments(data)
                } else {
                  localStorage.clear();
                  navigate("/");
                  window.location.reload();
                  setOpen(false)
                }
              })
              .catch((e) => {
                toast.error("Oops something went wrong", {
                  position: toast.POSITION.TOP_RIGHT,
                  pauseOnFocusLoss: false,
                  autoClose: 1500,
                  pauseOnHover: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                });
                console.log(e);
                setOpen(false)
              });
          }
        }
        else {
          setOpen(false)
          toast.warn("This store is closed. Please select another store", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            // pauseOnFocusLoss : false
          })
        }
      })
    }
    else if (deviceType && deviceType == "web" && storeName) {
      setOpen(true)
      getStoreStartendTime(deviceType == "web" ? storeName?.id : storeMobileId).then((res) => {
        let storeStatus = handleStoreStartEndTime(res, storeName?.time_zone);
        let timeRange = storeStatus?.timeRange;
        let storeActiveStatus = storeStatus?.storeActiveStatus ? storeStatus?.storeActiveStatus : "Active";
        let statusOnMobile = storeStatus?.statusOnMobile ? storeStatus?.statusOnMobile : "Active";

        if (storeStatus?.status[storeStatus?.timeZoneWeekDay].storeDayStatus == false &&
          storeStatus?.status[storeStatus?.timeZoneWeekDay]?.onLineStatus == "on" &&
          timeRange == true && storeActiveStatus == "Active" && statusOnMobile == "Active") {
          if (isGuestLogin) {
            handlePayments(data)
          } else {
            checkUser(userDetail?.phone_no ? userDetail?.phone_no : userDetail?.phone_number, 91)
              .then((res) => {
                if (res.response_code == 1 && res.result.otp == false) {

                  handlePayments(data)
                } else {
                  localStorage.clear();
                  navigate("/");
                  window.location.reload();
                  setOpen(false)
                }
              })
              .catch((e) => {
                toast.error("Oops something went wrong", {
                  position: toast.POSITION.TOP_RIGHT,
                  pauseOnFocusLoss: false,
                  autoClose: 1500,
                  pauseOnHover: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                });
                console.log(e);
                setOpen(false)
              });
          }
        }
        else {
          setOpen(false)
          toast.warn("This store is closed. Please select another store", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            // pauseOnFocusLoss : false
          })
        }
      })
    } else {
      toast.error("Please check your url", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handlePayments = (data) => {
    if (pickUpLaterTime && !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)) {
      setPickUpLaterPopup(true);
    }
    else {
      dispatch(setOrderTotalPrice(amount));
      // console.log(data)
      let newDate = new Date()
      data.requestId =
        // deviceType == "web"
        (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))?.toString()?.replace(/[T.Z:_-]/g, "")
      // : requestId

      let givexData = {
        amount: Number(giftCardAmount),
        cardNumber: giftCardPaymentDetail?.cardNumber,
        clientId: "AIPRUS",
        customerIdentifier: customerPhone ? customerPhone : userDetail?.phone_number ? userDetail?.phone_number : userDetail?.phone_no ? userDetail?.phone_no : "Guest@gmail.com",
        customerName: customerName ? customerName : userDetail?.first_name ? (userDetail?.first_name + " " + userDetail?.last_name) : "Guest",
        description: "demo payment",
        requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))?.toString()?.replace(/[T.Z:_-]/g, ""),
        storeId: storeMobileId ? storeMobileId : storeName?.id ? storeName?.id : 0,
        surcharge: 0,
      };



      if (giftCardAmount == 0) {
        // setCreditPopup(true);
        console.log(page, "mamam")
        if (defaultCard && page != "SavePay") {
          let card = savedCards.filter((e) => e.lastFourCardDigit == defaultCard)[0]
          let creditPayment = {
            profileId: card?.multiUseToken ? card?.multiUseToken : card?.profileId,
            amount: Number(remainingPayment),
            clientId: "AIPRUS",
            currency: "USD",
            customerIdentifier: customerPhone ? customerPhone : userDetail?.phone_number ? userDetail?.phone_number : userDetail?.phone_no ? userDetail?.phone_no : "Guest@gmail.com",
            customerName: customerName ? customerName : card.customerName ? card.customerName : "Guest",
            description: customerName ? customerName : userDetail?.first_name + " " + userDetail?.last_name,
            method: "card",
            requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))?.toString()?.replace(/[T.Z:_-]/g, ""),
            surcharge: 0,
            storeId: storeMobileId ? storeMobileId : storeName?.id ? storeName?.id : 1,
            zipCode: card?.zip,
            cvv: card?.cvv ? card?.cvv : card?.cvv,
          }
          handleCreditCardPayment(creditPayment)
        } else if (!defaultCard) {
          setPaymentTitle("credit_card");
          setpage("Pay")
          if (deviceType == "web") {
            setshowCard(true);
          } else {
            let path = `/addCard?amount=${remainingPayment}&deviceType=mobile&page=Pay&customerPhone=${customerPhone}&customerId=${customerId}&storeId=${storeMobileId}&paymentTitle=credit_card&fromScreen="addCard"`;
            navigate(path);
          }
        } else if (defaultCard && page == "SavePay") {
          setPaymentTitle("credit_card");
        }
      }
      else if (giftCardAmount != 0) {
        if (giftCardAmount == amount) {
          handleGivexPayment(givexData);
        }
        else if (giftCardAmount != amount) {
          if (defaultCard) {
            let card = savedCards.filter((e) => e.lastFourCardDigit == defaultCard)[0]
            let creditPayment = {
              profileId: card?.multiUseToken ? card?.multiUseToken : card?.profileId,
              amount: Number(remainingPayment),
              clientId: "AIPRUS",
              currency: "USD",
              customerIdentifier: customerPhone ? customerPhone : userDetail?.phone_number ? userDetail?.phone_number : userDetail?.phone_no ? userDetail?.phone_no : "Guest@gmail.com",
              customerName: customerName ? customerName : card.customerName ? card.customerName : "Guest",
              description: customerName ? customerName : userDetail?.first_name + " " + userDetail?.last_name,
              method: "card",
              requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))?.toString()?.replace(/[T.Z:_-]/g, ""),
              surcharge: 0,
              storeId: storeMobileId ? storeMobileId : storeName?.id ? storeName?.id : 1,
              zipCode: card?.zip,
              cvv: card?.cvv ? card?.cvv : card?.cvv,
            }
            handleSplitPaymentMethod(creditPayment)
          } else if (!defaultCard) {
            setpage("Pay");
            setPaymentTitle("split");
            if (deviceType == "web") {
              setshowCard(true);
            } else {
              let path = `/addCard?amount=${remainingPayment}&deviceType=mobile&page=Pay&customerId=${customerId}&customerPhone=${customerPhone}&storeId=${storeMobileId}&paymentTitle=split&fromScreen="addCard"`;
              navigate(path);
            }
          }

        }
      }
    }
  }
// console.log(!savedCards.imageUrl,"savedCardsss")
//   console.log(singleCardSelected ==
//     defaultCard,singleCardSelected,defaultCard, "hi")
  // const handleUserCardDetails = (data, textFieldType) => {
  //   setUserCardDetails({
  //     name: textFieldType == "name" ? data.target.value : userCardDetails.name,
  //     cardNumber:
  //       textFieldType == "cardNumber"
  //         ? data.target.value
  //         : userCardDetails.cardNumber,
  //     expiryDate:
  //       textFieldType == "expiryDate"
  //         ? data.target.value
  //         : userCardDetails.expiryDate,
  //   });
  // };

  // useEffect(() => {

  // getSavedCardDetail().then((res)=>{
  //   if(res?.responseCode == 1)
  //   {
  //     setSaveCardDetail(res?.object);
  //   }
  // }).catch((e)=> console.log(e))

  //   console.log(year);
  //   setValue("amount", Number(amount));
  //   // setValue(
  //   //   "requestId",
  //   //   deviceType == "web"
  //   //     ? date.toISOString() + Math.floor(Math.random() * 10000 + 1)
  //   //     : requestId
  //   // );
  //   setUserCardDetails({
  //     name: "",
  //     cardNumber: "",
  //     expiryDate: "",
  //   });
  // }, []);

  // useEffect(() => {
  //   // console.log(userDetail)
  //   console.log(getValues("cardExpiry"),"manan")
  //   console.log("amnan");
  //   if (
  //     (userCardDetails?.name?.length > 0 &&
  //       userCardDetails?.cardNumber?.length > 0 &&
  //       userCardDetails?.expiryDate?.length > 4) ||
  //     givexCardNumber?.length > 10
  //   ) {
  //     setButtonDisabled(true);
  //     console.log("amnan");
  //   } else {
  //     setButtonDisabled(false);
  //   }
  // }, [
  //   userCardDetails.name,
  //   userCardDetails.cardNumber,
  //   userCardDetails.expiryDate,
  //   givexCardNumber,
  // ]);

  //   const options= {
  //     url: "https://apple-pay-gateway.apple.com/paymentservices/paymentSession",
  //     cert: "merchIdentityCert",
  //     key: "merchIdentityCert",
  //     method: 'post',
  //     body:{
  //             merchantIdentifier: "merchant.com.example.mystore",
  //             displayName: "MyStore",
  //             initiative: "web",
  //             initiativeContext: "mystore.example.com"
  //           },
  //      json: true,
  // }
  // if(window.ApplePaySession)
  // window.ApplePaySession.canMakePayments()
  // var request = {
  //   countryCode: 'US',
  //   currencyCode: 'USD',
  //   supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
  //   merchantCapabilities: ['supports3DS'],
  //   total: { label: 'Your Merchant Name', amount: '10.00' },
  // }
  // var session = new ApplePaySession(3, request);

  // window.onpopstate = () => {
  //   if(location.pathname == "/payment"){
  //  if( window.confirm("Do you want to cancel your transaction")){
  //   navigate("/cart");
  //  }
  //  else{
  //   console.log(location)
  //   navigate(location.pathname + location.search)
  //  }
  // }

  // }

  const [giftCard, setGiftCard] = useState(false);
  const [title, setTitle] = useState("")

  const handleGiftCardEdit = () => {
    if (pickUpLaterTime && !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)) {
      setPickUpLaterPopup(true);
    }
    else {
      setGiftCard(true);
      setTitle("edit");
      setBoolBalanceFetched(true);
    }
  }
  const handleSplitPaymentChange = (event) => {
    if (pickUpLaterTime && !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)) {
      setPickUpLaterPopup(true);
    }
    else {
      dispatch(setOrderTotalPrice(amount));
      setGiftCard(true);
    }

  };
  const handleGiftCardSelection = (name) => {
    if (name == "edit") {
      if (pickUpLaterTime && !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)) {
        setPickUpLaterPopup(true);
      }
      else {
        setGiftCard(true);
        setTitle("edit");
        setBoolBalanceFetched(true);
      }
    } else if (name == "add") {
      if (pickUpLaterTime && !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)) {
        setPickUpLaterPopup(true);
      }
      else {
        dispatch(setOrderTotalPrice(amount));
        setGiftCard(true);
      }
    }
  };

  return (
    <>
      {cartCardDetail ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: { lg: "1rem", xs: "0rem" },
          }}
        >
          <CardMedia
            component="img"
            image={load}
            sx={{
              height:"100vh",
              // height: { lg: "100vh", md: "50vh", sm: "70vh", xs: "90vh" },
              // width: { lg: "34rem", md: "50rem", sm: "70rem", xs: "25rem" },
              objectFit: "contain"
            }}
          />
        </Box>
      ) : (
        <>
          {deviceType ? (
            <>
              {/* <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "rgba(0, 0, 0, 0)" }}
                open={open}

              >
                <CircularProgress color="error" />
              </Backdrop> */}
              {location.pathname == "/payment" && !paymentRequest ? (
                <div
                  style={{ paddingBottom: "1rem", backgroundColor: "white" }}
                >
                  <Container sx={{ p: { xs: 0, md: 5, sm: 5, lg: 5 } }}>
                    <Card
                      sx={{
                        borderRadius: { xs: "0rem", md: "1rem", sm: "1rem", lg: "1rem" },
                        minHeight: "100vh",
                        maxWidth: { xs: "100vw", sm: "70vw", md: "50vw", lg: "35vw" },
                        marginX: "auto",
                        padding: "0.5rem",
                        boxShadow:"0",
                      }}
                    >
                      <Container>
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                          {/* <div style={{display:"flex"}}> */}
                          {deviceType == "web" ? (
                            <Button
                              className={classes.btnLoginBack}
                              onClick={() => {
                                let path = `/cart`;
                                navigate(path);
                              }}
                            >
                              <ArrowLeftIcon /> Back to cart
                            </Button>
                          ) : (
                            ""
                          )}
                          <Typography
                            variant="h6"
                            align="center"
                            sx={{ fontWeight: "bold", marginTop: "0.8rem", marginBottom: "2rem" }}
                          >
                            Payment Options
                          </Typography>
                          {/* <Box sx={{ display:"flex",
                    marginBottom:"0.5rem",
                    marginTop:"5rem"}}>
                     <apple-pay-button
                        buttonstyle="black"
                        type="plain"
                        locale="en"
                      ></apple-pay-button>
                     <Typography variant="h6" marginLeft="1.6rem" marginTop="0.4rem" >Apple Pay</Typography>
                    </Box>
                    <Divider  />
                  <Box >
                    <GooglePayButton
                        // buttonColor="white"
                        // className="google-pay-button-container.not-ready"
                        style={{width:"6.3rem"}}
                        environment="TEST"
                        buttonSizeMode="fill"
                        paymentRequest={{
                          apiVersion: 2,
                          apiVersionMinor: 0,
                          allowedPaymentMethods: [
                            {
                              type: "CARD",
                              parameters: {
                                allowedAuthMethods: [
                                  "PAN_ONLY",
                                  "CRYPTOGRAM_3DS",
                                ],
                                allowedCardNetworks: ["MASTERCARD", "VISA"],
                              },
                              tokenizationSpecification: {
                                type: "PAYMENT_GATEWAY",
                                parameters: {
                                  gateway: "cardconnect",
                                  gatewayMerchantId: "800000000803",
                                },
                              },
                            },
                          ],
                          merchantInfo: {
                            merchantId: "BCR2DN4T2DKJ5PQ3",
                            merchantName: "Gongcha",
                          },
                          transactionInfo: {
                            totalPriceStatus: "FINAL",
                            totalPriceLabel: "Total",
                            totalPrice: amount,
                            currencyCode: "USD",
                            countryCode: "US",
                          },

                        callbackIntents: ["PAYMENT_AUTHORIZATION"],
                      }}
                      onLoadPaymentData={(paymentRequest) => {
                        console.log("load payment data", paymentRequest);
                      }}
                      onPaymentAuthorized={(paymentData) => {
                        if (paymentData) {
                          console.log(paymentData)
                          setPaymentRequest(paymentData);
                          let paymentDatas = {
                            amount: amount,
                            paymentRefId: 1234,
                            method: "card",
                            message: "payment Successful",
                          };
                          dispatch(setPaymentData(paymentDatas));
                          let path = "/payment/success";
                          navigate(path);
                          return {
                            transactionState: "SUCCESS",
                          };
                        } else {
                          return {
                            transactionState: "ERROR",
                            error : {
                            intent: "PAYMENT_AUTHORIZATION",
                             message: "REQUEST_TIMEOUT",
                              reason: "OTHER_ERROR"
                            }
                          };
                        }
                      }
                    }
                      buttonType="plain"
                      onError={(error) => {
                        console.log(error);
                        toast.error(error.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                      }}
                    />
                     <Typography variant="h6" marginLeft="1.6rem">Google Pay</Typography>
                    </Box> */}
                          {savedCards.map((cardDetail) => {
                            return (
                              <>
                                <div
                                  className={classes.cardStyling}
                                  onClick={() => handleChange(cardDetail)}>
                                  <Box sx={{
                                    display: "flex",
                                    margin: "0.5rem ",
                                    maxWidth: "2.5rem",
                                    alignItems: "center",
                                    minHeight: "2.19rem"
                                    // justifyContent:"space-between"

                                  }}
                                  >
                                    {/* <Radio
                              checked={defaultCard ? cardDetail.lastFourCardDigit == defaultCard : false}
                              onChange={handleChange}
                              value={cardDetail.lastFourCardDigit}
                              name="radio-buttons"
                              componentsProps={{ input: { 'aria-label': 'A' } }}
                            /> */} 
                                    {/* <Box component="img" sx={{ height: "100%", width: "100%" }} src={cardDetail.imageUrl} /> */}
                                    <div style={{width:"5rem"}}>
                                    <Box  component="img" sx={{ width:"2.5rem", marginTop:"-0.2rem" }} src={cardDetail.imageUrl} />
                                     </div>
                                    <Typography variant="h8" marginLeft={"2rem"} > {cardDetail.lastFourCardDigit
                                                  ? ` ****${cardDetail.lastFourCardDigit} `
                                                  : ` ****${cardDetail.givexCardNumber.substring(
                                                      17,
                                                      21
                                                    )} `}{" "}</Typography>
                                  </Box>
                    
                                  <span>
                                    {/* {(cardDetail.givexCardNumber ? cardDetail.givexCardNumber.substring(17,21):cardDetail.lastFourCardDigit) == defaultCard} */}
                                    {cardDetail.isDefault == true && (
                                      <DoneIcon
                                        sx={{
                                          color: "#B7273B",
                                          cursor: "pointer",
                                        }}
                                      />
                                    )}
                                  </span>
                                  {/* {defaultCard &&
                                      <span>{
                                      (cardDetail.lastFourCardDigit == defaultCard) && (cardDetail.lastFourCardDigit == singleCardSelected) ? <DoneIcon sx={{color:"#B7273B"}}/> : ""}
                                      </span>
                                      } */}

                                </div>
                                <Divider />
                              </>
                            );
                          })}

                          <Box className={classes.new}
                            onClick={() => {
                              if (deviceType == "web") {
                                setshowCard(true)
                                setpage("SavePay")
                              } else if (deviceType == "mobile") {
                                let path = `/addCard?amount=${remainingPayment}&deviceType=mobile&page=Save&customerName=${customerName}&customerId=${customerId}&storeId=${storeMobileId}&fromPage=payment&customerPhone=${customerPhone}&paymentTitle=${
                                  remainingPayment < amount ? "split" : ""
                                }`;
                                navigate(path);
                              }
                            }}
                          >
                            <AddIcon
                              fontSize="large" className={classes.addButton} />
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                              <Typography variant="h8" marginLeft="2.2rem">Credit or Debit card</Typography>
                              {/* {!defaultCard &&
                                  <Typography  >{`${Number(remainingPayment) > 0 ? "$" + remainingPayment : ""}`}</Typography>} */}
                            </div>
                          </Box>
                          {givexCardExist == true && <Divider />}

                          {givexCardExist == true && (
                            <Box
                              className={classes.new}
                              onClick={() =>
                                handleGiftCardSelection(
                                  amount - remainingPayment != 0
                                    ? "edit"
                                    : "add"
                                )
                              }
                            >
                              {amount - remainingPayment != 0 ? (
                                <EditIcon
                                  onClick={handleGiftCardEdit}
                                  className={classes.addButton}
                                  fontSize="large"
                                />
                              ) : (
                                <AddIcon
                                  onClick={handleSplitPaymentChange}
                                  className={classes.addButton}
                                  fontSize="large"
                                />
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Typography variant="h8" marginLeft="2.2rem">
                                  Gift card
                                </Typography>
                                {amount - remainingPayment != 0 && (
                                  <Typography>{`$${giftCardAmount}`}</Typography>
                                )}
                              </div>
                            </Box>
                          )}
                          {/* <Divider />  */}


                          <Container
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "5vh",
                              marginBottom: "1rem",
                            }}
                          >
                            <div className={classes.root}>
                              {/* <Button
                                className={classes.btnFinalPay}
                                variant="contained"
                                disabled={amount ? false : true}
                                type="submit"
                              >
                                {`Pay $${amount ? numberWithCommas(Number(amount)) : 0}`}
                              </Button> */}
                            </div>
                          </Container>
                        </form>
                      </Container>
                    </Card>
                  </Container>
                </div>
              ) : (
                <div>
                  <Container sx={{ p: 2 }}>
                    {/* <Card
                      sx={{
                        borderRadius: "1rem",
                        minHeight: "50vh",
                        maxWidth: { xs: "100vw", sm: "70vw", md: "50vw", lg: "35vw" },
                        marginX: "auto",
                        // background:"red"
                      backgroundImage:{Paymentsuccimg2}
                      }}
                    > */}
                    <div>
                      <form
                        onload={setTimeout(() => {
                          // console.log("trial page", deviceType);
                          if (
                            location.pathname == "/payment/success" &&
                            deviceType == "web"
                          ) {
                            let path = `/checkout`;
                            navigate(path);
                          } else if (
                            location.pathname == "/payment/failure" &&
                            deviceType == "web"
                          ) {
                            let path = "/cart";
                            navigate(path);
                          }
                          setPaymentRequest();
                        }, 2500)}
                      >
                        {location.pathname == "/payment/success" ? (
                          <>
                          <Box sx={{
          display: "flex", justifyContent: "center",
          // marginTop:{ lg:"0rem",xs: "0rem" }
        }} >
          <CardMedia
            component="img"
            // image={gifsucc}
            image={succGif}
            sx={{
             // height:"100vh",
               height: { lg: "100vh", md: "50vh", sm: "70vh", xs: "90vh" },
              objectFit: "contain"
            }}
          />
        </Box>
                            {/* <Box
                              sx={{
                                display: "flex", justifyContent: "center",
                                // marginTop:{ lg:"1rem",xs: "3.6rem" }
                              }}
                            >
                              <CardMedia
                              component="img"
                              image={gifsucc}
                              sx={{
                                width: { lg: "34rem", md: "50%", sm: "70%", xs: "90%" },
                                objectFit: "contain",
                                //  marginTop:{lg: "0rem", md: "4rem", sm: "2rem", xs: "3rem" },
                                // overflow:"hidden"
                              }}
                              // style={{
                              //   // position: "absolute",
                                
                              //   marginLeft: "0px",
                              //   //  marginTop: "1rem",
                              //   overflow:"hidden"
                              //   // marginBottom: "4.8rem",
                              // }}
                              />
                            </Box> */}
                            {/* <Box
                              sx={{
                                marginLeft: { lg: "23rem", md: "18rem", sm: "12rem", xs: "5.6rem" },
                                marginTop: { lg: "9rem", md: "10rem", sm: "10rem", xs: "8.5rem" },

                              }}>
                              <div style={{ width: "80%", margin: "auto" }}>
                                <CardMedia
                                  component="img"
                                  image={success_payment2}
                                  align="center"
                                  textAlign="center"
                                  sx={{
                                    width:{ lg: "10%", md: "10%", sm: "14%", xs: "18%"}
                                  }}
                                  style={{
                                    position: "absolute",
                                  }}

                                />
                              </div>
                            </Box> */}
                            
                          </>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              // marginTop:{ lg:"1.6rem",xs: "0rem" }
                            }}
                          >
                            <CardMedia
                              component="img"
                              // image={paymentFail}
                              image={failedPay}
                              sx={{
                                height: "100vh",
                                // height: { lg: "100vh", md: "50vh", sm: "70vh", xs: "90vh" },
                                // width: { lg: "34rem", md: "50rem", sm: "70rem", xs: "25rem" },
                                objectFit: "contain",
                              }}
                            />
                          </Box>
                        )}
                      </form>
                    </div>
                    {/* </Card>  */}
                  </Container>
                </div>
              )}
              {showCard && (
                <AddCard
                  showCard={showCard}
                  setshowCard={setshowCard}
                  remainingPayment={remainingPayment}
                  paymentTitle={paymentTitle}
                  giftCardPaymentDetail={giftCardPaymentDetail}
                  page={page}
                  defaultCard={defaultCard}
                />
              )}


              <GiftCard  giftCard={giftCard} setGiftCard={setGiftCard} payment={amount} setRemainingPayment={setRemainingPayment} setGiftCardAmount={setGiftCardAmount} giftCardPaymentDetail={giftCardPaymentDetail} setGiftCardPaymentDetail={setGiftCardPaymentDetail} boolBalanceFetched={boolBalanceFetched} setBoolBalanceFetched={setBoolBalanceFetched} title={title}  savedCards={savedCards} storeMobileId={storeMobileId} customerId={customerId} customerPhone={customerPhone} customerName={customerName} deviceType={deviceType} setGivexCardDetails={setGivexCardDetails} />

              <CreditCardPayment
                creditPopup={creditPopup}
                setCreditPopup={setCreditPopup}
                saveCardDetail={saveCardDetail}
                creditCardTitle={"payment"}
                remainingPayment={remainingPayment}
              />
            </>
          ) : (
            <div style={{ textAlign: "center", fontSize: "2rem" }}>
              404 Page not found
            </div>
          )}{" "}
        </>
      )}

      <AlertPopup
        open={pickUpLaterPopup}
        setOpen={setPickUpLaterPopup}
        title={"paymentCondition"}
        buttonType1={true}
        buttonType2={false}
        displayTitle={"The pickup time that you selected is no longer available. Please select another time slot."}
      />
    </>
  );
};

export default Payment;
