import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const ButtonLoader = ({props}) => {
  return (
    <div style={{width:props, display : "flex" , alignItems : "center" ,flexDirection : "column"}}>
    <CircularProgress color="inherit" size="1.53rem"/>
    </div>
  )
}

export default ButtonLoader