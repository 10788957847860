import React, { useEffect,useRef } from "react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { setLogin, setUser } from "../../Redux/UserSlice";
import Zoom from '@mui/material/Zoom';
import { getBalance, addGivexCard } from "../../Api/API";
import CancelIcon from "@mui/icons-material/Cancel";
import { activateCard } from "../../Api/API";
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { CircularProgress } from "@mui/material";
import { setDefaultCardd } from "../../Redux/UserSlice";
import {
  Dialog,
  Box,
  Button,
  FormControl,
  DialogTitle,
  TextField,
  Backdrop,
  Typography,
  Toolbar,
  Input
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  validateCreditCardNumber,
  validatePhoneNumber,
  validateUsername,
} from "../../Common/FormVailidation";
import { guestRegister } from "../../Api/API";
import { toast } from "react-toastify"
import { errorToastMessage } from "../../Common/utils";
import SplitCreditCardPayment from "./SplitCreditCardPayment";
import { useNavigate } from "react-router-dom";
toast.configure()
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: { borderRadius: 15, width: "20rem" },
  noBorder: {
    border: "none",
  },
  cardHeader: {
    backgroundColor: "#B7273B",
    color: "white",
    padding: "1rem",
    // justifyContent: "space-between",
    marginBottom: "0.2rem",
  },
  btnLogin: {
    padding: "0.2rem 1rem",
    backgroundColor: "brown",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  guest: {
    cursor: "pointer",
  },
  error: {
    color: "#bf1650",
    textAlign: "left",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});


const GiftCard = (props) => {
  const { giftCard, setGiftCard, setChecked, payment, savedCards, setRemainingPayment, setGiftCardAmount, giftCardPaymentDetail, setGiftCardPaymentDetail, boolBalanceFetched, setBoolBalanceFetched, title, storeMobileId, customerId, customerPhone, customerName, deviceType ,setpage,setGivexCardDetails} = props;
  const defaultValues = {
    giftCardNumber: "",
    amount: payment
  };
  const { control, handleSubmit, formState, setValue, reset } = useForm({
    defaultValues,
  });
  let textRef = useRef()
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { errors } = formState;
  const storeName = useSelector((state) => state.store.nearestStore);
  const userDetail = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const closeAd = () => {
    setGiftCard(false)
    setGivexCardDetails(false)
    setValue("giftCardNumber", "")
    setValue("amount", "")
    // setChecked(false)
    setBoolBalanceFetched(false)
  };
  const [showAd, setShowAd] = useState(false);
  const [splitPaymentDialog, setSplitPaymentDialog] = useState(false);
  // const [deviceType, setDeviceType] = useState("web");
  // const [remainingPayment  , setRemainingPayment] = useState(0);
  const amount = 0;
  const [availableBalance, setAvailableBalance] = useState(0);
  // const [boolBalanceFetched , setBoolBalanceFetched] =  useState(false);
  // const[giftCardPaymentDetail , setGiftCardPaymentDetail] = useState({
  //   cardNumber:"",
  //   payment:"",
  //   requestId:""
  // })

  useEffect(() => {
    let newDate = new Date();
    let givexData = {
      amount: 0,
      cardNumber: giftCardPaymentDetail?.cardNumber,
      clientId: "AIPRUS",
      customerIdentifier: userDetail?.email ? userDetail?.email : "Guest@gmail.com",
      customerName: userDetail?.first_name ? (userDetail?.first_name + " " + userDetail?.last_name) : "Guest",
      description: "demo payment",
      requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))?.toString()?.replace(/[T.Z:_-]/g, ""),
      storeId: storeName?.id ? storeName?.id : 0,
      surcharge: 0,
    };
    if (title == "edit") {
      getBalance(givexData)
        .then((ress) => {
          console.log(ress);
          if (ress.responseCode === 0 && ress.responseMessage === "Get Balance Successfully") {
            setAvailableBalance(ress?.response?.balance);
            setBoolBalanceFetched(true);
          }
          else {
            toast.error(errors.message, {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
          }
        })
        .catch((errors) => {
          //   setOpen(false)
          toast.error(errors.message, {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        });
    }

    setValue("giftCardNumber", giftCardPaymentDetail?.cardNumber)
    setValue("amount", giftCardPaymentDetail?.payment)
  }, [])

  const onSubmit = (data) => {
    textRef?.current?.blur()
    setLoading(true);
    if (!data.amount) {
      let newDate = new Date()
      let givexData = {
        amount: 0,
        cardNumber: data.giftCardNumber,
        clientId: "AIPRUS",
        customerIdentifier: customerPhone ? customerPhone : userDetail?.email ? userDetail?.email : "Guest@gmail.com",
        customerName: customerName ? customerName : userDetail?.first_name ? (userDetail?.first_name + " " + userDetail?.last_name) : "Guest",
        description: "demo payment",
        requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))?.toString()?.replace(/[T.Z:_-]/g, ""),
        storeId: storeMobileId ? storeMobileId : storeName?.id ? storeName?.id : 0,
        surcharge: 0,
      }
      let addGivexData = {
        "createdAt": "2023-01-02T10:37:04.203Z",
        "customerId": deviceType == "web" ? userDetail?.customer_id ? userDetail?.customer_id : userDetail?.person_id : customerId,
        "customerName": customerName ? customerName : userDetail?.first_name ? (userDetail?.first_name + " " + userDetail?.last_name) : "Guest",
        "givexCardNumber": data.giftCardNumber,
        "id": 0,
        "isDeleted": 0,
        "updatedAt": "2023-01-02T10:37:04.204Z"
      };
      setGiftCardPaymentDetail({
        cardNumber: "",
        payment: "",
        requestId: givexData?.requestId
      })
      activateCard(givexData)
        .then((res) => {
          console.log(res);
          if (
            res.responseCode == 1 &&
            res.responseMessage == "Card Already Activated"
          ) {
            getBalance(givexData)
              .then((ress) => {
                console.log(ress);
                if (ress.responseCode === 0 && ress.responseMessage === "Get Balance Successfully") {
                  setAvailableBalance(ress?.response?.balance);
                  setBoolBalanceFetched(true);
                  setValue("amount", payment);
                  setLoading(false);
                }
                else {

                }
              })
              if(deviceType !== "mobile" || deviceType == "mobile"){
              addGivexCard(addGivexData)
              .then((res) => {
                if (res.responseCode == 1) {
                  toast.success("Your card is saved successfully", {
                      position: toast.POSITION.TOP_RIGHT,
                  });
                  setLoading(false)
              }
              else if (res?.responseCode == 0 && res?.responseMessage == 
                "Dublicate Givex Card Detail") {
                toast.error("This card is already saved", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false);
            }
            else {
              toast.error("Oops something went wrong", {
                  position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false)
          }
            })
              .catch((errors) => {
                //   setOpen(false)
                setLoading(false);
                toast.error(errors.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  pauseOnFocusLoss: false,
                  autoClose: 1500,
                  pauseOnHover: false,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,

                });
              });
          }
        } else if (
            res.responseCode == 1 &&
            res.responseMessage == "Invalid user ID/pswd"
          ) {
            //   setOpen(false)
            setLoading(false);
            toast.error("Oops something went wrong ...please try again", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
          } else if (
            res.responseCode == 1 &&
            res.responseMessage == "Cert not exist"
          ) {
            //   setOpen(false)
            setLoading(false);
            toast.error(
              "Please try with another Card ,This card does not exist",
              {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              }
            );
          } else if (res.responseCode == null) {
            //   setOpen(false)
            setLoading(false);
            toast.error("Something went wrong please try again", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
          } else {
            //   setOpen(false)
            setLoading(false);
            toast.error("Please activate your Card with the store", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.message)
        });
       
    }
    else if (giftCardPaymentDetail?.cardNumber && !boolBalanceFetched) {
      setBoolBalanceFetched(true);
      setLoading(false);
    }
    else {
      setLoading(false);
      setGiftCardPaymentDetail({
        cardNumber: data?.giftCardNumber,
        payment: data?.amount,
        requestId: giftCardPaymentDetail?.requestId
      })
      if (payment == Number(data?.amount)) {
        dispatch(setDefaultCardd())
      }
      else {
        dispatch(setDefaultCardd(savedCards.length > 0 ? savedCards.filter((e) => e.isDefault == true)[0]?.lastFourCardDigit : null))
      }
      setGiftCardAmount(data?.amount);
      setRemainingPayment((payment - Number(data?.amount)).toFixed(2));
      setSplitPaymentDialog(true);
      setGiftCard(false);
      setGivexCardDetails(false)
      setBoolBalanceFetched(false);
      setValue("giftCardNumber", "")
      setValue("amount", "")
      if (deviceType == "mobile") {
      let path = `/payment`;
      let search = `?amount=${Number(data?.amount).toFixed(2)}&deviceType=${deviceType}&customerId=${customerId}&name=${customerName}&phoneNumber=${customerPhone}&storeId=${storeMobileId}&CardAdded=success&paymentWith=GiftCard&cardNumber=${data?.giftCardNumber}&cardImage=./giftcard.jpg&giftCardBalance=${
        availableBalance
      }`;
  
        navigate({
          pathname: path,
          search: search,
        });
      }
    }

  }
  const handleBackButton = ()=>{
    setGiftCard(false)
    setValue("giftCardNumber", "")
    setValue("amount", "")
    setGiftCardAmount(0);
    setBoolBalanceFetched(false)
    dispatch(setDefaultCardd(savedCards.length > 0 ? savedCards.filter((e) => e.isDefault == true)[0]?.lastFourCardDigit : null))
  }

  return (
    <>

      <Dialog
      BackdropProps={{invisible: deviceType == "mobile" ? true : false}}
        open={giftCard}
        classes={{
          paper: classes.paper,
        }}
        onKeyDown={(e) => e.key == "Escape" && closeAd()}
        TransitionComponent={Transition}
      >
        <Backdrop
         sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "rgba(0, 0, 0, 0)" }}
          // sx={{ color: '#fff', zIndex: 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* <Toolbar className={classes.cardHeader}> */}
        <Box className={classes.cardHeader} textAlign="center">
          Gift Card
        </Box>

        <Box p={1.5} textAlign="center">
          {/* <DialogTitle>
            <img src="./Gongcha Logo-02 1.png" alt=""  width="70%"  />
          </DialogTitle> */}
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box>
              <Typography align="left">Gift Card No.</Typography>
              <FormControl fullWidth>
                <Controller
                  name="giftCardNumber"
                  rules={
                    ({
                      required: false,
                    },
                    {
                      validate: (value) => {
                        return validateCreditCardNumber(value, "givexCard", value);
                      },
                    })
                  }
                  render={({ field }) => (
                    <input
                      {...field}
                      maxLength={21}
                      id="giftCardNumber"
                      type="text"
                      placeholder="Enter Gift Card No.*"
                      disabled={boolBalanceFetched}
                      inputMode="numeric"
                      ref={textRef}
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                        maxLength: 21,
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      inputProps={{ maxLength: 21, inputMode: 'numeric' }}
                      style={{ backgroundColor: "#E7E7E7", height:"2.5rem",fontFamily:'sans-serif' }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        )
                      }}
                      autoFocus
                    />
                  )}
                  control={control}
                />
              </FormControl>
              {errors?.giftCardNumber ? (
                <div className={classes.error}>
                  {errors.giftCardNumber.message}
                </div>
              ) : (
                <br />
              )}
              {boolBalanceFetched ? <div style={{ display: "flex" }}>
                <div>Available Balance : </div>
                <div style={{ marginLeft: "0.4rem", color: "brown" }}> ${availableBalance}</div>
              </div> : ""}
              {/* {errors?.giftCardNumber && <p className={classes.error}>{errors?.giftCardNumber?.message}</p>} */}
              {/* {errors?.giftCardNumber ? (
                <p className={classes.error}>{errors?.giftCardNumber?.message}</p>
              ) : (
                <p>
                  <br />
                </p>
              )} */}
            </Box>
            {boolBalanceFetched ? <Box>
              {/* <Typography align="left">Amount</Typography>
              <FormControl fullWidth>
                <Controller
                  name="amount"
                    rules={
                      ({
                        required: true,
                      },
                      {
                        validate: (value) => {
                          if(value == 0 || Number(value) > Number(payment) || Number(value) < 0)
                          {
                            return "Enter valid amount";
                          }
                          // return validatePhoneNumber(value);
                        },
                      })
                    }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="amount"
                      // type="number"
                      disabled={true}
                      // disabled = {boolBalanceFetched}
                      placeholder="Amount*"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      //   inputProps={{ maxLength: 12 }}
                      sx={{ backgroundColor: "#E7E7E7" }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      autoFocus
                    />
                  )}
                  control={control}
                />
              </FormControl>
              {errors?.amount && <div className={classes.error} style={{paddingRight:"10rem"}}>{errors?.amount?.message}</div>} */}
              {boolBalanceFetched ? <div style={{ display: "flex" }}>
                <div>Total Bill : </div>
                <div style={{ marginLeft: "0.4rem", color: "brown" }}> ${Number(payment).toFixed(2)}</div>
              </div> : ""}

              {/* {errors?.amount ? (
                <p className={classes.error}>{errors?.amount?.message}</p>
              ) : (
                <p>
                  <br />
                </p>
              )} */}
            </Box> : ""}

            <div style={{ float: "right", marginTop: "1rem" }}>
              {(title != "edit") && (boolBalanceFetched == false) && <CancelIcon fontSize="large" style={{ fill: "#C30E2F", cursor: "pointer" }} onClick={() => {
                textRef?.current?.blur()
                handleBackButton();
                // window.location.reload();
              }} /> 
            
              }
              {/* {boolBalanceFetched && (title != "edit") && <ReplayCircleFilledIcon fontSize="large" style={{ fill: "blue", cursor: "pointer" }} onClick={() => {
                setBoolBalanceFetched(false);
                setValue("giftCardNumber", "")
                setValue("amount", "")
              }} />}

              
              {boolBalanceFetched && (title == "edit") && <ReplayCircleFilledIcon fontSize="large" style={{ fill: "blue", cursor: "pointer" }} onClick={() => {
                setBoolBalanceFetched(false);
              }} />} */}
              <Button
                type="submit"
              >
                <CheckCircleRoundedIcon fontSize="large" style={{ fill: "green" }} />
              </Button>
            </div>
          </form>
        </Box>
      </Dialog>
      {/* <SplitCreditCardPayment splitPaymentDialog={splitPaymentDialog} setSplitPaymentDialog={setSplitPaymentDialog} giftCardPaymentDetail={giftCardPaymentDetail}/> */}
    </>
  );
};

export default GiftCard;
