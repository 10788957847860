import React, { useEffect } from "react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import {
  addCreditCard,
  cardPointePayment,
  creditCardRefundPayment,
} from "../../Api/API";
import { setOrderTotalPrice, setPaymentData } from "../../Redux/CartSlice";
import {
  setCheckoutFlag,
  setDefaultCardd,
  setIsCheckout,
} from "../../Redux/UserSlice";
import { setGivexPaymentDetail } from "../../Redux/StoreSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { handlePlaceOrderWithTime } from "../../Common/utils";
import creditCardType, {
  getTypeInfo,
  types as CardType,
} from "credit-card-type";
import CachedIcon from "@mui/icons-material/Cached";
import { givexCardPayment } from "../../Api/API";
import {
  Card,
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  keyframes,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  validateCardExpiry,
  validateCardHolderName,
  validateCreditCardNumber,
  validateNumber,
  validateZipCode,
} from "../../Common/FormVailidation";
import { generateCaptch, MenuProps } from "../../Common/utils";
// import GiftCard from "./GiftCard";
import GiftCard from "../Payment/GiftCard";
import { getValue } from "@testing-library/user-event/dist/utils";
import AlertPopup from "../../Common/AlertPopup";

const useStyles = makeStyles((theme) => ({
  btnFinalPay: {
    // padding: "0.3rem 6rem",
    padding: { xs: "0rem", lg: "0.3rem 6rem" },
    fontSize: "1rem",
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    borderRadius: "3rem",
    textTransform: "none",
  },
  cancelButton: {
    cursor: "pointer",
    "&:hover": {
      transform: "scale3d(1.1, 1.1, 1)",
    },
  },
  captchaField: {
    width: "100%",
    // paddingBottom : "1rem"
  },
  btn: {
    borderRadius: "0.4rem",
    boxShadow: "0 6px 20px 0 #dbdbe8",
    backgroundColor: "#fff",
    transition: "0.4s",
    padding: "0.7rem 3rem ",
    textTransform: "none",
  },
  spacing: {
    marginBottom: "0rem",
  },
  root: {
    "& .Mui-disabled": {
      background: "gray",
      color: "white",
    },
  },
  noBorder: {
    border: "none",
  },
  error: {
    color: "#bf1650",
    //  marginLeft: "9.7rem",
    // marginBottom : '0rem',
    textAlign: "left",
    display: "flex",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  captchaError: {
    color: "#bf1650",
    textAlign: "center",
    // display: "flex",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
    width: { md: "90%", sm: "80%", xs: "70%" },
  },
}));

const AddCard = (props) => {
  const {
    showCard,
    setshowCard,
    creditCardTitle,
    paymentTitle,
    defaultCard,
    remainingPayment,
    setCreditPopup,
    giftCardPaymentDetail,
    page,
    setGreenCheckbox,
    addCardFromCart,
    setGiftCardSelect,
    setGivxSavedCard,
    setSavedCard,
    setCartDataSave,
    setShowText,
    setChecoutButtonDisabled
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [saveCardd, setsaveCardd] = useState(false);
  const [userCardDetails, setUserCardDetails] = useState({
    name: "",
    cardNumber: "",
    expiryDate: "",
  });
  const [pickUpLaterPopup, setPickUpLaterPopup] = useState(false);
  const [captchaValue, setCaptchaValue] = useState();
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [givexCardNumber, setGiveExCardNumber] = useState();
  const [giveExCardPassword, setGiveExCardPassword] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const storeName = useSelector((state) => state.store.nearestStore);
  const userDetail = useSelector((state) => state.user.user);
  const saveCreditCard = useSelector((state) => state.store.saveCreditCard);
  const [paymentRequest, setPaymentRequest] = useState(null);
  let search = useLocation().search;
  const amount = new URLSearchParams(search).get("amount");
  const [value, setValues] = React.useState(new Date());
  const [cardNumber, setCardNumber] = React.useState("");
  const pickUpLaterTime = useSelector((state) => state.cart.pickUpLaterTime);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const [validCardNumber, setValidCardNumber] = useState();
  const [CardTypeNew, setCardTypeNew] = useState();
  // const [captchaToken, setCaptchaToken] = useState(null);
  // const captchaRef = useRef(null);
  const [cardTypeImage, setCardTypeImage] = React.useState("./creditcard.png");

  // const [captchaState, setCaptchaState] = useState(false)
  let month = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  let year = [];
  let date = new Date();
  let thisYear = date.getFullYear();
  for (let i = 0; i <= 20; i++) {
    year.push(thisYear);
    thisYear = thisYear + 1;
  }
  const [expiryDate, setExpiryDate] = useState({
    month: month[0],
    year: year[0],
  });
  const defaultValues = {
    customerName: "",
    zipCode: "",
    accountNumber: "",
    cvv: "",
    cardExpiry: "",
    enterCaptcha: "",
  };
  const {
    control,
    handleSubmit,
    formState,
    setValue,
    trigger,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
  });
  const { errors } = formState;

  useEffect(() => {
    // console.log(creditCardType("5105105105105100"))
    // setCaptchaValue(generateCaptch());
  }, []);
  // console.log(paymentTitle, page, giftCardPaymentDetail?.payment, amount, remainingPayment)
  const handleCardNumber = (e) => {
    e.preventDefault();
    // console.log(getValues("accountNumber").split(" ").join(""))
    const value = e.target.value;
    setCardNumber(value.split(" ").join(""));

    let suggestion;

    if (value.length > 0) {
      suggestion = creditCardType(e.target.value)[0];
      if (suggestion.type == "american-express") {
        suggestion.type = "amex";
        // console.log(suggestion.type)
      } else if (suggestion.type == "diners-club") {
        suggestion.type = "dinersclub";
      }
      setCardTypeNew(suggestion ? suggestion.type : "unknown");
      setValidCardNumber({
        length: suggestion ? suggestion.lengths : [12],
        cvv: suggestion ? suggestion.code.size : 3,
      });
    }
    const cardType = suggestion ? suggestion.type : "unknown";
    // console.log(value, suggestion,"hhhhhhhhhhhhhhhh")
    let imageUrl;

    switch (cardType.toLowerCase()) {
      case "visa":
        imageUrl = "./visa.png";
        break;
      case "mastercard":
        imageUrl = "./mastercard.png";
        break;
      case "amex":
        imageUrl = "./Amex.png";
        break;
      case "american-express":
        imageUrl = "./Amex.png";
        break;
      case "maestro":
        imageUrl = "./maestro.png";
        break;
      case "dinersclub":
        imageUrl = "./dinersclub.png";
        break;
      case "discover":
        imageUrl = "./discover.png";
        break;
      case "jcb":
        imageUrl = "./jcb.png";
        break;
      default:
        imageUrl = "./creditcard.png";
    }

    setCardTypeImage(imageUrl);
  };

  const handleDateChange = (event, type) => {
    // console.log(event, type)
    if (type == "month") {
      // setExpiryDate({month : event.target.value ,...rest})
      expiryDate.month = event.target.value;
      setExpiryDate(expiryDate);
      trigger("cardExpiry");
    } else if (type == "year") {
      expiryDate.year = event.target.value;
      setExpiryDate(expiryDate);
      trigger("cardExpiry");
    }
  };

  // const handleCaptcha = () => {
  //     setCaptchaState(!captchaState)
  //     setValue("enterCaptcha", "")
  //     errors.enterCaptcha = ""
  // }

  const handleCreditCardPayment = (data) => {
    setLoading(true);
    cardPointePayment(data)
      .then((res) => {
        setshowCard(false);
        // setCreditPopup(false);
        if (res.responseCode == 0) {
          // console.log(res, "INSIDE_CONSOLE");
          let paymentDetails = {
            amount: "0",
            amt: "0",
            auth_code: "",
            calculationAmt: "",
            card_last_no: 0,
            givex_code: 0,
            givex_num: 0,
            givex_trans_num: "",
            method: res?.response?.paymentMethod,
            payId: res?.response?.savedCardId,
            payType: res?.response?.paymentMethod,
            paymentMethod: res?.response?.paymentMethod,
            trans_num: 0,
          };
          let checkoutPaymentMethodArray = [];
          checkoutPaymentMethodArray.push(paymentDetails);

          let cardRefund = {
            type: "credit_card",
            requestId: data?.requestId,
            transactionRefNumber: res?.response?.paymentRefId,
            storeId: storeName?.id,
          };
          dispatch(setGivexPaymentDetail(cardRefund));
          dispatch(setPaymentData(checkoutPaymentMethodArray));

          let path = `/payment/success?deviceType=web`;
          navigate(path);
          dispatch(setCheckoutFlag(true));
          dispatch(setIsCheckout(true));
          setLoading(false);
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Duplicate Request Id"
        ) {
          setLoading(false);
          setshowCard(false);
          toast.error("Server error ,Please try again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Invalid card"
        ) {
          setLoading(false);
          setshowCard(false);
          toast.error("Please check your Card Number", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setLoading(false);
          setshowCard(false);
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        }
      })
      .catch((err) => {
        setshowCard(false);
        setLoading(false);
        console.log(err.message);
        if (err) {
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        }
      });
  };

  const handleCreditCardRefund = (creditCardDetail) => {
    creditCardRefundPayment(creditCardDetail)
      .then((res) => {
        if (res?.responseCode == 0) {
          toast.error(
            "Oops Something Went Wrong ,Your refund has been initiated",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .catch((e) => console.log(e));
  };

  const handleSplitPaymentMethod = (creditPayment) => {
    setLoading(true);
    cardPointePayment(creditPayment)
      .then((res) => {
        if (res.responseCode == 0) {
          let refundArray = [];
          let newDate = new Date();
          let givexData = {
            amount: Number(giftCardPaymentDetail?.payment),
            cardNumber: giftCardPaymentDetail?.cardNumber,
            clientId: "AIPRUS",
            customerIdentifier: userDetail?.phone_number
              ? userDetail?.phone_number
              : userDetail?.phone_no
              ? userDetail?.phone_no
              : "Guest@gmail.com",
            customerName: userDetail?.first_name
              ? userDetail?.first_name + " " + userDetail?.last_name
              : "Guest",
            description: "demo payment",
            requestId: (
              newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
            )
              ?.toString()
              ?.replace(/[T.Z:_-]/g, ""),
            storeId: storeName?.id ? storeName?.id : 0,
            surcharge: 0,
          };
          let checkoutPaymentMethodArray = [];

          let paymentDetails = {
            amount: "0",
            amt: "0",
            auth_code: "",
            calculationAmt: "",
            card_last_no: 0,
            givex_code: 0,
            givex_num: 0,
            givex_trans_num: "",
            method: res?.response?.paymentMethod,
            payId: res?.response?.savedCardId,
            payType: res?.response?.paymentMethod,
            paymentMethod: res?.response?.paymentMethod,
            trans_num: 0,
          };

          let cardRefund = {
            type: "credit_card",
            requestId: creditPayment?.requestId,
            transactionRefNumber: res?.response?.paymentRefId,
            storeId: storeName?.id,
          };
          refundArray.push(cardRefund);
          checkoutPaymentMethodArray.push(paymentDetails);
          givexCardPayment(givexData)
            .then((resp) => {
              if (
                res.responseCode == 0 &&
                res.responseMessage == "Payment Success"
              ) {
                paymentDetails = {
                  amount: "0",
                  amt: "0",
                  auth_code: "",
                  calculationAmt: "",
                  card_last_no: 0,
                  givex_code: 0,
                  givex_num: 0,
                  givex_trans_num: "",
                  method: "gift_card",
                  payId: resp?.response?.savedId,
                  payType: resp?.response?.paymentMethod,
                  paymentMethod: "gift_card",
                  trans_num: 0,
                };

                let givexDetailForRefund = {
                  type: "givex",
                  cardNumber: givexCardNumber,
                  clientId: "AIPRUS",
                  description: "demo payment",
                  originalRequestId: givexData?.requestId,
                  requestId: givexData?.requestId,
                  transactionRefNumber: resp?.response?.transactionRefNumber,
                  storeId: storeName?.id,
                };
                refundArray.push(givexDetailForRefund);
                dispatch(setGivexPaymentDetail(refundArray));
                checkoutPaymentMethodArray.push(paymentDetails);
                dispatch(setPaymentData(checkoutPaymentMethodArray));

                let path = `/payment/success?deviceType=web`;
                navigate(path);
                dispatch(setCheckoutFlag(true));
                dispatch(setIsCheckout(true));
                setLoading(false);
                setshowCard(false);
              } else if (
                res.responseCode == 1 &&
                res.responseMessage == "Cert not exist"
              ) {
                setLoading(false);
                setshowCard(false);
                toast.error("Please check your card number again", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                handleCreditCardRefund(cardRefund);
              } else if (
                res.responseCode == 1 &&
                res.responseMessage == "Invalid user ID/pswd"
              ) {
                setLoading(false);
                setshowCard(false);
                toast.error("Oops something went wrong please try again", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                handleCreditCardRefund(cardRefund);
              } else if (
                res.responseCode == 1 &&
                res.responseMessage == "Duplicate Request Id"
              ) {
                setLoading(false);
                setshowCard(false);
                toast.error("Something went wroung , please try again", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                handleCreditCardRefund(cardRefund);
                window.location.reload();
              } else {
                setLoading(false);
                setshowCard(false);
                handleCreditCardRefund(cardRefund);
                let path = `/payment/failure?deviceType=web`;
                navigate(path);
              }
            })
            .catch((err) => {
              setLoading(false);
              setshowCard(false);
              console.log(err.message);
              if (err) {
                let path = `/payment/failure?deviceType=web`;
                navigate(path);
              }
              handleCreditCardRefund(cardRefund);
            });
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Duplicate Request Id"
        ) {
          setLoading(false);
          setshowCard(false);
          toast.error("Server error ,Please try again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Invalid card"
        ) {
          setLoading(false);
          setshowCard(false);
          toast.error("Please check your Card Number", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setLoading(false);
          setshowCard(false);
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        }
      })
      .catch((err) => {
        setLoading(false);
        setshowCard(false);
        console.log(err.message);
        if (err) {
          let path = `/payment/failure?deviceType=web`;
          navigate(path);
        }
      });
  };
  // function IsRecapchaValid()
  //   {
  //   var res = grecaptcha.response()
  //   if (res == "" || res == undefined || res.length == 0)
  //      {
  //       return "this field is required.";
  //      }
  //    return true;
  //   }
  // const verify = () =>{
  //     captchaRef.current.getResponse().then(res => {
  //         setCaptchaToken(res)
  //     })

  // }
  const onSubmit = (data) => {
    // IsRecapchaValid();
    setShowText(false);
    if (amount) {
      dispatch(setOrderTotalPrice(amount));
    }

    data.cardExpiry = data?.cardExpiry?.replace("/", "20");
    // data.cardExpiry = `${cardExpiry}`
    if (page == "Save" || saveCardd) {
      setLoading(true);
      let cardData = {
        // storeId: storeName?.id ? storeName?.id : 1,
        account: data?.accountNumber,
        expiry: data?.cardExpiry,
        amount: "0",
        currency: "USD",
        name: data.customerName,
        postal: data.zipCode,
        profile: "Y",
        cvv: data.cvv,
        customerid: userDetail?.customer_id
          ? userDetail?.customer_id
          : userDetail?.person_id,
        isdefault: 1,
        cardtype: CardTypeNew,
      };
      if(cardData.customerid){
        addCreditCard(cardData)
        .then((res) => {
          if (res.responseCode == 1) {
            toast.success("Your card is saved successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });

            if (addCardFromCart == "card Added from Cart") {
              // console.log("card Added from Cart")
              setGiftCardSelect(false);
              setGivxSavedCard(true);
              dispatch(setDefaultCardd(""));
              setGivxSavedCard(false);
              dispatch(
                setDefaultCardd(cardNumber.slice(cardNumber.length - 4))
              );
              setGreenCheckbox(false);
            } else {
              dispatch(setDefaultCardd(""));
              dispatch(
                setDefaultCardd(cardNumber.slice(cardNumber.length - 4))
              );
            }

            setshowCard(false);
            setLoading(false);
            setChecoutButtonDisabled(false)
          } else if (
            res?.responseCode == 0 &&
            res?.message == "ProfileId already Added in SavedCard "
          ) {
            toast.error("This card is already saved", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          } else if (
            res?.responseCode == 0 &&
            res?.message == "Pin code is not valid"
          ) {
            toast.error("Please enter a valid zipcode", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          } else {
            toast.error("Oops something went wrong", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          toast.error("Unable to save your card", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        });
      }else{
        let savedCard = {
          account: data?.accountNumber,
          cardExpiry: data?.cardExpiry,
          cardType:CardTypeNew,
          city: "New York City",
          customerName: data.customerName,
          imageUrl:cardTypeImage,
          isDefault: true,
          // (Math.floor(Math.random() *1000000000000)).toString()+data?.accountNumber.replace(/\s/g, '').substr(-4),
          lastFourCardDigit: data?.accountNumber.replace(/\s/g, '').substr(-4),
          profileId: null,
          state: "NY",
          zip: data.zipCode,
        };
        setSavedCard([savedCard]);
        setCartDataSave(savedCard)
        if (addCardFromCart == "card Added from Cart") {
          // console.log("card Added from Cart")
          setGiftCardSelect(false);
          setGivxSavedCard(true);
          dispatch(setDefaultCardd(""));
          setGivxSavedCard(false);
          dispatch(
            setDefaultCardd(cardNumber.slice(cardNumber.length - 4))
          );
          setGreenCheckbox(false);
          setChecoutButtonDisabled(false)
        } else {
          dispatch(setDefaultCardd(""));
          dispatch(
            setDefaultCardd(cardNumber.slice(cardNumber.length - 4))
          );
        }
        setshowCard(false);
        setLoading(false);
      }
     
    }
    if (
      pickUpLaterTime &&
      !handlePlaceOrderWithTime(pickUpLaterTime, storeName?.time_zone)
    ) {
      setPickUpLaterPopup(true);
    } else {
      if (
        saveCreditCard.length == 0 &&
        page != "Save" &&
        !giftCardPaymentDetail?.payment
      ) {
        let newDate = new Date();
        let creditPayment = {
          accountNumber: data?.accountNumber.split(" ").join(""),
          amount: Number(remainingPayment),
          cardExpiry: data?.cardExpiry,
          clientId: "AIPRUS",
          currency: "USD",
          customerIdentifier: userDetail?.phone_number
            ? userDetail?.phone_number
            : userDetail?.phone_no,
          customerName: data.customerName,
          // description: userDetail?.first_name + " " + userDetail?.last_name,
          description: data.customerName,
          method: "card",
          requestId: (
            newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
          )
            ?.toString()
            ?.replace(/[T.Z:_-]/g, ""),
          saveCard:
            page == "SavePay"
              ? saveCardd
                ? 1
                : 0
              : page == "Pay"
              ? saveCardd
                ? 1
                : 0
              : 0,
          surcharge: 0,
          storeId: storeName?.id ? storeName?.id : 1,
          cvv: data.cvv,
          zipCode: data?.zip,
        };

        if (paymentTitle != "split") {
          handleCreditCardPayment(creditPayment);
        } else {
          handleSplitPaymentMethod(creditPayment);
        }
      } else if (
        saveCreditCard.length == 0 &&
        page != "Save" &&
        giftCardPaymentDetail?.payment < amount &&
        !defaultCard
      ) {
        let newDate = new Date();
        let creditPayment = {
          accountNumber: data?.accountNumber.split(" ").join(""),
          amount: Number(remainingPayment),
          cardExpiry: data?.cardExpiry,
          clientId: "AIPRUS",
          currency: "USD",
          customerIdentifier: userDetail?.phone_number
            ? userDetail?.phone_number
            : userDetail?.phone_no,
          customerName: data.customerName,
          // description: userDetail?.first_name + " " + userDetail?.last_name,
          description: data.customerName,
          method: "card",
          requestId: (
            newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
          )
            ?.toString()
            ?.replace(/[T.Z:_-]/g, ""),
          saveCard:
            page == "SavePay"
              ? saveCardd
                ? 1
                : 0
              : page == "Pay"
              ? saveCardd
                ? 1
                : 0
              : 0,
          surcharge: 0,
          storeId: storeName?.id ? storeName?.id : 1,
          cvv: data.cvv,
          zipCode: data?.zip,
        };

        // if (paymentTitle != "split") {
        //     handleCreditCardPayment(creditPayment);
        // }
        // else {
        handleSplitPaymentMethod(creditPayment);
        // }
      }
    }
  };

  useEffect(() => {
    if (!showCard) {
      setValue("accountNumber", "");
      setValue("customerName", "");
      setValue("cvv", "");
      setValue("enterCaptcha", "");
      setValue("zipCode", "");
      setCardTypeImage("./creditcard.png");
    }
    if (page == "SavePay" && !isGuestLogin) {
      setsaveCardd(true);
    } else {
      setsaveCardd(false);
    }
  }, [showCard]);

  return (
    <>
      {showCard && (
        <Dialog
          open={showCard}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                // width: "100%",
                minWidth: { xs: "90vw", sm: "50vw", md: "45vw", lg: "35vw" },
                margin: "0px", // Set your width here
              },
            },
          }}
        >
          <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box>
            <DialogTitle
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img src="./NewLogo.png" alt="" width="100vw" />
              <CloseIcon
                sx={{ color: "#C60734" }}
                className={classes.cancelButton}
                onClick={() => {
                  setshowCard(false);
                }}
              />
            </DialogTitle>
            <>
              <Container style={{ marginTop: "1vh" }}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{ fontWeight: "bold", marginY: "0.8rem" }}
                  >
                    Add Card Details
                  </Typography>
                  <Box
                    textAlign="center"
                    display="flex"
                    flexDirection="column"
                    // marginX="3rem"
                  >
                    <FormControl fullWidth>
                      <Controller
                        name="customerName"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validateCardHolderName(value);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="customerName"
                            variant="outlined"
                            size="small"
                            label="Card Holder's Name"
                            inputProps={{ maxLength: 30 }}
                            InputProps={{
                              classes: { notchedOutline: classes.noBorder },
                            }}
                            sx={{
                              backgroundColor: "#EEEEEE",
                              borderRadius: "0.5rem",
                            }}
                            // onInput={
                            //   (e) => handleUserCardDetails(e, "name")
                            //   //  userCardDetails.name = e.target.value
                            // }
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                    {errors?.customerName ? (
                      <p className={classes.error}>
                        {errors.customerName.message}
                      </p>
                    ) : (
                      <p style={{ marginBottom: "1.6rem" }}>{/* <br /> */}</p>
                    )}

                    <Box style={{ display: "flex", flexDirection: "row" }}>
                      <FormControl fullWidth>
                        <Controller
                          name="accountNumber"
                          rules={
                            ({
                              required: true,
                            },
                            {
                              validate: (value) => {
                                // console.log(validCardNumber)
                                return validateCreditCardNumber(
                                  value.split(" ").join(""),
                                  "creditCard",
                                  validCardNumber ? validCardNumber.length : 16
                                );
                              },
                            })
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="accountNumber"
                              inputProps={{ inputMode: "numeric" }}
                              // inputProps={{ maxLength: validCardNumber ? validCardNumber.length[validCardNumber.length.length] : 16 }}
                              variant="outlined"
                              size="small"
                              label="Card Number"
                              InputProps={{
                                classes: { notchedOutline: classes.noBorder },
                              }}
                              sx={{
                                backgroundColor: "#EEEEEE",
                                borderRadius: "0.5rem",
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                let lengths = validCardNumber
                                  ? validCardNumber.length
                                  : [16];
                                let gap = Math.floor(
                                  (validCardNumber
                                    ? validCardNumber.length[
                                        validCardNumber.length.length - 1
                                      ]
                                    : 16) / 4
                                );
                                e.target.value = e.target.value
                                  .replace(/(.{4})/g, "$1 ")
                                  .trim()
                                  .slice(0, lengths[lengths.length - 1] + gap);
                                handleCardNumber(e);
                              }}
                            />
                          )}
                          control={control}
                        />
                      </FormControl>

                      <img
                        src={cardTypeImage}
                        alt=""
                        height="40vh"
                        style={{ marginBottom: "0.3rem" }}
                      />
                    </Box>
                    {errors?.accountNumber ? (
                      <p className={classes.error}>
                        {errors.accountNumber.message}
                      </p>
                    ) : (
                      <p style={{ marginBottom: "1.6rem" }}>{/* <br /> */}</p>
                    )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl>
                        <Controller
                          name="cardExpiry"
                          rules={{
                            required: "Exp Date is required",
                            validate: (expiryDate) =>
                              validateCardExpiry(expiryDate),
                          }}
                          render={({ field }) => (
                            <div>
                              <TextField
                                {...field}
                                id="cardExpiry"
                                variant="outlined"
                                placeholder="MM/YY"
                                size="small"
                                label="Exp Date"
                                inputProps={{
                                  maxLength: 5,
                                  inputMode: "numeric",
                                }}
                                InputProps={{
                                  classes: { notchedOutline: classes.noBorder },
                                }}
                                sx={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "0.5rem",
                                  width: {
                                    md: "95%",
                                    sm: "98%",
                                    xs: "95%",
                                    lg: "100%",
                                  },
                                }}
                                onChange={(e) => {
                                  const validationResult = validateCardExpiry(
                                    e.target.value
                                  );
                                  if (validationResult) {
                                    setError("cardExpiry", {
                                      type: "manual",
                                      message: validationResult,
                                    });
                                  } else {
                                    clearErrors("cardExpiry");
                                  }
                                  field.onChange(e);
                                }}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  e.target.value = e.target.value.replace(
                                    /(\d{2})(\d{2})/,
                                    "$1/$2"
                                  );
                                }}
                              />
                              {errors.cardExpiry && (
                                <p className={classes.error}>
                                  {errors.cardExpiry.message ||
                                    "Please enter a valid expiry date"}
                                </p>
                              )}
                            </div>
                          )}
                          control={control}
                        />
                      </FormControl>

                      <Box
                        textAlign="center"
                        display="flex"
                        flexDirection="column"
                        // marginX="3rem"
                      >
                        <FormControl fullWidth>
                          <Controller
                            name="cvv"
                            rules={
                              ({
                                required: true,
                              },
                              {
                                validate: (value) => {
                                  let fieldName = "CVV";
                                  if (value) {
                                    if (
                                      value.length <
                                      (validCardNumber
                                        ? validCardNumber.cvv
                                        : 3)
                                    ) {
                                      return (
                                        "*Please enter at least" +
                                        ` ${
                                          validCardNumber
                                            ? validCardNumber.cvv
                                            : 3
                                        }` +
                                        " characters."
                                      );
                                    }
                                  }
                                  if (!value) {
                                    return validateNumber(value, fieldName);
                                  }
                                },
                              })
                            }
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="cvv"
                                variant="outlined"
                                size="small"
                                label="CVV"
                                inputProps={{ inputMode: "numeric" }}
                                InputProps={{
                                  classes: { notchedOutline: classes.noBorder },
                                }}
                                sx={{
                                  backgroundColor: "#EEEEEE",
                                  borderRadius: "0.5rem",
                                  width: {
                                    md: "100%",
                                    sm: "100%",
                                    xs: "100%",
                                    lg: "100%",
                                  },
                                }}
                                // inputProps={{ maxLength: 3 }}
                                onInput={(e) => {
                                  let re = new RegExp(
                                    "(.{" +
                                      `${
                                        validCardNumber
                                          ? validCardNumber.cvv
                                          : 3
                                      }}` +
                                      ")",
                                    "g"
                                  );
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  e.target.value = e.target.value
                                    .replace(re, "$1")
                                    .trim()
                                    .slice(
                                      0,
                                      validCardNumber ? validCardNumber.cvv : 3
                                    );
                                }}
                              />
                            )}
                            control={control}
                          />
                          {errors?.cvv ? (
                            <p className={classes.error}>
                              {errors.cvv.message}
                            </p>
                          ) : (
                            <p style={{ marginBottom: "1.6rem" }}>
                              {/* <br /> */}
                            </p>
                          )}
                        </FormControl>
                      </Box>
                    </div>
                  </Box>
                  <FormControl fullWidth>
                    <Controller
                      name="zipCode"
                      rules={
                        ({
                          required: true,
                        },
                        {
                          validate: (value) => {
                            return validateZipCode(value);
                          },
                        })
                      }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="zipCode"
                          variant="outlined"
                          size="small"
                          label="Zip Code"
                          inputProps={{ maxLength: 5 }}
                          InputProps={{
                            maxLength: 6,
                            inputMode: "numeric",
                            classes: { notchedOutline: classes.noBorder },
                          }}
                          sx={{
                            backgroundColor: "#EEEEEE",
                            borderRadius: "0.5rem",
                          }}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  {errors?.zipCode ? (
                    <p className={classes.error}>{errors.zipCode.message}</p>
                  ) : (
                    <p style={{ marginBottom: "1.6rem" }}>{/* <br /> */}</p>
                  )}

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FormControl>
                      <Controller
                        name="captcha"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (captcha) => {
                              if (!captcha) {
                                return "*Please verify the captcha";
                              }
                            },
                          })
                        }
                        render={({ field }) => (
                          <Box>
                            {/* // sx={{width:"100px"}}> */}
                            <ReCAPTCHA
                              {...field}
                              name="captcha"
                              sitekey="6LctGXkjAAAAACb5PoqhOMQvbGShJJ8eVBHSgUbG"
                            />
                          </Box>
                        )}
                        control={control}
                      />
                    </FormControl>
                  </div>
                  {errors?.captcha ? (
                    <p className={classes.captchaError}>
                      {errors.captcha.message}
                    </p>
                  ) : (
                    <p style={{ marginBottom: "1.6rem" }}>{/* <br /> */}</p>
                  )}

                  {/* <Box>
                                                    <FormControl
                                                    // required = "true" 
                                                    >
                                                   <ReCAPTCHA
                                                   name="captcha"
                                                   style={{marginLeft:"6rem"}}
                                              sitekey="6LctGXkjAAAAACb5PoqhOMQvbGShJJ8eVBHSgUbG"/>
                                              </FormControl>
                                              </Box> */}
                  {/* <div style ={{display : "flex" , flexDirection : "row" , alignItems:"center"}}> */}

                  {page != "Save" &&
                  remainingPayment > 0 &&
                  !isGuestLogin &&
                  !giftCardPaymentDetail?.payment ? (
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        checked={saveCardd}
                        onChange={(e) => {
                          setsaveCardd(e.target.checked);
                        }}
                      />
                      <Typography sx={{ marginTop: "0.5rem" }}>
                        Save this card for future
                      </Typography>
                    </div>
                  ) : defaultCard ? (
                    ""
                  ) : giftCardPaymentDetail?.payment < amount &&
                    !isGuestLogin ? (
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        checked={saveCardd}
                        onChange={(e) => {
                          setsaveCardd(e.target.checked);
                        }}
                      />
                      <Typography sx={{ marginTop: "0.5rem" }}>
                        Save this card for future
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}
                  <Container
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      // marginTop: "5vh",
                      marginBottom: "1rem",
                    }}
                  >
                    <div className={classes.root}>
                      <Button
                        className={classes.btnFinalPay}
                        variant="contained"
                        type="submit"
                      >
                        {(page == "Pay" || page == "SavePay") &&
                        remainingPayment > 0 &&
                        !giftCardPaymentDetail?.payment
                          ? `Make payment : $${remainingPayment}`
                          : defaultCard
                          ? "Add card"
                          : giftCardPaymentDetail?.payment < amount
                          ? `Make payment : $${remainingPayment}`
                          : "Add card"}
                      </Button>
                    </div>
                  </Container>
                  {/* </Grid> */}
                </form>
              </Container>
            </>
          </Box>
        </Dialog>
      )}
      <AlertPopup
        open={pickUpLaterPopup}
        setOpen={setPickUpLaterPopup}
        title={"paymentCondition"}
        buttonType1={true}
        buttonType2={false}
        displayTitle={
          "The pickup time that you selected is no longer available. Please select another time slot."
        }
      />
    </>
  );
};
export default AddCard;
