import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { getfavoriteMenuOfCustomerJson } from '../../Api/API';
import { useSelector } from 'react-redux';
import Card from '../../Common/Card';
import Loader from '../../Common/Loader';
import { Typography, Button, Backdrop, CircularProgress, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Login from '../Login/Login';
import { errorToastMessage } from '../../Common/utils';
import { Grid } from '@mui/material';

const Favorites = ({setState}) => {
  let navigate = useNavigate();
  const [menu, setMenu] = useState([]);
  const userDetail = useSelector((state) => state.user.user);
  const [showAd, setShowAd] = useState(false);
  const [loading, setLoading] = useState(false);
  const storeName = useSelector((state) => state.store.nearestStore);
  const fav = useSelector((state) => state.store.fav);
  const [favItem, setFavItem] = useState(true);
  const isLogin = useSelector((state) => state.user.isLogin);

  useEffect(() => {
    // console.log(storeName?.id , userDetail?.customer_id ? userDetail?.customer_id : userDetail?.person_id);
    if (userDetail?.customer_id || userDetail?.person_id) {
      setLoading(true);
      getfavoriteMenuOfCustomerJson(userDetail?.customer_id ? userDetail?.customer_id : userDetail?.person_id, storeName?.id,2,"web")
        .then((res) => {
          setMenu(res.result);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          errorToastMessage();
        });
    }
  }, [fav , storeName , favItem, userDetail]);
   return (
    <>
    
      {isLogin === true ?
        (
          <>
          <Backdrop
          sx={{ color: '#fff', zIndex: 1000 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
          <Box
            // className="row"
            sx={{
              paddingLeft: {lg:"13vw", xs:"5vw"},
              paddingRight: {lg:"13vw", xs:"5vw"},
              paddingBottom: {lg: "3vh" , xs : "10vh"},
              paddingTop: "5vh",
            }}
          >
            {menu?.length == 0 && !loading ?
              <div style={{ textAlign: "center", marginTop: "3rem" }}>
                <Typography variant='h6' sx={{ fontSize: {lg:"3rem",md:"3rem",sm:"2rem",xs:"1rem"} }}>There are no items in your favorite list!</Typography>
              </div> : ""}
            {menu?.map((e, index) => {
              return (
                <Grid item  xs={12}  lg={12} style={{marginBottom:"2rem"}}>
                    <Card
                      key={e?.id}
                      props={e.menu_details}
                      fav={true}
                      setFavItem={setFavItem}
                      favItem={favItem}
                      menuId = {e.menu_id}
                      max_calories = {e.max_calories}
                      min_calories = {e.min_calories}
                      favItemsDetail = {e}
                      setLoading = {setLoading}
                      setState={setState}
                    />
                 </Grid>
              );
            })}
          </Box>
          </>) : (
          <div style={{ textAlign: "center", marginTop: "5rem" , width:"100%" }}>
            <Typography variant='h6' sx={{ fontSize: "2rem" }}>Add your favorite drinks</Typography>
            {/* <Typography variant='subtitle1' >Your favorite will appear here to order again. */}
            {/* </Typography> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{
                  borderRadius: 20,
                  padding: "0.3rem 2rem",
                  backgroundColor: "brown",
                  textTransform: "none",
                  marginTop: "2rem",
                  marginRight: "3rem"
                }}
                variant="contained"
                onClick={() => {
                  let path = '/signup'
                  navigate(path)
                }}
              >
                Sign Up
              </Button>
              <Button
                sx={{
                  borderRadius: 20,
                  padding: "0.3rem 2rem",
                  backgroundColor: "brown",
                  textTransform: "none",
                  marginTop: "2rem",
                }}
                variant="contained"
                onClick={() => { setShowAd(true) }}
              >
                Log in
              </Button>
            </div>
          </div>
        )}
      <Login showAd={showAd} setShowAd={setShowAd}></Login>
    </>
  );
};

export default Favorites