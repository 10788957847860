import axios from "axios";

let username ="gongchausa.staging.mobile";
let password ="R3a#T9q2w%6pL@1x7*Jh!4k8z^5s2f"; 
const instance = axios.create({
  // baseURL: "https://apistaging.aiprusdev.com",
  baseURL: "https://api.gongchausa.com",
  // baseURL: process.env.REACT_APP_baseUrl,
});
instance.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8";
instance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default instance;
