import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  Typography,
  Grid
} from "@mui/material";
import { GetAllMenu } from "../../../Api/API";
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import MenuScreen from "../../../pages/Categories/MenuScreen";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTabValue, setValueSet } from "../../../Redux/CartSlice";
const useStyle = makeStyles((theme) => ({
  root: {
    "& .MuiAvatar-img": {
      width: "auto",
      // height: "inherit"
    }
  },
  item: {
    boxShadow: "0 6px 20px 0 #dbdbe8",
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  imgTran: {
    width: "25%",
    objectFit: "contain",
    transition: "transform 0.15s ease-in-out",
    "&:hover": { transform: "scale3d(1.15, 1.15, 1)" },
  }
}));

const CategoryTabBar = ({ Categories, setLoading, loading }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  let navigate = useNavigate();

  const handleChange = (newValue) => {
    console.log(newValue ,"#11111")
    setValue(newValue);
    dispatch(setValueSet(newValue));
    dispatch(setTabValue(0));
    let path = `/menu`;
    navigate(path);
  };

  return (
    <>
      <Box
        sx={{
          paddingLeft: { lg: "13vw", xs: "5vw" },
          paddingRight: { lg: "13vw", xs: "5vw" },
          paddingBottom: { lg: "3vh", xs: "10vh" },
          paddingTop: "5vh",
        }}
      >
        <Grid container spacing={4}>
          {Categories?.map((category, index) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Card className={classes.item}
                  onClick={() => handleChange(index)}
                  sx={{
                    borderRadius: "16px",
                    cursor: "pointer"
                  }}
                >
                  <Box p={1} sx={{ display: "flex", alignItems: "center", }}>
                    <Box className={classes.imgTran}
                      component="img"
                      sx={{
                        height: { lg: "12rem", xs: "9rem" },

                      }}
                      alt="Image"
                      src={category?.category_image ? category?.category_image : "./logos.png"}
                    />
                    <Typography sx={{ marginLeft: { lg: "2.3rem", xs: "1rem" }, color: "#39302B", fontSize: { lg: "23px", xs: "18px" } }}>
                      {category?.category_name}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {/* {menu && 
<MenuScreen menu={menu} loading ={loading} setLoading={setLoading} categoryId = {Categories[value]?.category_id} categoryName = {Categories[value]?.category_name}/>
}   */}
    </>
  );
};
export default CategoryTabBar;
