import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Backdrop, Box, Button, Card, CardActions, Grid, IconButton, Paper, Toolbar, Typography } from "@mui/material";
import ReactToPrint from "react-to-print";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Loader from "../../Common/Loader";
import { Close } from "@mui/icons-material";
import CustomizedTable from "../../Common/CustomizedTable";

const useStyle = makeStyles((theme) => ({
  // cardActions: {
  //   display: "flex",
  //   justifyContent: "center",
  //   marginBottom: "1rem",
  // },
  root: {
    // overflow: "auto",
    width: {lg:"60vw",md:"60vw",sm:"90vw",xs:"95vw"},
    // margin: "auto",
    // '@media (max-width : 600px)': {
    //   // overflow: "auto",
    //   // height: "90vh",
    //   width: "90vw",
    //   margin:"20px"
    //     },
  },
  cardActions: {
    bottom: "10%",
    position: "fixed",
  },
  cardActions1: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
  },
  cardHeader: {
    backgroundColor: "white",
    color: "white",
    justifyContent: "space-between",
  },
  capitalise: {
    "text-transform": "capitalize",
  },
  uppercase: {
    "text-transform": "uppercase",
  },
  boldColor: {
    "font-weight": "bold",
  },
  topTable: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #ECEAEA",
    margin: "10px 10px 0px 10px",
  },
  topTable2: {
    // marginTop:"10px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#ECEAEA",
    height: "2.1rem",
    alignItems: "center",
    margin: "0px 10px",
  },
}));

const KioskReceipt = () => {
  const componentRef = useRef();
  const classes = useStyle();
  // const location = useLocation();
  let search = useLocation().search;
  // const queryParams = new URLSearchParams(location.search);
  // const orderId = queryParams.get("orderId");
  
  const orderId = new URLSearchParams(search).get("orderId")
  const [orderDetails, setOrderDetails] = useState();
  const [paymentName, setPaymentName] = useState();
  const [loading, setLoading] = useState(false);

  const orderDetailsColumns = useMemo(() => [
    {
      Header: " ",
      columns: [
        {
          Header: "#",
          accessor: "id",
          defaultCanSort: true,
        },
        {
          Header: "Items",
          accessor: "menu_modifier_name",
          defaultCanSort: true,
        },
        {
          Header: "Quantity",
          accessor: "menu_modifier_quantity",
          defaultCanSort: true,
        },
        {
          Header: "Each Price",
          accessor: "menu_modifier_price",
          defaultCanSort: true,
        },
        {
          Header: "Total",
          accessor: "menu_modifier_Total",
          defaultCanSort: true,
        },
      ],
    },
  ]);
  const getOrderDetailsById = () => {
    setLoading(true)
    axios
      .get(
        `https://api-cms.gongchapos.com/orders/getOrderDetailById?id=${orderId}`,
        {
          headers: { personId: null },
          auth: {
            username: "gongchausa.prod.admin",
            password: "#2K@9&5q4Fp!7L@8r%3j*1H^6m$5Z",
          },
        }
      )
      .then((res) => {
        setPaymentName(res?.data[0].paymentMethod);
        setOrderDetails(res?.data[0].order[0]);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      });
  };
  const orderDetailsData = () => {
    const allRowData =
      orderDetails &&
      orderDetails?.orderDetails[0] &&
      orderDetails?.orderDetails[0]?.orderDetails &&
      orderDetails?.orderDetails[0]?.orderDetails.map(
        (eachOrderDetail, index) => {
          const allRowsEachOrder = {};
          const headRow = {
            id: index + 1,
            menu_modifier_name: <b>{eachOrderDetail.menu_name}</b>,
            menu_modifier_quantity: eachOrderDetail.quantity,
            menu_modifier_price: `$${Number(
              parseFloat(
                eachOrderDetail.totalTextPrice
                  ? eachOrderDetail.totalTextPrice/eachOrderDetail.quantity
                  : eachOrderDetail.originalCost,
                10
              )
            )?.toFixed(2)}`,
            menu_modifier_Total: `\u00A0$${Number(
              parseFloat(eachOrderDetail.quantity, 10) *
                parseFloat(
                  eachOrderDetail.totalTextPrice
                    ? eachOrderDetail.totalTextPrice/eachOrderDetail.quantity
                    : eachOrderDetail.originalCost,
                  10
                )
            )?.toFixed(2)}`,
          };

          const eachRow = eachOrderDetail?.customizationOpt?.map(
            (eachCustomizedOption) => {
              return eachCustomizedOption.option.map((eachModifier) => {
                const row = {};
                row.menu_modifier_name = `\u00A0\u00A0\u00A0\u00A0${eachCustomizedOption?.modifier_group_name} - ${eachModifier?.modifier_name}`;
                row.menu_modifier_quantity = eachModifier?.quantity;
                row.menu_modifier_price = isNaN(
                  `${Number(eachModifier?.modifier_price)}`
                )
                  ? "\u00A0\u00A0\u00A0\u00A0-"
                  : `$${Number(eachModifier?.modifier_price)?.toFixed(2)}`;
                row.menu_modifier_Total = isNaN(
                  `${Number(
                    parseFloat(eachModifier?.quantity, 10) *
                      parseFloat(eachModifier?.modifier_price, 10)
                  )}`
                )
                  ? "\u00A0\u00A0\u00A0\u00A0-"
                  : `\u00A0$${Number(
                      parseFloat(eachModifier?.quantity, 10) *
                        parseFloat(eachModifier?.modifier_price, 10)
                    )?.toFixed(2)}`;
                return row;
              });
            }
          );

          // if (eachOrderDetail?.discount_price) {
          const footerRowDiscount = {
            menu_modifier_name: "\u00A0\u00A0\u00A0\u00A0Discount",
            menu_modifier_quantity: "",
            menu_modifier_price: "",
            menu_modifier_Total: `-$${Number(
              typeof eachOrderDetail?.discount_price == "string"
                ? eachOrderDetail?.discount_price.replace("$", "")
                : eachOrderDetail?.discount_price
            )?.toFixed(2)}`,
          };
          // }
          const footerRowLoyalty = {
            menu_modifier_name: "\u00A0\u00A0\u00A0\u00A0Loyalty Discount",
            menu_modifier_quantity: "",
            menu_modifier_price: "",
            menu_modifier_Total: `-$${Number(
              eachOrderDetail?.loyalty_points
            )?.toFixed(2)}`,
          };
          //           menu_modifier_Total: isNaN(
          //     `${Number(orderDetails?.orderDetails[0]?.discount_total_price)}`
          //   )
          //     ? "\u00A0\u00A0\u00A0\u00A0-"
          //     : `$${Number(
          //         orderDetails?.orderDetails[0]?.discount_total_price
          //       )?.toFixed(2)}`,
          // };
          allRowsEachOrder.headRow = headRow;
          allRowsEachOrder.eachRow = eachRow;
          allRowsEachOrder.footerDiscountRow = footerRowDiscount;
          allRowsEachOrder.footerRowLoyalty = footerRowLoyalty;
          return allRowsEachOrder;
        }
      );
    const tableData = [];
    allRowData &&
      allRowData.map((eachItem) => {
        tableData.push(eachItem.headRow);
        for (let i = 0; i < eachItem?.eachRow?.length; i++) {
          tableData.push(...eachItem.eachRow[i]);
        }
        // tableData.push(...eachItem.eachRow);
        if (eachItem.footerDiscountRow.menu_modifier_Total != "-$0.00") {
          tableData.push(eachItem.footerDiscountRow);
        }
        if (eachItem.footerRowLoyalty.menu_modifier_Total != "-$0.00") {
          tableData.push(eachItem.footerRowLoyalty);
        }
        // tableData.push(eachItem.footerDiscountRow);
        // tableData.push(eachItem.footerRowLoyalty);
      });
    const footerRowSubTotal = {
      id: "",
      menu_modifier_name: "",
      menu_modifier_quantity: "",
      menu_modifier_price: <b>SubTotal</b>,
      menu_modifier_Total: `\u00A0$${Number(
        orderDetails?.orderDetails[0]?.subTotal
      )?.toFixed(2)}`,
    };
    const footerRowDiscount = {
      id: "",
      menu_modifier_name: "",
      menu_modifier_quantity: "",
      menu_modifier_price: <b>Discount</b>,
      // menu_modifier_Total: `-$${Number(
      //   orderDetails?.orderDetails[0]?.discount_total_price
      // )?.toFixed(2)}`,
      menu_modifier_Total: isNaN(
        `${Number(orderDetails?.orderDetails[0]?.discount_total_price)}`
       // `${Number(orderDetails?.totalRewardDiscount)}`
      )
        ? "-$0.00"
        : `-$${Number(
          orderDetails?.orderDetails[0]?.discount_total_price
            //orderDetails?.totalRewardDiscount
          )?.toFixed(2)}`,
    };
    const footerRowLoyalityDiscount = {
      id: "",
      menu_modifier_name: "",
      menu_modifier_quantity: "",
      menu_modifier_price: <b>Loyality Discount</b>,
      menu_modifier_Total: isNaN(
        //`${Number(orderDetails.orderDetails[0]?.total_fivestar_discount)}`
        `${Number(orderDetails?.totalRewardDiscount)}`
      )
        ? "-$0.00"
        : `-$${Number(
            //orderDetails.orderDetails[0]?.total_fivestar_discount
            orderDetails?.totalRewardDiscount
          )?.toFixed(2)}`,
      // menu_modifier_Total: `-$${Number(
      //   orderDetails.orderDetails[0]?.total_fivestar_discount
      //     ? orderDetails.orderDetails[0]?.total_fivestar_discount
      //     : 0.0
      // )?.toFixed(2)}`,
    };
    const footerRowTax = {
      id: "",
      menu_modifier_name: "",
      menu_modifier_quantity: "",
      menu_modifier_price: <b>Tax</b>,
      menu_modifier_Total: `\u00A0$${Number(
        orderDetails?.orderDetails[0]?.taxValue
      )?.toFixed(2)}`,
    };
    const footerRowTips = {
      id: "",
      menu_modifier_name: "",
      menu_modifier_quantity: "",
      menu_modifier_price: <b>Tips</b>,
      menu_modifier_Total: `\u00A0$${Number(orderDetails?.tips)?.toFixed(2)}`,
    };
    const footerRowTotal = {
      id: "",
      menu_modifier_name: "",
      menu_modifier_quantity: "",
      menu_modifier_price: <b>Total</b>,
      menu_modifier_Total: `\u00A0$${Number(
        orderDetails?.orderDetails[0]?.total
      )?.toFixed(2)}`,
    };
    tableData.push(footerRowSubTotal);
    tableData.push(footerRowDiscount);
    tableData.push(footerRowLoyalityDiscount);
    tableData.push(footerRowTax);
    tableData.push(footerRowTips);
    tableData.push(footerRowTotal);
    const rowData = [...tableData];
    return rowData;
  };
  useEffect(() => {
    getOrderDetailsById();
  }, []);

  const capitalizeFirstLetter=(text)=> {
    if (text && typeof text === 'string') {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    } else {
        return text; 
    }
}
  return (
    <>
          {loading?<Backdrop style={{ color: "#fff", zIndex: "0" }} open={loading}>
        {loading && (
          <img
            src="./BLACKPLAINLOADING.GIF"
            alt=""
            style={{ width: "4vw", margin: "auto" }}
          />
        )}
      </Backdrop>:
    <div style={{display:"flex",justifyContent:"center",overflow:"auto"}}>
     <Card className={classes.root}>
          <Box ref={componentRef} sx={{ p: 2, pb: 0 }} dir="ltr">
          <Toolbar style={{display:"flex",justifyContent:"center"}}>
            <Typography sx={{ ml: 2,color:"black" }} variant="h6" component="div">
              Order Receipt
            </Typography>
          </Toolbar>
            <Grid container>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{marginBottom:"1rem"}}>
                <div className={classes.topTable2}>
                  <h4 style={{marginTop:"0.3rem"}}>Customer Details</h4>
                </div>

                <div className={classes.topTable}>
                  <h5>Customer Name:</h5>
                  <span>
                    {orderDetails?.customerName === "null null"
                      ? "Guest"
                      : orderDetails?.customerName === "NA"
                      ? "N/A"
                      : orderDetails?.customerName}
                  </span>
                </div>
                <div className={classes.topTable}>
                  <span>Store Name:</span>
                  <span>{capitalizeFirstLetter(orderDetails?.storeName)}</span>
                </div>
                <div className={classes.topTable}>
                  <span>Payment Method:</span> <span>{paymentName}</span>
                </div>

                <div className={classes.topTable}>
                  <span>Custome Name:</span>
                  <span>{orderDetails?.customerName}</span>
                </div>

                {/* <div className={classes.topTable}>
                  <span>Transaction No.:</span>
                  <span>{orderDetails?.transNum}</span>
                </div> */}
                {(paymentName == "Credit Card" || paymentName == "Split") && (
                  <div className={classes.topTable}>
                    <span>CC No/Auth Code:</span>
                    <span>
                      {orderDetails?.cardLastNo}/{orderDetails?.authCode}
                    </span>
                  </div>
                )}
                {/* {(paymentName == "Gift Card" || paymentName == "Split") && <div className={classes.topTable}>
                  <span>Givex Code:</span>
                  <span>{orderDetails?.givexCode}</span>
                </div>} */}
                {/* {(paymentName == "Credit Card" || paymentName == "Split") && <div className={classes.topTable}>
                  <span>CC No.:</span>
                  <span>{orderDetails?.cardLastNo}</span>
                </div>} */}
                {(paymentName == "Gift Card" || paymentName == "Split") && (
                  <div className={classes.topTable}>
                    <span>Givex No/Givex Code:</span>
                    <span>
                      {orderDetails?.givexNum.substr(
                        orderDetails?.givexNum?.length - 9
                      )}
                      /{orderDetails?.givexCode}
                    </span>
                  </div>
                )}
              </Grid><br/>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.topTable2}>
                  <h4 style={{marginTop:"0.3rem"}}>Order Details</h4>
                </div>
                <div className={classes.topTable}>
                  <h5>Order #: </h5>
                  <span>{orderDetails?.customOrderNumber}</span>
                </div>
                <div className={classes.topTable}>
                  <span>Order Date:</span>
                  <span>{String(orderDetails?.createdAt).split("T")[0]}</span>
                </div>
                <div className={classes.topTable}>
                  <span>Order Status:</span>
                  <span>{capitalizeFirstLetter(orderDetails?.orderStatus)}</span>
                </div>
                <div className={classes.topTable}>
                  <span>Order From:</span>
                  <span >
                    {capitalizeFirstLetter(orderDetails?.orderType)}
                  </span>
                </div>
                <div className={classes.topTable}>
                  <span>Accumulated Points:</span>
                  <span>{orderDetails?.accumulatedPoints}</span>
                </div>
              </Grid>
            </Grid>

            {orderDetails && (
              <Grid container>
                <Grid item xs={12}>
                  {orderDetailsData && (
                    <CustomizedTable
                      columns={orderDetailsColumns}
                      data={orderDetailsData()}
                      onSort={orderDetailsData}
                      pageCount="0"
                      noData="orderDetails"
                      // orderDetailsPageSize={size}
                    />
                  )}
                </Grid>
              </Grid>
            )}

          </Box>
          <CardActions className={classes.cardActions1}>
            {/* <Button
              onClick={handleModalClose}
              variant="contained"
              size="large"
              color="inherit"
              tabIndex="19"
            >
              Cancel
            </Button> */}
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  tabIndex="19"
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </CardActions>
          {loading && <Loader />}
        </Card>
      </div>}
    </>
    
  );
};

export default KioskReceipt;

const buttonCSS = {
  fontSize: "2rem",
  color: "white",
  padding: "1px 20px",
  textTransform: "none",
  borderRadius: "0.75rem",
  fontFamily: "Lusitana",
  "&:active": {
    transform: "scale(0.0)",
    transition: "transform 2s ease",
  },
};
