import { Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CardDetail from '../Setting.js/CardDetail'
import Zoom from '@mui/material/Zoom';
import { makeStyles } from "@mui/styles";
import {Box, Button} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AddCard from '../Setting.js/AddCard';

const useStyles = makeStyles((theme) => ({
    root: {},
    paper: { borderRadius: 15 ,  width:"50rem"},
    noBorder: {
      border: "none",
    },
    cardHeader: {
      backgroundColor: "#B7273B",
      color: "white",
      padding:"1rem",
      // justifyContent: "space-between",
      marginBottom: "0.2rem",
    },
    btnLogin: {
      padding: "0.2rem 1rem",
      backgroundColor: "brown",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    guest: {
      cursor: "pointer",
    },
    error: {
      color: "#bf1650",
      " &::before": {
        display: "inline",
        content: "⚠ ",
      },
    },
    add:{
        backgroundColor: "brown",
        width:"fit-content"
    }
  }));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom  ref={ref} {...props} timeout={500}/>;
  });

const CreditCardPayment = (props) => {

   const {creditPopup , setCreditPopup , saveCardDetail , creditCardTitle , remainingPayment} = props;
   const classes = useStyles();
   const [showCard , setshowCard] = useState(false);
   const closeAd = () => {
    setCreditPopup(false);
  };

  const handleAddCard = () =>{
    console.log("reachingHereOrNot");
    // setshowCard(true);
  }
  

  return (
    <>
      <Dialog
        open={creditPopup}
        classes={{
          paper: classes.paper,
        }}
        onKeyDown = {(e)=>  e.key == "Escape" && closeAd() }
        TransitionComponent={Transition}
      >
        <Box  className={classes.cardHeader}>Select Card</Box>
        <Box>
            <div style={{fontWeight:"bold"}}>Saved Cards</div>
            {saveCardDetail.map((item)=>{
                return (
                    <div style={{display:"flex"}}>
                        <div><RadioButtonUncheckedIcon/></div>
                        <div> {item?.cardType} Ending In {item?.lastFourCardDigit}</div>
                    </div>
                )
            })}
        </Box>
        <Button
         className={classes.add}
         variant="contained"
         onClick={()=> setshowCard(true)}
        >
            Add +
        </Button>
      </Dialog>
      {showCard &&<AddCard showCard={showCard} setshowCard={setshowCard} creditCardTitle={creditCardTitle} remainingPayment={remainingPayment} setCreditPopup={setCreditPopup}/>}
    </>
  )
}

export default CreditCardPayment