import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import MapContainer from "../StoreLocation";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  FormControl,
  InputAdornment,
  TextField,
  SwipeableDrawer,
  Box,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import AlertPopup from "../../AlertPopup";
import StorePopup from "../Sidebar/StorePopup";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./Navbar.css";
import { useSelector, useDispatch } from "react-redux";
import { setTaxes } from "../../../Redux/CartSlice";
import { getTaxes } from "../../../Api/API";
import { detectMobileDevice } from "../../utils";
import { makeStyles } from "@mui/styles";
import {
  setActiveMarker,
  setUserFlag
} from "../../../Redux/StoreSlice";
const useStyles = makeStyles({
  BackdropProps: {
    background: "transparent",
  },
});
const Storebar = ({ state, setState ,refreshTax, storeLoading, setStoreLoading}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [currentLocation, setCurrentLocation] = useState(false);
  const deny = useSelector((state) => state.store.deny);

  const [open, setOpen] = useState(false);
  const cartItem = useSelector((state) => state.cart.cart);
  const locationPopup = useSelector((state) => state.store.locationPopup);
  const storeName = useSelector((state) => state.store.nearestStore);
  const isLogin = useSelector((state) => state.user.isLogin);
  const location = useLocation();
  // const [storeLoading, setStoreLoading] = useState(false);
  React.useEffect(() => {
    if (deny && location.pathname == "/") {
      setState(true);
    }
  }, [deny]);

  let detectDevice = detectMobileDevice();

  const removeStore = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (storeName?.id) {
      getTaxes(storeName?.id, 1)
        .then((res) => {
          dispatch(setTaxes(res[0]));

        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [storeName,refreshTax]);

  const toggleDrawer = (open) => (e) => {
    if (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
      return;
    }
    setState(open);
  };

  const list = (anchor) => (
    <div>
      <Box
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      ></Box>
    </div>
  );
  const handelToggleDrawer = () => {
    // console.log("bdwuvuhwjvbdbis");
    dispatch(setUserFlag(true));
    setState(true);
    dispatch(setActiveMarker("out"));
  };

  return (
    <>
      <FormControl sx={{ mt: 1.5 }} variant="outlined">
        <TextField
          sx={{ display: { xs: "none", sm: "block" } }}
          size="small"
          name="address"
          aria-describedby="address"
          variant="outlined"
          placeholder="Find a store"
          value={
            storeName
              ? storeName.address.length < 39
                ? `${storeName.address.replace("~", " ").substring(0, 43)} ${
                    storeName.zip_code
                  }`
                : `${storeName.address.replace("~", " ").substring(0, 43)}`
              : ""
          }
          onClick={
            location.pathname == "/checkout" || location.pathname == "/payment" || location.pathname == "/payment/success"
              ? toggleDrawer(false)
              : handelToggleDrawer
          }
          label="Store"
          InputProps={{
            sx: { width: { lg: "25.3rem", md: "20rem", sm: "15rem" } },
            startAdornment: (
              <InputAdornment position="start">
                <LocationOnIcon
                  fontSize="small"
                  style={{ marginLeft: "-45%" }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                {location.pathname == "/checkout" ||
                location.pathname == "/payment" || location.pathname == "/payment/success" ? (
                  ""
                ) : storeName?.id ? (
                  <CancelIcon
                    onClick={() => removeStore()}
                    fontSize="small"
                    style={{ marginRight: -15 }}
                  />
                ) : (
                  <EditIcon fontSize="small" style={{ marginRight: -15 }} />
                )}
              </InputAdornment>
            ),
            readOnly: true,
          }}
        />
        {/* <Box display="flex" sx={{ display: { xs: 'flex', sm: 'none' } }}>
          <LocationOnIcon onClick={toggleDrawer(true)} />
          <span style={{ maxWidth: "35vw", display:"flex",alignItems:"center", fontSize:"0.8rem" }} onClick={toggleDrawer(true)}>{storeName?.store_name}</span>
        </Box> */}
      </FormControl>
      {
        <SwipeableDrawer
          anchor="right"
          PaperProps={{
            sx: { width: { xs: "80vw", sm: "55vw", md: "43vw", lg: "43vw" } },
          }}
          //   ModalProps={{
          //   BackdropProps:{
          //     classes:{
          //       root:classes.BackdropProps
          //     }
          //   }
          // }}
          open={state}
          // onClose={toggleDrawer(false)}
          // onTouchEndCapture = {detectDevice ? toggleDrawer(false)  : ""}
          onClose={() => {
            setState(false);
            dispatch(setActiveMarker("out"));
          }}
          onOpen={toggleDrawer(true)}
        >
          {list(true)}
          <Box sx={{ m:"5px" }}>
            <Backdrop
              sx={{ color: "#fff", zIndex: 1300, position: "absolute" }}
              open={storeLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <MapContainer
              setState={setState}
              currentLocation={currentLocation}
              setCurrentLocation={setCurrentLocation}
              setStoreLoading={setStoreLoading}
              storeLoading={storeLoading}
            />
          </Box>
        </SwipeableDrawer>
      }
      <StorePopup
        showLocation={locationPopup}
        setshowLocation={locationPopup}
        setState={setState}
      ></StorePopup>
      <AlertPopup
        open={open}
        setOpen={setOpen}
        title={"removeStore"}
        displayTitle={
          cartItem?.length > 0
            ? "Removing the store will empty your cart"
            : "Are you sure you want to remove store?"
        }
        buttonType1={true}
        buttonType2={true}
        setCurrentLocation={setCurrentLocation}
      ></AlertPopup>
    </>
  );
};

export default Storebar;
