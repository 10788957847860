import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Box,
  Backdrop,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { fivestarReward, RewardList } from "../../Api/API";
import { LoyalityPoint } from "../../Api/API";
import reward from "./rewardpopup.png";
import { errorToastMessage, MenuProps } from "../../Common/utils";
import Zoom from "@mui/material/Zoom";
import { toast } from "react-toastify";
import {
  fiveStarResponse,
  setFiveStarIndex,
  setFiveStarResponse,
  setFiveStarRewardDetail,
  setRemoveReward,
} from "../../Redux/UserSlice";
import { Container } from "@mui/system";
toast.configure();
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: { borderRadius: 15 },
  btnLogin: {
    borderRadius: 5,
    backgroundColor: "brown",
    color: "white",
    textTransform: "none",
    marginTop: "3rem",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});
const RewardPopUp = (props) => {
  const {
    showRewards,
    setshowRewards,
    setTotalFivestar,
    totalFivestar,
    disc,
    totalCartItem,
    fivestarDiscount,
    setfivestarDiscount,
    combineDiscount,
    promoApply,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user.isLogin);
  const userDetail = useSelector((state) => state.user.user);
  const storeName = useSelector((state) => state.store.nearestStore);
  const discountDetail = useSelector((state) => state.user.discountDetail);
  const fiveStarIndex = useSelector((state) => state.user.fiveStarIndex);
  const cartItem = useSelector((state) => state.cart.cart);
  const cartData = useSelector((state) => state.cart.cartData);
  const user = useSelector((state) => state.user.user);
  const removeReward = useSelector((state) => state.user.removeReward);
  const [loyalityPnt, setLoyalityPnt] = useState();
  const [rewardlst, setrewardlst] = useState();
  // const [fiveStarLoyality , setFiveStarLoyality] = useState();
  const [fiveStarData, setFiveStarData] = useState();
  // const [fivestarDiscount, setfivestarDiscount] = useState(totalFivestar);
  const [loading, setLoading] = useState(false);
  const [rewardLoading, setRewardLoading] = useState(false);
  const [fivestarError, setFivestarError] = useState();

  let data;
  useEffect(() => {
    if (isLogin) {
      let orderDetail = cartItem.map((e) => {
        let selectedMenuServingDetail = [
          {
            created_at: e?.selectedCupSize[0]?.created_at,
            price: e?.selectedCupSize[0]?.price?.toString(),
            serving_actual_id: e?.selectedCupSize[0]?.serving_actual_id,
            serving_description: e?.selectedCupSize[0]?.serving_description,
            serving_icon: e?.selectedCupSize[0]?.serving_icon,
            serving_id: e?.selectedCupSize[0]?.serving_id,
            serving_name: e?.selectedCupSize[0]?.serving_name,
            status: e?.selectedCupSize[0]?.status,
            updated_at: e?.selectedCupSize[0]?.updated_at,
          },
        ];
      
        let options = e?.selectedCupSize?.map((element) => {
          let detail = {
            modifier_group_name: "Cup Size",
            option: [
              {
                id: element?.serving_actual_id?.toString(),
                isSelected: false,
                modifier_id: "",
                modifier_name: element?.serving_name,
                modifier_prefix_overide_mode: "No",
                modifier_prefix_overideMode: "",
                modifier_prefix_price: 0,
                modifier_prefixname: "",
                modifier_prefix_name: 0,
                modifier_price: element?.price?.toString(),
                prefix_name: "",
                price: element?.price?.toString(),
                quantity: 1,
                selected_modifier_serving: {
                  price: "0",
                  serving_name: "",
                },
                serving_id: element?.serving_actual_id?.toString(),
                serving_name: element?.serving_name,
                serving_price: element?.price?.toString(),
                sub_modifier_id: 0,
                title: "",
              },
            ],
            required: true,
            selection_time: "Single",
            title: "Cup Size",
          };
          return detail;
        });
        let obj = Object.assign({}, e);
        obj.selectedOptions = [...options, ...e.selectedOptions];
        e = obj;
        let detail = {
          apply_option: "-1",
          category_id: e?.categoryId?.toString(),
          category_name: e?.categoryName,
          costToDisplay: e?.orderItemPrice?.toString(),
          customLoyaltyId: "",
          custom_loyalty_id: "",
          customizationOpt: e?.selectedOptions,
          discountId: "",
          discount_price: "0",
          discount_rate: "0",
          discount_title: "",
          discount_type: "",
          fivestar_discount: "",
          fivestar_discount_id: "",
          gift_card_no: "",
          gift_card_type: "",
          id: 1372,
          is_giftcard: "",
          itemAliasName: "",
          itemCustomizedPrice: "",
          loyaltyDetails: "New Order",
          loyalty_id: "",
          loyalty_points: "0",
          loyalty_value: "0",
          menu_id: e?.id?.toString(),
          menu_image: e?.image,
          menu_name: e?.title,
          originalCost: "",
          quantity: e?.quantity,
          selected_menu_serving_detail: selectedMenuServingDetail,
          serving_id: e?.selectedCupSize[0]?.serving_actual_id?.toString(),
          serving_name: e?.selectedCupSize[0]?.serving_name,
          serving_price: e?.selectedCupSize[0]?.price?.toString(),
        };
        return detail;
      });
      //  let servingDetails = cartItem.map((e)=>e.selectedCupSize)
      //   setCartOrderDetails(customizationOption)
      //  setServingDetails(servingDetails)

      data = {
        customer_address: "",
        customer_email: userDetail ? userDetail?.email : "",
        customer_id: userDetail?.person_id
          ? userDetail?.person_id?.toString()
          : userDetail?.customer_id?.toString(),
        customer_mobile_no: userDetail?.phone_no
          ? userDetail?.phone_no
          : userDetail?.phone_number,
        customer_name: userDetail?.first_name
          ? userDetail?.first_name
          : userDetail?.username,
        employee_id: 1,
        employee_name: "",
        givex_code: "",
        givex_num: "",
        is_emv: 0,
        loyalty_point: userDetail?.loyalty_point
          ? userDetail?.loyalty_point
          : 0,
        loyalty_request: {
          checkin: "",
          phone: "",
          points: [],
          rewards: [],
        },
        loyalty_reward_json: [
          {
            reward_label: "",
            reward_points: 0,
            reward_uid: "",
          },
        ],
        loyalty_value: 0,
        order_details: [
          {
            discount: {
              discount: discountDetail[0]?.disArr?.discountRate
                ? discountDetail[0].disArr.discountRate
                : 0,
              id: discountDetail[0]?.disArr?.id
                ? discountDetail[0].disArr.id
                : -1,
              type: discountDetail[0]?.disArr?.discountType
                ? discountDetail[0].disArr.discountType
                : "",
            },
            discount_total_price: 0,
            loyalty_point: 0,
            loyalty_value: 0,
            orderDetails: orderDetail,
            pickup_date: cartData.pickupDate,
            pickup_type: cartData.pickupType,
            store_id: storeName.id.toString(),
            subTotal: cartData.subTotal?.toString(),
            tax: cartData?.taxRate?.toString(),
            taxValue: cartData?.taxValue?.toString(),
            timezone: -330,
            total: cartData.total,
            total_fivestar_discount: 0,
          },
        ],
        order_status: "pending",
        order_total_price: "",
        order_type: "web",
        payment_method: [
          {
            amount: "",
            amt: "",
            auth_code: "",
            calculationAmt: "",
            card_last_no: 0,
            givex_code: 0,
            givex_num: 0,
            givex_trans_num: "",
            method: "cash",
            payId: 0,
            payType: "",
            paymentMethod: "cash",
            trans_num: 0,
          },
        ],
        store_current_time: new Date().toISOString(),
        store_id: storeName.id.toString(),
        store_name: storeName.store_name,
        timezone: -330,
        user_id: userDetail?.person_id
          ? userDetail?.person_id?.toString()
          : userDetail?.customer_id?.toString(),
        formattedPickUpLaterDate: "",
        weekDay: "",
        discountAmount: disc,
      };
      setFiveStarData(data);
    }
  }, [isLogin, cartItem]);

  let phoneNumber = user?.phone_no ? user?.phone_no : user?.phone_number;
  let personId = user?.person_id ? user?.person_id : user?.customer_id;
  useEffect(() => {
    if (isLogin && phoneNumber && personId) {
      LoyalityPoint(phoneNumber, personId)
        .then((res) => {
          setLoyalityPnt(res?.userDetails);
        })
        .catch((err) => {
        
          errorToastMessage();
        });
    }
  }, [isLogin, phoneNumber, personId]);
 
  useEffect(() => {
    if (isLogin && personId) {
      setLoading(true);
      RewardList("", personId, storeName.id)
        .then((res) => {
          setrewardlst(res?.result);
          setLoading(false);
        })
        .catch((err) => {
      
          errorToastMessage();
          setLoading(false);
        });
    }
  }, [isLogin, personId]);

  const handleFivestarReward = (val, elm, index) => {
    dispatch(setRemoveReward(elm.perk));
    if (promoApply != null && combineDiscount == "false") {
      if (promoApply != null && combineDiscount == "false") {
        setFivestarError("Cannot combine these promotions in same order");
      } else {
        setFivestarError("");
      }
    } else {
      if (elm.point_cost <= loyalityPnt.loyalityPoint) {
        setRewardLoading(true);
        setFivestarError("");
        fivestarReward(window.btoa(val), fiveStarData)
          .then((res) => {
            if (res?.updatedCart == null) {
              setfivestarDiscount(totalFivestar);
              // setTotalFivestar(fivestarDiscount)
              dispatch(setFiveStarResponse(""));
            } else {
              setfivestarDiscount(
                res?.updatedCart?.order_details[0]?.total_fivestar_discount
              );
              // setTotalFivestar(fivestarDiscount)
              dispatch(setFiveStarResponse(res?.updatedCart));
            }
            if (res?.responseCode == 1) {
              dispatch(setFiveStarIndex(index));
              dispatch(setFiveStarRewardDetail(elm));
              setRewardLoading(false);
              // setshowRewards(true)
              toast.success(res?.message, {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
            } else {
              setTotalFivestar(0);
              dispatch(setFiveStarIndex(Number.MAX_VALUE));
              dispatch(setFiveStarRewardDetail(""));
              dispatch(setFiveStarResponse(""));
              setFiveStarResponse("");
              toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
              setRewardLoading(false);
            }
          })

          .catch((err) => {
         
            errorToastMessage();
            setRewardLoading(false);
            dispatch(setRemoveReward(""));
          });
      } else {
        toast.error(
          "No enough reward points, place order to earn reward points ",
          {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
        setRewardLoading(false);
        setTotalFivestar(0);
        setfivestarDiscount(0);
        dispatch(setFiveStarIndex(Number.MAX_VALUE));
        dispatch(setFiveStarRewardDetail(""));
        dispatch(setFiveStarResponse(""));
      }
    }
  };

  useEffect(() => {
    let totalFivestarDiscount = fivestarDiscount;
    setTotalFivestar(totalFivestarDiscount);
  }, [fivestarDiscount]);

  useEffect(() => {
    dispatch(setRemoveReward(""));
  }, []);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: 1000 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {rewardlst && rewardlst.length > 0 && (
        <>
          <Container style={{ textAlign: "center" }}>
            <FormControl sx={{ width: { lg: "75%", md: "75%", xs: "100%" } }}>
              <InputLabel style={{ color: "#C60734" }}>Rewards</InputLabel>

              <Select
                label="Rewards"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C60734",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C60734",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C60734",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#C60734",
                  },
                }}
                MenuProps={MenuProps}
                value={removeReward}
              >
                <MenuItem
                  key="try"
                  value=""
                  style={{ background: "white" }}
                ></MenuItem>
                {rewardlst?.map((elm, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={elm?.perk}
                      onClick={() => {
                        if (
                          fiveStarIndex == Number.MAX_VALUE ||
                          fiveStarIndex != index
                        ) {
                          if (combineDiscount != "false") {
                            handleFivestarReward(elm?.perk, elm, index);
                          } else {
                            handleFivestarReward(elm?.perk, elm, index);
                          }
                        } else {
                          dispatch(setFiveStarResponse(""));
                        }
                      }}
                    >
                      {elm?.perk}
                   
                    </MenuItem>
                  );
                })}
              
              </Select>
            </FormControl>
            <Box
              sx={{
                color: "#C30E2F",
              }}
            >
              {promoApply &&
              combineDiscount == "false" &&
              fivestarError == "Cannot combine these promotions in same order"
                ? "Cannot combine these promotions in same order"
                : ""}
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default RewardPopUp;
