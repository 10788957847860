import { CardHeader, Container, Typography, Card, CardContent, Grid, Button, Backdrop } from '@mui/material'
import { Divider } from '@mui/material'
import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "./Checkout.css"
import React from 'react'
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import CheckoutItem from './CheckoutItem'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { GoogleMap, InfoWindow } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { creditCardRefundPayment, givexCardRefundPayment, PlaceOrder, sendReciept } from '../../Api/API'
import { useLocation } from 'react-router-dom'
// import { useHistory } from 'react-router-dom';
import { setPlacedOrder, setUpdateCart } from '../../Redux/CartSlice'
import { useNavigate } from "react-router-dom";
import { setCheckoutFlag, setDiscountDetail, setFiveStarRewardDetail,setClickActiveOrders,setCheckActive, setAutoDiscountDetail } from '../../Redux/UserSlice'
import { errorToastMessage, numberWithCommas } from '../../Common/utils'
import { setFiveStarResponse } from '../../Redux/UserSlice'
import PersonIcon from '@mui/icons-material/Person';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

const Checkout = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isLogin = useSelector((state) => state.user.isLogin);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  console.log(isGuestLogin,"isGuestLogin");
  const fiveStarResponse = useSelector((state) => state.user.fiveStarResponse);
  const fiveStarRewardDetail = useSelector((state) => state.user.fiveStarRewardDetail);
  const storeCoordinate = useSelector((state) => state.store.storeCoordinate);
  const userDetail = useSelector((state) => state.user.user);
  const checkoutFlag = useSelector((state) => state.user.checkoutFlag);
  const storeName = useSelector((state) => state.store.nearestStore);
  const discountDetail = useSelector((state) => state.user.discountDetail);
  const autoDiscountDetail = useSelector((state) => state.user.autoDiscountDetail);
  const cartItem = useSelector((state) => state.cart.cart);
  const cartData = useSelector((state) => state.cart.cartData)
  
  const paymentData = useSelector((state) => state.cart.paymentData)
  const storeData = useSelector((state) => state.store.storeData)
  const orderTotalPrice = useSelector((state) => state.cart.orderTotalPrice)
  const givexPaymentDetail = useSelector((state) => state.store.givexPaymentDetail);
  const pickUpLaterTime = useSelector((state) => state.cart.pickUpLaterTime)
  const [activeMarker, setActiveMarker] = useState(null);
  const placedOrder = useSelector((state) => state.cart.placedOrder);
  const clickActive = useSelector((state) => state.user.clickActive);
  console.log(cartData,orderTotalPrice,"cartData")
  const mapStyles = {
    width: "100%",
    height: "30vh",
    marginTop: "2vh",
    borderRadius: "1rem",
    marginBottom: "1rem"
  };
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
// console.log(clickActive,2222)
console.log(discountDetail,"discountDetail");
const handleRefund = ()=>{
  let newDate = new Date();
  let flag = false;
  if (givexPaymentDetail?.type == "givex") {
    flag = true;
    let givexPaymentData = {
      cardNumber: givexPaymentDetail?.cardNumber,
      clientId: givexPaymentDetail?.clientId,
      description: givexPaymentDetail?.description,
      originalRequestId: givexPaymentDetail?.originalRequestId,
      requestId: givexPaymentDetail?.requestId,
      transactionRefNumber: givexPaymentDetail?.transactionRefNumber,
      storeId: givexPaymentDetail?.storeId
    }
    givexCardRefundPayment(givexPaymentData).then((res) => {
      if (res?.responseCode == 0) {
        toast.error(
          "Oops Something Went Wrong ,Your refund has been initiated",
          {
            position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          }
        );
      }
    }).catch((e) => console.log(e))
  }

  if (givexPaymentDetail?.type == "credit_card") {
    flag = true;
    let creditCardDetail = {
      requestId: givexPaymentDetail?.requestId,
      transactionRefNumber: givexPaymentDetail?.transactionRefNumber,
      storeId: givexPaymentDetail?.storeId
    }
    creditCardRefundPayment(creditCardDetail).then((res) => {
     
      if (res?.responseCode == 0) {
        toast.error(
          "Oops Something Went Wrong ,Your refund has been initiated",
          {
            position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          }
        );
      }
    }).catch((e) => console.log(e))
  }

  if (flag == false && givexPaymentDetail?.length == 2) {
    let creditCardDetail = {
      requestId: givexPaymentDetail[0]?.requestId,
      transactionRefNumber: givexPaymentDetail[0]?.transactionRefNumber,
      storeId: givexPaymentDetail[0]?.storeId
    }
    creditCardRefundPayment(creditCardDetail).then((res) => {
      if (res?.responseCode == 0) {
        toast.error(
          "Oops Something Went Wrong ,Your refund has been initiated",
          {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss : false,
            autoClose : 1500,
            pauseOnHover : false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    }).catch((e) => console.log(e))
    let givexPaymentData = {
      cardNumber: givexPaymentDetail[1]?.cardNumber,
      clientId: givexPaymentDetail[1]?.clientId,
      description: givexPaymentDetail[1]?.description,
      originalRequestId: givexPaymentDetail[1]?.originalRequestId,
      requestId: givexPaymentDetail[1]?.requestId,
      transactionRefNumber: givexPaymentDetail[1]?.transactionRefNumber,
      storeId: givexPaymentDetail[1]?.storeId
    }
    givexCardRefundPayment(givexPaymentData).then((res) => {
      if (res?.responseCode == 0) {
        toast.error(
          "Oops Something Went Wrong,Your refund has been initiated",
          {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss : false,
            autoClose : 1500,
            pauseOnHover : false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    }).catch((e) => console.log(e))
  }
}

  useEffect(() => {
    
    if (checkoutFlag) {
      setLoading(true);
      let orderDetailArray = cartItem.map((e, index) => {
      
        let selectedMenuServingDetail = [{

          created_at: e?.selectedCupSize[0]?.created_at,
          price: e?.selectedCupSize[0]?.price?.toString(),
          serving_actual_id: e?.selectedCupSize[0]?.serving_actual_id?.toString(),
          serving_description: e?.selectedCupSize[0]?.serving_description,
          serving_icon: e?.selectedCupSize[0]?.serving_icon,
          serving_id: e?.selectedCupSize[0]?.serving_id?.toString(),
          serving_name: e?.selectedCupSize[0]?.serving_name,
          status: e?.selectedCupSize[0]?.status,
          updated_at: e?.selectedCupSize[0]?.updated_at
        }]
        //  if(e.selectedOptions.length){
        // selectedOptions
        //  e.selectedOptions.map((element)=>element.isSelected = false)
        //  }
        let options = e.selectedCupSize.map((element) => {
          let detail = {
            modifier_group_name: "Cup Size",
            option: [{
              id: element.serving_actual_id?.toString(),
              isSelected: false,
              modifier_id: "",
              modifier_name: element.serving_name,
              modifier_prefix_overide_mode: "No",
              modifier_prefix_overideMode: "",
              modifier_prefix_price: 0,
              modifier_prefixname: "",
              modifier_prefix_name:0,
              modifier_price: element.price?.toString(),
              prefix_name: "",
              price: element.price?.toString(),
              quantity: 1,
              selected_modifier_serving: {
                price: "0",
                serving_name: "",
              },
              serving_id: element.serving_actual_id?.toString(),
              serving_name: element.serving_name,
              serving_price: element.price?.toString(),
              sub_modifier_id: 0,
              title: "",
            }],
            required: true,
            selection_time: "Single",
            title: "Cup Size",
          }
          return detail
        }
        )
        let obj = Object.assign({}, e)
        obj.selectedOptions = [...options, ...e.selectedOptions]
        e = obj
       
        let detail = {
          apply_option: "-1",
          category_id: e?.categoryId?.toString(),
          category_name: e?.categoryName,
          costToDisplay: e?.orderItemPrice?.toString(),
          customLoyaltyId: "",
          custom_loyalty_id: "",
          customizationOpt: e.selectedOptions,
          discountId: "-1",
          discount_price: "0",
          discount_rate: "0",
          discount_title: "",
          discount_type: "0",
          fivestar_discount: fiveStarResponse ? fiveStarResponse?.order_details[0]?.orderDetails[index]?.fivestar_discount : "",
          fivestar_discount_id: fiveStarResponse ? fiveStarResponse?.order_details[0]?.orderDetails[index]?.fivestar_discount_id : "",
          gift_card_no: "",
          gift_card_type: "",
          id: 1372,
          is_giftcard: "",
          itemAliasName: e?.itemAliasName,
          itemCustomizedPrice: e?.orderItemPrice?.toString() ? e?.orderItemPrice?.toString() : "0",
          loyaltyDetails: "New Order",
          loyalty_id: "",
          loyalty_points: "0",
          loyalty_value: "0",
          menu_id: e.id?.toString(),
          menu_image: e?.image,
          menu_name: e?.title,
          originalCost: e?.orderItemPrice?.toString() ? e?.orderItemPrice?.toString() : "0" ,
          quantity: e.quantity,
          selected_menu_serving_detail: selectedMenuServingDetail,
          serving_id: e.selectedCupSize[0].serving_actual_id?.toString(),
          serving_name: e.selectedCupSize[0].serving_name,
          serving_price: e.selectedCupSize[0].price?.toString()
        }
        return detail
      })
      //  let servingDetails = cartItem.map((e)=>e.selectedCupSize)
      // console.log(storeName)
      // console.log( servingDetails)
      //   setCartOrderDetails(customizationOption)
      //  setServingDetails(servingDetails)

      let data = {
        "customer_address": "",
         "customer_email": userDetail?.email?userDetail?.email : "NA",
         "customer_id":  userDetail?.person_id
         ? userDetail?.person_id.toString() // No need for additional check here
         : userDetail?.customer_id?.toString() || "0",
        "customer_mobile_no": userDetail?.phone_no
        ? userDetail?.phone_no
        : userDetail?.phone_number || "NA",
        "customer_name": userDetail?.first_name ? `${userDetail?.first_name} ${userDetail?.last_name ? userDetail?.last_name : ""}` : userDetail?.username,
        "employee_id": 1,
        "employee_name": "",
        "givex_code": "",
        "givex_num": "",
        "is_emv": 0,
        "loyalty_point": userDetail?.loyalty_point ? userDetail?.loyalty_point : 0,
        "loyalty_request": {
          "checkin": "",
          "phone": "",
          "points": [

          ],
          "rewards": [

          ]
        },
        "loyalty_reward_json": [
          {
            "reward_label": fiveStarRewardDetail ? fiveStarRewardDetail?.perk : "",
            "reward_points": fiveStarRewardDetail ? fiveStarRewardDetail?.point_cost : 0,
            "reward_uid": fiveStarRewardDetail ? fiveStarRewardDetail?.uid : "",
          }
        ],
        "loyalty_value": 0,
        "order_details": [
          {
            "discount": {
              "discount": discountDetail[0]?.disArr?.discountRate ? discountDetail[0].disArr.discountRate:autoDiscountDetail[0]?.disArr?.discountRate ? autoDiscountDetail[0].disArr.discountRate:0,
              "id": discountDetail[0]?.disArr?.id ? discountDetail[0].disArr.id : autoDiscountDetail[0]?.disArr?.id ? autoDiscountDetail[0].disArr.id:-1,
              "discountTitle": discountDetail[0]?.disArr?.title ? discountDetail[0].disArr.title : autoDiscountDetail[0]?.disArr?.title ? autoDiscountDetail[0].disArr.title:" ",
              "type": discountDetail[0]?.disArr?.discountType ? discountDetail[0].disArr.discountType : autoDiscountDetail[0]?.disArr?.discountType ? autoDiscountDetail[0].disArr.discountType:"",
            },
            "discount_total_price": cartData?.promoDiscount?.toString(),
            "loyalty_point": 0,
            "loyalty_value": 0,
            "orderDetails": orderDetailArray,
            "pickup_date": cartData.pickupDate,
            "pickup_type": cartData.pickupType,
            "store_id": storeName?.id?.toString(),
            "subTotal": (cartData?.subTotal)?.toString(),
            "tax": cartData?.taxRate?.toString(),
            "taxValue": (cartData?.taxValue-cartData?.webFeeValue).toString(),
            "serviceFeeValue":cartData?.webFeeValue?.toString(),
            "timezone": -330,
            "total": cartData?.total,
            "total_fivestar_discount": fiveStarResponse ? fiveStarResponse?.order_details[0]?.total_fivestar_discount : 0
          }
        ],
        "order_status": "pending",
        "order_total_price": orderTotalPrice,
        "order_type": "website",
        "payment_method": paymentData,
        "store_current_time": new Date()?.toISOString(),
        "store_id": storeName?.id?.toString(),
        "store_name": storeName?.store_name,
        "timezone": -330,
        "user_id": userDetail?.person_id ? (userDetail?.person_id)?.toString() : (userDetail?.customer_id)?.toString(),
        "formattedPickUpLaterDate": cartData?.formattedDate,
        "weekDay": cartData?.weekDay
      };

      PlaceOrder(data)
        .then((res) => {
          
          if (res.responseCode == 1) {
            setLoading(false);
            dispatch(setUpdateCart([]))
            dispatch(setFiveStarRewardDetail(""))
            dispatch(setFiveStarResponse(""));
            dispatch(setPlacedOrder(res?.result))
            dispatch(setDiscountDetail(""))
            dispatch(setAutoDiscountDetail(""))
            console.log(res?.result.order_id)
            
            if(data.customer_id==="0"){
              let email = "";
              sendReciept(
                res?.result.order_id,
                userDetail?.phone_number != "" ? String(userDetail?.phone_number).split("-").join("") : "",
                email
              ).then((res) => {
                console.log(res,"Reciept Response")
                // navigate("/");
              });
            }
           
          }
          else if (res.responseCode == 0 && res.msg == "Payment request is not good.") {
            setLoading(false);
            dispatch(setPlacedOrder(""))
            dispatch(setFiveStarRewardDetail(""))
            dispatch(setFiveStarResponse(""));
            dispatch(setDiscountDetail(""))
            dispatch(setAutoDiscountDetail(""))
            handleRefund()
            toast.error(
              "Oops Something Went Wrong, please try again",
              {
                position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
              }
            );
          }
          else if (res.responseCode == 0 && res.msg == "Customer not exists.") {
            setLoading(false);
            dispatch(setPlacedOrder(""))
            dispatch(setDiscountDetail(""))
            dispatch(setAutoDiscountDetail(""))
            dispatch(setFiveStarRewardDetail(""))
            dispatch(setFiveStarResponse(""));
            handleRefund()
            toast.error(
              "You have been denied permission to place order",
              {
                position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
              }
            );
          }
          else {
            setLoading(false);
            dispatch(setDiscountDetail(""))
            dispatch(setAutoDiscountDetail(""))
            dispatch(setPlacedOrder(""))
            dispatch(setFiveStarRewardDetail(""))
            dispatch(setFiveStarResponse(""));
            handleRefund()
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch(setDiscountDetail(""))
          dispatch(setAutoDiscountDetail(""))
          dispatch(setFiveStarRewardDetail(""))
          dispatch(setFiveStarResponse(""));
         handleRefund()
          dispatch(setPlacedOrder(""))
          console.log(err);
        });
      dispatch(setCheckoutFlag(false));
    }
  }, [])
  // useEffect (() => {

  // },[]);
  window.onpopstate = () => {
    navigate("/");
  }
  return (
    <>
     <div style={{ marginBottom:"1rem",marginTop:".4rem"}}>
          <Typography sx={{ color:"#C60734",marginLeft:{lg:"10.3rem",md:"1.6rem",sm:"1.6rem",xs:"1rem"}}}  variant='h5'>Order Summary</Typography>
            </div>
           
            
      <Box
       sx={{
          backgroundImage: {md:`url("skyline.png")`,xs:" "},
          backgroundSize:"100% 35%",
          backgroundRepeat:"no-repeat",
          backgroundPosition:"center bottom",
          paddingBottom:"1rem",
          height:"88vh"
          
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: 1000 }}
          open={loading}
        // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        
        {loading == false ? 
        <Container sx={{ marginTop: "1vh", paddingBottom:{lg:0,md:0,sm:"1rem",xs:"62px"} }}>
       {placedOrder ?
              <Grid container spacing={1.5}>
                <Grid item md={6} xs={12} sm={12} lg={6} order={{ xs: 1, sm: 1, }}>
                <Card sx={{borderRadius:"10px", height:{xs:"11rem",sm:"8rem"}}}>
                  <Container >
                  <div
                            style={{
                              textAlign:"center",
                              margin:"0.8rem 0rem",
                              // display:"flex",flexDirection:"column"
                            }}
                          >
                    <Typography variant='subtitle1' fontWeight="600">Your order has been placed!</Typography>
                      <Typography gutterBottom variant='subtitle2'>{!pickUpLaterTime ? "We will notify you when your order is ready" : `Your order will be ready at ${pickUpLaterTime}`}</Typography>
                   
                     {isGuestLogin === true? "": <Button 
                              variant="contained"
                              sx={{ 
                              marginTop:"1rem",
                                padding: {
                                  lg: "0.2rem 3rem",
                                  md: "0.2rem 3rem",
                                  sm: "0.2rem 3rem",
                                  xs: "0.2rem 2rem",
                                },
                              }}
                              style={{
                                borderRadius: 20,
                                backgroundColor: "white",
                                color: "#C60734",
                                textTransform: "none",
                                border:"1px solid #C60734",
                                fontWeight:"600",
                              }}
                              onClick={() => {
                                navigate(`/orderHistory`);
                                dispatch(setClickActiveOrders(true));
                                dispatch(setCheckActive(true));
                              }}
                            >
                              Track your order 
                      </Button>}
                      </div>
                  </Container>
                  </Card>          
                </Grid>
                <Grid item md={6} xs={12} sm={12} lg={6} order={{ xs: 4, sm: 2, }}>
                <Card sx={{borderRadius:"10px", height:"8rem", display:"flex",
                              justifyContent:"space-evenly",
                              alignItems:"center"}}>
                  <Typography><b>Congratulations! You earned {placedOrder?.accumlated_point} points</b></Typography>
                  <img src='./newCupImg.png' height="85rem"/>
                  </Card>
                </Grid>
                {placedOrder ?
                  <Grid item md={6} xs={12} sm={12} lg={6} order={{ xs: 2, sm: 3, }}>
                    <Card sx={{borderRadius:"10px", height : { lg: "16rem", md: "16rem" }, overflowY: "auto"}}>
                    <Container>
                        {placedOrder ? placedOrder?.result[0]?.orderDetails?.map((e) => {
                          return (
                            <CheckoutItem Detail={e} />
                          )
                        }) : ""}
                    </Container>
                    </Card>
                  </Grid>:" "} 
                  {placedOrder ?
                  <Grid item md={6} xs={12} sm={12} lg={6} order={{ xs: 3, sm: 4, }}>
                  <Card sx={{borderRadius:"10px", height:"16rem"}}>
                  <Container sx={{marginTop:"2rem"}}>
                      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' gutterBottom>Sub-Total:</Typography>
                        <Typography variant='subtitle1' gutterBottom>{`$${numberWithCommas((cartData.subTotal ? Number(cartData?.subTotal) : 0.00).toFixed(2))}`}</Typography>
                      </Box>
                      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' gutterBottom>Discount:</Typography>
                        <Typography variant='subtitle1' gutterBottom>
                          {`-$${cartData.totalDiscount ? Number(cartData.totalDiscount).toFixed(2) : "0.00"}`}
                        </Typography>
                      </Box>
                      {cartData?.webFeeValue!=0?
                      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' gutterBottom> Taxes & Fees:</Typography>
                        <Typography variant='subtitle1' gutterBottom>{`$${numberWithCommas(Number(cartData.taxValue ? Number(cartData.taxValue).toFixed(2) : "0.00").toFixed(2))}`}</Typography>
                      </Box>
                      :
                      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' gutterBottom> Tax:</Typography>
                        <Typography variant='subtitle1' gutterBottom>{`$${numberWithCommas(Number(cartData.taxValue ? Number(cartData.taxValue).toFixed(2) : "0.00").toFixed(2))}`}</Typography>
                      </Box>
}
                      <Divider style={{ marginTop: '1vh', marginBottom: '1vh' }} />
                      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='subtitle1' gutterBottom>Total:</Typography>
                        <Typography variant='subtitle1' gutterBottom>{`$${cartData.total ? numberWithCommas(Number(cartData.total).toFixed(2)) : "0.00"}`}</Typography>
                      </Box>
                      <Divider style={{ marginTop: '1vh', marginBottom: '1vh' }} />
                    </Container>
                  </Card>
                  </Grid>:" "} 
              </Grid>  
:  <div style={{ textAlign: "center", marginTop: "2rem" }}>
<Typography sx={{ color:"#C60734"}}  variant="h6" fontWeight="350">
  Your order is failed and refund initiated.
</Typography>
</div>}
        </Container> : ""}  
      </Box>
    </>
  )
}

export default Checkout