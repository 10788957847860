import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    coordinate: {
        lat: null,
        lng: null
    },
    userCoordinate: {
      lat: null,
      lng: null
  },
    storeData:[],
    markerSize : { width: 40, height: 52 },
    activeMarker: null,
    nearestStore:"",
    popUpTime:"",
    storeCoordinate:{
      // lat: null,lng:null
      lat:Number('40.7128'),
      lng:Number('-74.0060')
    },
    flag:true,
    userFlag:false,
    nearestDistance: "",
    locationPopup: false,
    deny : false,
    routeError : "",
    fav:false ,
    storeMarkers : [],
    locationAcess:false,
    givexPaymentDetail:"",
    saveCreditCard:[],
    storeIndexValue:0,
    checkScroll:true,
};

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setGivexPaymentDetail: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.givexPaymentDetail = payload;
    },
    setSaveCreditCard: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.saveCreditCard = payload;
    },
    setCoordinate: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.coordinate = payload;
    },
    setUserCoordinate: (state, { payload }) => {
      if (payload == null) state = initialState;
      state.userCoordinate = payload;
    },
    setStoreData:(state,{payload}) =>{
      if (payload == null) state = initialState;
      state.storeData = payload;
    },
    setNearestStore:(state,{payload}) =>{
      if (payload == null) state = initialState;
      state.nearestStore = payload;
    },
    setStoreCoordinate:(state,{payload}) =>{
      if (payload == null) state = initialState;
      state.storeCoordinate = payload;
    },
    setFlag:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.flag = payload;
    },
    setUserFlag:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.userFlag = payload;
    },
    setNearestDistance:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.nearestDistance = payload;
    },
    setLocationPopup:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.locationPopup = payload;
    },
    setDeny:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.deny = payload;
    },
    setRouteError:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.routeError = payload;
    },
    setFav:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.fav = payload;
    },
    setStoreMarkers:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.storeMarkers = payload;
    },
    setPopUpTime:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.popUpTime = payload;
    },
    setLocationAcess:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.locationAcess = payload;
    },
    setMarkerSize:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.markerSize = payload;
    },
    setActiveMarker:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.activeMarker = payload;
    },
    setStoreIndexValue:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.storeIndexValue = payload;
    },
    setCheckScroll:(state,{payload})=>{
      if (payload == null) state = initialState;
      state.checkScroll = payload;
    },
  },
});

const { reducer, actions } =  storeSlice;

export const {
    setCoordinate,
    setUserCoordinate,
    setStoreData,
    setNearestStore,
    setStoreCoordinate,
    setFlag,
    setUserFlag,
    setNearestDistance,
    setLocationPopup,
    setDeny,
    setRouteError,
    setFav,
    setPopUpTime,
    setStoreMarkers,
    setLocationAcess,
    setActiveMarker,
    setMarkerSize,
    setGivexPaymentDetail,
    setSaveCreditCard,
setStoreIndexValue,
setCheckScroll,
} = actions;

export const  coordinate = (state) => state.store.coordinate;
export const  userCoordinate = (state) => state.store.userCoordinate;
export const  storeData = (state) => state.store.storeData;
export const   nearestStore= (state) => state.store.nearestStore;
export const   storeCoordinate= (state) => state.store.storeCoordinate;
export const   flag= (state) => state.store.flag;
export const   userFlag= (state) => state.store.userFlag;
export const   nearestDistance= (state) => state.store.nearestDistance;
export const  locationPopup= (state) => state.store.locationPopup;
export const  deny= (state) => state.store.deny;
export const  routeError = (state)=>state.store.routeError;
export const  fav = (state)=>state.store.fav;
export const  storeMarkers = (state)=>state.store.storeMarkers;
export const  popUpTime = (state)=>state.store.popUpTime;
export const  locationAcess = (state)=>state.store.locationAcess;
export const markerSize= (state)=>state.store.markerSize;
export const activeMarker = (state)=>state.store.activeMarker;
export const givexPaymentDetail = (state)=> state.store.givexPaymentDetail;
export const saveCreditCard = (state)=> state.store.saveCreditCard;
export const storeIndexValue = (state)=> state.store.storeIndexValue;
export const checkScroll = (state)=> state.store.checkScroll;
export default reducer;
