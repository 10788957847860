import React, { useEffect, useState ,useRef } from "react";
import {
  Dialog,
  Box,
  Button,
  DialogTitle,
  TextField,
  FormControl,
  Typography,
  Backdrop
} from "@mui/material";
import Zoom from '@mui/material/Zoom';
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import { resendOtp, verifyOtp, expireOtp, deleteAccount, UpdateUserProfile } from "../../Api/API";
import { useForm, Controller } from "react-hook-form";
import { errorToastMessage } from "../../Common/utils";
import { setIsUserFiveStarData, setLogin, setUserGoogleData, setUser, setIsAlreadyUserChecked } from "../../Redux/UserSlice";
import NewPassword from "./NewPassword";
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailIcon from '@mui/icons-material/Email';
import {CircularProgress} from "@mui/material";
toast.configure();
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: { borderRadius: 15 },
  noBorder: {
    border: "none",
  },
  btnLogin: {
    padding: "0.2rem 5rem",
    backgroundColor: "#B7273B",
    margin: "auto",
    fontFamily: "Lusitana",
    textTransform: "none",
    marginTop: "1rem"
  },
  btnNew: {
    padding: "0.2rem 0.5rem",
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    textDecoration: "none",
    color: "white",
    textTransform: "none",
  },
  error: {
    color: "#bf1650",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom  ref={ref} {...props} timeout={500}/>;
});
const OtpPopUp = (props) => {
  const defaultValues = {
    deviceToken: "",
    deviceType: 0,
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    socialId: "",
    socialType: "",
    userId: "",
    phoneNumber: "",
  };
  const { control, handleSubmit, formState, setValue, reset } = useForm({
    defaultValues,
  });
  const { errors } = formState;
  const navigate = useNavigate();
  const { code , setCode, showPopup, setshowPopup, phoneNo,checkUserEmail, setCheckUserEmail, setShowAd, title,setUserRegistered, setValues, googleLoginData,  showLoginPopup, setShowLoginPopup, setSignUp , birth , setTitle} = props;
  const classes = useStyles();
  const [intervals , setIntervals] = useState();
  const closeAd = () => {
    clearInterval(intervals);
    setBtnDisabled(true)
    setshowPopup(false);
  }
  const userFiveStarData = useSelector((state) => state.user.userFiveStarData);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const Ref = useRef(null);
  const [timer, setTimer] = useState('01:00');
  // const [timer, setTimer] = useState("00:30");
  const [timeUp, setTimeUp] = useState(false);
  const dispatch = useDispatch();
  const [wrongOtp , setWrongOtp] = useState(false);
  const [showNewPass, setshowNewPass] = useState(false);
  const [loading , setLoading] = useState(false);
  const userGoogleData = useSelector((state) => state.user.userGoogleData);
  const updateProfileGoogleLoginData = {
    deviceType: 0,
    dateOfBirth: birth,
    email: googleLoginData?.email,
    firstName: userGoogleData?.firstName,
    lastName: userGoogleData?.lastName,
    phoneNumber: phoneNo,
    userId: googleLoginData?.customer_id,
    socialType: "google"
  };
  let fd = new FormData()
  const onSubmit = (data) => {
    let phoneNumer = props.phoneNo;
    phoneNumer = phoneNumer.replace(/-/g, "");
    setLoading(true);
    verifyOtp(phoneNumer, data)
      .then((res) => {
        setLoading(false)
        if (res.result == "True" || res.result == "true") {
          if (title == "ForgetPassword") {
          setBtnDisabled(true)
          setshowNewPass(true);
          // setshowPopup(false);
          setValue("otp","");
          setValues("phone","")
          setCode("+1")              
          setShowAd(false);
          setUserRegistered(false)
          setLoading(false)
          clearInterval(intervals); }
          else if(title == "Signup"){
            console.log("harshit_rawat_sign?U")
            setBtnDisabled(true)
            setshowPopup(false);
            clearInterval(intervals);
            setLoading(false)
            setSignUp(true);
          }
          else if(title == "GoogleLogin"){
            setLoading(false)
            setBtnDisabled(true)
            setshowPopup(false);
            setShowLoginPopup(false);
            clearInterval(intervals);
            setLoading(true)
              UpdateUserProfile(fd, updateProfileGoogleLoginData)
                .then((res) => {
                  setLoading(false)
                  console.log(res)
                  if (res.responseCode == 1 || res.response_code == 1) {
                    setShowLoginPopup(false);
                    dispatch(setLogin(true));
                    dispatch(setUser(res.result));
                    setLoading(false)
                    
                    toast.success("Login successful", {
                      position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
                    });

                  }
                  else if(res.response_code == 0 )
                  {
                    toast.error(res?.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      pauseOnFocusLoss : false,
                      autoClose : 1500,
                      pauseOnHover : false,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                    });
                    deleteAccount("", googleLoginData?.email);
                    setValue("phoneNo","")
                    setShowLoginPopup(false);
                    setShowAd(true);      
                    setLoading(false)    
                  }
                  else
                  {
                    toast.error(res?.responseMessage, {
                      position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
                    });
                  }
                  setLoading(false)
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false)
                });
            }
           else{
          
          console.log(userFiveStarData ,"login");
          setBtnDisabled(true)
          dispatch(setIsUserFiveStarData(true));
          setshowPopup(false);
          setShowAd(false);
          dispatch(setIsAlreadyUserChecked(true));
          setLoading(false);
          navigate("/signup");
          clearInterval(intervals);

        }
        } else {
          setWrongOtp(true);
          // clearInterval(intervals);
          // setLoop(false);
          setLoading(false)
          setBtnDisabled(true);
          toast.error(res?.msg, {
            position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        errorToastMessage();
      });
  };

  const getTimeRemaining = (e) => {
      const total = Date.parse(e) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      return {
          total, minutes, seconds
      };
  }
  const startTimer = (e) => {
    let { total, minutes, seconds } 
    = getTimeRemaining(e);
      if (total >= 0){
          setTimer(
              (minutes > 9 ? minutes : '0' + minutes) + ':'
              + (seconds > 9 ? seconds : '0' + seconds)
          )
      }
  }
  const clearTimer = (e) => {
    setTimer('01:00');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
          startTimer(e);
      }, 1000)
      Ref.current = id;
  }
  const getDeadTime = () => {
      let deadline = new Date();
      deadline.setSeconds(deadline.getSeconds() + 60);
      return deadline;
  }

  useEffect(() => {
      clearTimer(getDeadTime());
  }, []);

  useEffect(() => {
  if (timer == "00:01"){
    expireOtp(phoneNo);
    setBtnDisabled(false)
  }
}, [timer]);

  const handleResendOtp = () => {
    setTimeUp(false)
    setValue("otp","");
    setWrongOtp(false);
    resendOtp(phoneNo,code=="+91"?"%2B91":code)
      .then((res) => {
        clearTimer(getDeadTime());
        setBtnDisabled(true);
      })
      .catch((err) => {
        console.log(err);
        errorToastMessage();
    });
  }
  return (
    <>
     <Backdrop
        sx={{ color: '#fff', zIndex: 1000}}
        open={loading}
      ></Backdrop>
    <Dialog
      open={showPopup}
      classes={{
        paper: classes.paper,
      }}
      // onKeyDown = {(e)=>  e.key == "Escape" && closeAd() }
      TransitionComponent={Transition}
    > 
   
    {/* {title == "Signup" ? " " : */}
     <Box textAlign="right" sx={{cursor:"pointer"}}>
          <CancelIcon
            fontSize="large"
            onClick={() => {
              clearInterval(intervals);
              setBtnDisabled(true)
              setshowPopup(false);
              setTitle("")
              // setValues("phone","")
              // setUserRegistered(false)
            }}
          />
        </Box>
        {/* } */}
      <Box p={3}>
        <DialogTitle textAlign="center">
          <img src="./NewLogoG.png" alt="" width="70%" />
        </DialogTitle>
        <Typography  style={{textAlign:"center",fontWeight:"600" ,  marginBottom:"0.5rem"}}>Please enter the code sent to</Typography>
        <Box sx={{display:"flex",marginBottom:"0.5rem",
        // marginLeft:{lg:"3rem",md:"3rem",sm:"3rem",xs:"0"},
        //  justifyContent:{lg:"left",md:"left",sm:"left",xs:"center"}
        justifyContent : "center"
         }}>
        <SmartphoneIcon 
        style={{
          // marginLeft:"0.5rem"
          marginRight : "0.3rem"
        }}
        />
        <Typography> (***) *** {phoneNo.slice(6,10)}</Typography>
        </Box>
        <Box sx={{display:"flex",marginBottom:"0.8rem",
        // marginLeft:{lg:"3rem",md:"3rem",sm:"3rem",xs:"0"},
        //  justifyContent:{lg:"left",md:"left",sm:"left",xs:"center"}
        justifyContent : "center"
         }}>
        {checkUserEmail && 
        <>
        <EmailIcon style={{
          // marginLeft:"0.5rem"
          marginRight:"0.3rem"
          }}/>
        <Typography  style={{textAlign:"center",fontWeight:"600",fontSize:".8rem"}}>{checkUserEmail}</Typography>
        </>}
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Box dir="ltr">
            <FormControl fullWidth>
              <Controller
                name="otp"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="otp"
                    disabled={timeUp}
                    type="text"
                    placeholder="Enter code"
                    InputProps={{
                      classes: { notchedOutline: classes.noBorder },
                      autocomplete: "new-password",
                    }}
                    inputProps={{ maxLength: 6 , inputMode: 'numeric'}}
                    onInput={(e) => {
                            if (e.target.value.length != 0) {
                              e.target.value = e.target.value.replace(/[^0-9]/g, "")
                            }
                          }}
                    sx={{ backgroundColor: "#E7E7E7" }}
                    fullWidth
                    size="small"
                    variant="outlined"
                    autoFocus
                  />
                )}
                control={control}
              />
            </FormControl>
          </Box>
          <Box display={"flex"} justifyContent="space-between" alignItems="center">
            {btnDisabled?<Box sx ={{fontSize  : "0.9rem"}}>
              {`Expires in ${timer}`}
              </Box>:""}
            <Button
              disabled={btnDisabled}
              onClick={handleResendOtp}
            >
              Resend Code</Button>
          </Box>
          <Box textAlign="center">
            <Button
              type="submit"
              className={classes.btnLogin}
              variant="contained"
              disabled={!btnDisabled}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
    {showNewPass && <NewPassword showNewPass={showNewPass} setshowNewPass={setshowNewPass} setshowPopup={setshowPopup} setTitle = {setTitle}></NewPassword>}
    </>
  );
};
export default OtpPopUp;
