import "./StoreLocation.css";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  setNearestStore,
  setStoreData,
  setStoreCoordinate,
  setDeny,
  setRouteError,
  setStoreMarkers,
  setActiveMarker,
  setMarkerSize,
  setStoreIndexValue,
} from "../../Redux/StoreSlice";
import {
  FormControl,
  InputAdornment,
  Popover,
  Slide,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { GoogleMap, InfoWindow } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import { TextField } from "@mui/material";
import { Container } from "@mui/material";
import { Divider } from "@mui/material";
import StoreDistance from "./Sidebar/StoreDistance";
import {
  getStoreByCustomerLocation,
  getStoreStartendTime,
} from "../../Api/API";
import { setCoordinate, setUserCoordinate, setCheckScroll } from "../../Redux/StoreSlice";
import { detectMobileDevice } from "../utils";
import { ForkRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import ClearIcon from '@mui/icons-material/Clear';
import { setCart, setUpdateCart } from "../../Redux/CartSlice";
import { handleStoreStartEndTime } from "../utils";
import AlertPopup from "../AlertPopup";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
}));
const MapContainer = ({ setState, currentLocation, setCurrentLocation, setStoreLoading, storeLoading }) => {
  const dispatch = useDispatch();
  let locationn = useLocation();
  let navigate = useNavigate();
  const classes = useStyles();
  const abc = useRef(null);
  const textRef = useRef();
  const deny = useSelector((state) => state.store.deny);
  const storeCoordinate = useSelector((state) => state.store.storeCoordinate);
  const coordinate = useSelector((state) => state.store.coordinate);
  const userCoordinate = useSelector((state) => state.store.userCoordinate);
  const [address, setAddress] = React.useState("");
  const [removeAddress, setRemoveAddress] = React.useState(false);
  const [coordinates, setCoordinates] = React.useState();
  const [userCoordenates, setUserCoordinates] = useState({
    lat: Number("40.7128"),
    lng: Number("-74.0060"),
  });
  const [userAddress, setUserAddress] = useState();
  const [select, setSelect] = useState(false);
  const [current, setCurrent] = useState(false);
  // const [ routeError , setRouteError] = useState();
  const [userAutoAddress, setUserAutoAddress] = useState();
  const routeError = useSelector((state) => state.store.routeError);
  // const [activeMarker, setActiveMarker] = useState(null);
  const markers = useSelector((state) => state.store.storeMarkers);
  const [geolocationError, setGeoLocationError] = useState("");
  const nearestStoreData = useSelector((state) => state.store.nearestStore);
  const [popOver, setPopOver] = useState(false);
  const storeData = useSelector((state) => state.store.storeData);
  const markerSize = useSelector((state) => state.store.markerSize);
  const flag = useSelector((state) => state.store.flag);
  const activeMarker = useSelector((state) => state.store.activeMarker);
  const [storeClose, setStoreClose] = useState(false);
  const [mapref, setMapRef] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState({});
  const [areaClickCords, setAreaClickCords] = useState();
  const [activeMarkerName, setActiveMarkerName] = useState(nearestStoreData.store_name);
  const [activeMarkerId, setActiveMarkerId] = useState(nearestStoreData.id);
  const [markerList, setMarkerList] = useState(false)
  const storeIndexValue = useSelector((state) => state.store.storeIndexValue)
  const userFlag = useSelector((state) => state.store.userFlag);
  const checkScroll = useSelector((state) => state.store.checkScroll);
  const cartItem = useSelector((state) => state.cart.cart);
  const handleActiveMarker = (id, name) => {
    // window.scrollBy(100, 100);
    let selectedStore = storeData.filter((e) => e.id == id)[0]
    setSelectedMarker(selectedStore)

    // dispatch(setMarkerSize({ width: 55, height: 81 }));
    setActiveMarkerId(id);
    setActiveMarkerName(name)
    dispatch(setActiveMarker(id))
    // let selectStore = storeData.filter((item) => item.store_name == name);
    let markedStoreIndex = storeData.findIndex(
      (item) => item.store_name == name
    );
    abc?.current?.childNodes[markedStoreIndex]?.scrollIntoView({ behavior: "smooth" })
    // let newListOfStore = [...storeData];
    // let Store = newListOfStore?.splice(markedStoreIndex, 1);
    // newListOfStore?.sort((a, b) => Number(a.distance) - Number(b.distance));
    // newListOfStore = [...Store, ...newListOfStore];
    // dispatch(setStoreData(newListOfStore));

    // getStoreStartendTime(selectStore[0]?.id).then((res) => {
    //   let storeStatus = handleStoreStartEndTime(res,selectStore[0]?.time_zone);
    //  let timeRange = storeStatus?.timeRange;
    //   if (timeRange) {
    //     // dispatch(setNearestStore(selectStore[0]));
    //     // setState(false);
    //   } else {
    //     console.log("please select another store");
    //     setStoreClose(true);
    //   }
    // });
  };
  useEffect(() => {
    if (checkScroll == true) {
      setTimeout(() => {
        let activeStoreIndex = storeData?.findIndex(
          (item) => item.store_name == nearestStoreData?.store_name
        );
        abc?.current?.childNodes[activeStoreIndex]?.scrollIntoView({ behavior: "smooth" })
        dispatch(setCheckScroll(false))
      }, 1000);
    }
  }, [checkScroll])

  let storeCoordinates = [];
  const mapStyles = {
    // maxWidth: "50vw",
    height: "50vh",
    zIndex: "1",
    // margin: "1vh 0vh",
    borderRadius: "1rem",
    // Float: "right",
    //  position: "fixed",
    // top: 0
  };
  const handleUserInputs = () => {
    dispatch(setDeny(false));
    location(userCoordenates.lat, userCoordenates.lng);
  };

  const handleClose = () => {
    setPopOver(false);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(locationn.search);
    let qlat = searchParams.get("latitude")
    let qlng = searchParams.get("longitude")
    let pathname = {
      coords: {
        latitude: qlat ? Number(qlat) : null,
        longitude: qlng ? Number(qlng) : null
      }
    }
    if (pathname.coords.latitude && pathname.coords.longitude && flag === true) {
      success(pathname)
    }
    else {
      if (currentLocation) {
        navigator.geolocation.getCurrentPosition(success, error);
        setCurrentLocation(false);
      }
    }
  }, [currentLocation]);

  const remove = () => {
    setAddress("")
    setRemoveAddress(false)
  };

  const curLocation = () => {
    navigator.geolocation.getCurrentPosition(success, error);
    dispatch(setActiveMarker(""))
    setCurrent(true);
    setRemoveAddress(true)
    if (markerList == false) {
      setMarkerList(true)
    } else {
      setMarkerList(false)
    }
    // abc.current.childNodes[0].scrollIntoView({ behavior: "smooth" })
  };

  const error = (err) => {
    setRemoveAddress(false)
    setGeoLocationError(
      "You'll need to enable location sharing to use this feature."
    );
    setPopOver(true);
  };
  const success = (position) => {
    const coordinates = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    dispatch(setUserCoordinate(coordinates));
    // coordinates of user \\
    /* eslint-disable no-undef */

    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ latLng: coordinates }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        var address = results[0].formatted_address;
        if (deny) {
          setUserAutoAddress(address);
          setUserAddress(address);
          setUserCoordinates(coordinates);
          setSelect(true);
        } else {
          dispatch(setStoreCoordinate(coordinates));
          dispatch(setStoreMarkers([]));
          setAddress(address);
          setCoordinates(coordinates);
          // location()
        }
      }
    });
    dispatch(setCoordinate(coordinates));
  };

  const handleSelect = async (value) => {
    textRef.current.blur()
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    dispatch(setCoordinate(latLng));
    setCoordinates(latLng);
  };
  const buttonClick = async (value) => {
    textRef.current.blur()
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    dispatch(setCoordinate(latLng));
    setCoordinates(latLng);
  };
  useEffect(() => {
    if (coordinates == userCoordinate && current == true) {
      setTimeout(() => {
        location(coordinates?.lat, coordinates?.lng);
      }, 400)

    }
  }, [markerList]);

  useEffect(() => {

    if (coordinates) {
      // setStoreLoading(true)
      setTimeout(() => {
        location(coordinates?.lat, coordinates?.lng);
      }, 400)

    }
  }, [coordinates?.lat, coordinates?.lng]);



  const handleUserLocation = async (value) => {
    setSelect(true);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setUserAddress(value);
    let coordinates = {
      lat: latLng.lat,
      lng: latLng.lng,
    };
    dispatch(setCoordinate(coordinates));
    // dispatch(setUserCoordinate(coordinates));
    setUserCoordinates(coordinates);
  };

  const location = (lat, lng, text) => {
    setStoreLoading(true)
    const fetchStoreData = async () => {  
   await getStoreByCustomerLocation(lat, lng)
      .then((res) => {
        res?.result.map((item, index) => {
          storeCoordinates.push({
            lat: item?.latitude,
            lng: item?.longitude,
            item: item,
          });
        });

        for (var i = 0; i <= storeCoordinates.length; i++) {
          if (i < storeCoordinates.length) {
            m_get_directions_route(storeCoordinates[i], i, text);
          }
        }
      })
      .catch((e) => console.log(e));
    }
    fetchStoreData()
  };

  var delayFactor = 0;
  let storeDetail = [];
  let marker = [];
  let indexArray = [];
  function m_get_directions_route(storeCoordinate, index, text) {
    // console.log(storeCoordinate.item,"ghhggh",index)
    // const directionsService = new google.maps.DirectionsService();
    // directionsService.route(request, function (result, status) {
    // console.log(result,"result")
    // if (status === google.maps.DirectionsStatus.OK) {
    // console.log(result, "distance check", item);
    // let dis = result?.routes[0]?.legs[0]?.distance?.text
    //   .split(" ")[0]
    //   .split(",");
    let time = Math.round(storeCoordinate.item.distance / 0.5);
    var Hours = Math.floor(time / 60);
    var minutes = time % 60;
    var duration = Hours > 0 ? `${Hours} hour ${minutes}` : minutes
    storeCoordinate.item = {
      ...storeCoordinate.item,
      ["distance"]: storeCoordinate.item?.distance?.toFixed(2).toString(),
      // ["duration"]: result?.routes[0]?.legs[0]?.duration?.text,
      ["duration"]: duration,
    };
    let detail = [];
    if (storeCoordinate.item.store_name && storeCoordinate.item.id) {
      detail.push(storeCoordinate.item);
    }
    let markerArray = [];
    let image = "./red_dot.png"
    if (storeCoordinate.item.store_name && storeCoordinate.item.id) {
      markerArray.push({
        id: storeCoordinate?.item?.id,
        name: storeCoordinate?.item?.store_name,
        position: {
          lat: Number(storeCoordinate.lat),
          lng: Number(storeCoordinate.lng),
        },
        icon: image,
      });
    }

    indexArray.push(index)
    storeDetail = [...storeDetail, ...detail];
    marker = [...marker, ...markerArray];
    storeDetail?.sort((a, b) => Number(a.distance) - Number(b.distance));

    abc?.current?.childNodes[0]?.scrollIntoView({ behavior: "smooth" })
    if (storeDetail.length > 0) {
      setStoreLoading(false)
      //  console.log(storeDetail[0]?.id,"store id")
      if (storeCoordinates.length == indexArray.length) {
        getStoreStartendTime(storeDetail[0]?.id).then((res) => {
          let storeStatus = handleStoreStartEndTime(res, storeDetail[0]?.time_zone);
          let timeRange = storeStatus?.timeRange;
          if (storeStatus?.status[storeStatus?.timeZoneWeekDay].storeDayStatus == false &&
            storeStatus?.status[storeStatus?.timeZoneWeekDay]?.onLineStatus == "on" &&
            timeRange == true && !text) {
            // if(storeCoordinates.length==indexArray.length){
            if (cartItem.length == 0) {
              dispatch(setNearestStore(storeDetail[0]));
              dispatch(setUpdateCart([]));
            }

            setActiveMarkerId(storeDetail[0].id);
            setActiveMarkerName(storeDetail[0].store_name)
            dispatch(setActiveMarker(storeDetail[0].id))

            // }

          }
          if (Math.max(...indexArray) == (storeDetail.length || storeDetail.length - 1)) {
            dispatch(setStoreIndexValue(Math.max(...indexArray)));
            setTimeout(() => {
              setStoreLoading(false)
            }, 2000);
          }
        });
      }
      // dispatch(setNearestStore([]));
      dispatch(setStoreMarkers(marker));
      dispatch(setStoreData(storeDetail));
      dispatch(setRouteError(""));


      if (storeCoordinates.length == indexArray.length) {
        dispatch(setActiveMarker(storeDetail[0].id))
        dispatch(
          setStoreCoordinate({
            lat: Number(storeDetail[0].latitude),
            lng: Number(storeDetail[0].longitude),
          })
        );
      }
      if (locationn.pathname == "/customization") {
        navigate(`/`);
      }


    }
  }
  const onError = (status, clearSuggestions) => {
    clearSuggestions();
  };
  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    let coordinates = {
      lat: lat,
      lng: lng,
    };



    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ latLng: coordinates }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        var address = results[0].formatted_address;
        if (deny) {
          setUserAddress(address);
          dispatch(setCoordinate(coordinates));
          setUserCoordinates(coordinates);
          setSelect(true);
          dispatch(
            setRouteError(
              "Please Select Location to get nearest Gongcha store "
            )
          );
        } else {
          setAddress(address);
          location(lat, lng);
        }
      }
    });
  };

  const handleMarkerState = (id) => {
    dispatch(setMarkerSize({ width: 55, height: 81 }));
    dispatch(setActiveMarker(id));
  };
  const handleMarkerStateSec = (name) => {
    dispatch(setMarkerSize({ width: 35, height: 52 }));
    dispatch(setActiveMarker(name));
  };

  let detectDevice = detectMobileDevice();

  const handleOnLoad = map => {
    setMapRef(map);
  };

  const handleAreaClick = () => {
    location(areaClickCords.lat, areaClickCords.lng, "search")
  }

  const handleCenterChanged = () => {
    if (mapref) {
      const newCenter = mapref.getCenter();
      let Areacoordinates = {
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      };
      setAreaClickCords(Areacoordinates)
      dispatch(setCoordinate(Areacoordinates));
      // dispatch(setStoreCoordinate(Areacoordinates))
    }
  };
  useEffect(() => {
    if (mapref) {
      if (userCoordinate.lat != null && userCoordinate.lng != null && !storeLoading) {
        var latlngbounds = new google.maps.LatLngBounds();
        let bounds = [userCoordinate, storeCoordinate]
        for (var i = 0; i < bounds.length; i++) {
          var data = bounds[i]
          var myLatlng = new google.maps.LatLng(data.lat, data.lng);
          var markerr = new google.maps.Marker({
            position: myLatlng,
          });
          latlngbounds.extend(markerr.position);
        }
        mapref.fitBounds(latlngbounds);

      }
    }
  }, [storeCoordinate])

  let myLatLng = userCoordinate;
  const userImage = "./userImage.png"

  return (
    <>
      {/* {deny? <div style={{textAlign:"center"}}>Locate Your Self</div>: */}
      <PlacesAutocomplete
        value={!deny ? address : userAddress}
        onChange={!deny ? setAddress : setUserAddress}
        // ontouchstart = {() =>detectDevice ? !deny ? handleSelect : handleUserLocation  : ""}
        onSelect={!deny ? handleSelect : handleUserLocation}
        onError={onError}
        onKeyPress={(e) => {

          e.key == "Enter" && e.preventDefault();
          // e.target.blur();
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <FormControl fullWidth>
            <TextField
              // label={"Find a Store"}
              inputRef={textRef}
              variant="outlined"
              size="small"
              sx={{
                pt: 0.5, backgroundColor: "#E7E7E7", borderRadius: "0.5rem",
                boxShadow: "0 6px 20px 0 #dbdbe8",
              }}
              // focused={false}
              InputLabelProps={{
                shrink: true,
              }}
              {...getInputProps({
                placeholder: "Enter City/State/Zip",
              })}
              InputProps={{
                inputMode: "none",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      fontSize="small"
                      style={{ fill: "#C60732" }}
                    />
                  </InputAdornment>),
                endAdornment: (
                  <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                    {removeAddress && <ClearIcon onClick={() => remove()} style={{ fill: "black", marginRight: "0.5rem" }} />}
                    <MyLocationIcon onClick={() => curLocation()} style={{ fill: "#C60732" }} />
                  </InputAdornment>
                ),
                classes: { notchedOutline: classes.noBorder },
              }}
              onKeyDown={(e) => {
                e.key == "Enter"
                  && buttonClick(suggestions[0].description);
              }}
              onInput={(e) => {
                if (e.target.value.length >= 1) {
                  setRemoveAddress(true)
                }if(e.target.value == ""){
                  setRemoveAddress(false)
                }
              }}
            />
            <Box
              mt={1}
              className="autocomplete-dropdown-container"
              key={suggestions.description}
              style={{ minWidth: "100%" }}
            >
              {loading && (
                <Card
                  sx={{
                    padding: "1rem",
                    textAlign: "center",
                    variant: "outlined",
                    fontWeight: "600",
                  }}
                >
                  Loading...
                </Card>
              )}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <Card
                    variant="outlined"
                    sx={{ height: "100%", padding: "1rem" }}
                    {...getSuggestionItemProps(suggestion, {
                      className: "input-suggestion",
                      style,
                    })}
                  >
                    <LocationOnIcon style={{ color: "brown" }} />
                    <div>{suggestion.description}</div>
                  </Card>
                );
              })}
            </Box>
          </FormControl>
        )}
      </PlacesAutocomplete>
      {/* } */}
      <div style={{
        border: "1px solid #C60734", marginTop: "2vh",
        borderRadius: "1rem",
      }}>
        <GoogleMap
          apiKey={"AIzaSyBVNeXhVJbD9mHKqwMRIWZuwR8tBhthIus"}
          mapContainerStyle={mapStyles}
          zoom={12}
          options={{
            clickableIcons: false,
            // zoomControl: true,
            // streetViewControl: true,
            gestureHandling: "greedy",
            // mapTypeControl: true,
            // fullscreenControl: true,
          }}
          // onBoundsChanged={handleBoundChanged}
          center={!deny ? storeCoordinate : userCoordenates}
          onLoad={handleOnLoad}
          onCenterChanged={handleCenterChanged}

        >
          {markers?.length == 0 ? (
            <Marker
              position={!deny ? storeCoordinate : userCoordenates}
              onDragEnd={(e) => onMarkerDragEnd(e)}
              defaultDraggable={true}
              draggable={true}
            />
          ) : (
            markers?.map(({ id, name, position, icon }) => (

              <Marker
                key={id}
                position={position}
                clickable={true}
                animation={2}
                onClick={(e) => handleActiveMarker(id, name)}
                onMouseOver={() =>
                  detectMobileDevice() ? "" : handleMarkerState(id)
                }
                onMouseOut={() =>
                  detectMobileDevice() ? "" : handleMarkerStateSec(name)
                }
                // ontouchstart={() =>
                //   detectMobileDevice() ? handleMarkerState(id) : ""
                // }
                // ontouchend={() => (detectDevice ? handleMarkerStateSec() : "")}
                icon={{
                  url: (nearestStoreData.id == id || activeMarker == id) ? "./pin1.png" : icon,
                  fillOpacity: 1.0,
                  strokeWeight: 0,
                  scaledSize: {
                    width: activeMarker == id ? markerSize.width : (nearestStoreData.id == id ? 35 : 18),
                    height: activeMarker == id ? (markerSize.height) : (nearestStoreData.id == id ? 52 : 18),
                  }
                }}
              >
                {(activeMarker == id) && position ? (
                  <InfoWindow position={position}>
                    <div>{name}</div>
                  </InfoWindow>
                ) : null}
              </Marker>

            ))
          )}
          <Marker position={myLatLng}
            icon={{
              url: userImage,
              fillOpacity: 1.0,
              strokeWeight: 0,
              scaledSize: {
                width: 19,
                height: 19,
              },
            }}
          />
        </GoogleMap>
        {!deny ?
          <Typography textAlign="center" color="#C60734" style={{ cursor: "pointer", margin: "0.5rem 0rem" }}
            onClick={handleAreaClick}
          >Search this area</Typography> : " "}
      </div>
      <Divider style={{ margin: "0.5rem" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // height: "15rem",
        }}
      >
        {deny && (
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={handleUserInputs}
              sx={{ width: "100%" }}
              disabled={select ? false : true}
            >
              Confirm Location
            </Button>
          </div>
        )}
        {!deny && (
          <Container sx={{ pt: 1, overflow: "auto", height: "31vh" }}>
            {/* <div style={{ textAlign: "center",}}>Stores near your Location</div> */}
            {/* {storeIndexValue == (storeData.length || storeData.length - 1 ) ? */}
            {storeData.length > 0 ?
              <div ref={abc}>
                <StoreDistance setState={setState} setStoreLoading={setStoreLoading} indexArray={storeIndexValue}
                  activeMarkerName={activeMarkerName} activeMarkerId={activeMarkerId}
                  setActiveMarkerName={setActiveMarkerName} setActiveMarkerId={setActiveMarkerId}
                  selectedMarker={selectedMarker}
                />
              </div>
              :
              (
                // storeNotAvailable &&
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >{storeData.length == 0 ?
                    "Please search by City/State/Zip to find the closest location to you" : ""}
                  </div>
                  <Divider />
                  {/* {storeNotAvailable && <div>Please select another location</div>} */}
                </div>
              )
            }
          </Container>
        )}
        {deny && (
          <div>
            {/* <Container fullWidth> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px dotted black",
                height: "7rem",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <MyLocationIcon
                fontSize="large"
                onClick={() => setCurrentLocation(true)}
              />
              <div style={{ padding: "1rem" }}>
                Tap the target to find the closest Gong cha store
              </div>
            </div>
            {/* </Container> */}
          </div>
        )}
      </div>
      <Popover
        open={popOver}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            p: 3,
            width: "100vw",
            textAlign: "center",
            backgroundColor: "#786259",
            color: "white",
          }}
        >
          {geolocationError}
        </Typography>
      </Popover>
      <AlertPopup
        open={storeClose}
        setOpen={setStoreClose}
        title={"selectStore"}
        displayTitle={
          "This store is currently closed.  Please select another store"
        }
        buttonType1={true}
      />
    </>
  );
};
export default MapContainer;
