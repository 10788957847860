import React, { useEffect, useState } from "react";
import { Box, Container, Divider, Tab, Tabs } from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import Feature from "../../../pages/Featured/Feature";
import CategoryTabBar from "./CategoryTabBar";
import { getAdvertismentDetail, GetAllCategories } from "../../../Api/API";
import BestSeller from "../../../pages/BestSeller/BestSeller";
import PreviousOrder from "../../../pages/PreviousOrder/PreviousOrder";
import { useDispatch, useSelector } from "react-redux";
import AdvertismentPopup from "./AdvertismentPopup";
import Favorites from "../../../pages/Favorites/Favorites";
import { setCategoriesSet, setTabValue } from "../../../Redux/CartSlice";
import { setPopUpTimmer } from "../../../Redux/UserSlice";
import { setPopUpTime } from "../../../Redux/StoreSlice";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const useStyle = makeStyles((theme) => ({
  root: {
    padding: "0",
  },
}));

const TabBar = ({ setState }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate =useNavigate();
  const [value, setValue] = React.useState();
  const [valueN, setValueN] = React.useState(2);
  const [Categories, setCategories] = useState([]);
  const [showAdvertismentPopup, setShowAdvertismentPopup] = useState(false);
  const [advertismentData, setAdvertismentData] = useState();
  const isLogin = useSelector((state) => state.user.isLogin);
  const tabValue = useSelector((state) => state.cart.tabValue);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const storeName = useSelector((state) => state.store.nearestStore);
  const [loading, setLoading] = useState(true);
  // const [popUpTimmer , setPopUpTimmer] = useState(true)
  const popUpTimmer = useSelector((state) => state.user.popUpTimmer);
  const popUpTime = useSelector((state) => state.store.popUpTime);

  const handleChange = (event, newValue) => {
    if(location.pathname!="/"){
      dispatch(setTabValue(newValue));
      navigate("/")
    }
    setValue(newValue);
    // console.log(newValue , tabValue , "kkkk")
    // dispatch(setTabValue(newValue));
    // console.log(newValue , tabValue , "kkkk")
    // console.log(newValue,tabValue,"kk")
    // setValue(tabValue);
  };
  useEffect(() => {
    dispatch(setTabValue(value));
    // console.log(value, tabValue, "kkkk");
  }, [value]);

  useEffect(() => {
    let data = {
      storeId: storeName?.id ? storeName?.id : "",
      appType: "web",
    };
    setLoading(true);
    GetAllCategories(data)
      .then((res) => {
        setLoading(false);
        dispatch(setCategoriesSet(res.result));
        setCategories(res.result);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [storeName, tabValue]);

  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <Container maxWidth="xl" sx={{ padding: "0" }} className={classes.root}>
          <Box
            sx={{
              margin: {
                xs: "0 6vw 0 1vw",
                sm: "0 0 0 2.5vw",
                md: "0 0 0 10vw",
                lg: "0 0 0 10vw",
              },
            }}
          >
            <Tabs
              allowScrollButtonsMobile
              value={tabValue}
              onChange={handleChange}
              textColor="none"
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#C30E2F" } }}
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3, flexShrink: 0.5 },
                  opacity: 0.8,
                  flexShrink: 0.5,
                },
              }}
            >
              <Tab
                label="Categories"
                className="text-capitalize"
                value={2}
                style={{ fontFamily: "Lusitana", fontSize: 17 }}
                sx={{
                  padding: "0.2rem",
                  minWidth: "0",
                  margin: "0rem 1rem 0rem 0rem",
                  paddingLeft:{xs:"11px"}
                }}
              />
              <Tab
                label="Best Sellers"
                className="text-capitalize"
                value={1}
                style={{ fontFamily: "Lusitana", fontSize: 17 }}
              />

              <Tab
                label="Featured"
                className="text-capitalize"
                value={3}
                style={{ fontFamily: "Lusitana", fontSize: 17 }}
              />
              {isLogin && (
                <Tab
                  label="Re-Order"
                  className="text-capitalize"
                  value={4}
                  style={{ fontFamily: "Lusitana", fontSize: 17 }}
                  sx={{
                    padding: "0.2rem",
                    minWidth: "0",
                    margin: "0rem 1rem 0rem 0rem",
                  }}
                />
              )}
              {isGuestLogin ? (
                " "
              ) : (
                <Tab
                  label="Favorites"
                  className="text-capitalize"
                  value={5}
                  style={{ fontFamily: "Lusitana", fontSize: 17 }}
                  sx={{
                    padding: "0.2rem",
                    minWidth: "0",
                    margin: "0rem 1rem 0rem 0rem",
                  }}
                />
              )}
            </Tabs>
          </Box>
        </Container>
        <Divider />
      </div>
      {tabValue === 1 && <BestSeller setState={setState} />}
      {tabValue === 2 && (
        <>
          <CategoryTabBar
            Categories={Categories}
            setLoading={setLoading}
            loading={loading}
          />
        </>
      )}

      {tabValue === 5 && <Favorites setState={setState}/>}
      {tabValue === 3 && <Feature setValue={setValue} setState={setState} />}
      {tabValue === 4 && <PreviousOrder  setState={setState} />}

      {/* {showAdvertismentPopup &&  value === 2 && <AdvertismentPopup  showAdvertismentPopup={showAdvertismentPopup}  setShowAdvertismentPopup={setShowAdvertismentPopup} advertismentData={advertismentData}></AdvertismentPopup>} */}
    </>
  );
};
export default TabBar;
