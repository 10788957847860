import React from 'react';
import { useState } from 'react';
import Card from '../../Common/Card';
import Loader from '../../Common/Loader';
import { Backdrop, Box, CardHeader, Divider, Typography } from '@mui/material';
import {CircularProgress} from "@mui/material";
import { GetAllMenu } from '../../Api/API';
import { useSelector } from "react-redux";
import { useEffect } from 'react';
import { Grid }from '@mui/material';
import {Card as MyCard} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {  useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Container } from '@mui/system';
import { setTabValue } from '../../Redux/CartSlice';
const MenuScreen = ({setState }) => {
  const dispatch =useDispatch()
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(false)
  const store = useSelector((state) => state.store.nearestStore);
  const CategoriesSet = useSelector((state) => state.cart.CategoriesSet);
  const valueSet = useSelector((state) => state.cart.valueSet);
  let navigate = useNavigate();
  const routeChange = () => {
    dispatch(setTabValue(2))
    let path = `/`;
    navigate(path);
 
  };
  const handleLeftArrow=()=>{
    console.log("object")
    dispatch(setTabValue(2))
    let path = `/`;
    navigate(path);
  }

 
  useEffect (()=>{
    window.addEventListener('popstate', handleLeftArrow);
    return () => {
      window.removeEventListener('popstate', handleLeftArrow);
    };
  },[])

  useEffect(() => {
    if(CategoriesSet?.length>0){
      let data = {
        requestDate: "",
        categoryId: CategoriesSet[valueSet]?.category_id,
        storeId: store?.id ? store?.id : 1,
        appType: "web",
        customerId: 0,
      };
      setLoading(true);
      return GetAllMenu(data)
        .then((res) => {
          setLoading(false);
          setMenu(res.result);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
      }
      dispatch(setTabValue(0));
    }, [CategoriesSet,store , valueSet]);


  return (
    <>
    <Backdrop
    sx={{ color: '#fff', zIndex: 1000}}
    open={loading}
  // onClick={handleClose}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
    <Box
      // className="row"
      sx={{
        paddingLeft: {lg:"13vw", xs:"5vw"},
        paddingRight: {lg:"13vw", xs:"5vw"},
        paddingBottom: {lg:"3vh", xs:"69px"},
        paddingTop:"5vh",
        // margin :"auto"
      }}
    >
       {/* {(menu == null || menu?.length == 0) ? 
          <div style={{fontSize:"2rem", textAlign:"center",marginTop:"4rem", color:"#868686"}}>Currently this category is not available, feel free to select another menus.</div>: ""} */}
         
         <div style={{ display:"flex", padding:"1rem 0rem", cursor: "pointer" }} onClick={routeChange} >
          <ArrowBackIosIcon  style={{ fill: "#C60734"}} />
          <Typography sx={{ color:"#C60734"}}  variant='h5'>{CategoriesSet[valueSet]?.category_name}</Typography>
         </div>
          <Grid container spacing={3}>
      {menu?.map((e) => {
        return (
           <Grid item  xs={12} sm={6} md={6} lg={6}>
            <Card
              key={e?.id}
              props={e}
              fav={false}
              categoryId ={CategoriesSet[valueSet]?.category_id}
              categoryName ={CategoriesSet[valueSet]?.category_name}
              setLoading={setLoading}
              setState={setState}
            />
          </Grid>
        );
      })}
      </Grid>
     
    </Box>
    </>
  );
};
export default MenuScreen;