import React, { useEffect } from "react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { cardPointePayment, creditCardRefundPayment } from "../../Api/API";
import creditCardType, {
  getTypeInfo,
  types as CardType,
} from "credit-card-type";
import {
  Card,
  Container,
  Typography,
  InputAdornment,
  Box,
  Button,
  TextField,
  FormControl,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

import {
  validateCardExpiry,
  validateCreditCardNumber,
  validateNumber,
  validateZipCode,
} from "../../Common/FormVailidation";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  btnFinalPay: {
    padding: { xs: "0rem", lg: "0.3rem 6rem" },
    fontSize: "1rem",
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    borderRadius: "3rem",
    textTransform: "none",
  },
  cancelButton: {
    position: "absolute",
    left: "100%",
    cursor: "pointer",
    "&:hover": {
      transform: "scale3d(1.1, 1.1, 1)",
    },
  },
  captchaField: {
    width: "100%",
  },
  captchaError: {
    color: "#bf1650",
    textAlign: "center",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
    width: { md: "90%", sm: "80%", xs: "70%" },
  },
  btn: {
    borderRadius: "0.4rem",
    boxShadow: "0 6px 20px 0 #dbdbe8",
    backgroundColor: "#fff",
    transition: "0.4s",
    padding: "0.7rem 3rem ",
    textTransform: "none",
  },
  spacing: {
    marginBottom: "0rem",
  },
  root: {
    "& .Mui-disabled": {
      background: "gray",
      color: "white",
    },
  },
  noBorder: {
    border: "none",
  },
  error: {
    color: "#bf1650",
    textAlign: "left",
    display: "flex",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));

const PosCardEntry = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  let search = useLocation().search;
  const amount = new URLSearchParams(search).get("amount");
  const deviceType = new URLSearchParams(search).get("deviceType");
  const customerPhone = new URLSearchParams(search).get("customerPhone");
  const customerName = new URLSearchParams(search).get("customerName");
  const storeId = new URLSearchParams(search).get("storeId");
  const [cardNumber, setCardNumber] = React.useState("");
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const [validCardNumber, setValidCardNumber] = useState();
  const [CardTypeNew, setCardTypeNew] = useState();
  const [cardTypeImage, setCardTypeImage] = React.useState("./creditcard.png");
  const [accountNumber, setAccountNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isFormComplete, setIsFormComplete] = useState(false);

  let year = [];
  let date = new Date();
  let thisYear = date.getFullYear();
  for (let i = 0; i <= 20; i++) {
    year.push(thisYear);
    thisYear = thisYear + 1;
  }
  const defaultValues = {
    zipCode: "",
    accountNumber: "",
    cvv: "",
    cardExpiry: "",
  };
  const { control, handleSubmit, formState, setValue, trigger, getValues } =
    useForm({
      defaultValues,
    });
  const { errors } = formState;

  const handleCardNumber = (e) => {
    e.preventDefault();
    const value = e?.target?.value;
    setCardNumber(value?.split(" ")?.join(""));
    let suggestion;
    if (value.length > 0) {
      suggestion = creditCardType(e?.target?.value)[0];
      if (suggestion?.type === "american-express") {
        suggestion.type = "amex";
      } else if (suggestion?.type === "diners-club") {
        suggestion.type = "dinersclub";
      }
      setCardTypeNew(suggestion ? suggestion?.type : "unknown");
      setValidCardNumber({
        length: suggestion ? suggestion?.lengths : [12],
        cvv: suggestion ? suggestion?.code?.size : 3,
      });
    }
    const cardType = suggestion ? suggestion?.type : "unknown";
    console.log(value, suggestion);
    let imageUrl;
    switch (cardType?.toLowerCase()) {
      case "visa":
        imageUrl = "./visa.png";
        break;
      case "mastercard":
        imageUrl = "./mastercard1.png";
        break;
      case "amex":
        imageUrl = "./Amex.png";
        break;
      case "american-express":
        imageUrl = "./Amex.png";
        break;
      case "maestro":
        imageUrl = "./maestro.png";
        break;
      case "dinersclub":
        imageUrl = "./dinersclub.png";
        break;
      case "discover":
        imageUrl = "./discover.png";
        break;
      case "jcb":
        imageUrl = "./jcb1.png";
        break;
      default:
        imageUrl = "./creditcard.png";
    }

    setCardTypeImage(imageUrl);
  };

  const handleCreditCardPayment = (data) => {
    setLoading(true);
    // let path = `/payment/success?deviceType=${deviceType}`
    //       navigate(path);
    //   axios.post('https://api-staging-orders.gongchausa.com/gongcha/cardPointe/makePayment',data
//     , {
//     auth: {
//       username: "gongchausa.staging.payment",
//       password: "D5g#wwl%6pL@1wmdh!4k8z^5s",
//     },
//   }
// )
    axios.post('https://api.gongchausa.com/gongcha/cardPointe/makePayment',data, {
      auth: {
        username: "gongchausa.prod.payment",
        password: "D5g#fvl%6pL@1wdddh!4k8z^5s",
      },
    }).then((res) => {      
      let ress= res?.data
        if (ress.responseCode === 0) {
          setLoading(false);
          window.location.href = `/payment/success?deviceType=${deviceType}&paymentMethod1=${ress?.response?.paymentMethod}&paymentRefId=${ress?.response?.paymentRefId}&lastFourDigit=${ress?.response?.lastFourDigit}&authCode=${ress?.response?.authCode}`;
        } else if (
          ress.responseCode === 1 &&
          ress.responseMessage === "Duplicate Request Id"
        ) {
          setLoading(false);
          toast.error("Server error ,Please try again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
        } else if (
          ress.responseCode === 1 &&
          ress.responseMessage === "Invalid card"
        ) {
          setLoading(false);
          toast.error("Please check your Card Number", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setLoading(false);
          // let path = `/payment/failure?deviceType=${deviceType}`;
          // navigate(path);
          window.location.href = `/payment/failure?deviceType=${deviceType}`;
        }
      })
      .catch((err) => {
        console.log("on catch block ")
        setLoading(false);
        console.log(err.message);
        if (err) {
          window.location.href = `/payment/failure?deviceType=${deviceType}`;
        }
      });
  };

  // Handler for updating form completion status
  const handleFormChange = (event) => {
    // Perform any necessary validation or checks for mandatory fields
    if (event.target.name === "accountNumber") {
      setAccountNumber(event.target.value);
    }
    if (event.target.name === "cvv") {
      setCvv(event.target.value);
    }
    if (event.target.name === "cardExpiry") {
      setCardExpiry(event.target.value);
    }
    if (event.target.name === "zipCode") {
      setZipCode(event.target.value);
    }
    console.log(zipCode, "zipCode");
    const complete =
      accountNumber !== "" &&
      cvv !== "" &&
      cardExpiry !== "" &&
      zipCode.length >= 4;
    setIsFormComplete(complete);
  };
  const onSubmit = (data) => {
    let newDate = new Date();
    let creditCardPayment = {
      accountNumber: cardNumber,
      amount: amount,
      cardExpiry: data.cardExpiry,
      clientId: "AIPRUS",
      currency: "USD",
      customerIdentifier: customerPhone ? customerPhone : "Guest@gmail.com",
      customerName: customerName ? customerName : "Guest",
      description: customerName ? customerName : "Guest",
      deviceInfo: "",
      method: "card",
      requestId: (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))
        ?.toString()
        ?.replace(/[T.Z:_-]/g, ""),
      saveCard: 0,
      storeId: storeId ? Number(storeId) : 1,
      surcharge: 0,
      zipCode: data.zipCode,
      cvv: data.cvv,
    };
    handleCreditCardPayment(creditCardPayment);
  };

  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        open={loading}
      >
        <CircularProgress color="error" />
      </Backdrop>
      <Container sx={{ p: 0 }}>
        <Card
          sx={{
            backgroundColor: "#d3d3d3",
            minHeight: "100vh",
            maxWidth: { xs: "100%", sm: "100%", md: "70%", lg: "70%" },
            marginX: "auto",
            p: 0,
          }}
        >
          <Container>
            <Box
              style={{
                backgroundColor: "#d3d3d3",
                height: "100%",
                width: "100%",
                padding: "8%",
                justifyContent: "center",
              }}
            >
              <form
                onSubmit={handleSubmit(onSubmit)}
                onChange={handleFormChange}
                autoComplete="off"
              >
                <Container>
                  <Box display="flex" sx={{ mt: 0, mb: 2 }}>
                    <Typography variant="h5" sx={{ m: 1, fontWeight: "bold" }}>
                      Manual Card Entry
                    </Typography>
                  </Box>
                  <Box
                    textAlign="center"
                    display="flex"
                    flexDirection="column"
                    sx={{ mt: 5 }}
                    // marginX="3rem"
                  >
                    <Box style={{ display: "flex", flexDirection: "row" }}>
                      <FormControl fullWidth>
                        <Controller
                          name="accountNumber"
                          rules={
                            ({
                              required: true,
                            },
                            {
                              validate: (value) => {
                                console.log(validCardNumber);
                                return validateCreditCardNumber(
                                  value.split(" ").join(""),
                                  "creditCard",
                                  validCardNumber ? validCardNumber.length : 16
                                );
                              },
                            })
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="accountNumber"
                              // inputProps={{ inputMode: 'numeric' }}
                              // inputProps={{ maxLength: validCardNumber ? validCardNumber.length[validCardNumber.length.length] : 16 }}
                              variant="outlined"
                              placeholder="0000 0000 0000 0000"
                              type="num"
                              size="medium"
                              label="Card Number"
                              sx={{
                                backgroundColor: "#EEEEEE",
                              }}
                              inputProps={{ inputMode: "numeric" }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                let lengths = validCardNumber
                                  ? validCardNumber.length
                                  : [16];
                                let gap = Math.floor(
                                  (validCardNumber
                                    ? validCardNumber.length[
                                        validCardNumber.length.length - 1
                                      ]
                                    : 16) / 4
                                );
                                e.target.value = e.target.value
                                  .replace(/(.{4})/g, "$1 ")
                                  .trim()
                                  .slice(0, lengths[lengths.length - 1] + gap);
                                handleCardNumber(e);
                                // e.target.value =e.target.value.cardNum;
                              }}
                              InputProps={{
                                inputMode: "numeric",
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img
                                      src={cardTypeImage}
                                      alt="cardIcon"
                                      height="30vh"
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                          control={control}
                        />
                      </FormControl>
                    </Box>
                    {errors?.accountNumber ? (
                      <p className={classes.error}>
                        {errors.accountNumber.message}
                      </p>
                    ) : (
                      <p>
                        <br />
                      </p>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControl fullWidth>
                        <Controller
                          name="cvv"
                          rules={
                            ({
                              required: true,
                            },
                            {
                              validate: (value) => {
                                console.log(validCardNumber, "manan");
                                let fieldName = "CVV";
                                if (value) {
                                  if (
                                    value.length <
                                    (validCardNumber ? validCardNumber.cvv : 3)
                                  ) {
                                    return (
                                      "*Please enter at least" +
                                      ` ${
                                        validCardNumber
                                          ? validCardNumber.cvv
                                          : 3
                                      }` +
                                      " characters."
                                    );
                                  }
                                }
                                if (!value) {
                                  return validateNumber(value, fieldName);
                                }
                              },
                            })
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="cvv"
                              variant="outlined"
                              size="medium"
                              placeholder="000"
                              label="CVV"
                              type="num"
                              inputProps={{ inputMode: "numeric" }}
                              sx={{
                                backgroundColor: "#EEEEEE",
                                // borderRadius: "0.5rem",
                                marginLeft: "1rem",
                                // "& fieldset": { border: 'none' },
                              }}
                              // inputProps={{ maxLength: 3 }}
                              onInput={(e) => {
                                let re = new RegExp(
                                  "(.{" +
                                    `${
                                      validCardNumber ? validCardNumber.cvv : 3
                                    }}` +
                                    ")",
                                  "g"
                                );
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                e.target.value = e.target.value
                                  .replace(re, "$1")
                                  .trim()
                                  .slice(
                                    0,
                                    validCardNumber ? validCardNumber.cvv : 3
                                  );
                              }}
                            />
                          )}
                          control={control}
                        />
                        {errors?.cvv ? (
                          <p className={classes.error}>{errors.cvv.message}</p>
                        ) : (
                          <p>
                            <br />
                          </p>
                        )}
                      </FormControl>
                      <FormControl fullWidth>
                        <Controller
                          name="cardExpiry"
                          rules={
                            ({
                              required: true,
                            },
                            {
                              validate: (expiryDate) => {
                                return validateCardExpiry(expiryDate);
                              },
                            })
                          }
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="cardExpiry"
                              variant="outlined"
                              placeholder="MM/YY"
                              size="medium"
                              type="num"
                              label="Exp Date"
                              inputProps={{
                                maxLength: 5,
                                inputMode: "numeric",
                              }}
                              sx={{
                                backgroundColor: "#EEEEEE",
                              }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                e.target.value = e.target.value.replace(
                                  /(\d{2})(\d{2})/,
                                  "$1/$2"
                                );
                              }}
                              // value={cardNum}
                            />
                          )}
                          control={control}
                        />
                        {errors?.cardExpiry ? (
                          <p className={classes.error}>
                            {errors.cardExpiry.message}
                          </p>
                        ) : (
                          <p>
                            <br />
                          </p>
                        )}
                      </FormControl>
                    </div>
                    <FormControl>
                      <Controller
                        name="zipCode"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validateZipCode(value);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="zipCode"
                            variant="outlined"
                            placeholder="00000"
                            size="medium"
                            type="num"
                            label="Zip Code"
                            inputProps={{
                              maxLength: 6,
                              inputMode: "numeric",
                            }}
                            sx={{
                              backgroundColor: "#EEEEEE",
                              width: "50%",
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                    {errors?.zipCode ? (
                      <p className={classes.error}>{errors.zipCode.message}</p>
                    ) : (
                      <p>
                        <br />
                      </p>
                    )}
                  </Box>
                  <Box>
                    <Button
                      type="submit"
                      variant="outlined"
                      sx={{
                        m: 0.5,
                        mt: 2,
                        bgcolor: isFormComplete == true ? "#B7273B" : "#c6c6c6",
                        color: isFormComplete == true ? "white" : "#a0a0a0",
                        height: 60,
                        width: "100%",
                        textTransform: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 20,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        Charge ${amount ? amount : 0.0}
                      </Typography>
                    </Button>
                  </Box>
                </Container>
              </form>
            </Box>
          </Container>
        </Card>
      </Container>
    </>
  );
};
export default PosCardEntry;
