import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBestSellers } from '../../Api/API';
import Card from '../../Common/Card';
import Loader from "../../Common/Loader";
import { Typography, Grid, Backdrop,CircularProgress,Box } from "@mui/material";
import { errorToastMessage } from "../../Common/utils";
import CancelIcon from '@mui/icons-material/Cancel';

const BestSeller = ({setState}) => {
    const [menu, setMenu] = useState([]);
    const [loading , setLoading] = useState(false);
    const [bestSeller , setBestSeller] = useState(true);
    const store = useSelector((state) => state.store.nearestStore);
    useEffect(() => {
        setLoading(true);
        return getBestSellers(store ? store?.id : "", 2)
          .then((res) => {
            setMenu(res?.result);         
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            errorToastMessage();
          });
    }, [store]);   
  return (
    <>
    <Backdrop
    sx={{ color: '#fff', zIndex: 1000}}
    open={loading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
    
      <Box
        sx={{
          paddingLeft: {lg:"13vw", xs:"5vw"},
        paddingRight: {lg:"13vw", xs:"5vw"},
          paddingBottom: {lg : "3vh", xs : "12vh"},
          paddingTop:"5vh",
        }}
      >
       {menu?.length == 0 && !loading? 
        <Typography variant='h6' sx={{fontSize:"3rem", textAlign:"center", marginTop:"3rem",color:"#868686"}}>No Best Sellers Available</Typography>: ""}
        <Grid container spacing={3}>
        {menu?.map((e) => {
          return (
            <Grid item  xs={12} sm={6} md={6} lg={6}>
            <Card
              key={e?.id}
              props={e}
              fav={false}
              setLoading={setLoading}
              setState={setState}
              bestSeller={bestSeller}
            />
            {/* {e?.menuExists==false ? <div style={{display:"flex", justifyContent:"center"}}>
           <CancelIcon style={{fill:"#bf1650"}} />
            <div style={{fontWeight:"bold"}}>Unavailable at selected store</div>
            </div>:<br></br>} */}
          </Grid>
          );
        })}
        </Grid>
      </Box>
      </>
  );
};
export default BestSeller;