import React, { useEffect } from "react";
import { useState } from "react";
import { Avatar, Box, Typography, Card, Button, Checkbox } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Popup from "../Categories/CategoryPopUp";
import { setCart, setUpdateCart } from "../../Redux/CartSlice";
import { setLocationPopup } from "../../Redux/StoreSlice";
import { toast } from "react-toastify";
import { getCustomizationOption } from "../../Api/API";
import SnackbarPopUp from "../../Common/SnackBar";
import AlertPopup from "../../Common/AlertPopup";
import ShowMoreText from "react-show-more-text";
import { GetAllCategories } from "../../Api/API";
import { GetAllMenu } from "../../Api/API";
import ButtonLoader from "../../Common/ButtonLoader";
import { favoriteUnfavoriteMenu } from "../../Api/API";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { errorToastMessage } from "../../Common/utils";
const PreviousOrderCard = ({
  props,
  storeId,
  storeName,
  setState,
  setFavorite,
  getFavorite,
}) => {
  const nearestStore = useSelector((state) => state.store.nearestStore);
  const [customizeOption, setCustomizeOption] = useState();
  const [menuServingDetail, setmenuServingDetail] = useState();
  const [counter, setCounter] = useState(1);
  const [flag, setFlag] = useState(true);
  const [fav, setFav] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const cartItem = useSelector((state) => state.cart.cart);
  const [displayTitle, setDisplayTitle] = useState();
  const userDetail = useSelector((state) => state.user.user);
  // const [storeName , setStoreName] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch();
  const [alertPopup, setAlertPopUp] = useState(false);
  const [addCartClicked, setAddCartClicked] = useState(false);
  const [title, setTitle] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [expand, setExpand] = useState(false);

  toast.configure();
  const handleIncrement = () => {
    // if (flag && index >= 0) {
    //   setCounter(cartItem[index].quantity + 1);
    // } else {
    setCounter(counter + 1);
    // }
    setFlag(false);
  };
  const handleDecrement = () => {
    // if (flag && index >= 0) {
    //   setCounter(cartItem[index].quantity - 1);
    // } else {
    if (counter - 1 > 0) {
      setCounter(counter - 1);
    }
    // }
    setFlag(false);
  };

  const handleStorePopUp = () => {
    if (nearestStore?.address == null || nearestStore?.address == "") {
      // dispatch(setLocationPopup(true));
    }
  };
  //  let cartDetail = {
  //         id: props.menu_id,
  //         categoryId: props.category_id,
  //         customizationOpt: props.customizationOpt,
  //         quantity: Number(counter),
  //         price: props.item_customized_price,
  //         image: props.menu_image,
  //         title: props.menu_name,
  //         servingName: props.serving_name,
  //         totalPrice: props.price * counter,
  //         btnModifierId: [],
  //         btnModifier: [],
  //         menuServingDetail:props?.all_menu_serving_detail,
  //         selectedCupSize:props?.menu_serving_detail,
  //         orderItemPrice:Number(props?.item_customized_price) * Number(props?.quantity_purchased),
  //         selectedOptions:props?.order_details?.filter((e,index)=> ( e.modifier_group_name !='Cup Size' || index>0 ))
  //       };
  let index;
  let cartDetail = {};
  const handleReorder = () => {
    setButtonLoader(true);
    let catData = {
      storeId: nearestStore?.id,
      appType: "web",
    };
    GetAllCategories(catData)
      .then((res) => {
        let categoryExist = res?.result?.filter(
          (element) =>
            Number(element?.category_id) == Number(props?.category_id)
        );
        if (categoryExist?.length > 0) {
          let menuItem = {
            appType: "web",
            categoryId: props?.category_id,
            customerId: 0,
            requestDate: "",
            storeId: nearestStore?.id,
          };

          GetAllMenu(menuItem).then((res) => {
            let menuExist = res?.result.filter(
              (element) => Number(element?.id) == Number(props?.menu_id)
            );
            if (menuExist.length > 0) {
              if (storeId == nearestStore.id) {
                // cartDetail = {
                //   id: props.menu_id,
                //   categoryId: props?.category_id,
                //   customizationOpt: props?.customizationOpt,
                //   quantity: Number(counter),
                //   price: props?.item_customized_price/props?.quantity_purchased,
                //   image: props.menu_image,
                //   title: props.menu_name,
                //   servingName: props.serving_name,
                //   totalPrice: props.price * counter,
                //   btnModifierId: [],
                //   btnModifier: [],
                //   menuServingDetail: props?.all_menu_serving_detail,
                //   selectedCupSize: props?.all_menu_serving_detail.filter((e)=>e?.serving_name == props?.serving_name),
                //   orderItemPrice: Number(props?.item_customized_price/props?.quantity_purchased) ,
                //   selectedOptions: props?.order_details?.filter((e, index) => (e.modifier_group_name != 'Cup Size' || index > 0))
                // };
                // // console.log(cartDetail.selectedCupSize," cartdeatil reorder")
                // // console.log(props?.menu_serving_detail,"cart ser")
                // // && Object.keys(cartDetail.selectedCupSize[0]).length != 0
                // hadleAddToCart();
                // if(cartDetail.selectedCupSize.length >0&& Object.keys(cartDetail.selectedCupSize[0]).length != 0){
                // toast.success(`${cartDetail.title} Added to the cart`, {
                //   position: "bottom-left",
                //   autoClose: 1000,
                //   hideProgressBar: true,
                //   closeOnClick: true,
                //   pauseOnHover: false,
                //   draggable: true,
                //   progress:undefined,
                //   theme:"dark"
                //   });
                // }
                // setOpenSnackBar(true)
                let data = {
                  appType: "web",
                  customerId: userDetail?.customer_id,
                  menuId: props.menu_id,
                  storeId: nearestStore.id,
                };
                getCustomizationOption(data)
                  .then((res) => {
                    // console.log(res);
                    let result = res.result[0][0];
                    let modifierDetail = [];
                    let isRequiredChecked = false;
                    cartDetail = {
                      id: props.menu_id,
                      categoryId: result.category_id,
                      customizationOpt: result.customizationOpt
                        ? result.customizationOpt
                        : [],
                      quantity: Number(counter),
                      price: result?.price?.toString(),
                      image: result.image,
                      title: result.title,
                      categoryName: result?.category_name,
                      servingName: props?.serving_name,
                      totalPrice:
                        props.item_customized_price * props?.quantity_purchased,
                      btnModifierId: [],
                      btnModifier: [],
                      menuServingDetail: result?.menu_serving_detail,
                      selectedCupSize: result?.menu_serving_detail.filter(
                        (e) => e?.serving_name == props?.serving_name
                      ),
                      orderItemPrice: Number(
                        props?.item_customized_price / props.quantity_purchased
                      ),
                      selectedOptions: result?.customizationOpt?.filter(
                        (e, index) => {
                          props.order_details
                            .filter(
                              (cup) => cup.modifier_group_name != "Cup Size"
                            )
                            .map((modifiers) => {
                              if (
                                modifiers.modifier_group_name ==
                                e.modifier_group_name
                              ) {
                                if (modifiers.option.length >= 1) {
                                  let x = [];
                                  modifiers.option.forEach((toppings) => {
                                    let singleTopping = e?.option.filter(
                                      (newStoreTopping) =>
                                        Number(newStoreTopping.modifier_id) ==
                                          Number(toppings.modifier_id) &&
                                        newStoreTopping.modifier_group_description !=
                                          ""
                                    )[0];

                                    if (singleTopping) {
                                      singleTopping.quantity =
                                        toppings.quantity;
                                    } else {
                                      setButtonLoader(false);
                                      toast.error(
                                        `${toppings.modifier_name} ${e.modifier_group_name} is not present for this item`,
                                        {
                                          position: "bottom-left",
                                          autoClose: 1000,
                                          hideProgressBar: true,
                                          closeOnClick: true,
                                          pauseOnHover: false,
                                          draggable: true,
                                          progress: undefined,
                                          theme: "dark",
                                        }
                                      );
                                    }

                                    let selected_modifier_serving =
                                      singleTopping?.modifier_group_description

                                        .filter(
                                          (topServing) =>
                                            topServing?.serving_name ==
                                            props?.serving_name
                                        )
                                        .map((e) => {
                                          return {
                                            price: e?.price
                                              ? e?.price?.toString()
                                              : "0",
                                            serving_name: e?.serving_name
                                              ? e.serving_name
                                              : props?.serving_name,
                                          };
                                        })[0];
                                    singleTopping.selected_modifier_serving =
                                      selected_modifier_serving
                                        ? selected_modifier_serving
                                        : {
                                            price: "0",
                                            serving_name: props?.serving_name,
                                          };
                                    singleTopping.price =
                                      singleTopping?.selected_modifier_serving?.price;
                                    singleTopping.modifier_price =
                                      singleTopping?.selected_modifier_serving?.price;
                                    singleTopping.modifier_prefix_name = 0;
                                    x.push(singleTopping);
                                  });
                                  e.option = x;
                                }
                              }
                            });
                          return props.order_details.some((f) => {
                            return (
                              f.modifier_group_name === e.modifier_group_name
                            );
                          });
                        }
                      ),
                    };
                    result?.customizationOpt.forEach((element, index) => {
                      let selectedCustomizationOption =
                        cartDetail.selectedOptions.filter(
                          (ele) =>
                            element.modifier_group_id == ele.modifier_group_id
                        );
                      if (selectedCustomizationOption.length == 0) {
                        if (element.is_required) {
                          isRequiredChecked = true;
                          modifierDetail.push(element.modifier_group_name);
                        }
                      }
                    });
                    if (isRequiredChecked) {
                      toast.error(
                        `${modifierDetail[0]} modifier is reqired for this item`,
                        {
                          position: "bottom-left",
                          autoClose: 1000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        }
                      );
                      setButtonLoader(false);
                    } else {
                      let itemAddedToCartStatus = hadleAddToCart();
                      if (itemAddedToCartStatus) {
                        toast.success(`${cartDetail.title} Added to the cart`, {
                          position: "bottom-left",
                          autoClose: 1000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        });
                        setButtonLoader(false);
                      } else {
                      }
                    }
                    // setOpenSnackBar(true)
                  })

                  .catch((err) => {
                    setButtonLoader(false);
                    console.log(err);
                  });
              } else {
                let data = {
                  appType: "web",
                  customerId: userDetail?.customer_id,
                  menuId: props.menu_id,
                  storeId: nearestStore.id,
                };
                getCustomizationOption(data)
                  .then((res) => {
                    console.log(res);
                    let result = res?.result[0][0];
                    cartDetail = {
                      id: props?.menu_id,
                      categoryId: result?.category_id,
                      customizationOpt: result?.customizationOpt
                        ? result?.customizationOpt
                        : [],
                      quantity: Number(counter),
                      price: result?.price?.toString(),
                      image: result.image,
                      title: result.title,
                      servingName: props?.serving_name,
                      totalPrice:
                        props.item_customized_price * props?.quantity_purchased,
                      btnModifierId: [],
                      btnModifier: [],
                      menuServingDetail: result?.menu_serving_detail,
                      selectedCupSize: result?.menu_serving_detail.filter(
                        (e) => e?.serving_name == props?.serving_name
                      ),
                      orderItemPrice: Number(
                        props?.item_customized_price / props.quantity_purchased
                      ),
                      selectedOptions: result?.customizationOpt?.filter(
                        (e, index) => {
                          props.order_details
                            .filter(
                              (cup) => cup.modifier_group_name != "Cup Size"
                            )
                            .map((modifiers) => {
                              if (
                                modifiers.modifier_group_name ==
                                e.modifier_group_name
                              ) {
                                if (modifiers.option.length == 0) {
                                  e.option = e.option
                                    .filter(
                                      (singleModi) =>
                                        singleModi.modifier_id ==
                                        modifiers.option[0].modifier_id
                                    )
                                    .map((change) => {
                                      change.modifier_price =
                                        change.modifier_group_description
                                          .filter(
                                            (servPrice) =>
                                              servPrice.serving_name ==
                                              props?.serving_name
                                          )[0]
                                          ?.price?.toString();
                                      change.quantity = 1;
                                      change.selected_modifier_serving =
                                        change.modifier_group_description
                                          .filter(
                                            (serving) =>
                                              serving.serving_name ==
                                              props?.serving_name
                                          )
                                          .map((ele) => {
                                            return {
                                              price: ele?.price?.toString(),
                                              serving_name: ele?.serving_name,
                                            };
                                          })[0];
                                      return change;
                                    });
                                } else if (modifiers.option.length >= 1) {
                                  let x = [];
                                  modifiers.option.forEach((toppings) => {
                                    let singleTopping = e?.option.filter(
                                      (newStoreTopping) =>
                                        Number(newStoreTopping.modifier_id) ==
                                          Number(toppings.modifier_id) &&
                                        newStoreTopping.modifier_group_description !=
                                          ""
                                    )[0];

                                    if (singleTopping) {
                                      singleTopping.quantity =
                                        toppings.quantity;
                                    } else {
                                      setButtonLoader(false);
                                      toast.error(
                                        `${toppings.modifier_name} ${e.modifier_group_name} is not present for this item`,
                                        {
                                          position: "bottom-left",
                                          autoClose: 1000,
                                          hideProgressBar: true,
                                          closeOnClick: true,
                                          pauseOnHover: false,
                                          draggable: true,
                                          progress: undefined,
                                          theme: "dark",
                                        }
                                      );
                                    }

                                    let selected_modifier_serving =
                                      singleTopping?.modifier_group_description

                                        .filter(
                                          (topServing) =>
                                            topServing?.serving_name ==
                                            props?.serving_name
                                        )
                                        .map((e) => {
                                          return {
                                            price: e?.price
                                              ? e?.price?.toString()
                                              : "0",
                                            serving_name: e?.serving_name
                                              ? e.serving_name
                                              : props?.serving_name,
                                          };
                                        })[0];
                                    singleTopping.selected_modifier_serving =
                                      selected_modifier_serving
                                        ? selected_modifier_serving
                                        : {
                                            price: "0",
                                            serving_name: props?.serving_name,
                                          };
                                    singleTopping.price =
                                      singleTopping?.selected_modifier_serving?.price;
                                    singleTopping.modifier_price =
                                      singleTopping?.selected_modifier_serving?.price;
                                    singleTopping.modifier_prefix_name = 0;
                                    x.push(singleTopping);
                                  });
                                  e.option = x;
                                }
                              }
                            });
                          return props.order_details.some((f) => {
                            return (
                              f.modifier_group_name === e.modifier_group_name
                            );
                          });
                        }
                      ),
                    };
                    let modfier_group_price = 0;
                    cartDetail.selectedOptions.map((e) => {
                      e?.option?.map((toppingPrice) => {
                        modfier_group_price += isNaN(
                          Number(
                            toppingPrice?.price || toppingPrice.modifier_price
                          ) * toppingPrice.quantity
                        )
                          ? 0
                          : Number(
                              (toppingPrice?.price ||
                                toppingPrice.modifier_price) *
                                toppingPrice.quantity
                            );
                      });
                      // console.log(modfier_group_price,"modfier_group_price")
                    });
                    cartDetail.orderItemPrice =
                      cartDetail.selectedCupSize[0]?.price +
                      modfier_group_price;
                    cartDetail.totalPrice =
                      cartDetail.orderItemPrice * cartDetail.quantity;
                    let modifierDetail = [];
                    let isRequiredChecked = false;
                    result?.customizationOpt.forEach((element, index) => {
                      let selectedCustomizationOption =
                        cartDetail.selectedOptions.filter(
                          (ele) =>
                            element.modifier_group_id == ele.modifier_group_id
                        );
                      if (selectedCustomizationOption.length == 0) {
                        if (element.is_required) {
                          isRequiredChecked = true;
                          modifierDetail.push(element.modifier_group_name);
                        }
                      }
                    });
                    if (isRequiredChecked) {
                      toast.error(
                        `${modifierDetail[0]} modifier is reqired for this item`,
                        {
                          position: "bottom-left",
                          autoClose: 1000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: false,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        }
                      );
                      setButtonLoader(false);
                    } else {
                      let itemAddedToCartStatus = hadleAddToCart();
                      if (itemAddedToCartStatus) {
                        toast.success(
                          `${cartDetail?.title} Added to the cart`,
                          {
                            position: "bottom-left",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                          }
                        );
                      } else {
                      }
                    }
                    // setOpenSnackBar(true)
                  })

                  .catch((err) => {
                    setButtonLoader(false);
                    console.log(err);
                  });
              }
            } else {
              toast.error(
                `This ${props.menu_name} is not available for selected store`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setButtonLoader(false);
            }
          });
        } else {
          toast.error(
            `This ${props.menu_name} is not available for selected store`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setButtonLoader(false);
        }
      })
      .catch((e) => {
        setButtonLoader(false);
        console.log(e);
      });
  };
  useEffect(() => {
    if (props?.is_favourite == 1) {
      setFav(true);
    }
  }, []);
  // console.log(cartDetail)
  const handleFavUnfav = (val) => {
    setFav(val);
    let favorite = val ? 1 : 0;
    let custom = [];
    let arr = [];
    cartDetail = {
      id: props.menu_id,
      categoryId: props.category_id,
      customizationOpt: props.customizationOpt,
      quantity: Number(counter),
      price: props?.item_customized_price / props?.quantity_purchased,
      image: props.menu_image,
      title: props.menu_name,
      servingName: props.serving_name,
      totalPrice: props.price * counter,
      btnModifierId: [],
      btnModifier: [],
      menuServingDetail: props?.all_menu_serving_detail,
      selectedCupSize: props?.all_menu_serving_detail.filter(
        (e) => e?.serving_name == props?.serving_name
      ),
      orderItemPrice: Number(
        props?.item_customized_price / props?.quantity_purchased
      ),
      selectedOptions: props?.order_details?.filter(
        (e, index) => e.modifier_group_name != "Cup Size" || index > 0
      ),
    };
    arr.push(cartDetail);
    const favUnfavData = {
      cart_id: 0,
      category_id: props?.category_id,

      customer_id: userDetail?.customer_id
        ? userDetail?.customer_id
        : userDetail?.person_id,
      fav_id: 0,
      fav_type: "menujson",
      is_favourite: favorite,
      selectedOptions: cartDetail?.selectedOptions,
      orderItemPrice: props?.item_customized_price / props?.quantity_purchased,
      menu_details: {
        cart_id: 0,
        category_id: props?.category_id,
        category_name: props?.category_name,
        created_at: new Date().toISOString(),
        customizationOpt: cartDetail?.customizationOpt,
        description: "",
        discount_details: [],
        id: 0,
        image: props.menu_image,
        is_active: true,
        is_deleted: "1",
        is_favourite: favorite,
        menu_serving_detail: [
          {
            created_at: new Date().toISOString(),
            price: props?.menu_serving_detail[0]?.price
              ? props?.menu_serving_detail[0]?.price
              : props.serving_price,
            serving_actual_id: props?.menu_serving_detail[0]?.serving_actual_id,
            serving_description:
              props?.menu_serving_detail[0]?.serving_description,
            serving_icon: props?.menu_serving_detail[0]?.serving_icon,
            serving_id: props?.menu_serving_detail[0]?.serving_id
              ? props?.menu_serving_detail[0]?.serving_id
              : props?.serving_id,
            serving_name: props?.menu_serving_detail[0]?.serving_name
              ? props?.menu_serving_detail[0]?.serving_name
              : props.serving_name,
            status: "Active",
            tier_id: props?.menu_serving_detail[0]?.tier_id,
            updated_at: new Date().toISOString(),
          },
        ],
        modifier_price: props?.serving_price,
        price: props?.serving_price,
        status: "Active",
        store_id: 0,
        title: props?.title,
        updated_at: new Date().toISOString(),
      },
      menu_id: props.id,
      order_id: "",
    };
    favoriteUnfavoriteMenu(favUnfavData)
      .then((res) => {
        if (val) {
          if (getFavorite == false) {
            setFavorite(true);
          } else {
            setFavorite(false);
          }
          toast.success("Added to your favorite", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          if (getFavorite == false) {
            setFavorite(true);
          } else {
            setFavorite(false);
          }
          toast.error("Removed from your favorite", {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        }
        // setFav(val);
      })
      .catch((e) => {
        errorToastMessage();
        console.log(e);
      });
  };
  const hadleAddToCart = () => {
    let arr = [];
    arr.push(cartDetail);
    if (
      cartDetail.selectedCupSize.length > 0 &&
      Object.keys(cartDetail.selectedCupSize[0]).length != 0
    ) {
      let cartItemCheck = cartItem.filter(
        (e) =>
          e.id == cartDetail.id &&
          e.selectedOptions.length == cartDetail.selectedOptions.length &&
          e.selectedCupSize[0].serving_name == cartDetail?.servingName &&
          e.selectedOptions.filter((singleOption) =>
            cartDetail.selectedOptions.find(
              (e) =>
                e?.option[0]?.modifier_id ==
                singleOption?.option[0]?.modifier_id
            )
          ).length == cartDetail.selectedOptions.length
      );
      let sameCustomization = cartItemCheck[0]?.selectedOptions?.filter(
        (options, indexOfOption) =>
          cartDetail.selectedOptions.find((modi) => {
            if (modi.option.length > 1) {
              let sameToppings = modi.option.filter((e) =>
                options.option.find(
                  (element) => element.modifier_id == e.modifier_id
                )
              );
              if (sameToppings.length == modi.option.length) {
                return true;
              } else {
                return false;
              }
            } else {
              return (
                modi.option[0]?.modifier_id == options.option[0]?.modifier_id
              );
            }
          })
      );
      index = cartItem?.findIndex(
        (item) =>
          item.id === cartDetail.id && item.categoryId === cartDetail.categoryId
      );
      if (
        !sameCustomization ||
        sameCustomization?.length != cartDetail?.selectedOptions?.length
      ) {
        if (counter !== 0) {
          dispatch(setCart(arr));
          setAddCartClicked(false);
          setButtonLoader(false);
          return true;
        }
      } else {
        let updatedCart = [...cartItem];
        let obj = Object.assign({}, updatedCart[index]);
        obj.quantity += counter;
        updatedCart?.splice(index, 1, obj);
        dispatch(setUpdateCart(updatedCart));
        setAddCartClicked(false);
        setButtonLoader(false);
        return true;
      }
      setButtonLoader(false);
    } else {
      setButtonLoader(false);
      toast.error(
        `${cartDetail.servingName} serving is not present for this item`,
        {
          position: "bottom-left",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      return false;
    }
  };

  useEffect(() => {
    if (!alertPopup && addCartClicked) {
      handleReorder();
    }
  }, [alertPopup]);
  const onClick = () => {
    setExpand(!expand);
  };
  return (
    <>
      <Card style={{ cursor: "pointer" }}>
        <Box
          p={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "left",
              marginBottom: "1rem",
            }}
          >
            <Box
              component="img"
              sx={{
                height: "9rem",
                width: "7rem",
                maxWidth: "25%",
                objectFit: "contain",
                transition: "transform 0.15s ease-in-out",
                "&:hover": { transform: "scale3d(1.15, 1.15, 1)" },
              }}
              alt="menuImage"
              src={props?.menu_image ? props?.menu_image : "./logos.png"}
            />
            <Box sx={{ marginLeft: { lg: "4rem", xs: "1rem" } }}>
              <Typography variant="h5">
                {props.menu_name}
                {nearestStore?.id && (
                  <Checkbox
                    style={{ marginBottom: "0.7rem" }}
                    icon={
                      props.is_favourite == 1 && fav ? (
                        <FavoriteIcon style={{ fill: "#C30E2F" }} />
                      ) : (
                        <FavoriteBorderIcon />
                      )
                    }
                    checkedIcon={<FavoriteIcon style={{ fill: "#C30E2F" }} />}
                    checked={fav}
                    onChange={(e) => {
                      if (props?.is_favourite == 1 && fav) {
                        handleFavUnfav(false);
                      } else {
                        handleFavUnfav(e.target.checked);
                      }
                    }}
                  />
                )}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ width: { lg: "32rem", md: "30rem" } }}
              >
                {props?.order_details?.map(
                  (elm, index) =>
                    (elm?.option?.length > 0
                      ? elm?.option?.map((item) => {
                          return (
                            " " +
                            item?.modifier_name +
                            (item?.quantity > 1
                              ? " (" + item?.quantity + ") "
                              : " ")
                          );
                        })
                      : " ") +
                    (props?.order_details?.length - 1 != index ? "| " : " ")
                )}
              </Typography>
            </Box>
          </div>
          <Button
            style={{
              borderRadius: "15px",
              padding: "0.2rem 1.6rem",
              backgroundColor: "white",
              color: "#C60734",
              textTransform: "none",
              border: "1px solid #C60734",
            }}
            onClick={() => {
              if (nearestStore) {
                setTitle("");
                if (storeId == nearestStore.id) {
                  handleReorder();
                } else {
                  setAlertPopUp(true);
                  setDisplayTitle(
                    `Your previous order is from ${storeName} store , reordering it from ${nearestStore?.store_name} store may incorporate changes in its  price`
                  );
                  setAddCartClicked(true);
                }
              } else {
                // setTitle("noReorder")
                // setDisplayTitle("To reorder please select your nearest store")
                // setAlertPopUp(true)
                setState(true);
              }
            }}
          >
            {buttonLoader ? <ButtonLoader props={"4.5rem"} /> : "Add to Cart"}
          </Button>
        </Box>
      </Card>

      <AlertPopup
        open={alertPopup}
        setOpen={setAlertPopUp}
        setState={setAddCartClicked}
        title={title ? title : "previousOrder"}
        displayTitle={displayTitle}
        buttonType1={title ? false : true}
        buttonType2={true}
      />
      {openSnackBar && (
        <SnackbarPopUp
          key={cartDetail.id}
          open={openSnackBar}
          setopenpopup={setOpenSnackBar}
          messageInfo={`${props.menu_name} Added to the cart`}
        />
      )}
    </>
  );
};

export default PreviousOrderCard;
