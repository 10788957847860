import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import cartReducer from "./CartSlice.js"
import storeReducer from "./StoreSlice"
import userReducer from "./UserSlice"
const reducers = combineReducers({
    cart: cartReducer,
    store:storeReducer,
    user:userReducer
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
