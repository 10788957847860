import React, { useState } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import { Avatar } from '@mui/material'
import ListItemAvatar from '@mui/material/ListItemAvatar';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import FreeBreakfastOutlinedIcon from "@mui/icons-material/FreeBreakfastOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GTranslateOutlinedIcon from "@mui/icons-material/GTranslateOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {  useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./Sidebar.css";
import LangPopup from "./Languagepopup";
import MapContainer from "../StoreLocation";
import { useLocation  } from 'react-router-dom'
import { setCart } from "../../../Redux/CartSlice";
import {
  setCoordinate,
  setStoreData,
  setStoreCoordinate,
  setNearestDistance,
  setFlag,
  setNearestStore,
  setLocationPopup,
} from "../../../Redux/StoreSlice";
import { formatPhoneNumber } from "../../utils";
import { makeStyles } from "@mui/styles";
import AlertPopup from "../../AlertPopup";
 const useStyles = makeStyles ({
   item: {
     zIndex: 1, 
     transition: '0.4s',
     '&:hover': {
       backgroundColor: '#E7E7E7',
   },
 },
 BackdropProps: {
  background: 'transparent'
}
 });
const Sidebar = () => {
  const classes=useStyles();
  const deny = useSelector((state) => state.store.deny);
  const cartItem = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  const [openbutton, setopenbutton] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);
  const [storeState, setStoreState] = useState(false);
  const isLogin = useSelector((state) => state.user.isLogin);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const userDetail = useSelector((state) => state.user.user);
  const storeName = useSelector((state) => state.store.nearestStore);
const location = useLocation()
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/`;
    navigate(path);
  };
  const routeRewards = () => {
    let path = `/rewards`;
    navigate(path);
  };
  const routeSetting = () => {
    let path = `/setting`;
    navigate(path);
  };

  const routeOrderHistory = () => {
    let path = `/orderHistory`;
    navigate(path);
  };

  const routeFavorites = () => {
    let path = `/favorites`;
    navigate(path);
  }

  const handleLogout = () => {
    setOpen(true);
    // localStorage.clear();
    // navigate("/")
    // window.location.reload();
  };

  const toggleDrawer = (open) => (e) => {
    if (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
      return;
    }

    setState(open);
  };

  const storeToggleDrawer = (open) => (e) => {
    if (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
      return;
    }
    setStoreState(open);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {isLogin ?
          <ListItem button className={classes.item} onClick={routeSetting}>
            <ListItemAvatar>
              <Avatar src={userDetail?.profile_pic?userDetail?.profile_pic:userDetail?.file}>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={userDetail?.first_name + " "+userDetail?.last_name } secondary={userDetail?.phone_number?userDetail?.phone_number:userDetail.phone_no} />
            {/* <ListItemText primary={userDetail?.username?userDetail?.username: userDetail.first_name} secondary={userDetail?.phone_number?userDetail?.phone_number:userDetail.phone_no} /> */}

          </ListItem> : ""
        }
        { isGuestLogin ?
          <ListItem button className={classes.item} >
          <ListItemAvatar>
              <Avatar src={userDetail?.profile_pic?userDetail?.profile_pic:userDetail?.file}>
              </Avatar>
            </ListItemAvatar>
          <ListItemText primary={userDetail?.username?userDetail?.username: userDetail.first_name} secondary={userDetail?.phone_number?userDetail?.phone_number:userDetail.phone_no} />
          </ListItem> : ""
        }
        <ListItem button className={classes.item} onClick={routeChange}>
          <ListItemIcon>
            <HomeOutlinedIcon style={{ color: "#C30E2F" }} />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {isLogin ? <ListItem button className={classes.item} onClick={routeRewards}>
          <ListItemIcon>
            <StarsOutlinedIcon style={{ color: "#C30E2F" }} />
          </ListItemIcon>
          <ListItemText primary="Rewards" />
        </ListItem>: ""}
        {isLogin === true? <ListItem button className={classes.item} onClick={routeOrderHistory}>
          <ListItemIcon>
            <FreeBreakfastOutlinedIcon style={{ color: "#C30E2F" }} />
          </ListItemIcon>
          <ListItemText primary="Order History" />
        </ListItem> : ""}
        {/* <ListItem button className={classes.item} onClick={(location.pathname == "/checkout" || location.pathname == "/payment") ?storeToggleDrawer(false): storeToggleDrawer(true)}>
          <ListItemIcon>
            <LocationOnIcon style={{ color: "#C30E2F" }} />
          </ListItemIcon>
          <ListItemText primary= {deny == false?"Locate Nearby Store":"Locate YourSelf"} />
        </ListItem> */}
      </List>
      <Divider />
      <List>
        {/* <ListItem button className={classes.item} onClick={() => setopenbutton(true)}>
          <ListItemIcon>
            <GTranslateOutlinedIcon style={{ color: "#C30E2F" }} />
          </ListItemIcon>
          <ListItemText primary="Change Language" />
        </ListItem> */}
        <ListItem button
        className={classes.item}
        onClick={()=>{
          window.open(`https://gongchausa.com/contact-us/`)
        }}
        >
          <ListItemIcon>
            <SupportAgentOutlinedIcon style={{ color: "#C30E2F" }} />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </ListItem>
        {isLogin ? (
          <ListItem button className={classes.item} onClick={routeSetting}>
            <ListItemIcon>
              <SettingsOutlinedIcon style={{ color: "#C30E2F" }} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        ) : (
          ""
        )}
      </List>
     
      <Divider />
      {isLogin || isGuestLogin ?
      <List>
        <ListItem button className={classes.item} onClick={handleLogout} key="Logout">
          <ListItemIcon>
            <LogoutOutlinedIcon style={{ color: "#C30E2F" }} />
          </ListItemIcon>
          <ListItemText primary= {isLogin === true ? "Logout" : " Guest Logout"} />
        </ListItem>
      </List>:""}
    </Box>
  );
 
  return (
    <>
      <button
        className="btn d-inline offcanvas_btn"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="/offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        onClick={toggleDrawer(true)}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      {
        <SwipeableDrawer
        //  PaperProps={{
        //     sx: {  top:122.5 },
        //   }}
        //   ModalProps={{
        //   BackdropProps:{
        //     classes:{
        //       root:classes.BackdropProps
        //     }
        //   }
        // }}
          anchor="right"
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list(true)}
        </SwipeableDrawer>
      }
      {
        <SwipeableDrawer
          anchor="right"
          PaperProps={{
            sx: { width: { xs: "80vw", sm: "55vw", md: "40vw", lg: "35vw" } },
          }}
          open={storeState}
          onClose={storeToggleDrawer(false)}
          onOpen={storeToggleDrawer(true)}
        >
          <Box sx={{ p: 1 }}>
            <MapContainer />
          </Box>
        </SwipeableDrawer>
      }

      {
        <LangPopup
          openbutton={openbutton}
          setopenbutton={setopenbutton}
        ></LangPopup>
      }
      <AlertPopup
       open={open}
       setOpen={setOpen}
       title= {"logout"}
       displayTitle = { "Are you sure you want to log out?"}
       buttonType1 = {true}
       buttonType2 = {true}
      ></AlertPopup>
    </>
  );
};

export default Sidebar;
