import React from "react";
import { useEffect } from "react";
import { Button, Box, Container, CircularProgress, Card, Divider } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { GetAllCategories, GetAllMenu, getAllOrdersByCustomer, getCustomizationOption,getAllOrderByCustomersec, getCustomerOrderById } from "../../Api/API";
import PreviousOrderCard from "./PreviousOrderCard";
import { Typography, Backdrop } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setCart, setUpdateCart } from "../../Redux/CartSlice";
import Loader from "../../Common/Loader";
import { toast } from "react-toastify";
import SnackbarPopUp from "../../Common/SnackBar";
import AlertPopup from "../../Common/AlertPopup";
import Pagination from "@mui/material/Pagination";
import ButtonLoader from "../../Common/ButtonLoader";
import { uiDate } from "../../Common/utils";
import CircleIcon from '@mui/icons-material/Circle';

toast.configure();
const useStyle = makeStyles((theme) => ({
  
  btnLogin: {
    backgroundColor: "#B7273B",
    // margin: "auto",
    fontFamily: "Lusitana",
    textTransform: "none",
  },
}));

const PreviousOrder = ({setState}) => {
  const classes = useStyle();
  const [page, setPage] = useState(0);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [totalRecord, setTotalRecord] = useState(1);
  const dispatch = useDispatch();
  const [menu, setMenu] = useState([]);
  const [storeName, setStoreName] = useState();
  const [indexState, setIndexState] = useState(0);
  const [loading, setLoading] = useState(false);
  const cartItem = useSelector((state) => state.cart.cart);
  const store = useSelector((state) => state.store.nearestStore);
  const userDetail = useSelector((state) => state.user.user);
  const [displayTitle, setDisplayTitle] = useState("");
  const [title, setTitle] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [remove, setRemove] = useState(false);
  const [reorderClicked, setReorderClicked] = useState();
  const [snackBarTitle, setSnackBarTitle] = useState();
  const [viewMoreLoader, setViewMoreLoader] = useState(false);
  const [dataAvailability, setDataAvailability] = useState(true);
  const [getFavorite, setFavorite] = useState(false)
  const[previousOrderMenu,setPreviousOrderMenu]=useState([])
  
  let data = {
    orderType: "",
    storeId: store ? store?.id : 1,
    // storeId: 2,
    type: "all",
    userId: userDetail.customer_id
      ? userDetail.customer_id
      : userDetail.person_id,
    // userId: 86079
  };
  // useEffect(() => {
  //   setLoading(true);
  //   getAllOrdersByCustomer(data,0, 5, "created_at")
  //     .then((res) => {
  //       setMenu(res?.result);
  //       setTotalRecord(Math.ceil(res?.totalRecord / 5));
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });
  // }, [getFavorite]);

  useEffect(()=>{
    setLoading(true);
   getAllOrderByCustomersec(data,0,5,"created_at")
   .then((res)=>{
     setPreviousOrderMenu(res?.result)
     setTotalRecord(Math.ceil(res?.totalRecord / 5));
     setLoading(false);
     console.log(res,"ressss")
   }).catch((err)=>{
     setLoading(false)
     
   })
 },[getFavorite]);
 

  let index;
  let cartDetail = {};
  const handleReorder = (orders, ele) => {
    setButtonLoader(true);
    let totalOrdersCount = orders?.length;
    let catBool = true;
    ele?.ordermenu?.map((element, indexes) => {
      let catData = {
        storeId: store?.id,
        appType: "web",
      }
      GetAllCategories(catData).then((res) => {
        let categoryExist = res?.result?.filter((individual) => Number(individual?.category_id) == Number(element?.category_id))
        if (categoryExist?.length > 0) {
          let menuItem = {
            appType: "web",
            categoryId: element?.category_id,
            customerId: 0,
            requestDate: "",
            storeId: store?.id
          }
          GetAllMenu(menuItem).then((res) => {
            let menuExist = res?.result.filter((individual) => Number(individual?.id) == Number(element?.menu_id));
            if (menuExist.length > 0) {
              totalOrdersCount = totalOrdersCount - 1;
           
            if (totalOrdersCount == 0  ) {
              if (store?.id == ele?.store_id) {
                // orders.map((element) => {
                //   cartDetail = {
                //     id: element.menu_id,
                //     categoryId: element.category_id,
                //     customizationOpt: element.customizationOpt
                //       ? element.customizationOpt
                //       : [],        
                //     // quantity: Number(element?.quantity_purchased),
                //     quantity:1,
                //     price: element.serving_price,
                //     image: element.menu_image,
                //     title: element.menu_name,
                //     servingName: element?.serving_name,
                //     totalPrice:
                //       element.item_customized_price * element?.quantity_purchased,
                //     btnModifierId: [],
                //     btnModifier: [],
                //     menuServingDetail: element?.all_menu_serving_detail,
                //     // selectedCupSize: element?.menu_serving_detail,
                //     selectedCupSize: element?.all_menu_serving_detail.filter((e)=>e.serving_name == element?.serving_name),
                //     orderItemPrice:
                //       Number(element?.item_customized_price),

                //     selectedOptions: element?.order_details?.filter(
                //       (e, index) => e.modifier_group_name != "Cup Size" || index > 0
                //     ),
                //   };
                //   index = cartItem?.findIndex(
                //     (item) =>
                //       item.id === element.menu_id &&
                //       item.categoryId === element.category_id
                //   );
                //   hadleAddToCart();
                //   // setSnackBarTitle(cartDetail.title);
                //   // console.log(cartDetail.selectedCupSize,"selected cup size")
                //   if(cartDetail.selectedCupSize.length >0 && Object.keys(cartDetail.selectedCupSize[0]).length != 0){
                //   toast.success(`${cartDetail.title} Added to the cart`, {
                //     position: "bottom-left",
                //     autoClose: 1000,
                //     hideProgressBar: true,
                //     closeOnClick: true,
                //     pauseOnHover: false,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "dark"
                //   });
                // }
                //   // setOpenSnackBar(true);
                // });
                orders.map((element) => {
                  let data = {
                    appType: "web",
                    customerId: userDetail?.customer_id,
                    menuId: element.menu_id,
                    storeId: store.id,
                  };
                  getCustomizationOption(data)
                    .then((res) => {
                      let result = res.result[0][0];
                      cartDetail = {
                        id: element.menu_id,
                        categoryId: result.category_id,
                        customizationOpt: result.customizationOpt
                          ? result.customizationOpt
                          : [],
                        quantity: Number(element?.quantity_purchased),
                        // quantity:1,
                        price: result?.price?.toString(),
                        image: result.image,
                        categoryName :result?.category_name,
                        title: result.title,
                        servingName: element?.serving_name,
                        totalPrice:
                        element.item_customized_price *
                        element?.quantity_purchased,
                        btnModifierId: [],
                        btnModifier: [],
                        menuServingDetail: result?.menu_serving_detail,
                        selectedCupSize: result?.menu_serving_detail.filter(
                          (e) => e?.serving_name == element?.serving_name
                        ),
                        orderItemPrice:
                          Number(element?.item_customized_price/element?.quantity_purchased),
                        selectedOptions: result?.customizationOpt?.filter((e, index) => {
                          element.order_details
                            .filter((cup) => cup.modifier_group_name != "Cup Size")
                            .map((modifiers) => {
                              if (
                                modifiers.modifier_group_name == e.modifier_group_name
                              ) {
                                if (modifiers.option.length == 0) {
                                  e.option = e.option
                                    .filter(
                                      (singleModi) =>
                                        singleModi.modifier_id ==
                                        modifiers.option[0].modifier_id
                                    )
                                    .map((change) => {
                                      change.modifier_price =
                                        change.modifier_group_description
                                          .filter(
                                            (servPrice) =>
                                              servPrice?.serving_name ==
                                              element?.serving_name
                                          )[0]
                                          ?.price?.toString();
                                      change.quantity = 1;
                                      change.selected_modifier_serving =
                                        change.modifier_group_description
                                          .filter(
                                            (serving) =>
                                              serving?.serving_name ==
                                              element?.serving_name
                                          )
                                          .map((ele) => {
                                            return {
                                              price: ele?.price?.toString(),
                                              serving_name: ele?.serving_name,
                                            };
                                          })[0];
                                      return change;
                                    });
                                } else if (modifiers.option.length >= 1) {
                                  let x = [];
                                  modifiers.option.forEach((toppings) => {
                                    let singleTopping = e.option.filter(
                                      (newStoreTopping) =>
                                        Number(newStoreTopping.modifier_id) ==
                                        Number(toppings.modifier_id)&&newStoreTopping.modifier_group_description!=""
                                    )[0];
                                    if(singleTopping){
                                      singleTopping.quantity = toppings.quantity
                                    }else{ 
                                      toast.error(`${toppings.modifier_name
                                      } ${e.modifier_group_name} is not present for this item`, {
                                        position: "bottom-left",
                                        autoClose: 2000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: false,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "dark"
                                      });
                                      setButtonLoader(false);
                                    }
                                    let selected_modifier_serving =
                                      singleTopping?.modifier_group_description
                                        .filter(
                                          (topServing) =>
                                            topServing?.serving_name ==
                                            element?.serving_name
                                        )
                                        .map((e) => {
                                          return {
                                            price: e?.price ? e?.price?.toString() : "0",
                                            serving_name: e?.serving_name
                                              ? e.serving_name
                                              : element.serving_name,
                                          };
                                        })[0];
                                    singleTopping.selected_modifier_serving =
                                      selected_modifier_serving
                                        ? selected_modifier_serving
                                        : {
                                          price: "0",
                                          serving_name: element.serving_name,
                                        };

                                    singleTopping.price =
                                      singleTopping?.selected_modifier_serving?.price;
                                      singleTopping.modifier_price=singleTopping?.selected_modifier_serving?.price;
                                      singleTopping.modifier_prefix_name=0;
                                    x.push(singleTopping);
                                  });
                                  e.option = x;
                                }
                              }
                            });
                          return element.order_details.some((f) => {
                            return f.modifier_group_name === e.modifier_group_name;
                          });
                        }),
                      };
                      let modifierDetail = [];
                 let isRequiredChecked = false;
                      result?.customizationOpt.forEach((element,index)=>{
                        let selectedCustomizationOption = cartDetail.selectedOptions.filter((ele)=>element.modifier_group_id  == ele.modifier_group_id);
                        if(selectedCustomizationOption.length == 0)
                        {
                          if(element.is_required)
                          {
                            isRequiredChecked = true;
                            modifierDetail.push(element.modifier_group_name)
                          }
                        }
                      })
                      if(isRequiredChecked)
                      {
                        toast.error(
                          `${modifierDetail[0]} modifier is reqired for this item`,
                          {
                            position: "bottom-left",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                          }
                        );
                        setButtonLoader(false);
                      }
                      else
                      {
                      let itemAddedToCartStatus =  hadleAddToCart()
                      // setSnackBarTitle(cartDetail.title);
                      // setOpenSnackBar(true);
                      if(itemAddedToCartStatus){
                      toast.success(`${cartDetail.title} Added to the cart`, {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "dark"
                      });
                      setButtonLoader(false);
                    }else{
                   
                    }
                  }
                    })
                    .catch((err) => {
                      setButtonLoader(false);
                      console.log(err.message)
                    });
                });
              } else {
                orders.map((singleOrder) => {
                  let data = {
                    appType: "web",
                    customerId: userDetail?.customer_id,
                    menuId: singleOrder.menu_id,
                    storeId: store.id,
                  };
                  getCustomizationOption(data)
                    .then((res) => {
                      let result = res.result[0][0];
                      cartDetail = {
                        id: singleOrder.menu_id,
                        categoryId: result.category_id,
                        customizationOpt: result.customizationOpt
                          ? result.customizationOpt
                          : [],
                        quantity: Number(singleOrder?.quantity_purchased),
                        // quantity:1,
                        price: result?.price?.toString(),
                        image: result.image,
                        title: result.title,
                        servingName: singleOrder?.serving_name,
                        totalPrice:
                          singleOrder.item_customized_price *
                          singleOrder?.quantity_purchased,
                        btnModifierId: [],
                        btnModifier: [],
                        menuServingDetail: result?.menu_serving_detail,
                        selectedCupSize: result?.menu_serving_detail.filter(
                          (e) => e?.serving_name == singleOrder?.serving_name
                        ),
                        orderItemPrice:
                          Number(singleOrder?.item_customized_price/singleOrder?.quantity_purchased),
                        selectedOptions: result?.customizationOpt?.filter((e, index) => {
                          singleOrder.order_details
                            .filter((cup) => cup.modifier_group_name != "Cup Size")
                            .map((modifiers) => {
                              if (
                                modifiers.modifier_group_name == e.modifier_group_name
                              ) {
                                if (modifiers.option.length == 0) {
                                  e.option = e.option
                                    .filter(
                                      (singleModi) =>
                                        singleModi.modifier_id ==
                                        modifiers.option[0].modifier_id
                                    )
                                    .map((change) => {
                                      change.modifier_price =
                                        change.modifier_group_description
                                          .filter(
                                            (servPrice) =>
                                              servPrice?.serving_name ==
                                              singleOrder?.serving_name
                                          )[0]
                                          ?.price?.toString();
                                      change.quantity = 1;
                                      change.selected_modifier_serving =
                                        change.modifier_group_description
                                          .filter(
                                            (serving) =>
                                              serving?.serving_name ==
                                              singleOrder?.serving_name
                                          )
                                          .map((ele) => {
                                            return {
                                              price: ele?.price?.toString(),
                                              serving_name: ele?.serving_name,
                                            };
                                          })[0];
                                      return change;
                                    });
                                } else if (modifiers.option.length >= 1) {
                                  let x = [];
                                  modifiers.option.forEach((toppings) => {
                                    let singleTopping = e.option.filter(
                                      (newStoreTopping) =>
                                        Number(newStoreTopping.modifier_id) ==
                                        Number(toppings.modifier_id)&&newStoreTopping.modifier_group_description!=""

                                    )[0];
                                    if(singleTopping){
                                      singleTopping.quantity = toppings.quantity
                                    }else{
                                      setButtonLoader(false);
                                      toast.error(`${toppings.modifier_name
                                      } ${e.modifier_group_name} is not present for this item`, {
                                        position: "bottom-left",
                                        autoClose: 2000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: false,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "dark"
                                      });
                                    }
                                    let selected_modifier_serving =
                                      singleTopping?.modifier_group_description
                                        .filter(
                                          (topServing) =>
                                            topServing?.serving_name ==
                                            singleOrder?.serving_name
                                        )
                                        .map((e) => {
                                          return {
                                            price: e?.price ? e?.price?.toString() : "0",
                                            serving_name: e?.serving_name
                                              ? e.serving_name
                                              : singleOrder.serving_name,
                                          };
                                        })[0];
                                    singleTopping.selected_modifier_serving =
                                      selected_modifier_serving
                                        ? selected_modifier_serving
                                        : {
                                          price: "0",
                                          serving_name: singleOrder.serving_name,
                                        };

                                    singleTopping.price =
                                      singleTopping?.selected_modifier_serving?.price;
                                      singleTopping.modifier_price=singleTopping?.selected_modifier_serving?.price;
                                      singleTopping.modifier_prefix_name=0;
                                    x.push(singleTopping);
                                  });
                                  e.option = x;
                                }
                              }
                            });
                          return singleOrder.order_details.some((f) => {
                            return f.modifier_group_name === e.modifier_group_name;
                          });
                        }),
                      };
                      let modfier_group_price = 0;
                      cartDetail.selectedOptions.map((e) => {
                        e?.option?.map((toppingPrice) => {
                          modfier_group_price += isNaN(Number(toppingPrice?.price || toppingPrice?.modifier_price)*toppingPrice?.quantity) ? 0 : Number((toppingPrice?.price || toppingPrice?.modifier_price)*toppingPrice?.quantity)
                        });
                      });
                      cartDetail.orderItemPrice =
                        cartDetail.selectedCupSize[0]?.price + modfier_group_price;
                      cartDetail.totalPrice =
                        cartDetail.orderItemPrice * cartDetail.quantity;
                        let modifierDetail = [];
                        let isRequiredChecked = false;
                             result?.customizationOpt.forEach((element,index)=>{
                               let selectedCustomizationOption = cartDetail.selectedOptions.filter((ele)=>element.modifier_group_id  == ele.modifier_group_id);
                               if(selectedCustomizationOption.length == 0)
                               {
                                 if(element.is_required)
                                 {
                                   isRequiredChecked = true;
                                   modifierDetail.push(element.modifier_group_name)
                                 }
                               }
                             })
                             if(isRequiredChecked)
                             {
                               toast.error(
                                 `${modifierDetail[0]} modifier is reqired for this item`,
                                 {
                                   position: "bottom-left",
                                   autoClose: 1000,
                                   hideProgressBar: true,
                                   closeOnClick: true,
                                   pauseOnHover: false,
                                   draggable: true,
                                   progress: undefined,
                                   theme: "dark",
                                 }
                               );
                               setButtonLoader(false);
                             }
                             else
                             {
                      let itemAddedToCartStatus =  hadleAddToCart()
                      // setSnackBarTitle(cartDetail.title);
                      // setOpenSnackBar(true);
                      if(itemAddedToCartStatus){
                      toast.success(`${cartDetail.title} Added to the cart`, {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "dark"
                      });
                      setButtonLoader(false);
                    }else{
                   
                    }
                  }
                    })
                    .catch((err) => {
                      setButtonLoader(false);
                      console.log(err.message)
                    });
                });
              }
            }
            else if(indexes == orders?.length - 1){
                // toast.error("This Order is not available for selected storep", {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
              // setButtonLoader(false);
            }
          }else{
            toast.error(`This ${element.menu_name} is not available for selected store`,{
              position: toast.POSITION.TOP_RIGHT,
            });
            setButtonLoader(false);
          }
          })
        } else {
          if (catBool) {
            toast.error("This Order is not available for selected store", {
              position: toast.POSITION.TOP_RIGHT,
            });
            catBool = false;
          }
          setButtonLoader(false);
        }
      }).catch((e) => {
        setButtonLoader(false);
        console.log(e)
      })
    })
  };
  const hadleAddToCart = () => {
    let arr = [];
    arr.push(cartDetail);
    // setButtonLoader(true);
    if(cartDetail.selectedCupSize.length >0 && Object.keys(cartDetail.selectedCupSize[0]).length != 0){
    let cartItemCheck = cartItem.filter(
      (e) =>
        e.id == cartDetail.id &&
        e.selectedOptions.length == cartDetail.selectedOptions.length &&
        e.selectedCupSize[0].serving_name == cartDetail?.servingName &&
        e.selectedOptions.filter((singleOption) =>
          cartDetail.selectedOptions.find(
            (e) =>
              e?.option[0]?.modifier_id == singleOption?.option[0]?.modifier_id
          )
        ).length == cartDetail.selectedOptions.length
    );
    let sameCustomization = cartItemCheck[0]?.selectedOptions?.filter(
      (options, indexOfOption) =>
        cartDetail.selectedOptions.find((modi) => {
          if (modi.option.length > 1) {
            let sameToppings = modi.option.filter((e) =>
              options.option.find(
                (element) => element.modifier_id == e.modifier_id
              )
            );
            if (sameToppings.length == modi.option.length) {
              return true;
            } else {
              return false;
            }
          } else {
            return modi.option[0]?.modifier_id == options.option[0]?.modifier_id;
          }
        })
    );
    index = cartItem?.findIndex(
      (item) =>
        item.id === cartDetail.id && item.categoryId === cartDetail.categoryId
    );
    if (
      !sameCustomization ||
      sameCustomization?.length != cartDetail.selectedOptions?.length
    ) {
      if (cartDetail?.quantity_purchased !== 0) {
        dispatch(setCart(arr));
        setButtonLoader(false);
        return true
      }
    } else {
      const item = cartDetail;
      const updatedCart = [...cartItem];
      let obj = Object.assign({}, updatedCart[index]);
      obj.quantity += item.quantity;
      updatedCart?.splice(index, 1, obj);
      dispatch(setUpdateCart(updatedCart));
      setButtonLoader(false);
      return true
    }
  }else{
    toast.error(`${cartDetail.servingName} serving is not present for ${cartDetail?.title}`, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark"
    });
    setButtonLoader(false);
    return false
  }
  };
  useEffect(() => {
    if (!remove && reorderClicked) {
      handleReorder(reorderClicked.ordermenu, reorderClicked);
    }
  }, [remove]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleViewMore = () => {
    setViewMoreLoader(true);
    let pageForData = page + 1; 
    setPage(pageForData);
    // console.log(page<totalRecord-1,"check")
    getAllOrderByCustomersec(data, pageForData, 5, "created_at")
      .then((res) => {
        let isDataExist = res?.result?.length > 0;
        setDataAvailability(isDataExist);
        let dataForViewMore = [...menu, ...res?.result];
        // setMenu(dataForViewMore);
        setPreviousOrderMenu(dataForViewMore)
        setTotalRecord(Math.ceil(res?.totalRecord / 5));
        setViewMoreLoader(false);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setViewMoreLoader(false);
        // setLoading(false);
      });
  }

  return loading == true ? (
    <Backdrop
    sx={{ color: '#fff', zIndex: 1000}}
    open={loading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
  ) :

  previousOrderMenu?.length == 0 ? (
      <Typography
        variant="h6"
        sx={{
          fontSize: "3rem",
          textAlign: "center",
          marginTop: "3rem",
          color: "#868686",
        }}
      >
        No Previous Order Available
      </Typography>
    ) : (
      <>
        <Box 
          sx={{
        paddingLeft: {lg:"13vw", xs:"3vw"},
        paddingRight: {lg:"13vw", xs:"3vw"},
        paddingTop:"3vh",}}
        >
          {previousOrderMenu?.map((element, index) => {
            console.log(element,"rrrrrrr");
            return (
             
                <Card style={{borderRadius:"10px", marginBottom:"3rem"}}>
                <div style={{backgroundColor:"#D9D9D9", display:"flex", alignItems:"center", justifyContent:"space-between", padding:"0.3rem 1rem"}}>
                        <Typography
                        >
                        {uiDate(element?.pickup_date)}
                        </Typography>
                      <Button
                          variant="contained"
                          onClick={() => {
                            setIndexState(index)
                            if (store) {
                              setTitle("");
                              setStoreName(element.store_name);
                              if (store.id == element.store_id) {
                                // handleReorder(element?.ordermenu, element);
                                // console.log(store.id,element.id,element.store_id,"element.store_idelement.store_id")
                                getCustomerOrderById(element.id,"all").then((res=>{
                                  let propss = res.result[0]
                                  handleReorder(propss?.ordermenu, propss);
                                }))
                              } else {
                                setDisplayTitle(
                                  `Your previous order is from ${element.store_name} store , reordering it from ${store?.store_name} store may incorporate changes in its  price`
                                );
                                setRemove(true);
                                // setReorderClicked(element);
                                getCustomerOrderById(element.id,"all").then((res=>{
                                  let propss = res.result[0]
                                  setReorderClicked(propss);
                                }))
                              }
                            } else {
                              setState(true)
                              // setTitle("noReorder");
                              // setDisplayTitle(
                              //   "To reorder please select your nearest store"
                              // );
                              
                            }
                          }}
                          style={{
                            color: "black",
                            textTransform: "none",
                            backgroundColor:"#D9D9D9",
                            padding:"0.1rem 1.7rem",
                            fontWeight:"550",
                          }}
                        >
                          { (buttonLoader && index == indexState)  ? <ButtonLoader props={"3rem"}/> : (element?.ordermenu?.length > 1)? `Reorder All` : `Reorder`}
                        </Button>
                        </div>
                    <div >
                      {element?.ordermenu.map((e,index) => {
                        console.log(e,"eeeeeee");
                        return (
                         <>
                            <PreviousOrderCard
                              key={Number(e.menu_id)}
                              props={e}
                              orderId={element.id}
                              storeId={element.store_id}
                              storeName={element.store_name}
                              setState={setState}
                              is_Item_fav={element.ordermenu[index].is_favourite}
                              selectOrderItemIndex={index}
                              setFavorite={setFavorite}
                              getFavorite={getFavorite}
                              // setFav = {setFav}
                              // fav= {fav}
                            />
                            <Divider variant="middle" />
                            </>
                        );
                      })}
                    </div>
                  
                </Card>
             
            );
          })}
          {dataAvailability ?
            viewMoreLoader ? <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "3vh 0",
              }}
            > <CircularProgress /> </div> :
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "3vh 0",
                }}
              >
                {/* <Pagination
            count={totalRecord}
            page={page}
            disabled={totalRecord == 1 ? true : false}
            showFirstButton
            showLastButton
            onChange={handleChange}
            color="secondary"
          /> */}
           {page<totalRecord-1?
               ( <Button
                  sx={{ padding: { xs: "0.2rem 4rem", sm: "0.2rem 5rem", md: "0.2rem 5rem", lg: "0.2rem 5rem" },
                marginBottom:{xs:"3.5rem",sm: "0rem", md: ".5rem", lg: ".5rem"} }}
                  className={classes.btnLogin}
                  variant="contained"
                  onClick={handleViewMore}
                >
                  View More
                </Button>):(<div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "3vh 0",
            }}
          >No more data to display
          </div>)
           }
              </div>
            : ""}
          {dataAvailability == false ? <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "3vh 0",
            }}
          >No more data to display
          </div> : ""}
        </Box>

        <AlertPopup
          open={remove}
          setOpen={setRemove}
          setState={setReorderClicked}
          title={title ? title : "previousOrder"}
          displayTitle={displayTitle}
          // setCounter = {setTitle}
          buttonType1={title ? false : true}
          buttonType2={true}
        />
        {openSnackBar && <SnackbarPopUp
          key={cartDetail.id}
          open={openSnackBar}
          setopenpopup={setOpenSnackBar}
          messageInfo={`${snackBarTitle} Added to the cart`}
        />}
      </>
    );
};

export default PreviousOrder;
