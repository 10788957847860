import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Box, Backdrop, Avatar, Grid } from "@mui/material";
import { Card as MyCard } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ShowMoreText from "react-show-more-text";
import Popup from "../pages/Categories/CategoryPopUp";
import { setCart, setCartDetailes, setCustomizeOptions, setMenuServingDetails, setSelectedCupSizes, setOpenPopUps, setUpdateCart, setTitles,setEditTitles, setBestSellerCheck,setCheckBestSellerId, setTabValue} from "../Redux/CartSlice";
import { setLocationPopup } from "../Redux/StoreSlice";
import { getCustomizationOption, GetAllCategories, GetAllMenu } from "../Api/API";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SnackbarPopUp from "../Common/SnackBar";
import { Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { favoriteUnfavoriteMenu } from "../Api/API";
import { setFav } from "../Redux/StoreSlice";
import ButtonLoader from "./ButtonLoader";
import AlertPopup from "./AlertPopup";
// import {withStyles} from "@mui/material";
import { errorToastMessage } from "./utils";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

toast.configure();
// const useStyles = makeStyles({
//   item: {
//     boxShadow: "0 6px 20px 0 #dbdbe8",
//     transition: "transform 0.15s ease-in-out",
//     "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
//     // border:"1px solid #9F9F9F"
//   },
//   imgTran: {
//     // filter: "drop-shadow(0 5px 0 #E7E7E7)",
//     width: "25%",
//     objectFit: "contain",
//     // maxHeight:"25%",
//     transition: "transform 0.15s ease-in-out",
//     // "&:hover": { transform: "scale3d(1.15, 1.15, )" },
//   },
//   //  tooltip: {
//   //     color: "black",
//   //     backgroundColor: "transparent"
//   //     } 
//   root: {
//     "& .MuiAvatar-img": {
//       width: "auto",
//       // height: "inherit"
//     }
//   }
// });

// const BlueOnGreenTooltip = withStyles({
//   tooltip: {
//   color: "lightblue",
//   backgroundColor: "green"
//   }
//   })(Tooltip);
const Card = ({ bestSeller, setState, state, props, fav, setFavItem, categoryId, categoryName, favItem, min_calories, max_calories, menuId, favItemsDetail, setLoading }) => {
  const useStyles = makeStyles({
    item: {
      boxShadow: "0 6px 20px 0 #dbdbe8",
      transition: "transform 0.15s ease-in-out",
       
      "&:hover":( bestSeller && props?.menuExists == false) ?"": { transform: "scale3d(1.05, 1.05, 1)" },
      // border:"1px solid #9F9F9F"
    },
    favItem: {
      boxShadow: "0 6px 20px 0 #dbdbe8",
      transition: "transform 0.15s ease-in-out",
       
      "&:hover":(favItemsDetail?.menuExists == false) ?"": { transform: "scale3d(1.05, 1.05, 1)" },
      // border:"1px solid #9F9F9F"
    },
    imgTran: {
      // filter: "drop-shadow(0 5px 0 #E7E7E7)",
      width: "25%",
      objectFit: "contain",
      // maxHeight:"25%",
      transition: "transform 0.15s ease-in-out",
      "&:hover": ( bestSeller && props?.menuExists == false) ? "": { transform: "scale3d(1.15, 1.15, 1)" },
    },
    favImgTran: {
      // filter: "drop-shadow(0 5px 0 #E7E7E7)",
      width: "25%",
      objectFit: "contain",
      // maxHeight:"25%",
      transition: "transform 0.15s ease-in-out",
      "&:hover": (favItemsDetail?.menuExists == false) ? "": { transform: "scale3d(1.15, 1.15, 1)" },
    },
    //  tooltip: {
    //     color: "black",
    //     backgroundColor: "transparent"
    //     } 
    root: {
      "& .MuiAvatar-img": {
        width: "auto",
        // height: "inherit"
      }
    }
  });
  const styles = useStyles();
  let navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [catCustomize, setCatCustomize] = useState([]);
  const userDetail = useSelector((state) => state.user.user);
  const nearestStore = useSelector((state) => state.store.nearestStore);
  const [customizeOption, setCustomizeOption] = useState([]);
  const [menuServingDetail, setmenuServingDetail] = useState();
  const [counter, setCounter] = useState(1);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [flag, setFlag] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const cartItem = useSelector((state) => state.cart.cart);
  const [selectedCupSize, setSelectedCupSize] = useState();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const [removeFav, setRemoveFav] = useState(false);
  const [favState, setFavState] = useState(false);
  const store = useSelector((state) => state.store.nearestStore);
  const checkBestSellerId = useSelector((state)=> state.cart.checkBestSellerId)
  const [cartDetailState, setCartDetailState] = useState()
  const [ checkBestSeller, setCheckBestSeller] = useState()
  const [selectedFav, setSelectedFav] = useState()
  
  const onClick = () => {
    setExpand(!expand);
  };
  const indexs = cartItem?.findIndex(
    (item) => item?.id === (menuId ? menuId : props?.id) && item?.categoryId === (categoryId ? categoryId : props.category_id)
  );
  const handleIncrement = () => {
    setCounter(counter + 1);
    setFlag(false);
  };

  const handleDecrement = () => {
    if (counter - 1 > 0) {
      setCounter(counter - 1);
    }
    setFlag(false);
  };

  const handleStorePopUp = () => {
    // if (nearestStore?.address == null || nearestStore?.address == "") {
    //   dispatch(setLocationPopup(true));
    // }
  };
  let cartDetail = {
    id: fav ? favItemsDetail?.menu_id : props?.id,
    categoryId: fav ? favItemsDetail?.category_id : categoryId ? categoryId : props?.category_id,
    customizationOpt: customizeOption,
    quantity: counter,
    price: props?.price,
    image: props?.image,
    title: props?.title,
    categoryName: fav ? favItemsDetail?.menu_details?.category_name : categoryName ? categoryName : props?.category_name,
    totalPrice: props?.price * counter,
    btnModifierId: [],
    btnModifier: [],
    custom: [],
    selectedCupSize: fav ? [favItemsDetail?.menu_details?.menu_serving_detail[0]] : [],
    menuServingDetail: fav ? favItemsDetail?.menu_serving_detail : [],
    orderItemPrice: fav ? favItemsDetail?.order_item_price : "",
    selectedOptions: fav ? favItemsDetail?.selectedOption : [],
    cold: false,
    caffine: false,
    max_calories: props?.max_calories,
    min_calories: props?.min_calories,
    is_favourite: 0,
    description:props?.description
  };
  let data = {
    customerId: userDetail?.customer_id
      ? userDetail?.customer_id
      : userDetail?.person_id,
    menuId: menuId ? menuId : props?.id,
    storeId: store?.id ? store?.id : 1,
    appType: "web",
  };

  const handelfavOrder = () => {
    if(store.id){
      let data = {
        appType: "web",
        customerId: userDetail?.customer_id,
        menuId: favItemsDetail.menu_id,
        storeId: store.id,
      };
      setButtonLoader(true);
      getCustomizationOption(data)
        .then((res) => {
          let result = res.result[0][0];
          let modifierDetail = [];
          let isRequiredChecked = false;
          cartDetail.customizationOpt=result?.customizationOpt
          result?.customizationOpt.forEach((element,index)=>{
            let selectedCustomizationOption = cartDetail.selectedOptions.filter((ele)=>element.modifier_group_id  == ele.modifier_group_id);
            if(selectedCustomizationOption.length == 0)
            {
              if(element.is_required)
              {
                isRequiredChecked = true;
                modifierDetail.push(element.modifier_group_name)
              }
            }
          })
  
          if(isRequiredChecked)
          {
            toast.error(
              `${modifierDetail[0]} modifier is reqired for this item`,
              {
                position: "bottom-left",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              }
            );
            setButtonLoader(false);
          }
          else
          {
           cartDetail.selectedOptions= result?.customizationOpt?.filter((e, index) => {
                          // is_required =
             cartDetail.selectedOptions
               .filter((cup) => cup.modifier_group_name != "Cup Size")
               ?.map((modifiers) => {
                 if (modifiers.modifier_group_name == e.modifier_group_name) {
                   if (modifiers.option.length >= 1) {
                     let x = [];
                     modifiers.option.forEach((toppings) => {
                       let singleTopping = e?.option.filter(
                         (newStoreTopping) =>
                           Number(newStoreTopping.modifier_id) ==
                           Number(toppings.modifier_id)&&newStoreTopping.modifier_group_description!=""
                       )[0];
                       if (singleTopping) {
                         singleTopping.quantity = toppings.quantity;
                       } else {
                         setButtonLoader(false);
                         toast.error(
                           `${toppings.modifier_name} ${e.modifier_group_name} is not present for this item`,
                           {
                             position: "bottom-left",
                             autoClose: 1000,
                             hideProgressBar: true,
                             closeOnClick: true,
                             pauseOnHover: false,
                             draggable: true,
                             progress: undefined,
                             theme: "dark",
                           }
                         );
                         setButtonLoader(false);
                       }
   
                       let selected_modifier_serving =
                         singleTopping?.modifier_group_description
   
                           .filter(
                             (topServing) =>
                               topServing?.serving_name == cartDetail?.selectedCupSize[0].serving_name
                           )
                           .map((e) => {
                             return {
                               price: e?.price ? e?.price?.toString() : "0",
                               serving_name: e?.serving_name
                                 ? e.serving_name
                                 : cartDetail?.selectedCupSize[0].serving_name,
                             };
                           })[0];
                       singleTopping.selected_modifier_serving =
                         selected_modifier_serving
                           ? selected_modifier_serving
                           : { price: "0", serving_name: cartDetail?.selectedCupSize[0].serving_name };
                       singleTopping.price =
                         singleTopping?.selected_modifier_serving?.price;
                         singleTopping.modifier_price=singleTopping?.selected_modifier_serving?.price;
                         singleTopping.modifier_prefix_name=0;
                       x.push(singleTopping);
                     });
                     let isRequired = e.is_required;
                     if(isRequired && x.length==0)
                     {
                         console.log("someitem is missing")
                     }
                     else
                     {
                       e.option = x;
                     }
                    
                   }
                 }
               });
             return cartDetail?.selectedOptions?.some((f) => {
               return f.modifier_group_name === e.modifier_group_name;
             });
           });
           let modfier_group_price = 0
           cartDetail.selectedOptions.map((e) => {     
             e?.option?.map((toppingPrice) => {
               modfier_group_price += isNaN(Number(toppingPrice?.price || toppingPrice.modifier_price)*toppingPrice.quantity) ? 0 : Number((toppingPrice?.price || toppingPrice.modifier_price)*toppingPrice.quantity)
     
             })
           })
           cartDetail.orderItemPrice = cartDetail.selectedCupSize[0]?.price + modfier_group_price
           cartDetail.totalPrice = cartDetail.orderItemPrice * cartDetail.quantity
           let itemAddedToCartStatus = hadleAddToCart();
           if (itemAddedToCartStatus) {
             toast.success(`${cartDetail.title} Added to the cart`, {
               position: "bottom-left",
               autoClose: 1000,
               hideProgressBar: true,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
               theme: "dark",
             });
             setButtonLoader(false);
           } else {
           }
          }
        })
  
        .catch((err) => {
          setButtonLoader(false);
          console.log(err);
        });
    }else{
      setState(true)
    }
    
  };
  const hadleAddToCart = () => {
    setSelectedFav(cartDetail);
    if (store?.id) {
      let arr = [];
      let cartItemindex;
      arr.push(cartDetail);
      let selectedItemCupSize = favItemsDetail?.menu_serving_detail.filter(
        (e) => e?.serving_name == cartDetail?.selectedCupSize[0].serving_name
      );
      if (selectedItemCupSize.length > 0) {
        let cartItemCheck = cartItem.filter((e, index) => {
          if (
            e.id == cartDetail.id &&
            e.selectedOptions.length == cartDetail.selectedOptions.length &&
            // e.selectedCupSize[0].serving_name == menuServingDetail[0]?.serving_name &&
            e.selectedOptions.filter((singleOption) =>
              cartDetail.selectedOptions.find(
                (e) =>
                  e.option[0].modifier_id == singleOption.option[0].modifier_id
              )
            ).length == cartDetail.selectedOptions.length
          ) {
            cartItemindex = index;
            return true;
          }
        });
        let sameCustomization = cartItemCheck[0]?.selectedOptions?.filter(
          (options, indexOfOption) =>
            cartDetail.selectedOptions.find((modi) => {
              if (modi.option.length > 1) {
                let sameToppings = modi.option.filter((e) =>
                  options.option.find(
                    (element) => element.modifier_id == e.modifier_id
                  )
                );
                if (sameToppings.length == modi.option.length) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return (
                  modi.option[0].modifier_id == options.option[0].modifier_id
                );
              }
            })
        );

        if (
          !sameCustomization ||
          sameCustomization?.length != cartDetail.selectedOptions?.length
        ) {
          if (counter !== 0) {
            dispatch(setCart(arr));
            // setOpenSnackBar(true);
            setButtonLoader(false);
            toast.success(`${cartDetail.title} Added to the cart`, {
              position: "bottom-left",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              theme: "dark",
            });
          }
        } else {
          const item = cartDetail;
          const updatedCart = [...cartItem];
          let obj = Object.assign({}, updatedCart[cartItemindex]);
          obj.quantity += counter;
          updatedCart?.splice(cartItemindex, 1, obj);
          dispatch(setUpdateCart(updatedCart));
          // setOpenSnackBar(true);
          setButtonLoader(false);
          toast.success(`${cartDetail.title} Added to the cart`, {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "dark",
          });
        }
        setSelectedFav("");
      } else {
        toast.error(
          `${cartDetail.selectedCupSize[0].serving_name} serving is not present for ${cartDetail?.title}`,
          {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        setButtonLoader(false);
        return false;
      }
    } else {
      setState(true);
    }
  };
  useEffect(() => {
    if (selectedFav) {
      // setButtonLoader(true);
      let catData = {
        storeId: nearestStore?.id,
        appType: "web",
      };
      GetAllCategories(catData)
        .then((res) => {
          let categoryExist = res?.result?.filter(
            (element) =>
              Number(element?.category_id) == Number(selectedFav?.categoryId)
          );
          if (categoryExist?.length > 0) {
            let menuItem = {
              appType: "web",
              categoryId: selectedFav?.categoryId,
              customerId: 0,
              requestDate: "",
              storeId: nearestStore?.id,
            };
            GetAllMenu(menuItem)
              .then((res) => {
                let menuExist = res?.result.filter(
                  (element) => Number(element?.id) == Number(selectedFav?.id)
                );
                if (menuExist.length > 0) {
                  let arr = [];
                  let cartItemindex;
                  arr.push(selectedFav);
                  let cartItemCheck = cartItem.filter((e, index) => {
                    if (
                      e.id == selectedFav.id &&
                      e.selectedOptions.length ==
                        selectedFav.selectedOptions.length &&
                      // e.selectedCupSize[0].serving_name == menuServingDetail[0]?.serving_name &&
                      e.selectedOptions.filter((singleOption) =>
                        selectedFav.selectedOptions.find(
                          (e) =>
                            e.option[0].modifier_id ==
                            singleOption.option[0].modifier_id
                        )
                      ).length == selectedFav.selectedOptions.length
                    ) {
                      cartItemindex = index;
                      return true;
                    }
                  });
                  let sameCustomization =
                    cartItemCheck[0]?.selectedOptions?.filter(
                      (options, indexOfOption) =>
                        selectedFav.selectedOptions.find((modi) => {
                          if (modi.option.length > 1) {
                            let sameToppings = modi.option.filter((e) =>
                              options.option.find(
                                (element) =>
                                  element.modifier_id == e.modifier_id
                              )
                            );
                            if (sameToppings.length == modi.option.length) {
                              return true;
                            } else {
                              return false;
                            }
                          } else {
                            return (
                              modi.option[0].modifier_id ==
                              options.option[0].modifier_id
                            );
                          }
                        })
                    );

                  if (
                    !sameCustomization ||
                    sameCustomization?.length !=
                      selectedFav.selectedOptions?.length
                  ) {
                    if (counter !== 0) {
                      dispatch(setCart(arr));
                      // setOpenSnackBar(true);
                      setButtonLoader(false);
                      toast.success(`${selectedFav.title} Added to the cart`, {
                        position: "bottom-left",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        theme: "dark",
                      });
                    }
                  } else {
                    const item = selectedFav;
                    const updatedCart = [...cartItem];
                    let obj = Object.assign({}, updatedCart[cartItemindex]);
                    obj.quantity += counter;
                    updatedCart?.splice(cartItemindex, 1, obj);
                    dispatch(setUpdateCart(updatedCart));
                    // setOpenSnackBar(true);
                    setButtonLoader(false);
                    toast.success(`${selectedFav.title} Added to the cart`, {
                      position: "bottom-left",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      theme: "dark",
                    });
                  }
                  setSelectedFav("");
                } else {
                  toast.error(
                    `This ${selectedFav.categoryName} is not available for selected store`,
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                  setButtonLoader(false);
                  setSelectedFav("");
                }
              })
              .catch((e) => {
                setButtonLoader(false);
                console.log(e);
              });
          } else {
            toast.error(
              `This ${selectedFav.title} is not available for selected store`,
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
            setLoading(false);
            setSelectedFav("");
          }
        })
        .catch((e) => {
          setButtonLoader(false);
          console.log(e);
        });
    }
  }, [store?.id]);

  const handleCustomization = (favorite) => {
    if (bestSeller) {
      // ((props.menuExists==null) || (props.menuExists==true))
      if (props.menuExists != false) {
        setCheckBestSeller(props);
        dispatch(setCheckBestSellerId(props));
        if (store?.id) {
          setLoading(true);
          let catData = {
            storeId: store?.id,
            appType: "web",
          };
          GetAllCategories(catData)
            .then((res) => {
              let categoryExist = res?.result?.filter(
                (element) =>
                  Number(element?.category_id) == Number(props?.category_id)
              );
              if (categoryExist?.length > 0) {
                let menuItem = {
                  appType: "web",
                  categoryId: props?.category_id,
                  customerId: 0,
                  requestDate: "",
                  storeId: store?.id,
                };
                GetAllMenu(menuItem).then((res) => {
                  let menuExist = res?.result.filter(
                    (element) => Number(element?.id) == Number(props?.id)
                  );
                  if (menuExist.length > 0) {
                    getCustomizationOption(data).then((res) => {
                      if (res.result && res) {
                        setLoading(false);

                        cartDetail.customizationOpt =
                          res?.result[0][0]?.customizationOpt;
                        cartDetail.cold = res?.result[0][0]?.cold;
                        cartDetail.caffine = res?.result[0][0]?.caffeine;
                        cartDetail.max_calories =
                          res?.result[0][0]?.max_calories;
                        cartDetail.min_calories =
                          res?.result[0][0]?.min_calories;
                        cartDetail.is_favourite =
                          res?.result[0][0]?.is_favourite;
                        cartDetail.selectedCupSize = fav
                          ? [
                              favItemsDetail?.menu_details
                                ?.menu_serving_detail[0],
                            ]
                          : [res?.result[0][0]?.menu_serving_detail[0]];
                        dispatch(
                          setSelectedCupSizes(
                            cartDetail?.selectedCupSize?.length > 1
                              ? cartDetail?.selectedCupSize?.filter(
                                  (e) => e.tier_id > 0
                                )
                              : cartDetail?.selectedCupSize
                          )
                        );
                        // cartDetail.customizationOpt(res?.result[0][0]?.customizationOpt);
                        cartDetail.menuServingDetail =
                          res?.result[0][0]?.menu_serving_detail;
                          cartDetail.description=res?.result[0][0].description
                        setCartDetailState(cartDetail);
                        dispatch(setCartDetailes(cartDetail));
                        dispatch(
                          setCustomizeOptions(cartDetail.customizationOpt)
                        );
                        dispatch(
                          setMenuServingDetails(cartDetail.menuServingDetail)
                        );
                        dispatch(setOpenPopUps(true));
                        dispatch(setBestSellerCheck(true));
                        dispatch(setTitles(""));
                        dispatch(setEditTitles(""));
                        // setCheckBestSeller("")
                        dispatch(setCheckBestSellerId(""));
                        dispatch(setTabValue(0));
                        navigate(`/customization`);
                      }
                    });
                  } else {
                    toast.error(
                      `This ${props.title} is not available for selected store`,
                      {
                        position: toast.POSITION.TOP_RIGHT,
                      }
                    );
                    setLoading(false);
                  }
                });
              } else {
                toast.error(
                  `This ${props.title} is not available for selected store`,
                  {
                    position: toast.POSITION.TOP_RIGHT,
                  }
                );
                setLoading(false);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          // setOpenAlert(true)
          setState(true);
        }
      } else {
        // console.log("not exist");
      }
    } else {
      if (store?.id) {
      setLoading(true);
      getCustomizationOption(data)
        .then((res) => {
          // console.log(favItemsDetail,"favItemsDetail")
          let servingName = '';
          if(fav){
              servingName = cartDetail.selectedCupSize[0].serving_name;
          }
          //  let ss = res?.result[0][0].menu_serving_detail.filter((e)=>e.serving_name==cartDetail.selectedCupSize[0].serving_name)
          if (res.result && res) {
            setLoading(false);
            cartDetail.customizationOpt = res?.result[0][0]?.customizationOpt;
            cartDetail.cold = res?.result[0][0]?.cold;
            cartDetail.caffine = res?.result[0][0]?.caffeine;
            cartDetail.max_calories = res?.result[0][0]?.max_calories;
            cartDetail.min_calories = res?.result[0][0]?.min_calories;
            cartDetail.is_favourite = res?.result[0][0]?.is_favourite;
            cartDetail.selectedCupSize = fav ? (res?.result[0][0].menu_serving_detail.filter((e)=>e.serving_name==cartDetail.selectedCupSize[0].serving_name)[0]?res?.result[0][0].menu_serving_detail.filter((e)=>e.serving_name==cartDetail.selectedCupSize[0].serving_name):'') :
              [res?.result[0][0]?.menu_serving_detail[0]];
             cartDetail.description= res?.result[0][0]?.description;
              if(fav){
                dispatch(setSelectedCupSizes(""));
                dispatch(setTitles(""))
                dispatch(setEditTitles("FromCart"));
              }
              else{
            dispatch(setSelectedCupSizes(
            cartDetail?.selectedCupSize?.length > 1
            ? cartDetail?.selectedCupSize?.filter((e) => e.tier_id > 0)
            : cartDetail?.selectedCupSize
            ));
            dispatch(setTitles(""))
            dispatch(setEditTitles(""));
            
          }
            // ));}
            // cartDetail.customizationOpt(res?.result[0][0]?.customizationOpt);
            cartDetail.menuServingDetail = res?.result[0][0]?.menu_serving_detail
            cartDetail.selectedOptions = favorite ? favorite?.selectedOption : []
            if(cartDetail.selectedCupSize[0]==null){
              toast.error(
                `${servingName} serving size is not available for selected store`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setLoading(false);
            }
            setCartDetailState(cartDetail)
            dispatch(setCartDetailes(cartDetail))
            dispatch(setCustomizeOptions(cartDetail.customizationOpt))
            dispatch(setMenuServingDetails(cartDetail.menuServingDetail))
            dispatch(setOpenPopUps(true))
            // dispatch(setTitles(""))
            // dispatch(setSelectedCupSizes(""))
            dispatch(setCheckBestSellerId (""))
            dispatch(setTabValue(0));
            navigate(`/customization`)
          }
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          errorToastMessage();
        });
      }else{
        setState(true);
      }
  }
  };
  useEffect(() => {
    if (bestSeller) {
      if (
        store?.id &&
        checkBestSeller?.id &&
        checkBestSellerId?.id &&
        checkBestSeller?.id == checkBestSellerId?.id
      ) {
        setLoading(true);
        let catData = {
          storeId: store?.id,
          appType: "web",
        };
        GetAllCategories(catData)
          .then((res) => {
            let categoryExist = res?.result?.filter(
              (element) =>
                Number(element?.category_id) ==
                Number(checkBestSellerId?.category_id)
            );
            if (categoryExist?.length > 0) {
              let menuItem = {
                appType: "web",
                categoryId: checkBestSellerId?.category_id,
                customerId: 0,
                requestDate: "",
                storeId: store?.id,
              };
              GetAllMenu(menuItem).then((res) => {
                let menuExist = res?.result.filter(
                  (element) =>
                    Number(element?.id) == Number(checkBestSellerId?.id)
                );
                if (menuExist.length > 0) {
                  getCustomizationOption(data).then((res) => {
                    if (res.result && res) {
                      setLoading(false);

                    cartDetail.customizationOpt = res?.result[0][0]?.customizationOpt;
                    cartDetail.cold = res?.result[0][0]?.cold;
                    cartDetail.caffine = res?.result[0][0]?.caffeine;
                    cartDetail.max_calories = res?.result[0][0]?.max_calories;
                    cartDetail.min_calories = res?.result[0][0]?.min_calories;
                    cartDetail.selectedCupSize = fav ? [favItemsDetail?.menu_details?.menu_serving_detail[0]] :
                      [res?.result[0][0]?.menu_serving_detail[0]];
                      cartDetail.description = res?.result[0][0]?.description;
                    dispatch(setSelectedCupSizes(
                      cartDetail?.selectedCupSize?.length > 1
                        ? cartDetail?.selectedCupSize?.filter((e) => e.tier_id > 0)
                        : cartDetail?.selectedCupSize
                    ));


                      // cartDetail.customizationOpt(res?.result[0][0]?.customizationOpt);
                      cartDetail.menuServingDetail =
                        res?.result[0][0]?.menu_serving_detail;
                      setCartDetailState(cartDetail);
                      dispatch(setCartDetailes(cartDetail));
                      dispatch(
                        setCustomizeOptions(cartDetail.customizationOpt)
                      );
                      dispatch(
                        setMenuServingDetails(cartDetail.menuServingDetail)
                      );
                      dispatch(setOpenPopUps(true));
                      dispatch(setBestSellerCheck(true));
                      dispatch(setTitles(""));
                      dispatch(setEditTitles(""));
                      dispatch(setCheckBestSellerId(""));
                      // checkBestSeller("")
                      dispatch(setTabValue(0));
                      navigate(`/customization`);
                    }
                  });
                } else {
                  toast.error(
                    `This ${checkBestSellerId.title} is not available for selected store`,
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                  setLoading(false);
                }
              });
            } else {
              toast.error(
                `This ${checkBestSellerId.title} is not available for selected store`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setLoading(false);
            }
          })
          .catch((e) => {
            console.log(e);
          });
        // setCheckBestSeller("")
      } else {

      }
    } else {
    }
  }, [store]);

  const handleFav = () => {
    if (favState == false) {
      setRemoveFav(true);
    } else {
      let fav = {
        cart_id: props?.cart_id,
        category_id: props?.category_id,
        customer_id: userDetail?.customer_id
          ? userDetail?.customer_id
          : userDetail?.person_id,
        fav_id: 0,
        fav_type: "menujson",
        is_favourite: 0,
        selectedOptions: favItemsDetail?.selectedOption,
        orderItemPrice: favItemsDetail?.order_item_price,
        menu_details: {
          cart_id: props?.cart_id,
          category_id: props?.category_id,
          category_name: props?.category_name,
          created_at: new Date()?.toISOString(),
          customizationOpt: props.customizationOpt,
          description: props?.Carddescription,
          discount_details: props?.discount_details,
          id: props.id,
          image: props?.image,
          is_active: props?.is_active,
          is_deleted: props?.is_deleted,
          is_favourite: props?.is_favourite,
          menu_serving_detail: props?.menu_serving_detail,
          price: props?.price,
          status: props?.status,
          store_id: props?.store_id,
          title: props.title,
          updated_at: new Date().toISOString(),
          min_calories: props?.min_calories,
          max_calories: props?.max_calories,
        },
        menu_id: menuId ? menuId : props?.id,
        order_id: "0",
      };
      favoriteUnfavoriteMenu(fav)
        .then((res) => {
          if (res?.responseCode == 1) {
            dispatch(setFav(true));
            setFavItem(favItem ? false : true);
            toast.success("Item is removed from your favorite", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
          } else {
            errorToastMessage();
          }
        })
        .catch((e) => {
          errorToastMessage();
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (fav && favState) {
      handleFav();
    }
  }, [favState]);

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "15rem",
    },
  });

  return (
    <>
      {!fav && (
        <MyCard
          onClick={handleCustomization}
          className={styles.item}
          style={{
            cursor: bestSeller && props?.menuExists == false ? "" : "pointer",
            borderRadius: "10px",
            opacity: bestSeller && props?.menuExists == false ? "0.5" : "",
          }}
        >
          <Box p={1} sx={{ display: "flex", alignItems: "center" }}>
            <Box
              className={styles.imgTran}
              component="img"
              sx={{
                height: { lg: "12rem", xs: "9rem" },
              }}
              alt="menuImage"
              src={props?.image ? props?.image : "./logos.png"}
            />
            <div >
            <Typography sx={{ marginLeft: { lg: "2.3rem", xs: "1rem" }, color: "#39302B", fontSize: { lg: "23px", xs: "18px" } }} >
              {props?.title}
            </Typography>
            {( bestSeller && props?.menuExists == false) ?
            <Typography sx={{marginLeft: { lg: "2.3rem", xs: "1rem" },fontWeight:"bold",}}>Unavailable at selected store</Typography>
            :<br></br>}
            </div>
          </Box>
        </MyCard>
      )}

      {fav && (
        <MyCard
          className={styles.favItem}
          style={{
            cursor: favItemsDetail?.menuExists == false ? "" : "pointer",
            borderRadius: "10px",
            opacity: favItemsDetail?.menuExists == false ? "0.5" : "",
          }}
        >
          <Box
            p={2}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                textAlign: "left",
                paddingBottom: { xs: "0.5rem", lg: "0rem" },
                width: { xs: "100%", sm: "100%", md: "83%", lg: "83%" },
              }}
            >
              <Box
                className={styles.favImgTran}
                component="img"
                sx={{
                  height: { lg: "12rem", xs: "9rem" },
                }}
                alt="menuImage"
                src={props?.image ? props?.image : "./logos.png"}
              />
              <Box sx={{ width: "70%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "#39302B",
                      fontSize: { lg: "23px", xs: "18px" },
                    }}
                  >
                    {props?.title}
                  </Typography>

                  <FavoriteIcon
                    fontSize="small"
                    style={{ fill: "#C30E2F", marginLeft: "1rem" }}
                    onClick={handleFav}
                  />
                </div>
                <Typography variant="subtitle2"  >
                  {favItemsDetail?.menu_details?.menu_serving_detail[0]?.serving_name}
                </Typography>
                <Typography variant="subtitle2"  >
                  {favItemsDetail?.selectedOption?.map((elm, index) =>
                    (elm?.option?.length > 0 ? elm?.option?.map((item) => { return (" " + item?.modifier_name + (item?.quantity > 1 ? " (" + item?.quantity + ") " : "")) }) : " ") +
                    ((favItemsDetail?.selectedOption?.length - 1) != index ? " | " : " ")
                  )}
                </Typography>
                <Button 
                  disabled={(favItemsDetail?.menuExists==false)? true:false}
                  variant="text"
                  sx={{
                    color: "#C60734",
                    padding: "0",
                    minWidth: "10px",
                    cursor: "default",
                  }}
                  onClick={() => handleCustomization(favItemsDetail)}
                >
                  Edit
                </Button>
                {(favItemsDetail?.menuExists==false) ?
            <Typography sx={{fontWeight:"bold",}}>Unavailable at selected store</Typography>
            :<br></br>}
              </Box>
            </Box>
            <Button
             disabled={(favItemsDetail?.menuExists==false)? true:false}
              style={{
                borderRadius: "15px",
                padding: "0.2rem 1.6rem",
                backgroundColor: "white",
                color: "#C60734",
                textTransform: "none",
                border: "1px solid #C60734"
              }}
              onClick={handelfavOrder}
              // onClick={hadleAddToCart}
              // disabled={indexs<0?false:true}
            >
              {buttonLoader ? <ButtonLoader props={"4.5rem"} /> : "Add to Cart"}
            </Button>
          </Box>
        </MyCard>
      )}

      {fav && (
        <AlertPopup
          open={removeFav}
          setOpen={setRemoveFav}
          setFavState={setFavState}
          title={"removefavoritesItem"}
          buttonType1={true}
          buttonType2={true}
          displayTitle={
            "Are you sure want to remove this item from your favorites?"
          }
        />
      )}
      <AlertPopup
        open={openAlert}
        setOpen={setOpenAlert}
        setState={setState}
        state={state}
        title={"noStoreBestSeller"}
        displayTitle={"Please select store to order from Best sellers"}
        buttonType1={true}
        buttonType2={true}
      />
      {/* {openPopup && !fav && (
        <Popup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          customizeOption={cartDetailState.customizationOpt}
          menuServingDetail={cartDetailState.menuServingDetail}
          menuItem={cartDetailState}
          selectedCupSize={selectedCupSize}
          setOpenSnackBar={setOpenSnackBar}
          minCalories = {props?.min_calories}
          maxCalories = {props?.max_calories}
        ></Popup>
      )}
      {openPopup && fav && (
        <Popup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          customizeOption={cartDetailState.customizationOpt}
          setCustomizeOption={setCustomizeOption}
          menuItem={cartDetailState}
          menuServingDetail={cartDetailState.menuServingDetail}
          setCatCustomize={setCatCustomize}
          setOpenSnackBar={setOpenSnackBar}
          favFromCard={fav}
          minCalories = {props?.min_calories}
          maxCalories = {props?.max_calories}
        // title = "editFromCart"
        // cartItemIndex = {listKey}
        />
      )} */}
      {openSnackBar && (
        <SnackbarPopUp
          key={indexs}
          open={openSnackBar}
          setopenpopup={setOpenSnackBar}
          messageInfo={`${cartDetail.title} Added to the cart`}
        />
      )}
    </>
  );
};

export default Card;