import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {CircularProgress} from "@mui/material";
import {
  Dialog,
  Box,
  Button,
  DialogTitle,
  TextField,
  FormControl,
  Typography,
  Select,
  Backdrop,
  MenuItem,
} from "@mui/material";
import Zoom from "@mui/material/Zoom";
import { setIsAlreadyUserChecked, setIsUserFiveStarData } from "../../Redux/UserSlice";
import Grow from "@mui/material/Grow";
import { makeStyles } from "@mui/styles";
import SocialButton from "./SocialButton";
import { toast } from "react-toastify";
import { forgetPassword, Log_in, SocialLogin } from "../../Api/API";
import { checkUser } from "../../Api/API";
import { useForm, Controller } from "react-hook-form";
import {
  setIsSignInFromGoogle,
  setLogin,
  setUser,
  setUserFiveStarData,
  setUserGoogleData,
  setUserId,
} from "../../Redux/UserSlice";
import {
  validatePassword,
  validatePhoneNumber,
} from "../../Common/FormVailidation";
import OtpPopUp from "./OtpPopUp";
import SocialLogins from "./SocialLogins";
import { resendOtp, verifyOtp, expireOtp } from "../../Api/API";
import CancelIcon from "@mui/icons-material/Cancel";
import NewPassword from "./NewPassword";
import { errorToastMessage, MenuProps } from "../../Common/utils";
import SignUp from "../SignUp/SignUp";
toast.configure();
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: { borderRadius: 15 },
  noBorder: {
    border: "none",
  },
  btnLogin: {
    backgroundColor: "#B7273B",
    // margin: "auto",
    fontFamily: "Lusitana",
    textTransform: "none",
  },
  btnNew: {
    padding: "0.2rem 0.5rem",
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    textDecoration: "none",
    color: "white",
    textTransform: "none",
    marginTop: "0.5rem"
    // marginRight:"1.1rem"
  },
  focus: {
    "& .MuiSelect-select:focus": {
        backgroundColor: "#E7E7E7",
    },
},
  error: {
    color: "#bf1650",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});
const Login = (props) => {
  const defaultValues = {
    deviceToken: "",
    deviceType: 0,
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    password: "",
  };
  const {
    control,
    handleSubmit,
    formState,
    setValue,
    reset,
    getValues,
    clearErrors,
  } = useForm({
    defaultValues,
  });

  const { errors } = formState;
  const { showAd, setShowAd } = props;
  const [userRegistered, setUserRegistered] = useState(false);
  const [code, setCode] = useState("+1");
  const classes = useStyles();
  const isUserFiveStarData = useSelector(
    (state) => state.user.isUserFiveStarData
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeAd = () => {
    clearErrors();
    setShowAd(false);
    setCheckUserError("");
    setValue("phone", "");
    setCode("+1");
    setUserRegistered(false);
    setValue("password", "");
  };
  const [showlogoutButton, setShowlogoutButton] = useState(false);
  const [showPopup, setshowPopup] = useState(false);
  const [showForgetPopup, setshowForgetPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const userDetail = useSelector((state) => state.user.user);
  const [checkUserError, setCheckUserError] = useState("");
  const [checkUserPhone, setCheckUserPhone] = useState();
  const [checkUserEmail, setCheckUserEmail] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [title, setTitle] = useState();
  const [socialLoading , setsocialLoading] = useState(false)
  const [googleLoginData, setGoogleLoginData] = useState();
  const isLogin = useSelector((state) => state.user.isLogin);
  const [loading , setLoading] = useState(false);

  const handleSocialLogin = (user) => {
    const loginData = {
      deviceToken: "",
      email: user?.profile?.email,
      socialId: user?.profile?.id,
      lastName: user?.profile?.lastName,
      deviceType: 0,
      firstName: user?.profile?.firstName,
      imageurl: user?.profile?.profilePicURL,
      socialType: 2,
    };
    dispatch(setUserGoogleData(user?.profile));
    handleLoginThroughGoogle(loginData);
    setShowlogoutButton(true);
  };

  const handleSocialFacebookLogin = (user) => {
    console.log(user);
    const loginData = {
      deviceToken: "",
      deviceType: 0,
      email: user?.profile?.email,
      // phone: user?.profile?.email,
      firstName: user?.profile?.firstName,
      lastName: user?.profile?.lastName,
      imageurl: user?.profile?.profilePicURL,
      socialId: user?.profile?.id,
      // password: user?.profile?.id,
      socialType: 1,
    };
    dispatch(setUserGoogleData(user?.profile));
    handleLoginThroughGoogle(loginData);
    setShowlogoutButton(true);
  };

  const handleSocialFacebookLoginFailure = (err) => {
    console.error(err);
    setLoading(false)
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
    setLoading(false)
  };
  const handleLoginThroughGoogle = (loginData) => {
    setsocialLoading(true)
    SocialLogin(loginData)
      .then((res) => {
        if (res.response_code == 1 || res.responseCode == 1) {
          if (
            res.result.phone_number == "" ||
            res.result.phone_number == null
          ) {
            setGoogleLoginData(res.result);
            setsocialLoading(false)
            setShowLoginPopup(true);
          } else {
            toast.success("Logged in Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss : false,
              autoClose : 1000,
              pauseOnHover : false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
            dispatch(setLogin(true));
            setsocialLoading(false)
            dispatch(setUser(res.result));
          }
        } else if (res.responseCode == 0) {
          console.log(res);
          setsocialLoading(false)
          toast.error(res.responseMessage, {
            position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1000,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          });
          // dispatch(setIsSignInFromGoogle(true));
          // console.log("inside");
          // navigate("/signup");
        }
        setShowAd(false);
      })
      .catch((err) => {
        setsocialLoading(false)
        console.log(err);
      });
  };
  const onSubmit = (data) => {
    if (!data.phone) {
      setCheckUserError("Mobile Number is required");
    }
    if (showAd && data.phone) {
      setCheckUserError("");
      if (!userRegistered) {
        setLoading(true);
        let phone = data.phone;
        phone = phone.replace(/-/g, "");
        setPhoneNo(phone);
        return checkUser(phone, code=="+91"?"%2B91":code)
          .then((res) => {
            setCheckUserPhone(res?.result?.phone);
            setCheckUserEmail(res?.result?.email);
            setCheckUserError("");
            if (res.response_code == 1 && res.result.otp == false) {
              setUserRegistered(true);
              setLoading(false);
            } else if (
              res.response_code == 1 &&
              res.result.otp == true &&
              res?.response_message == "This phone no already exists"
            ) {
              console.log(res);

              dispatch(setUserFiveStarData(res?.result));
              setLoading(false);
              resendOtp(phone, code=="+91"?"%2B91":code)
                .then((resp) => {
                  console.log("harshit_inside_resend")
                  setshowPopup(true);
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            }else if(res?.response_code == 1 && res?.result == "user is inactive")
            {
              toast.error(res?.response_message, {
                position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1000,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
              });
              setLoading(false);
            }
             else {
              setshowPopup(true);
              //  setCheckUserPhone(res?.result?.phone)
              setLoading(false);
              dispatch(setUserFiveStarData(res.result));
              setValue("phone", "");
              // setCheckUserError("User not registered");
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            errorToastMessage();
          });
      } else {
        data.email = data.phone;
        setLoading(true);
        Log_in(data)
          .then((res) => {
            if (res.responseCode == 1) {
              setLoading(false);
              toast.success("Logged in Successfully", {
                position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1000,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
              });
              dispatch(setLogin(true));
              dispatch(setUserGoogleData(""));
              dispatch(setUser(res.result));
              setShowAd(false);
            } else {
              toast.error(res.responseMessage, {
                position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1000,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
              });
              setLoading(false);
              // setValue("phone","");
              // setUserRegistered(false);
              setValue("password", "");
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            errorToastMessage();
          });
      }
    }
  };
  const handleForgetPassword = () => {
    setTitle("ForgetPassword");
    setLoading(true);
    forgetPassword("website", checkUserEmail, checkUserPhone, code=="+91"?"%2B91":code)
      .then((res) => {
        if (res?.responseCode == 1) {
          dispatch(setUserId(res?.userId));
          setshowPopup(true);
          setLoading(false);
          toast.success("The verification code has been sent", {
            position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          });
        } else {
          setLoading(false);
          toast.error(res?.responseMessage, {
            position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        errorToastMessage();
      });
  };

  const handleEnterPassword = () => {
    const PasswordData = {
      deviceToken: "",
      deviceType: 0,
      email: getValues("phone"),
      phone: getValues("phone"),
      firstName: "",
      lastName: "",
      password: getValues("password"),
    };
    onSubmit(PasswordData);
  };

  // const countryCode = ["+1"];
  // const handleCoutryCode = (e) => {
  //   let value = e.target.value;
  //   setCode(e.target.value);
  // };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1000}}
        open={socialLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={showAd}
        classes={{
          paper: classes.paper,
        }}
        onClose={isLogin}
        onKeyDown={(e) => e.key == "Escape" && closeAd()}
        TransitionComponent={Transition}
      >
        <Backdrop
        sx={{ color: '#fff', zIndex: 1000}}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        {isLogin == false ? (
          <Box textAlign="right" sx={{ cursor: "pointer" }}>
            <CancelIcon
              fontSize="large"
              onClick={() => {
                clearErrors();
                setCheckUserError("");
                // setShowLoginPopup(false);
                setShowAd(false);
                setValue("phone", "");
                setCode("+1");
                setUserRegistered(false);
                setValue("password", "");
              }}
            />
          </Box>
        ) : (
          ""
        )}
        {isLogin == false ? (
          <Box p={3}>
            <DialogTitle textAlign="center">
              <img src="./NewLogoG.png" alt="" width="70%" />
            </DialogTitle>
            <>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Box dir="ltr">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {/* <Select
                    className={classes.focus}
                      // MenuProps={MenuProps}
                      label=" "
                      id="mobileNoCode"
                      value={code}
                      onChange={handleCoutryCode}
                      size="small"
                      variant="standard"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                      sx={{
                        backgroundColor: "#E7E7E7",
                        // width: {lg:"5vw",md:"9vw",sm:"9vw",xs:"28vw"},
                        marginRight: "0.3rem",
                        minWidth:"4rem"
                      }}
                      disableUnderline
                      style={{ paddingLeft: "0.7rem" }}
                    >
                      {countryCode.map((code, index) => {
                        return (
                          <MenuItem style={{marginRight:"0.5rem"}} key={index} value={code}>
                            {code}
                          </MenuItem>
                        );
                      })}
                    </Select> */}
                    <FormControl fullWidth>
                      <Controller
                        name="phone"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              if (value) {
                                if (validatePhoneNumber(value)) {
                                  setCheckUserError("");
                                }
                                return validatePhoneNumber(value);
                              }
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="phone"
                            type="text"
                            placeholder="Phone Number*"
                            InputProps={{
                              classes: { notchedOutline: classes.noBorder },
                              inputMode: 'numeric',
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            inputProps={{ maxLength: 12 ,  inputMode: 'numeric'}}
                            sx={{ backgroundColor: "#E7E7E7" }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            onInput={(e) => {
                              
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                                e.target.value = e.target.value.replace(
                                  /(\d{3})(\d{3})(\d{4})/,
                                  "$1-$2-$3"
                                );
                              
                               if (e.target.value.length < 10) {
                                setUserRegistered(false);
                              }
                            }}
                            // autoFocus
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                  </div>
                  {errors?.phone || checkUserError ? (
                    <p className={classes.error}>
                      {errors?.phone?.message || checkUserError}
                    </p>
                  ) : (
                    <p>
                      <br />
                    </p>
                  )}
                </Box>
                {userRegistered && (
                  <Box dir="ltr">
                    <FormControl fullWidth>
                      <Controller
                        name="password"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validatePassword(value);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="password"
                            type="password"
                            placeholder="Password*"
                            autoFocus={true}
                            InputProps={{
                              classes: { notchedOutline: classes.noBorder },
                            }}
                            inputProps={{
                              maxLength: 16,
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            sx={{ backgroundColor: "#E7E7E7" }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            onKeyPress={(e) => {
                              e.key == "Enter" &&
                                userRegistered &&
                                handleEnterPassword();
                            }}
                          />
                        )}
                        control={control}
                      />
                    </FormControl>
                    {errors?.password ? (
                      <div className={classes.error}>
                        {errors.password.message}
                      </div>
                    ) : (
                      <br />
                    )}
                    <Typography 
                      sx={{
                        textDecoration: "underline",
                        textAlign: "right",
                        
                        marginTop:"0.5rem",
                        marginBottom:"1.2rem"
                      }}
                      
                    >
                     {<span style = {{cursor: "pointer",}} onClick={handleForgetPassword}>Forget Password</span>} 
                    </Typography>
                  </Box>
                )}
                <Box textAlign="center">
                  <Button
                    sx={{
                      padding: {
                        xs: "0.2rem 4rem",
                        sm: "0.2rem 5rem",
                        md: "0.2rem 5rem",
                        lg: "0.2rem 5rem",
                      },
                      marginBottom: "1.5rem",
                    }}
                    type="submit"
                    className={classes.btnLogin}
                    variant="contained"
                  >
                    Log in
                  </Button>
                  {/* <div style={{marginTop:"0.5rem",marginBottom:"0.5rem"}}>Do not have an account?</div> */}
                  <div style={{ marginBottom: "0.5rem" }}>
                    <div>
                      <SocialButton
                        provider="google"
                        appId="582546805022-cua7jqr50ar1s334p914oso1patlk192.apps.googleusercontent.com"
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                      >
                        <img
                          src="./google.png"
                          alt=""
                          height="25vh"
                          style={{ marginBottom: "0.3rem" }}
                        />
                        <span onClick={closeAd}> Sign in using Google</span>
                      </SocialButton>
                    </div>
                    <div>
                      <SocialButton
                        provider="facebook"
                        appId="1383395692123961"
                        onLoginSuccess={handleSocialFacebookLogin}
                        onLoginFailure={handleSocialFacebookLoginFailure}
                      >
                        <img
                          src="./facebook.png"
                          alt=""
                          height="25vh"
                          style={{
                            marginBottom: "0.5rem",
                            marginLeft: "0.8rem",
                          }}
                        />
                        <span> Sign in using Facebook</span>
                      </SocialButton>
                    </div>
                  </div>
                  <Button
                    className={classes.btnNew}
                    variant="contained"
                    onClick={() => {
                      dispatch(setIsUserFiveStarData(false));
                      dispatch(setUserFiveStarData([]));
                      dispatch(setIsAlreadyUserChecked(false));
                      dispatch(setIsSignInFromGoogle(false));
                      navigate("/signup");
                      setShowAd(false);
                    }}
                  >
                    Create a new account
                  </Button>
                </Box>
              </form>
            </>
          </Box>
        ) : (
          ""
        )}
      </Dialog>
      {showPopup && (
        <OtpPopUp
          showPopup={showPopup}
          code={code}
          setCode={setCode}
          setshowPopup={setshowPopup}
          phoneNo={phoneNo}
          setShowAd={setShowAd}
          title={title}
          setTitle = {setTitle}
          setValues={setValue}
          setUserRegistered={setUserRegistered}
          setCheckUserPhone={setCheckUserPhone}
          checkUserPhone={checkUserPhone}
          checkUserEmail={checkUserEmail}
          setCheckUserEmail={setCheckUserEmail}
          showLoginPopup={showLoginPopup}
          setShowLoginPopup={setShowLoginPopup}
          googleLoginData={googleLoginData}
        />
      )}
      {showLoginPopup && (
        <SocialLogins
          showLoginPopup={showLoginPopup}
          setShowLoginPopup={setShowLoginPopup}
          googleLoginData={googleLoginData}
        ></SocialLogins>
      )}
      {/* <SignUp checkUserPhone={checkUserPhone}/> */}
    </>
  );
};

export default Login;
