import { Backdrop, TableContainer } from "@mui/material";
import Paper from "@mui/material/Paper";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";

//  css styling ******
const useStyle = makeStyles((theme) => ({
  tableRow: {
    "& >.MuiTableCell-root": {
      color: "#ab1700",
    },
  },
}));

const KioskTable = ({
  loading,
  setLoading,
  columns,
  data,
  deleteApiCall,
  getApiCall,
  searchParam,
  pageCount,
  totalDataCount,
  onSort,
  noData,
  setSearchParam,
  exportPdfName,
  stockFlag,
  setStockFlag,
  setRunButton,
  orderDetailsPageSize,
  kioskReceipt,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: pageCount,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      deleteApiCall &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                {/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}
              </div>
            ),
            Cell: ({ row }) => {
              if (row.values.actions != "total") {
                // return (
                //   <div>
                //     <IndeterminateCheckbox
                //       {...row.getToggleRowSelectedProps()}
                //     />
                //   </div>
                // );
              }
              return "";
            },
          },
          ...columns,
        ]);
    }
  );
  const classes = useStyle();
  const tableStateUpdateRef = React.useRef(false);
  const [oldSearchParam, setOldSearchParam] = useState(searchParam);
  const dispatch = useDispatch();
  React.useEffect(() => {
    tableStateUpdateRef.current = true;
  }, [pageIndex, pageSize]);

  React.useEffect(() => {
    if (!tableStateUpdateRef.current) {
      gotoPage(pageIndex);
      setPageSize(pageSize);
    }

    // if (orderPageIndex == 0 && !tableStateUpdateRef.current) {
    //   gotoPage(0);
    // //   dispatch(setOrderPageIndex(-1));
    // }
  }, [data, gotoPage]);
  // dispatch(setUpdatedPageNumber(pageIndex));

  // clear our ref when the data is loaded, after we perform any side effects
  React.useEffect(() => {
    tableStateUpdateRef.current = false;
  }, [data]);

  const getRequestParams = useCallback(
    (sortBy, isDesc, page) => {
      // if (pageSize < 20) setPageSize(20);
      if (
        oldSearchParam != searchParam
        // ||
        // oldPageCount != pageCount
      ) {
        page = 0;
        gotoPage(0);
        setOldSearchParam(searchParam);
        // setOldStoreId(storeId);
        // setOldPageCount(pageCount);
      }
      let params = {
        searchString: searchParam || "",
        page: page,
        pageSize: pageSize < 20 ? 20 : pageSize,
        storeId: 2,
        sortBy: sortBy || null,
        order:
          noData === "Order" && isDesc === undefined
            ? isDesc
              ? "asc"
              : "desc"
            : isDesc
            ? "desc"
            : "asc",
      };
      return params;
    },

    [searchParam, pageIndex, pageSize]
  );

  useEffect(() => {
    if (stockFlag && stockFlag == true) {
      let params = getRequestParams(sortBy[0]?.id, sortBy[0]?.desc, pageIndex);
      onSort(params);
      setStockFlag(false);
    }
  }, [stockFlag]);

  return (
    <div style={{ padding: 0, marginLeft: 0 }}>
      <Backdrop style={{ color: "#fff", zIndex: "0" }} open={loading}>
        {loading && (
          <img
            src="./BLACKPLAINLOADING.GIF"
            alt=""
            style={{ width: "4vw", margin: "auto" }}
          />
        )}
      </Backdrop>

      <TableContainer
        component={Paper}
        style={{
          paddingLeft: 0,
          border: 0,
          boxShadow: kioskReceipt ? "none" : "",
          fontFamily: "lusitana",
        }}
      >
        <MaUTable {...getTableProps()} size="small">
          <TableHead>
            {
              <TableRow
                {...headerGroups[1].getHeaderGroupProps()}
                hover
                size="small"
              >
                {headerGroups[1].headers.map((column) => {
                  return (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        fontSize: "0.75rem",
                        minWidth: "auto",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                        fontFamily: "lusitana",
                        // backgroundColor: "#ECEAEA",
                        border: kioskReceipt ? 0 : ".5px solid #C9C0C0",
                        padding: 0,
                      }}
                      align={
                        column.Header === "Items"
                          ? "left"
                          : column.Header === "Quantity"
                          ? "center"
                          : "right"
                      }
                    >
                      {column.render("Header")}
                    </TableCell>
                  );
                })}
              </TableRow>
            }
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {noData && rows.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={headerGroups[1].headers.length}
                  align="center"
                >
                  No {noData} available to display.
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows?.length > 0 &&
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow
                    {...row.getRowProps()}
                    hover
                    selected={row.isSelected}
                    className={
                      row?.values?.isApproved === "No" ? classes.tableRow : ""
                    }
                  >
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          {...cell.getCellProps({
                            style: {
                              fontSize: "0.75rem",
                              minWidth: "auto",
                              padding: "5px 0px",
                              whiteSpace: "nowrap",
                              fontFamily: "lusitana",
                              border: kioskReceipt
                                ? 0
                                : ".5px solid rgba(224, 224, 224, 1)",
                              lineHeight: "1", // Set line height to 1
                            },
                          })}
                          align={
                            cell.column.Header === "Items"
                              ? "left"
                              : cell.column.Header === "Total" ||
                                cell.column.Header === "Each Price"
                              ? "right"
                              : "center"
                          }
                          // align="center"
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </div>
  );
};

export default KioskTable;
