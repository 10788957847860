import React, { useEffect } from "react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  addCreditCard,
  cardPointePayment,
  creditCardRefundPayment,
} from "../../Api/API";
import { setOrderTotalPrice, setPaymentData } from "../../Redux/CartSlice";
import { setCheckoutFlag, setDefaultCardd } from "../../Redux/UserSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { setGivexPaymentDetail } from "../../Redux/StoreSlice";
import { handlePlaceOrderWithTime } from "../../Common/utils";
import creditCardType, {
  getTypeInfo,
  types as CardType,
} from "credit-card-type";
import CachedIcon from "@mui/icons-material/Cached";
import { givexCardPayment } from "../../Api/API";
import Checkmark from "../Payment/Checkmark.gif";
import failed from "../Payment/failed.gif";
import {
  Card,
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  keyframes,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  validateCardExpiry,
  validateCardHolderName,
  validateCardMobileExpiry,
  validateCreditCardNumber,
  validateNumber,
  validateZipCode,
} from "../../Common/FormVailidation";
import { generateCaptch, MenuProps } from "../../Common/utils";
// import GiftCard from "./GiftCard";
import GiftCard from "../Payment/GiftCard";
import { getValue } from "@testing-library/user-event/dist/utils";
import AlertPopup from "../../Common/AlertPopup";

const useStyles = makeStyles((theme) => ({
  btnFinalPay: {
    // padding: "0.3rem 6rem",
    padding: { xs: "0rem", lg: "0.3rem 6rem" },
    fontSize: "1rem",
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    borderRadius: "3rem",
    textTransform: "none",
  },
  cancelButton: {
    position: "absolute",
    left: "100%",
    cursor: "pointer",
    "&:hover": {
      transform: "scale3d(1.1, 1.1, 1)",
    },
  },
  captchaField: {
    width: "100%",

    // paddingBottom : "1rem"
  },
  captchaError: {
    color: "#bf1650",
    textAlign: "center",
    // display: "flex",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
    width: { md: "90%", sm: "80%", xs: "70%" },
  },
  btn: {
    borderRadius: "0.4rem",
    boxShadow: "0 6px 20px 0 #dbdbe8",
    backgroundColor: "#fff",
    transition: "0.4s",
    padding: "0.7rem 3rem ",
    textTransform: "none",
  },
  spacing: {
    marginBottom: "0rem",
  },
  root: {
    "& .Mui-disabled": {
      background: "gray",
      color: "white",
    },
  },
  noBorder: {
    // borderStyle: "none",
    border: "none",
  },
  error: {
    color: "#bf1650",
    // marginLeft: "9.7rem",
    // marginBottom : '0rem',
    textAlign: "left",
    display: "flex",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  error1: {
    color: "#bf1650",
    marginLeft: "1.1rem",
    // marginBottom : '0rem',
    textAlign: "left",
    display: "flex",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));

const AddCardMobile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [saveCardd, setsaveCardd] = useState(false);
  const [userCardDetails, setUserCardDetails] = useState({
    name: "",
    cardNumber: "",
    expiryDate: "",
  });
  const [pickUpLaterPopup, setPickUpLaterPopup] = useState(false);
  // const [captchaValue, setCaptchaValue] = useState();
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [givexCardNumber, setGiveExCardNumber] = useState();
  const [giveExCardPassword, setGiveExCardPassword] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // const storeName = useSelector((state) => state.store.nearestStore);

  const saveCreditCard = useSelector((state) => state.store.saveCreditCard);
  const [paymentRequest, setPaymentRequest] = useState(null);
  let search = useLocation().search;
  let location = useLocation();
  const amount = new URLSearchParams(search).get("amount");
  const page = new URLSearchParams(search).get("page");
  const paymentTitle = new URLSearchParams(search).get("paymentTitle");
  const customerId = new URLSearchParams(search).get("customerId");
  const phoneNumber = new URLSearchParams(search).get("phoneNumber");
  const defaultCard = new URLSearchParams(search).get("defaultCard");
  const giftCardAmount = new URLSearchParams(search).get("giftCardAmount");
  const [remainingPayment, setRemainingPayment] = useState(amount);
  const giftCardNumber = new URLSearchParams(search).get("giftCardNumber");
  const deviceType = new URLSearchParams(search).get("deviceType");
  const customerPhone = new URLSearchParams(search).get("customerPhone");
  const customerName = new URLSearchParams(search).get("customerName");
  const [value, setValues] = React.useState(new Date());
  const storeName = new URLSearchParams(search).get("storeId");
  const fromPage = new URLSearchParams(search).get("fromPage");
  const [cardNumber, setCardNumber] = React.useState("");
  const pickUpLaterTime = useSelector((state) => state.cart.pickUpLaterTime);
  const isGuestLogin = useSelector((state) => state.user.isGuestLogin);
  const [validCardNumber, setValidCardNumber] = useState();
  const [CardTypeNew, setCardTypeNew] = useState();
  const [cardTypeImage, setCardTypeImage] = React.useState("./creditcard.png");

  // const [captchaState, setCaptchaState] = useState(false)
  let month = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  let year = [];
  let date = new Date();
  let thisYear = date.getFullYear();
  for (let i = 0; i <= 20; i++) {
    year.push(thisYear);
    thisYear = thisYear + 1;
  }
  const [expiryDate, setExpiryDate] = useState({
    month: month[0],
    year: year[0],
  });
  const defaultValues = {
    customerName: "",
    zipCode: "",
    accountNumber: "",
    cvv: "",
    cardExpiry: "",
    enterCaptcha: "",
  };
  const {
    control,
    handleSubmit,
    formState,
    setValue,
    trigger,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
  });
  const { errors } = formState;
  // console.log(new URLSearchParams(search).get("paymentTitle"), saveCardd , phoneNumber  , customerId)
  useEffect(() => {}, []);

  useEffect(() => {
    setRemainingPayment(
      giftCardAmount ? Number(amount) - Number(giftCardAmount) : amount
    );
    if (page == "SavePay" && !isGuestLogin) {
      setsaveCardd(true);
    } else {
      setsaveCardd(false);
    }
  }, []);

  const handleCardNumber = (e) => {
    e.preventDefault();
    // console.log(getValues("accountNumber").split(" ").join(""))
    const value = e.target.value;
    // setCardNumber(value);
    setCardNumber(value.split(" ").join(""));

    let suggestion;

    if (value.length > 0) {
      suggestion = creditCardType(e.target.value)[0];
      if (suggestion.type == "american-express") {
        suggestion.type = "amex";
        console.log(suggestion.type);
      } else if (suggestion.type == "diners-club") {
        suggestion.type = "dinersclub";
      }
      setCardTypeNew(suggestion ? suggestion.type : "unknown");
      setValidCardNumber({
        length: suggestion ? suggestion.lengths : [12],
        cvv: suggestion ? suggestion.code.size : 3,
      });
    }
    const cardType = suggestion ? suggestion.type : "unknown";
    console.log(value, suggestion);
    let imageUrl;

    switch (cardType.toLowerCase()) {
      case "visa":
        imageUrl = "./visa.png";
        break;
      case "mastercard":
        imageUrl = "./mastercard.png";
        break;
      case "amex":
        imageUrl = "./Amex.png";
        break;
      case "american-express":
        imageUrl = "./Amex.png";
        break;
      case "maestro":
        imageUrl = "./maestro.png";
        break;
      case "dinersclub":
        imageUrl = "./dinersclub.png";
        break;
      case "discover":
        imageUrl = "./discover.png";
        break;
      case "jcb":
        imageUrl = "./jcb.png";
        break;
      default:
        imageUrl = "./creditcard.png";
    }

    setCardTypeImage(imageUrl);
  };

  const handleDateChange = (event, type) => {
    console.log(event, type);
    if (type == "month") {
      // setExpiryDate({month : event.target.value ,...rest})
      expiryDate.month = event.target.value;
      setExpiryDate(expiryDate);
      trigger("cardExpiry");
    } else if (type == "year") {
      expiryDate.year = event.target.value;
      setExpiryDate(expiryDate);
      trigger("cardExpiry");
    }
  };

  // const handleCaptcha = () => {
  //     setCaptchaState(!captchaState)
  //     setValue("enterCaptcha", "")
  //     errors.enterCaptcha = ""
  // }

  const handleCreditCardPayment = (data) => {
    setLoading(true);
    cardPointePayment(data)
      .then((res) => {
        console.log(res.responseCode, "harshit_payment");
        // setshowCard(false);
        // setCreditPopup(false);
        if (res.responseCode == 0) {
          console.log(res, "INSIDE_CONSOLE");
          let paymentDetails = {
            amount: "0",
            amt: "0",
            auth_code: "",
            calculationAmt: "",
            card_last_no: 0,
            givex_code: 0,
            givex_num: 0,
            givex_trans_num: "",
            method: res?.response?.paymentMethod,
            payId: res?.response?.savedCardId,
            payType: res?.response?.paymentMethod,
            paymentMethod: res?.response?.paymentMethod,
            trans_num: 0,
          };
          let checkoutPaymentMethodArray = [];
          checkoutPaymentMethodArray.push(paymentDetails);

          let cardRefund = {
            type: "credit_card",
            requestId: data?.requestId,
            transactionRefNumber: res?.response?.paymentRefId,
            storeId: Number(storeName),
          };
          dispatch(setGivexPaymentDetail(cardRefund));
          dispatch(setPaymentData(checkoutPaymentMethodArray));

          let path = `/payment/success?deviceType=${deviceType}&paymentMethod1=${
            res?.response?.paymentMethod
              ? res?.response?.paymentMethod
              : "credit_card"
          }&payId1=${
            res?.response?.savedCardId ? res?.response?.savedCardId : 0
          }&transactionRefNumber=${res?.response?.paymentRefId}&requestId=${
            data?.requestId
          }`;
          navigate(path);
          dispatch(setCheckoutFlag(true));
          setLoading(false);
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Duplicate Request Id"
        ) {
          setLoading(false);
          // setshowCard(false)
          toast.error("Server error ,Please try again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Invalid card"
        ) {
          setLoading(false);
          // setshowCard(false)
          toast.error("Please check your Card Number", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setLoading(false);
          // setshowCard(false)
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
      })
      .catch((err) => {
        // setshowCard(false)
        setLoading(false);
        console.log(err.message);
        if (err) {
          let path = `/payment/failur?deviceType=${deviceType}`;
          navigate(path);
        }
      });
  };
  useEffect(() => {
    console.log("mobile card page called");
  }, []);

  const handleCreditCardRefund = (creditCardDetail) => {
    creditCardRefundPayment(creditCardDetail)
      .then((res) => {
        if (res?.responseCode == 0) {
          toast.error(
            "Oops Something Went Wrong ,Your refund has been initiated",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .catch((e) => console.log(e));
  };

  const handleSplitPaymentMethod = (creditPayment, data) => {
    setLoading(true);
    cardPointePayment(creditPayment)
      .then((res) => {
        if (res.responseCode == 0) {
          let refundArray = [];
          let newDate = new Date();
          let givexData = {
            amount: Number(giftCardAmount),
            cardNumber: giftCardNumber,
            clientId: "AIPRUS",
            customerIdentifier: phoneNumber ? phoneNumber : "Guest@gmail.com",
            customerName: data.customerName,
            description: "demo payment",
            requestId: (
              newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
            )
              ?.toString()
              ?.replace(/[T.Z:_-]/g, ""),
            storeId: Number(storeName) ? Number(storeName) : 1,
            surcharge: 0,
          };
          let checkoutPaymentMethodArray = [];

          let paymentDetails = {
            amount: "0",
            amt: "0",
            auth_code: "",
            calculationAmt: "",
            card_last_no: 0,
            givex_code: 0,
            givex_num: 0,
            givex_trans_num: "",
            method: res?.response?.paymentMethod,
            payId: res?.response?.savedCardId,
            payType: res?.response?.paymentMethod,
            paymentMethod: res?.response?.paymentMethod,
            trans_num: 0,
          };

          let cardRefund = {
            type: "credit_card",
            requestId: creditPayment?.requestId,
            transactionRefNumber: res?.response?.paymentRefId,
            storeId: Number(storeName),
          };
          refundArray.push(cardRefund);
          checkoutPaymentMethodArray.push(paymentDetails);
          givexCardPayment(givexData)
            .then((resp) => {
              if (
                res.responseCode == 0 &&
                res.responseMessage == "Payment Success"
              ) {
                paymentDetails = {
                  amount: "0",
                  amt: "0",
                  auth_code: "",
                  calculationAmt: "",
                  card_last_no: 0,
                  givex_code: 0,
                  givex_num: 0,
                  givex_trans_num: "",
                  method: "gift_card",
                  payId: resp?.response?.savedId,
                  payType: resp?.response?.paymentMethod,
                  paymentMethod: "gift_card",
                  trans_num: 0,
                };

                let givexDetailForRefund = {
                  type: "givex",
                  cardNumber: givexCardNumber,
                  clientId: "AIPRUS",
                  description: "demo payment",
                  originalRequestId: givexData?.requestId,
                  requestId: givexData?.requestId,
                  transactionRefNumber: resp?.response?.transactionRefNumber,
                  storeId: Number(storeName),
                };
                refundArray.push(givexDetailForRefund);
                dispatch(setGivexPaymentDetail(refundArray));
                checkoutPaymentMethodArray.push(paymentDetails);
                dispatch(setPaymentData(checkoutPaymentMethodArray));

                let path = `/payment/success?deviceType=${deviceType}&paymentMethod1=${
                  res?.response?.paymentMethod
                    ? res?.response?.paymentMethod
                    : "credit_card"
                }&payId1=${
                  res?.response?.savedCardId ? res?.response?.savedCardId : 0
                }&paymentMethod2=${resp?.response?.paymentMethod}&payId2=${
                  resp?.response?.savedId
                }&transactionRefNumber=${
                  resp?.response?.transactionRefNumber
                }&requestId=${givexData?.requestId}`;
                navigate(path);
                dispatch(setCheckoutFlag(true));
                setLoading(false);
                // setshowCard(false)
              } else if (
                res.responseCode == 1 &&
                res.responseMessage == "Cert not exist"
              ) {
                setLoading(false);
                // setshowCard(false)
                toast.error("Please check your card number again", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                handleCreditCardRefund(cardRefund);
              } else if (
                res.responseCode == 1 &&
                res.responseMessage == "Invalid user ID/pswd"
              ) {
                setLoading(false);
                // setshowCard(false)
                toast.error("Oops something went wrong please try again", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                handleCreditCardRefund(cardRefund);
              } else if (
                res.responseCode == 1 &&
                res.responseMessage == "Duplicate Request Id"
              ) {
                setLoading(false);
                // setshowCard(false)
                toast.error("Something went wroung , please try again", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                handleCreditCardRefund(cardRefund);
                window.location.reload();
              } else {
                setLoading(false);
                // setshowCard(false)
                handleCreditCardRefund(cardRefund);
                let path = `/payment/failure?deviceType=${deviceType}`;
                navigate(path);
              }
            })
            .catch((err) => {
              setLoading(false);
              // setshowCard(false)
              console.log(err.message);
              if (err) {
                let path = `/payment/failure?deviceType=${deviceType}`;
                navigate(path);
              }
              handleCreditCardRefund(cardRefund);
            });
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Duplicate Request Id"
        ) {
          setLoading(false);
          // setshowCard(false)
          toast.error("Server error ,Please try again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload();
        } else if (
          res.responseCode == 1 &&
          res.responseMessage == "Invalid card"
        ) {
          setLoading(false);
          // setshowCard(false)
          toast.error("Please check your Card Number", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setLoading(false);
          // setshowCard(false)
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
      })
      .catch((err) => {
        setLoading(false);
        // setshowCard(false)
        console.log(err.message);
        if (err) {
          let path = `/payment/failure?deviceType=${deviceType}`;
          navigate(path);
        }
      });
  };
  const onSubmit = (data) => {
    // console.log(customerId, customerPhone, deviceType, amount)
    if (customerId && page) {
      // data.cardExpiry = `${expiryDate.month}${(expiryDate.year).toString().slice(2, 4)}`
      data.cardExpiry = data?.cardExpiry?.replace("/", "20");

      if (page === "Save" || saveCardd) {
        setLoading(true);
        console.log("djhbdjk", page, saveCardd.toString());
        let cardData = {
          // storeId: storeName?.id ? storeName?.id : 1,
          account: data?.accountNumber,
          expiry: data?.cardExpiry,
          amount: "0",
          currency: "USD",
          name: data.customerName,
          postal: data.zipCode,
          profile: "Y",
          cvv: data.cvv,
          customerid: customerId,
          isdefault: 1,
          cardtype: CardTypeNew,
        };
        addCreditCard(cardData)
          .then((res) => {
            if (res.responseCode == 1) {
              dispatch(setDefaultCardd(""));
              toast.success("Your card is saved successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
              console.log(cardNumber, "card number");
              dispatch(
                setDefaultCardd(cardNumber.slice(cardNumber.length - 4))
              );
              // setshowCard(false)
              if (fromPage == "setting") {
                let path = `/addCard/success?deviceType=${deviceType}`;
                navigate(path);
              } else {
                // /payment?amount=17.55&deviceType=mobile&customerId=717127&name=mmmm%20fIrYeL&phoneNumber=9718831543&storeId=%2088&CardAdded=success&paymentWith=CreditCard&cardNumber=1111&cardImage=./mastercard1.png
                let path = `/payment`;
                let search = `?amount=${Number(amount).toFixed(
                  2
                )}&deviceType=${deviceType}&customerId=${customerId}&name=${customerName}&phoneNumber=${customerPhone}&storeId=${storeName}&CardAdded=success&paymentWith=CreditCard&cardNumber=${cardNumber.slice(cardNumber.length - 4)}&cardImage=${cardTypeImage}`;

                navigate({
                  pathname: path,
                  search: search,
                });
              }
              setLoading(false);
            } else if (
              res?.responseCode == 0 &&
              res?.message == "ProfileId already Added in SavedCard "
            ) {
              toast.error("This card is already saved", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false);
            } else if (
              res?.responseCode == 0 &&
              res?.message == "Pin code is not valid"
            ) {
              toast.error("Please enter a valid zipcode", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false);
            } else if (
              res?.responseCode == 0 &&
              res?.message == "Card can not be saved"
            ) {
              toast.error("Unable to save your card", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false);
            } else {
              toast.error("Oops something went wrong", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false);
            }
          })
          .catch((error) => {
            toast.error("Unable to save your card", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
            let path = `/addCard/failure?deviceType=${deviceType}`;
            navigate(path);
          });
      }
      if (
        storeName &&
        storeName != "null" &&
        (page == "SavePay" || page == "Pay")
      ) {
        if (
          saveCreditCard.length == 0 &&
          (page == "SavePay" || page == "Pay") &&
          !giftCardAmount &&
          (amount > 0 || remainingPayment > 0)
        ) {
          console.log("testing");
          let newDate = new Date();
          let creditPayment = {
            accountNumber: data?.accountNumber.split(" ").join(""),
            amount: Number(remainingPayment),
            cardExpiry: data?.cardExpiry,
            clientId: "AIPRUS",
            currency: "USD",
            customerIdentifier: customerPhone
              ? customerPhone
              : phoneNumber
              ? phoneNumber
              : "Guest@gmail.com",
            customerName: data.customerName,
            // description: userDetail?.first_name + " " + userDetail?.last_name,
            description: data.customerName,
            method: "card",
            requestId: (
              newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
            )
              ?.toString()
              ?.replace(/[T.Z:_-]/g, ""),
            saveCard:
              page == "SavePay"
                ? saveCardd
                  ? 1
                  : 0
                : page == "Pay"
                ? saveCardd
                  ? 1
                  : 0
                : 0,
            surcharge: 0,
            storeId: storeName ? Number(storeName) : 1,
            cvv: data.cvv,
            zipCode: data?.zip,
          };

          if (paymentTitle != "split") {
            handleCreditCardPayment(creditPayment);
          } else {
            handleSplitPaymentMethod(creditPayment, data);
          }
        } else if (
          saveCreditCard.length == 0 &&
          (page == "SavePay" || page == "Pay") &&
          giftCardAmount &&
          giftCardAmount < amount &&
          !defaultCard &&
          (amount || remainingPayment)
        ) {
          let newDate = new Date();
          let creditPayment = {
            accountNumber: data?.accountNumber.split(" ").join(""),
            amount: Number(remainingPayment),
            cardExpiry: data?.cardExpiry,
            clientId: "AIPRUS",
            currency: "USD",
            customerIdentifier: customerPhone ? customerPhone : phoneNumber,
            customerName: data.customerName,
            // description: userDetail?.first_name + " " + userDetail?.last_name,
            description: data.customerName,
            method: "card",
            requestId: (
              newDate.toISOString() + Math.floor(Math.random() * 10000 + 1)
            )
              ?.toString()
              ?.replace(/[T.Z:_-]/g, ""),
            saveCard:
              page == "SavePay"
                ? saveCardd
                  ? 1
                  : 0
                : page == "Pay"
                ? saveCardd
                  ? 1
                  : 0
                : 0,
            surcharge: 0,
            storeId: storeName ? Number(storeName) : 1,
            cvv: data.cvv,
            zipCode: data?.zip,
          };

          // if (paymentTitle != "split") {
          //     handleCreditCardPayment(creditPayment);
          // }
          // else {
          handleSplitPaymentMethod(creditPayment, data);
          // }
        } else {
          toast.error("Please change the value of page in your url", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        if (page == "SavePay" || page == "Pay") {
          toast.error("Please select store to make payment", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
        }
      }
    } else {
      toast.error(
        !customerId
          ? "Please login before proceeding"
          : "Please check your url",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  //    console.log(remainingPayment > 0, saveCardd , customerId ,  page , isGuestLogin , giftCardAmount , amount,'manan',paymentTitle)

  return (
    <>
      {deviceType ? (
        location.pathname == "/addCard" ? (
          <>
            <Backdrop
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "rgba(0, 0, 0, 0)",
              }}
              open={loading}
            >
              <CircularProgress color="error" />
            </Backdrop>
            <Box>
              <>
                <div style={{ backgroundColor: "#FdF4F4" }}>
                  <Container sx={{ p: 0 }}>
                    <Card
                      sx={{
                        // borderRadius: "1rem",
                        minHeight: "100vh",
                        maxWidth: {
                          xs: "100vw",
                          sm: "70vw",
                          md: "50vw",
                          lg: "35vw",
                        },
                        marginX: "auto",
                        p: 0,
                      }}
                    >
                      <Container>
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          autoComplete="off"
                        >
                          <Typography
                            variant="h6"
                            align="center"
                            sx={{ fontWeight: "bold", marginY: "0.8rem" }}
                          >
                            Add Card Details
                          </Typography>
                          <Box
                            textAlign="center"
                            display="flex"
                            flexDirection="column"
                            // marginX="3rem"
                          >
                            <FormControl fullWidth>
                              <Controller
                                name="customerName"
                                rules={
                                  ({
                                    required: true,
                                  },
                                  {
                                    validate: (value) => {
                                      return validateCardHolderName(value);
                                    },
                                  })
                                }
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="customerName"
                                    variant="outlined"
                                    size="small"
                                    label="Card Holder's Name"
                                    inputProps={{
                                      maxLength: 30,
                                      classes: {
                                        notchedOutline: classes.noBorder,
                                      },
                                    }}
                                    sx={{
                                      backgroundColor: "#EEEEEE",
                                      borderRadius: "0.5rem",
                                      "& fieldset": { border: "none" },
                                    }}
                                    // onInput={
                                    //   (e) => handleUserCardDetails(e, "name")
                                    //   //  userCardDetails.name = e.target.value
                                    // }
                                  />
                                )}
                                control={control}
                              />
                            </FormControl>
                            {errors?.customerName ? (
                              <p className={classes.error}>
                                {errors.customerName.message}
                              </p>
                            ) : (
                              <p style={{ marginBottom: "1.6rem" }}>
                                {/* <br /> */}
                              </p>
                            )}

                            <Box
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <FormControl fullWidth>
                                <Controller
                                  name="accountNumber"
                                  rules={
                                    ({
                                      required: true,
                                    },
                                    {
                                      validate: (value) => {
                                        console.log(validCardNumber);
                                        return validateCreditCardNumber(
                                          value.split(" ").join(""),
                                          "creditCard",
                                          validCardNumber
                                            ? validCardNumber.length
                                            : 16
                                        );
                                      },
                                    })
                                  }
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      id="accountNumber"
                                      inputProps={{ inputMode: "numeric" }}
                                      // inputProps={{ maxLength: validCardNumber ? validCardNumber.length[validCardNumber.length.length] : 16 }}
                                      variant="outlined"
                                      size="small"
                                      label="Card Number"
                                      InputProps={{
                                        classes: {
                                          notchedOutline: classes.noBorder,
                                        },
                                      }}
                                      sx={{
                                        backgroundColor: "#EEEEEE",
                                        borderRadius: "0.5rem",
                                        "& fieldset": { border: "none" },
                                      }}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        let lengths = validCardNumber
                                          ? validCardNumber.length
                                          : [16];
                                        let gap = Math.floor(
                                          (validCardNumber
                                            ? validCardNumber.length[
                                                validCardNumber.length.length -
                                                  1
                                              ]
                                            : 16) / 4
                                        );
                                        e.target.value = e.target.value
                                          .replace(/(.{4})/g, "$1 ")
                                          .trim()
                                          .slice(
                                            0,
                                            lengths[lengths.length - 1] + gap
                                          );
                                        handleCardNumber(e);
                                      }}
                                    />
                                  )}
                                  control={control}
                                />
                              </FormControl>

                              <img
                                src={cardTypeImage}
                                alt=""
                                height="40vh"
                                style={{ marginBottom: "0.1rem" }}
                              />
                            </Box>
                            {errors?.accountNumber ? (
                              <p className={classes.error}>
                                {errors.accountNumber.message}
                              </p>
                            ) : (
                              <p style={{ marginBottom: "1.6rem" }}>
                                {/* <br /> */}
                              </p>
                            )}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "space-between",
                              }}
                            >
                              <FormControl>
                                <Controller
                                  name="cvv"
                                  rules={
                                    ({
                                      required: true,
                                    },
                                    {
                                      validate: (value) => {
                                        console.log(validCardNumber, "manan");
                                        let fieldName = "CVV";
                                        if (value) {
                                          if (
                                            value.length <
                                            (validCardNumber
                                              ? validCardNumber.cvv
                                              : 3)
                                          ) {
                                            return (
                                              "*Please enter at least" +
                                              ` ${
                                                validCardNumber
                                                  ? validCardNumber.cvv
                                                  : 3
                                              }` +
                                              " characters."
                                            );
                                          }
                                        }
                                        if (!value) {
                                          return validateNumber(
                                            value,
                                            fieldName
                                          );
                                        }
                                      },
                                    })
                                  }
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      id="cvv"
                                      variant="outlined"
                                      size="small"
                                      label="CVV"
                                      inputProps={{ inputMode: "numeric" }}
                                      InputProps={{
                                        classes: {
                                          notchedOutline: classes.noBorder,
                                        },
                                      }}
                                      sx={{
                                        backgroundColor: "#EEEEEE",
                                        borderRadius: "0.5rem",
                                        marginLeft: "1rem",
                                        "& fieldset": { border: "none" },
                                      }}
                                      // inputProps={{ maxLength: 3 }}
                                      onInput={(e) => {
                                        let re = new RegExp(
                                          "(.{" +
                                            `${
                                              validCardNumber
                                                ? validCardNumber.cvv
                                                : 3
                                            }}` +
                                            ")",
                                          "g"
                                        );
                                        e.target.value = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        e.target.value = e.target.value
                                          .replace(re, "$1")
                                          .trim()
                                          .slice(
                                            0,
                                            validCardNumber
                                              ? validCardNumber.cvv
                                              : 3
                                          );
                                      }}
                                    />
                                  )}
                                  control={control}
                                />
                                {errors?.cvv ? (
                                  <p className={classes.error1}>
                                    {errors.cvv.message}
                                  </p>
                                ) : (
                                  <p style={{ marginBottom: "1.6rem" }}>
                                    {/* <br /> */}
                                  </p>
                                )}
                              </FormControl>

                              {/* <div style={{ width: "35%", textAlign: "left" }}>Expiry date : </div> */}
                              {/* <FormControl
                                                            sx={{ width: { lg: "10vw", xs: "29vw" } }}
                                                            size="small"
                                                        >
                                                            <Controller
                                                                name="cardExpiry"
                                                                rules={
                                                                    ({
                                                                        required: true,
                                                                    },
                                                                    {
                                                                        validate: (value) => {
                                                                            return validateCardExpiry(expiryDate);
                                                                        },
                                                                    })
                                                                }
                                                                render={({ field }) => (
                                                                    <>
                                                                        <InputLabel id="demo-simple-select-autowidth-label">
                                                                            Month
                                                                        </InputLabel>
                                                                        <Select
                                                                            MenuProps={MenuProps}
                                                                            // sx={{}}
                                                                            onChange={(e) => handleDateChange(e, "month")}
                                                                            label="Month"
                                                                            InputProps={{
                                                                                classes: { notchedOutline: classes.noBorder },
                                                                            }}
                                                                            sx={{
                                                                                margin: "0rem 0.25rem" ,
                                                                                backgroundColor: "#EEEEEE",
                                                                                borderRadius: "0.5rem",
                                                                                borderStyle : "none"
                                                                            }}
                                                                            variant="outlined"
                                                                            defaultValue={month[0]}
                                                                        >
                                                                            {month.map((item, index) => {
                                                                                return (
                                                                                    <MenuItem value={item} key={index}>
                                                                                        {item}
                                                                                    </MenuItem>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                    </>
                                                                )}
                                                                control={control}
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: { lg: "10vw", xs: "29vw" } }} size="small">
                                                            <Controller
                                                                name="cardExpiry"
                                                                rules={
                                                                    ({
                                                                        required: true,
                                                                    },
                                                                    {
                                                                        validate: (value) => {
                                                                            return validateCardMobileExpiry(expiryDate);
                                                                        },
                                                                    })
                                                                }
                                                                render={({ field }) => (
                                                                    <>
                                                                        <InputLabel id="demo-simple-select-autowidth-label">
                                                                            Year
                                                                        </InputLabel>
                                                                        <Select
                                                                            MenuProps={MenuProps}
                                                                            label="Year"
                                                                            variant="outlined"
                                                                            InputProps={{
                                                                                classes: { notchedOutline: classes.noBorder },
                                                                            }}
                                                                            sx={{
                                                                                backgroundColor: "#EEEEEE",
                                                                                borderRadius: "0.5rem",
                                                                            }}
                                                                            onChange={(e) => handleDateChange(e, "year")}
                                                                            defaultValue={year[0]}
                                                                        >
                                                                            {year.map((item, index) => {
                                                                                return (
                                                                                    <MenuItem value={item} key={index}>
                                                                                        {item}
                                                                                    </MenuItem>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                    </>
                                                                )}
                                                                control={control}
                                                            />

                                                        </FormControl> */}
                              <FormControl>
                                <Controller
                                  name="cardExpiry"
                                  rules={
                                    ({
                                      required: true,
                                    },
                                    {
                                      validate: (expiryDate) => {
                                        return validateCardExpiry(expiryDate);
                                      },
                                    })
                                  }
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      id="cardExpiry"
                                      variant="outlined"
                                      placeholder="MM/YY"
                                      size="small"
                                      label="Exp Date"
                                      inputProps={{
                                        maxLength: 5,
                                        inputMode: "numeric",
                                      }}
                                      InputProps={{
                                        classes: {
                                          notchedOutline: classes.noBorder,
                                        },
                                      }}
                                      sx={{
                                        backgroundColor: "#EEEEEE",
                                        borderRadius: "0.5rem",
                                        // width : "50%",

                                        "& fieldset": { border: "none" },
                                      }}
                                      //   onChange={(e) => {
                                      //     const validationResult =
                                      //       validateCardExpiry(e.target.value);
                                      //     if (validationResult) {
                                      //       setError("cardExpiry", {
                                      //         type: "manual",
                                      //         message: validationResult,
                                      //       });
                                      //     } else {
                                      //       clearErrors("cardExpiry");
                                      //     }
                                      //     field.onChange(e);
                                      //   }}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                        e.target.value = e.target.value.replace(
                                          /(\d{2})(\d{2})/,
                                          "$1/$2"
                                        );
                                      }}
                                    />
                                  )}
                                  control={control}
                                />

                                {errors?.cardExpiry ? (
                                  <p className={classes.error}>
                                    {errors.cardExpiry.message}
                                  </p>
                                ) : (
                                  <p style={{ marginBottom: "1.6rem" }}>
                                    {/* <br /> */}
                                  </p>
                                )}
                              </FormControl>

                              {/* {errors?.cardExpiry ? (
                                                                <p className={classes.error}>
                                                                    {errors.cardExpiry.message}
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    <br />
                                                                </p>
                                                            )} */}
                            </div>
                            <FormControl>
                              <Controller
                                name="zipCode"
                                rules={
                                  ({
                                    required: true,
                                  },
                                  {
                                    validate: (value) => {
                                      return validateZipCode(value);
                                    },
                                  })
                                }
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="zipCode"
                                    variant="outlined"
                                    size="small"
                                    label="Zip Code"
                                    inputProps={{
                                      maxLength: 6,
                                      inputMode: "numeric",
                                    }}
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes.noBorder,
                                      },
                                    }}
                                    sx={{
                                      backgroundColor: "#EEEEEE",
                                      width: "45%",
                                      borderRadius: "0.5rem",
                                      "& fieldset": { border: "none" },
                                    }}
                                    onInput={(e) => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                    }}
                                  />
                                )}
                                control={control}
                              />
                            </FormControl>
                            {errors?.zipCode ? (
                              <p className={classes.error}>
                                {errors.zipCode.message}
                              </p>
                            ) : (
                              <p style={{ marginBottom: "1.6rem" }}>
                                {/* <br /> */}
                              </p>
                            )}
                          </Box>
                          <Divider style={{ marginBottom: "1.5rem" }} />
                          {/* <div style ={{display : "flex" , flexDirection : "row" , alignItems:"center"}}> */}

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <FormControl>
                              <Controller
                                name="captcha"
                                rules={
                                  ({
                                    required: true,
                                  },
                                  {
                                    validate: (captcha) => {
                                      if (!captcha) {
                                        return "*Please verify the captcha";
                                      }
                                    },
                                  })
                                }
                                render={({ field }) => (
                                  <Box
                                    sx={
                                      {
                                        //  marginLeft: { md: "5.1rem", lg: "5.1rem", sm: "4.5rem", xs: "1.3rem" }
                                      }
                                    }
                                  >
                                    {/* // sx={{width:"100px"}}> */}
                                    <ReCAPTCHA
                                      {...field}
                                      name="captcha"
                                      sitekey="6LctGXkjAAAAACb5PoqhOMQvbGShJJ8eVBHSgUbG"
                                    />
                                  </Box>
                                )}
                                control={control}
                              />
                            </FormControl>
                          </div>
                          {errors?.captcha ? (
                            <p className={classes.captchaError}>
                              {errors.captcha.message}
                            </p>
                          ) : (
                            <p>{/* <br /> */}</p>
                          )}

                          {page != "Save" &&
                          remainingPayment > 0 &&
                          !isGuestLogin &&
                          !giftCardAmount ? (
                            <div style={{ display: "flex" }}>
                              <Checkbox
                                checked={saveCardd}
                                onChange={(e) => {
                                  setsaveCardd(e.target.checked);
                                }}
                              />
                              <Typography sx={{ marginTop: "0.5rem" }}>
                                Save this card for future
                              </Typography>
                            </div>
                          ) : defaultCard ? (
                            ""
                          ) : giftCardAmount &&
                            giftCardAmount < amount &&
                            !isGuestLogin ? (
                            <div style={{ display: "flex" }}>
                              <Checkbox
                                checked={saveCardd}
                                onChange={(e) => {
                                  setsaveCardd(e.target.checked);
                                }}
                              />
                              <Typography sx={{ marginTop: "0.5rem" }}>
                                Save this card for future
                              </Typography>
                            </div>
                          ) : (
                            ""
                          )}
                          <Container
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "1vh",
                              marginBottom: "1rem",
                            }}
                          >
                            <div className={classes.root}>
                              <Button
                                className={classes.btnFinalPay}
                                variant="outlined"
                                sx={{
                                  border: "1px solid #B7273B",
                                  color: "#B7273B",
                                  borderRadius: "25px",
                                }}
                                type="submit"
                              >
                                {(page == "Pay" || page == "SavePay") &&
                                remainingPayment > 0 &&
                                !giftCardAmount
                                  ? `Make payment : $${remainingPayment}`
                                  : defaultCard
                                  ? "Add card"
                                  : giftCardAmount && giftCardAmount < amount
                                  ? `Make payment : $${remainingPayment}`
                                  : "Add and Continue"}
                              </Button>
                            </div>
                          </Container>
                        </form>
                      </Container>
                    </Card>
                  </Container>
                </div>
              </>
            </Box>
          </>
        ) : (
          <div>
            <Container sx={{ p: 0 }}>
              <Card
                sx={{
                  padding: "0rem",
                  minHeight: "50vh",
                  maxWidth: { xs: "100vw", sm: "70vw", md: "50vw", lg: "35vw" },
                  marginX: "auto",
                }}
              >
                <Container>
                  <form
                    onload={setTimeout(() => {
                      console.log("trial page", deviceType);
                      if (
                        location.pathname == "/addCard/success" &&
                        deviceType == "mobile"
                      ) {
                      } else if (
                        location.pathname == "/addCard/failure" &&
                        deviceType == "mobile"
                      ) {
                        console.log("manan payment utility");
                        let path = "/";
                        navigate(path);
                      }
                      setPaymentRequest();
                    }, 2000)}
                  >
                    {location.pathname == "/addCard/success" ? (
                      <>
                        <div style={{ width: "100%", margin: "auto" }}>
                          <img
                            src={Checkmark}
                            alt="Payment Successfull"
                            align="center"
                            textAlign="center"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <Typography
                          variant="h6"
                          align="center"
                          sx={{
                            fontWeight: "bold",
                            marginBottom: "1rem",
                            color: "green",
                          }}
                        >
                          Card added successfully
                        </Typography>
                      </>
                    ) : (
                      <div style={{ width: "100%", margin: "auto" }}>
                        <img
                          src={failed}
                          alt="Payment failed"
                          align="center"
                          textAlign="center"
                          style={{ width: "100%" }}
                        />
                      </div>
                    )}
                  </form>
                </Container>
              </Card>
            </Container>
          </div>
        )
      ) : (
        <div style={{ textAlign: "center", fontSize: "2rem" }}>
          404 Page not found
        </div>
      )}
      <AlertPopup
        open={pickUpLaterPopup}
        setOpen={setPickUpLaterPopup}
        title={"paymentCondition"}
        buttonType1={true}
        buttonType2={false}
        displayTitle={
          "The pickup time that you selected is no longer available. Please select another time slot."
        }
      />
    </>
  );
};
export default AddCardMobile;
