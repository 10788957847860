import { postPaymentWrapper, putPaymentWrapper, getPaymentWrapper } from "./PaymentWrapper";
import { getWrapper, postWrapper, putWrapper, deleteWrapper } from "./Wrapper";

export const GetAllCategories = (data) => {
  let url = `/gongchamobile/getCategories?${
    data.storeId ? "storeId=" + data.storeId : ""
  }${data.storeId && data.appType ? "&appType=" + data.appType : ""}
  `;
  return getWrapper(url);
};

export const getStoreStartendTime = (storeId) => {
  let url = `gongchamobile/getStoreStartendTime?storeId=${storeId}`;
  return getWrapper(url);
};

export const getGivexDetails = (storeId) => {
  let url = `/gongchamobile/getGivexDetails?storeId=${storeId}`;
  return getWrapper(url);
};

export const GetAllMenu = (data) => {
  let url = `/gongchamobile/getMenuItemsSec`;
  return postWrapper(url, data);
};

export const getBestSellers = (storeId, deviceType) => {
  let url = `/gongchamobile/getBestSellersSec?${ `storeId=` + (storeId ? storeId :1)}
  ${deviceType == 2 ? `&deviceType=` + 0 : 0}`;
  return getWrapper(url);
};

export const guestRegister = (data) => {
  let url = `/gongchamobile/guestRegister`;
  return postWrapper(url, data);
};

export const getfavoriteMenuOfCustomerJson = (customerId, storeId , deviceType,appType) => {
  let url = `/gongchamobile/getFavouriteMenuOfCustomerJson?${
    customerId ? `customerId=` + customerId : ""
  }${storeId ? `&storeId=` + storeId : ""}${deviceType ? `&deviceType=` + 0 : ""}${appType ? `&appType=` +appType : ""}`;
  return getWrapper(url);
};

export const getTaxes = (storeId, sync) => {
  let url = `/gongchamobile/getTaxes?${storeId ? `storeId=` + storeId : ""}${
    sync ? `&sync=` + sync : ""
  }`;
  return getWrapper(url);
};

export const getCustomizationOption = (data) => {
  let url = `/gongchamobile/getMenuItemById`;
  return postWrapper(url, data);
};

export const ApplyCoupon = (data) => {
  let url = `/gongchamobile/applyCouponCode`;
  return postWrapper(url, data);
};

export const PlaceOrder = (data) => {
  let url = `/gongchamobile/placeOrder`;
  return postWrapper(url, data);
};

export const Log_in = (data) => {
  let url = `/gongchamobile/login`;
  return postWrapper(url, data);
};

export const SocialLogin = (data) => {
  let url = `/gongchamobile/socialLogin`;
  return postWrapper(url, data);
};

export const ChangePassword = (data) => {
  let url = `/gongchamobile/changePassword`;
  return postWrapper(url, data);
};

export const getAllOrdersByCustomer = (data, pageNumber, pageSize, sortBy) => {
  let url = `/gongchamobile/getAllOrdersByCustomer?${
    pageNumber >= 0 ? `pageNumber=` + pageNumber : ""
  }${pageSize ? `&pageSize=` + pageSize : ""}${
    sortBy ? `&sortBy=` + sortBy : ""
  }`;
  return postWrapper(url, data);
};

export const getAllOrderByCustomersec=(data,pageNumber,pageSize,sortBy)=>{
  let url=`/gongchamobile/getAllOrdersByCustomerSec?${
    pageNumber >= 0 ? `pageNumber=` + pageNumber : ""
  }${pageSize ? `&pageSize=` + pageSize : ""}${
    sortBy ? `&sortBy=` + sortBy : ""
  }`;
  return postWrapper(url, data);
 };
 export const getCustomerOrderById=(orderId,orderType)=>{
  let url =`/gongchamobile/getCustomerOrderById?${
    orderId ? `orderId=` + orderId : ""
  }${orderType ? `&orderType=` + orderType : "all"}`
  return postWrapper(url);
 }

export const LoyalityPoint = (phoneNumber, personId) => {
  let url = `/gongchamobile/getCustomerLoyaltyPoint?${
    phoneNumber ? `phoneNumber=` + phoneNumber : ""
  }${personId ? `&personId=` + personId : ""}`;
  return postWrapper(url);
};

export const RewardList = (uid, personId,storeId) => {
  let url = `/gongchamobile/getRewardList?${uid ? `uid=` + uid : ""}${
    personId ? `&personId=` + personId : ""
  }${
    storeId ? `&storeId=` + storeId : ""
  }`;
  return getWrapper(url);
};

export const getStoreByCustomerLocation = (currentLat, currentLong) => {
  var url = `/gongchamobile/getStoreList?${
    currentLat ? `currentLat=` + currentLat : ""
  }
 ${currentLong ? `&currentLong=` + currentLong : ""}
 ${`&deviceType=` + 2}`;
  return getWrapper(url);
};

export const Register = (imageUpload, data) => {
  let url = `/gongchamobile/userRegister?${
    data.email ? `email=` + data.email : ""
  }${data.username ? `&username=` + data.username : ""}${
    data.firstName ? `&firstName=` + data.firstName : ""
  }${data.lastName ? `&lastName=` + data.lastName : ""}${
    data.phoneNumber ? `&phoneNumber=` + data.phoneNumber : ""
  }${data.password ? `&password=` + data.password : ""}${
    data.deviceToken ? `&deviceToken=` + data.deviceToken : ""
  }${`&deviceType=` + data.deviceType}${`&isKioskReg=` + data.isKioskReg}${
    data.dob ? `&dob=` + data.dob : ""
  }${data.fileName ? `&fileName=` + data.fileName : ""}`;
  return postWrapper(url, imageUpload);
};

export const UpdateUserProfile = (imageUpload, data) => {
  let url = `/gongchamobile/updateUserProfile?${
    data.email ? `email=` + data.email : ""
  }${data.userId ? `&userId=` + data.userId : ""}${
    data.firstName ? `&firstName=` + data.firstName : ""
  }${data.lastName ? `&lastName=` + data.lastName : ""}${
    data.phoneNumber ? `&phoneNumber=` + data.phoneNumber : ""
  }${`&deviceType=` + data.deviceType}${
    `&dateOfBirth=` + (data.dateOfBirth ? data.dateOfBirth : "")
  }${`&socialType=` + 0}`;
  // console.log(url, imageUpload);
  return postWrapper(url, imageUpload);
};

export const resendOtp = (phoneNumber, countryCode) => {
  let url = `/fivestarloyalty/resendOtp?${
    phoneNumber ? `phoneNumber=` + phoneNumber : ""
  }${countryCode ? `&mobileNoCode=` + countryCode : ""}`;
  return postWrapper(url);
};

export const checkUser = (phoneNumber, countryCode) => {
  let url = `/fivestarloyalty/checkUser?${
    phoneNumber ? `phoneNumber=` + phoneNumber : ""
  }${countryCode ? `&mobileNoCode=` + countryCode : ""}`;
  return postWrapper(url);
};

export const verifyOtp = (phoneNumber, data) => {
  let url = `/fivestarloyalty/verifyOtp?${
    phoneNumber ? `phoneNumber=` + phoneNumber : ""
  }${`&otp=` + data.otp}`;
  return getWrapper(url);
};

export const getAdvertismentDetail = (deviceType, storeId) => {
  let url = `/gongchamobile/getAdvertismentDetailSec?${
    deviceType == 2 ? `deviceType=` + 0 : 0
  }${storeId ? `&storeId=` + storeId : ""}`;
  return getWrapper(url);
};

export const forgetPassword = (deviceType, email, mobileNo, countryCode) => {
  let url = `/gongchamobile/forgetPassword?${
    deviceType ? `deviceType=` + deviceType : ""
  }${email ? `&email=` + email : ""}${mobileNo ? `&mobileNo=` + mobileNo : ""}${
    countryCode ? `&mobileNoCode=` + countryCode : ""
  }`;
  return getWrapper(url);
};

export const expireOtp = (phoneNumber) => {
  let url = `/fivestarloyalty/expireOtp?${
    phoneNumber ? `phoneNumber=` + phoneNumber : ""
  }`;
  return putWrapper(url);
};
export const cardPointePayment = (data) => {
  let url = `/gongcha/cardPointe/makePayment`;
  return postPaymentWrapper(url, data);
};

export const givexCardPayment = (data) => {
  let url = `/gongcha/givex/makePayment`;
  return postPaymentWrapper(url, data);
};

export const getGivexCardDetail = (customerId) => {
  let url = `/gongcha/givex/getGivexCard?customerId=${customerId}`;
  return getPaymentWrapper(url);
};

export const addGivexCard = (data) => {
  let url = `/gongcha/givex/addGivexCard`;
  return postPaymentWrapper(url, data);
};

export const deleteGivexCard = (givexCardNumber,userId) => {
  let url = `/gongcha/givex/deleteGivexCard?${
    givexCardNumber ? `givexCardNumber=` + givexCardNumber : ""
  }${userId ? `&userId=` + userId : ""}`;
  return putPaymentWrapper(url);
};

export const givexCardRefundPayment = (data) => {
  let url = `/gongcha/givex/refundPayment`;
  return postPaymentWrapper(url, data);
}

export const creditCardRefundPayment = (data) => {
  let url = `/gongcha/cardPointe/refund`;
  return postPaymentWrapper(url, data);
}
export const activateCard = (data) => {
  let url = `/gongcha/givex/activateCard`;
  return postPaymentWrapper(url, data);
};
export const getBalance = (data) => {
  let url = `/gongcha/givex/getBalance`;
  return postPaymentWrapper(url, data);
};
export const deleteAccount = (phoneNumber, email) => {
  let url = `/gongchamobile/deleteUser?${
    phoneNumber ? `&phoneNumber=` + phoneNumber : ""
  }${email ? `&emailId=` + email : ""}`;
  return deleteWrapper(url);
};

export const favoriteUnfavoriteMenu = (data) => {
  let url = `/gongchamobile/favouriteUnfavoriteMenu`;
  return postWrapper(url, data);
};

export const fivestarReward = (rewardTitle, data) => {
  let url = `/fivestarloyalty/fivestarRewardSec?${
    rewardTitle ? `rewardTitle=` + rewardTitle : ""
  }`;
  return postWrapper(url, data);
};

export const getSavedCardDetail = (CustomerId) => {
  let url = `/gongchamobile/getSavedCardDetail?${
    CustomerId ? `CustomerId=` + CustomerId : ""
  }`;
  return getWrapper(url);
}

export const addCreditCard = (data) => {
  let url = `/gongcha/cardPointe/cardpointeSaveProfile`
  return postPaymentWrapper(url,data);
}

export const cardPointePaymentByProfileId = (data) => {
  let url = `gongcha/cardPointe/makepaymentByProfile`;
  return postPaymentWrapper(url, data);
};

export const deleteSavedCard = (customerId, tokenNumber) => {
  let url = `/gongchamobile/deleteSaveCard?${
    customerId ? `customerId=` + customerId : ""
  }${tokenNumber ? `&tokenNumber=` + tokenNumber : ""}`;
  return putWrapper(url);
};
export const makeDefaultCard = (customerId,cardType,multiUseToken ) => {
  let url = `/gongchamobile/cardDefault?${
    customerId ? `customerId=` + customerId : ""
  }${cardType ? `&type=` + cardType : ""}${multiUseToken ? `&cardDetail=` + multiUseToken : ""}`;
  return putWrapper(url);
};
export const getAllPromotions = () => {
  let url = `/promotion/findAll`;
  return getWrapper(url);
};

export const getCustomerDetails = (partnerId, personId, phoneNumber, businessSoftwareId) => {
  let url = `/fivestarloyalty/getCustomerDetails?${
    partnerId ? `partnerId=` + partnerId : ""
  }${personId ? `&personId=` + personId : ""}${phoneNumber ? `&phoneNumber=` + phoneNumber : ""}${
    businessSoftwareId ? `&businessSoftwareId=` + businessSoftwareId : ""
  }`;
  return getWrapper(url);
};

export const sendReciept = (orderId, phoneNumber, email) => {
  let url = `/kiosk/sendReceipt?${
    phoneNumber != "" ? "phoneNumber=" + phoneNumber : ""
  }&${
    phoneNumber != "" ? "mobileNoCode=" + 1 : ""
  }&deviceType=kiosk&orderId=${orderId}&${email != "" ? "email=" + email : ""}`;
  return postWrapper(url);
};

export const ApplyAutomaticCoupon = (data) => {
  let url = `/gongchamobile/autoDiscount`;
  return postWrapper(url, data);
};