import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import "./customizedTable.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Paper from "@mui/material/Paper";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import { setOrderPageIndex } from "../../../app/storeSlice";
import { Backdrop, Button, TableContainer, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { IndeterminateCheckbox } from "./IndeterminateCheckbox";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import TableToolBar from "./TableToolBar";
// import { setFlag } from "../../../app/storeSlice";
// import { setUpdatedParams } from "../../../app/storeSlice";

//  css styling ******
const useStyle = makeStyles((theme) => ({
  tableRow: {
    "& >.MuiTableCell-root": {
      color: "#ab1700",
    },
  },
}));

const CustomizedTable = ({
  loading,
  setLoading,
  columns,
  data,
  deleteApiCall,
  getApiCall,
  searchParam,
  pageCount,
  totalDataCount,
  onSort,
  noData,
  setSearchParam,
  exportPdfName,
  stockFlag,
  setStockFlag,
  setRunButton,
  orderDetailsPageSize,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: pageCount,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      deleteApiCall &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                {/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}
              </div>
            ),
            Cell: ({ row }) => {
              if (row.values.actions != "total") {
                // return (
                //   <div>
                //     <IndeterminateCheckbox
                //       {...row.getToggleRowSelectedProps()}
                //     />
                //   </div>
                // );
              }
              return "";
            },
          },
          ...columns,
        ]);
    }
  );
  const classes = useStyle();
  // const [controlledPageIndex, setControlledPage] = React.useState(0)
  const tableStateUpdateRef = React.useRef(false);
  //   const storeId = useSelector((state) => state.stores.selectedStoreId);
  const [oldSearchParam, setOldSearchParam] = useState(searchParam);
  //   const [oldStoreId, setOldStoreId] = useState(storeId);
  // const [oldPageCount, setOldPageCount] = useState(pageCount);
  const dispatch = useDispatch();
  //   const user = useSelector((state) => state.user);
  //   const orderNo = useSelector((state) => state.permissions.orderNo);
  //   const orderPageIndex = useSelector((state) => state.stores.orderPageIndex);
  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    tableStateUpdateRef.current = true;
  }, [pageIndex, pageSize]);

  React.useEffect(() => {
    if (!tableStateUpdateRef.current) {
      gotoPage(pageIndex);
      setPageSize(pageSize);
    }

    // if (orderPageIndex == 0 && !tableStateUpdateRef.current) {
    //   gotoPage(0);
    // //   dispatch(setOrderPageIndex(-1));
    // }
  }, [data, gotoPage]);
  // dispatch(setUpdatedPageNumber(pageIndex));

  // clear our ref when the data is loaded, after we perform any side effects
  React.useEffect(() => {
    tableStateUpdateRef.current = false;
  }, [data]);

  const getRequestParams = useCallback(
    (sortBy, isDesc, page) => {
      // if (pageSize < 20) setPageSize(20);
      if (
        oldSearchParam != searchParam
        // ||
        // oldPageCount != pageCount
      ) {
        page = 0;
        gotoPage(0);
        setOldSearchParam(searchParam);
        // setOldStoreId(storeId);
        // setOldPageCount(pageCount);
      }
      let params = {
        searchString: searchParam || "",
        page: page,
        pageSize: pageSize < 20 ? 20 : pageSize,
        storeId: 2,
        sortBy: sortBy || null,
        order:
          noData === "Order" && isDesc === undefined
            ? isDesc
              ? "asc"
              : "desc"
            : isDesc
            ? "desc"
            : "asc",
      };
      return params;
    },

    [searchParam, pageIndex, pageSize]
  );
  useEffect(() => {
    let params = getRequestParams(sortBy[0]?.id, sortBy[0]?.desc, pageIndex);
    // if (orderNo) {
    //   params.searchString = orderNo;
    // }
    if (setRunButton) {
      setRunButton(params);
    }
    if (noData === "orderDetails") {
      setPageSize(orderDetailsPageSize);
    }
    onSort(params);
    // dispatch(
    //   setUpdatedParams({
    //     pageSize: pageSize,
    //     order: params?.order,
    //     pageNumber: pageIndex,
    //   })
    // );
  }, [searchParam, sortBy, pageIndex, pageSize]);

  useEffect(() => {
    if (stockFlag && stockFlag == true) {
      let params = getRequestParams(sortBy[0]?.id, sortBy[0]?.desc, pageIndex);
      onSort(params);
      setStockFlag(false);
    }
  }, [stockFlag]);

  //   const deleteUserHandler = (event) => {
  //     const idsTodelete = Object.values(selectedFlatRows).map(
  //       (data) => data.original
  //     );
  //     let params = getRequestParams(sortBy[0]?.id, sortBy[0]?.desc, pageIndex);
  //     deleteApiCall(idsTodelete, user?.roleId).then((res) => {
  //       getApiCall({
  //         pageSize: pageSize,
  //         order: params?.order,
  //         pageNumber: pageIndex,
  //       });

  //       setSearchParam("");
  //       if (res.code === 200) {
  //         toast.success(res.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //       if (res.code === 400) {
  //         toast.error(res.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     });
  //   };

  return (
    <div style={{ padding: 0, marginLeft: 0 }}>
      {deleteApiCall ? (
        <TableToolBar
          numSelected={Object.keys(selectedRowIds).length}
          //   deleteUserHandler={deleteUserHandler}
        />
      ) : (
        <TableToolBar />
      )}

      <Backdrop style={{ color: "#fff", zIndex: "0" }} open={loading}>
        {loading && (
          <img
            src="./BLACKPLAINLOADING.GIF"
            alt=""
            style={{ width: "4vw", margin: "auto" }}
          />
        )}
      </Backdrop>

      <TableContainer component={Paper} style={{ paddingLeft: 0 }}>
        <MaUTable {...getTableProps()} size="small">
          <TableHead>
            {
              <TableRow
                {...headerGroups[1].getHeaderGroupProps()}
                hover
                size="small"
              >
                {headerGroups[1].headers.map((column) => {
                  return (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        fontSize: "0.75rem",
                        minWidth: "auto",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                        backgroundColor: "#ECEAEA",
                        border: ".5px solid #C9C0C0",
                        // height:"5000"
                      }}
                      align={column.Header === "Items" ? "left" : "center"}
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardIcon
                            style={{
                              fontSize: 18,
                              float: "center",
                              paddingTop: 4,
                            }}
                          />
                        ) : (
                          <ArrowUpwardIcon
                            style={{
                              fontSize: 18,
                              float: "center",
                              paddingTop: 4,
                            }}
                          />
                        )
                      ) : (
                        <Typography>{""}</Typography>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            }
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {noData && rows.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={headerGroups[1].headers.length}
                  align="center"
                >
                  No {noData} available to display.
                </TableCell>
              </TableRow>
            )}
            {rows &&
              rows?.length > 0 &&
              rows.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow
                    {...row.getRowProps()}
                    hover
                    selected={row.isSelected}
                    className={
                      row?.values?.isApproved === "No" ? classes.tableRow : ""
                    }
                  >
                    {row.cells.map((cell) => {
                      return cell.column.Header == "Modifier Name" ||
                        cell.column.Header == "Store Names" ||
                        cell.column.Header == "Tier Description" ? (
                        <TableCell
                          {...cell.getCellProps({
                            style: {
                              fontSize: "0.75rem",
                              minWidth: "auto",
                              whiteSpace: "normal",
                              border: ".5px solid rgba(224, 224, 224, 1)",
                            },
                          })}
                          align="center"
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      ) : (
                        <TableCell
                          {...cell.getCellProps({
                            style: {
                              fontSize: "0.75rem",
                              minWidth: "auto",
                              whiteSpace: "nowrap",
                              border: ".5px solid rgba(224, 224, 224, 1)",
                            },
                          })}
                          align={
                            cell.column.Header === "Items"
                              ? "left"
                              : cell.column.Header === "Total" ||
                                cell.column.Header === "Each Price"
                              ? "right"
                              : "center"
                          }
                          // align="center"
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </MaUTable>
      </TableContainer>

      {noData !== "orderDetails" &&
        pageCount !== 0 &&
        pageCount != null &&
        data.length > 0 && (
          <div className="pagination">
            <span>
              Showing {1 + pageSize * pageIndex} to{" "}
              {totalDataCount < pageSize * (pageIndex + 1)
                ? totalDataCount
                : pageSize * (pageIndex + 1)}{" "}
              of {totalDataCount} entries. {"  "}
            </span>
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </Button>
            <Button
              onClick={() => gotoPage(pageIndex - 1)}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
            {pageIndex > 0 && (
              <Button
                onClick={() => gotoPage(pageIndex - 1)}
                disabled={!canPreviousPage}
              >
                {pageIndex}
              </Button>
            )}
            <Button disabled onClick={() => gotoPage(pageIndex)}>
              {pageIndex + 1}
            </Button>
            {pageIndex + 2 <= pageCount && (
              <Button
                disabled={!canNextPage}
                onClick={() => gotoPage(pageIndex + 1)}
              >
                {pageIndex + 2}
              </Button>
            )}
            {pageIndex + 3 <= pageCount && (
              <Button
                onClick={() => gotoPage(pageIndex + 2)}
                disabled={!canNextPage}
              >
                {pageIndex + 3}
              </Button>
            )}
            <Button
              onClick={() => gotoPage(pageIndex + 1)}
              disabled={!canNextPage}
            >
              {">"}
            </Button>
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>

            <span>
              <strong>
                Page {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize} per page
                  </option>
                ))}
              </select>
            </span>
          </div>
        )}
    </div>
  );
};

export default CustomizedTable;
