import React, { useEffect } from "react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { setGuestLogin, setLogin , setUser } from "../../Redux/UserSlice";
import Zoom from '@mui/material/Zoom';
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Dialog,
  Box,
  Button,
  FormControl,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Login from "./Login";
import {
  validateGuestUsername,
  validatePhoneNumber,
  validateUsername,
} from "../../Common/FormVailidation";
import { guestRegister } from "../../Api/API";
import { toast } from "react-toastify"
import { errorToastMessage } from "../../Common/utils";
toast.configure()
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: { borderRadius: 15 },
  noBorder: {
    border: "none",
  },
  btnLogin: {
    padding: "0.2rem 1rem",
    backgroundColor: "brown",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  guest: {
    cursor: "pointer",
  },
  error: {
    color: "#bf1650",
    textAlign : "left",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom  ref={ref} {...props} timeout={500}/>;
});


const Guest = (props) => {
  const defaultValues = {
    phoneNumber: "",
    name: "",
  };
  const { control, handleSubmit, formState, setValue, reset } = useForm({
    defaultValues,
  });
  const dispatch = useDispatch();
  const { errors } = formState;
  const { showGuest, setshowGuest } = props;
  const classes = useStyles();
  const closeAd = () => {
    setshowGuest(false)
    setValue("phoneNumber", "")
    // setValue("name", "Guest")
  };
  const [showAd, setShowAd] = useState(false);

   useEffect(()=>{
   if(showGuest){
    setValue("name","")
   }
   },[showGuest])
  

  const onSubmit = (data) => {
    data.phoneNumber = data.phoneNumber.replace(/-/g, "");
    console.log(data,"data22")
    dispatch(setGuestLogin(true))    
    dispatch(setUser({ first_name: data?.name.trim() ,phone_number:data.phoneNumber}));
    closeAd()
    // guestRegister(data)
    //   .then((res) => {
    //     console.log(res);
    //     if (res?.responseCode == 1) {
    //       toast.success(res?.responseMessage, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       pauseOnFocusLoss : false,
    //       autoClose : 1500,
    //       pauseOnHover : false,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: false,
    //       draggable: true,
    //       });
    //       let guestObject = {
    //         email:res?.result?.email,
    //         username: res?.result?.fullname,
    //         userId:res?.result?.id,
    //         loyalty_point : res?.result?.loyalty_point,
    //         phone_number : res?.result?.mobile_no,
    //         customer_id:res?.result?.id, 
    //         person_id:res?.result?.id
    //       }
    //       // dispatch(setLogin(true));
    //       dispatch(setGuestLogin(true))
    //       dispatch(setUser(guestObject));
    //       closeAd()
        
    //     }
    //     else {
    //       toast.error(res.responseMessage, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       pauseOnFocusLoss : false,
    //       autoClose : 1500,
    //       pauseOnHover : false,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: false,
    //       draggable: true,
    //       });
    //       setShowAd(true);
    //       setshowGuest(false);
    //       setValue("phoneNumber", "")
        
    //     }
    //   })
    //   .catch((err) => {
    //     errorToastMessage();
    //     console.log(err);
    //   });
  }



  return (
    <>
      <Dialog
        open={showGuest}
        // onClose={closeAd}
        classes={{
          paper: classes.paper,
        }}
        onKeyDown = {(e)=>  e.key == "Escape" && closeAd() }
        TransitionComponent={Transition}
      >
         <Box textAlign="right">
          <CancelIcon
            fontSize="large"
            onClick={() => {
              setshowGuest(false)
              setValue("phoneNumber", "")
              // setValue("name", "Guest")
            }}
          /> 
        </Box>
        <Box  p={3} textAlign="center">
          <DialogTitle>
            <img src="./NewLogoG.png" alt=""  width="70%"  />
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box>
              <FormControl fullWidth>
                <Controller
                  name="name"
                  rules={
                    ({
                      required: true,
                    },
                    {
                      validate: (value) => {
                        if(!value){
                          return "Please enter valid User Name";
                        }   
                      },
                    })
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="name"
                      type="text"
                      placeholder="Name*"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                      inputProps={{ maxLength: 30 }}
                      sx={{ backgroundColor: "#E7E7E7" }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^a-zA-Z.' ]/g,"")
                      }}
                    />
                  )}
                  control={control}
                />
              </FormControl>
              {errors?.name ? (
                <p className={classes.error}>{errors?.name?.message}</p>
              ) : (
                <p>
                  <br />
                </p>
              )}
            </Box>
            <Box>
              <FormControl fullWidth>
                <Controller
                  name="phoneNumber"
                  rules={
                    ({
                      required: true,
                    },
                    {
                      validate: (value) => {
                        return validatePhoneNumber(value);
                      },
                    })
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="phoneNumber"
                      type="text"
                      placeholder="Phone Number*"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                        form: {
                          autocomplete: "off",
                        },
                        
                      }}
                      inputProps={{ maxLength: 12 ,inputMode: 'numeric'}}
                      sx={{ backgroundColor: "#E7E7E7" }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "")
                        e.target.value = e.target.value.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "$1-$2-$3"
                        );
                      }}
                      
                    />
                  )}
                  control={control}
                />
              </FormControl>    
                <p>
                This phone number is used for sending receipts and notifications purposes.
                </p>
            </Box>
            <div>
              <Button
                type="submit"
                className={classes.btnLogin}
                variant="contained"
                style={{ textTransform: "none" }}
              >
                Continue as Guest
              </Button>
            </div>
          </form>
          <div className={classes.guest}>
            <Typography
              onClick={() => {
                setShowAd(true);
                setshowGuest(false);
              }}
              gutterBottom
              variant="subtitle1"
            >
              Existing Customer? Log in
            </Typography>
          </div>
        </Box>
      </Dialog>
      <Login showAd={showAd} setShowAd={setShowAd}></Login>
    </>
  );
};

export default Guest;
