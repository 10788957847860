import React from 'react'
import { useState } from 'react';
import Zoom from '@mui/material/Zoom';
import {
    Dialog,
    Box,
    Button,
    Select,
    FormControl,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
    InputLabel,
} from "@mui/material";
import { givexCardPayment } from '../../Api/API';
import { cardPointePayment } from '../../Api/API';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { Container } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { validateCreditCardNumber } from '../../Common/FormVailidation';
import { validateCardHolderName } from '../../Common/FormVailidation';
import { validateCardExpiry } from '../../Common/FormVailidation';
import { makeStyles } from "@mui/styles";
import { MenuProps } from '../../Common/utils';
import { setPaymentData } from '../../Redux/CartSlice';
import { setCheckoutFlag } from '../../Redux/UserSlice';
toast.configure();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} timeout={500} />;
});

const useStyles = makeStyles((theme) => ({
    paper: { borderRadius: 15 },
    btnFinalPay: {
        padding: "0.3rem 6rem",
        fontSize: "1rem",
        backgroundColor: "#B7273B",
        fontFamily: "Lusitana",
        borderRadius: "3rem",
        textTransform: "none",
    },
    payButton: {
        display: "flex",
        marginTop: "1rem",
        marginBottom: "1rem",
        justifyContent: "space-around",
        flexWrap: "wrap"
    },
    btn: {
        borderRadius: "0.4rem",
        boxShadow: "0 6px 20px 0 #dbdbe8",
        backgroundColor: "#fff",
        transition: "0.4s",
        padding: "0.7rem 3rem ",
        textTransform: "none",
    },
    spacing: {
        marginBottom: "0rem",
    },
    root: {
        "& .Mui-disabled": {
            background: "gray",
            color: "white",
        },
    },
    error: {
        color: "#bf1650",
        // marginLeft: "9.7rem",
        // marginBottom : '0rem',
        display: "flex",
        " &::before": {
            display: "inline",
            content: "⚠ ",
        },
    },
}));


const SplitCreditCardPayment = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    let year = [];
    let date = new Date();
    let thisYear = date.getFullYear();
    for (let i = 0; i <= 20; i++) {
        year.push(thisYear);
        thisYear = thisYear + 1;
    }
    const defaultValues = {
        accountNumber: "",
        cardNumber: "",
        amount: 0,
        cardExpiry: "",
        clientId: "AIPRUS",
        currency: "USD",
        customerIdentifier: "amish.dsy@gmail.com",
        customerName: "",
        password: "",
        description: "Demo payment",
        method: "card",
        requestId: "",
        saveCard: 0,
        surcharge: 0,
    };

    const { control, handleSubmit, formState, setValue, reset } = useForm({
        defaultValues,
    });
    const { errors } = formState;
    let month = [ "01","02","03","04","05","06","07","08","09","10","11","12"];
    const { splitPaymentDialog, setSplitPaymentDialog, remainingPayment, giftCardPaymentDetail } = props;
    const [userCardDetails, setUserCardDetails] = useState({
        name: "",
        cardNumber: "",
        expiryDate: "",
    });
    const storeName = useSelector((state) => state.store.nearestStore);
    const userDetail = useSelector((state) => state.user.user);
    const [expiryDate, setExpiryDate] = useState({ month: month[0], year: year[0] })
    const handleUserCardDetails = (data, textFieldType) => {
        setUserCardDetails({
            name: textFieldType == "name" ? data.target.value : userCardDetails.name,
            cardNumber:
                textFieldType == "cardNumber"
                    ? data.target.value
                    : userCardDetails.cardNumber,
            expiryDate:
                textFieldType == "expiryDate"
                    ? data.target.value
                    : userCardDetails.expiryDate,
        });
    };

    const handleDateChange = (event, type) => {
        console.log(event, type)
        if (type == "month") {
            // setExpiryDate({month : event.target.value ,...rest})
            expiryDate.month = event.target.value
            setExpiryDate(expiryDate)
        }
        else if (type == "year") {
            expiryDate.year = event.target.value
            setExpiryDate(expiryDate)
        }
    }
    console.log("harshitRwat_counting" ,giftCardPaymentDetail )
    const onSubmit = (data) => {
        data.amount = remainingPayment
        data.cardExpiry = `${expiryDate.month}/${(expiryDate.year).toString().slice(2, 4)}`;
        data.storeId = storeName.id;
        data.requestId = giftCardPaymentDetail.requestId
        data.saveCard = 1;
        console.log("harshit_rawat_split_payment" , data);
        cardPointePayment(data)
        .then((res) => {
          console.log(data, "harshit");
          if (res.responseCode == 0) {
            let newDate = new Date()
            let givexData = {
                amount:Number(giftCardPaymentDetail?.payment),
                cardNumber: giftCardPaymentDetail?.cardNumber,
                clientId: "AIPRUS",
                customerIdentifier: userDetail?.email ? userDetail?.email : "",
                customerName: userDetail
                  ? userDetail?.username || userDetail?.firstName
                  : "",
                description: "demo payment",
                requestId:   (newDate.toISOString() + Math.floor(Math.random() * 10000 + 1))?.toString()?.replace(/[T.Z:_-]/g,""),
                storeId: storeName?.id ? storeName?.id : 0,
                surcharge: 0,
              };
            //   console.log("beforeGivexHarshit" , givexData)
            let checkoutPaymentMethodArray = [];
            let paymentDetails = {
                "amount": "0",
                "amt": "0",
                "auth_code": "",
                "calculationAmt": "",
                "card_last_no": 0,
                "givex_code": 0,
                "givex_num": 0,
                "givex_trans_num": "",
                "method": res?.response?.paymentMethod,
                "payId": res?.response?.savedCardId,
                "payType": res?.response?.paymentMethod,
                "paymentMethod": res?.response?.paymentMethod,
                "trans_num": 0
            } 
            checkoutPaymentMethodArray.push(paymentDetails);
              givexCardPayment(givexData).then((resp)=>{
                // console.log(resp, "harshit_givex_details");

                paymentDetails = {
                    "amount": "0",
                    "amt": "0",
                    "auth_code": "",
                    "calculationAmt": "",
                    "card_last_no": 0,
                    "givex_code": 0,
                    "givex_num": 0,
                    "givex_trans_num": "",
                    "method": "gift_card",
                    "payId": resp?.response?.savedId,
                    "payType": resp?.response?.paymentMethod,
                    "paymentMethod": "gift_card",
                    "trans_num": 0
                } 
                checkoutPaymentMethodArray.push(paymentDetails);
                dispatch(setPaymentData(checkoutPaymentMethodArray));
                dispatch(setCheckoutFlag(true));
                setSplitPaymentDialog(false);
                let path = "/payment/success";
                navigate(path);
              })

            // let paymentData = {
            //   amount: remainingPayment,
            //   paymentRefId: res.response.paymentRefId,
            //   method: res.response.paymentMethod,
            //   message: res.message,
            //   type: "credit_card",
            //   savedCardId: res?.response?.savedCardId,
            // };
            
          } else if (
            res.responseCode == 1 &&
            res.responseMessage == "Duplicate Request Id"
          ) {
            // setOpen(false)
            toast.error("Server error ,Please try again", {
              position: toast.POSITION.TOP_RIGHT,
            });
            window.location.reload();
          } else if (
            res.responseCode == 1 &&
            res.responseMessage == "Invalid card"
          ) {
            // setOpen(false)
            toast.error("Please check your Card Number", {
              position: toast.POSITION.TOP_RIGHT,
            });
            window.location.reload();
          } else {
            // setOpen(false)
            let path = "/payment/failure";
            // navigate(path);
          }
        })
        .catch((err) => {
        //   setOpen(false)
          console.log(err.message);
        //   if (err) {
        //     let path = "/payment/failure";
        //     navigate(path);
        //   }
        });
    }

    return (
        <Dialog
            open={splitPaymentDialog}
            classes={{
                paper: classes.paper,
            }}
            // onKeyDown = {(e)=>  e.key == "Escape" && closeAd() }
            TransitionComponent={Transition}
        >
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box
                textAlign="center"
                display="flex"
                flexDirection="column"
            // marginX="3rem"
            >
                <FormControl fullWidth>
                    <Controller
                        name="customerName"
                        rules={
                            ({
                                required: true
                            },
                            {
                                validate: (value) => {
                                    return validateCardHolderName(value);
                                },
                            })
                        }
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="customerName"
                                variant="outlined"
                                size="small"
                                label="Card Holder's Name"
                                onInput={
                                    (e) => handleUserCardDetails(e, "name")
                                    //  userCardDetails.name = e.target.value
                                }
                            />
                        )}
                        control={control}
                    />
                </FormControl>
                {errors?.customerName ? (
                    <p className={classes.error}>
                        {errors.customerName.message}
                    </p>
                ) : (
                    <p>
                        <br />
                    </p>
                )}
                <FormControl fullWidth>
                    <Controller
                        name="accountNumber"
                        rules={
                            ({
                                required: true
                            },
                            {
                                validate: (value) => {
                                    return validateCreditCardNumber(
                                        value,
                                        "creditCard"
                                    );
                                },
                            })
                        }
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="accountNumber"
                                inputProps={{ maxLength: 16 }}
                                variant="outlined"
                                size="small"
                                label="Card Number"
                                onInput={(e) =>
                                    handleUserCardDetails(e, "cardNumber")
                                }
                            />
                        )}
                        control={control}
                    />
                </FormControl>
                {errors?.accountNumber ? (
                    <p className={classes.error}>
                        {errors.accountNumber.message}
                    </p>
                ) : (
                    <p>
                        <br />
                    </p>
                )}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <div style={{ width: "30%", textAlign: "left" }}>Expiry date : </div>
                    <FormControl
                        sx={{ width: { lg: "10vw", xs: "25vw" } }}
                        size="small"
                    >
                        <Controller
                            name="cardExpiry"
                            rules={
                                ({
                                    required: true,
                                },
                                {
                                    validate: (value) => {
                                        return validateCardExpiry(expiryDate);
                                    },
                                })
                            }
                            render={({ field }) => (
                                <>
                                    <InputLabel id="demo-simple-select-autowidth-label">
                                        Month
                                    </InputLabel>
                                    <Select
                                        MenuProps={MenuProps}
                                        sx={{ margin: "0rem 0.25rem" }}
                                        onChange={(e) => handleDateChange(e, "month")}
                                        label="Month"
                                        variant="outlined"
                                        defaultValue={month[0]}
                                    >
                                        {month.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </>
                            )}
                            control={control}
                        />
                    </FormControl>
                    <FormControl sx={{ width: { lg: "10vw", xs: "25vw" } }} size="small">
                        <Controller
                            name="cardExpiry"
                            rules={
                                ({
                                    required: true,
                                },
                                {
                                    validate: (value) => {
                                        return validateCardExpiry(expiryDate);
                                    },
                                })
                            }
                            render={({ field }) => (
                                <>
                                    <InputLabel id="demo-simple-select-autowidth-label">
                                        Year
                                    </InputLabel>
                                    <Select
                                        MenuProps={MenuProps}
                                        // value={time ? time : new Date().toTimeString().slice(0, 5)}
                                        onChange={(e) => handleDateChange(e, "year")}
                                        label="Year"
                                        variant="outlined"
                                        defaultValue={year[0]}
                                    >
                                        {year.map((item, index) => {
                                            return (
                                                <MenuItem value={item} key={index}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </>
                            )}
                            control={control}
                        />

                    </FormControl>
                </div>
                {errors?.cardExpiry ? (
                    <p className={classes.error}>
                        {errors.cardExpiry.message}
                    </p>
                ) : (
                    <p>
                        <br />
                    </p>
                )}

                <Container
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "5vh",
                        marginBottom: "1rem",
                    }}
                >
                    <div className={classes.root}>
                        <Button
                            className={classes.btnFinalPay}
                            variant="contained"
                            type="submit"
                        >
                            {`$${remainingPayment}`}
                        </Button>
                    </div>
                </Container>
            </Box>
            </form>
        </Dialog>
    )
}

export default SplitCreditCardPayment