import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import Upload from "./FileUpload";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setIsUserFiveStarData,
  setUserGoogleData,
} from "../../Redux/UserSlice";
import { formatPhoneNumbers, MenuProps } from "../../Common/utils";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import { checkUser } from "../../Api/API";
import OtpPopUp from "../Login/OtpPopUp";
import { errorToastMessage } from "../../Common/utils";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  Box,
  Backdrop,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Register, resendOtp, getCustomerDetails } from "../../Api/API";
import {
  validateConfirmPassword,
  validateDob,
  validateEmail,
  validatePassword,
  validatePhoneNumber,
  validateUsername,
} from "../../Common/FormVailidation";
import { setLogin, setUser, setUserFiveStarData } from "../../Redux/UserSlice";
import { useSelector } from "react-redux";
toast.configure();
const useStyles = makeStyles((theme) => ({
  error: {
    color: "#bf1650",
    // textAlign:"center",
    // marginLeft: "12rem",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  errorP: {
    color: "#bf1650",
    // marginLeft: "13.5rem",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
  hide: {
    display: "none",
  },
}));

const SignUp = () => {
  const defaultValues = {
    deviceToken: "",
    deviceType: 0,
    dob: "",
    email: "",
    firstName: "",
    isKioskReg: 0,
    lastName: "",
    password: "",
    phoneNumber: "",
    username: "",
    fileName: "",
  };
  const { control, handleSubmit, formState, setValue, reset, getValues } =
    useForm({
      defaultValues,
    });
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { errors } = formState;
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [imageUpload, setImageUpload] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneNo, setPhoneNo] = useState();
  const [showPopup, setshowPopup] = useState(false);
  const [signupCondition, setsignupCondition] = useState(false);
  const [month, setMonth] = useState("");
  const [monthss, setMonthss] = useState("");
  const [day, setDay] = useState("");
  const [dayss, setDayss] = useState("");
  const [dataReplicate, setDataReplicate] = useState("");
  const [checkUserError, setCheckUserError] = useState("");
  const [userRegistered, setUserRegistered] = useState(false);
  const [title, setTitle] = useState();
  const [verifiedUser, setVerifiedUser] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [selectedCategoryImage, setSelectedCategoryImage] = useState("");
  const isSignInFromGoogle = useSelector(
    (state) => state.user.isSignInFromGoogle
  );
  const isAlreadyUserChecked = useSelector(
    (state) => state.user.isAlreadyUserChecked
  );
  const isUserFiveStarData = useSelector(
    (state) => state.user.isUserFiveStarData
  );
  const userGoogleData = useSelector((state) => state.user.userGoogleData);
  const userFiveStarData = useSelector((state) => state.user.userFiveStarData);
  const [leapyear, setLeapyear] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [fiveStarUserDetails, setFiveStarUserDetails] = useState("");
  // console.log(isSignInFromGoogle);
  //  console.log(imageUpload)
  console.log(isAlreadyUserChecked, "harshit_rawat_from_login");
  console.log(userFiveStarData, "harshit_rawat_signupData");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  console.log(getValues("phoneNumber"), "response");

  console.log(fiveStarUserDetails, month, day, "response");
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [valuesCN, setValuesCN] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPasswordCN = () => {
    setValuesCN({ ...valuesCN, showPassword: !valuesCN.showPassword });
  };

  const handlePasswordChangeCN = (prop) => (event) => {
    setValuesCN({ ...valuesCN, [prop]: event.target.value });
  };
  useEffect(() => {
    // const googleLogin = {
    //   email: userGoogleData.email,
    //   firstName: userGoogleData.firstName,
    //   lastName: userGoogleData.lastName,
    //   username:userGoogleData.firstName + " "+ userGoogleData.lastName,
    // }
    // console.log(googleLogin);
    if (isSignInFromGoogle) {
      const googleLogin = {
        email: userGoogleData.email,
        firstName: userGoogleData.firstName,
        lastName: userGoogleData.lastName,
        username: userGoogleData.firstName + " " + userGoogleData.lastName,
      };
      reset(googleLogin);
      setSelectedCategoryImage(userGoogleData?.profilePicURL);
    }

    if (isUserFiveStarData) {
      console.log("harshit_fivestrt", userFiveStarData);
      const fiveStarLogin = {
        email: userFiveStarData.email,
        firstName: userFiveStarData.name,
        lastName: userFiveStarData.name,
        username: userFiveStarData.name,
        phoneNumber: formatPhoneNumbers(userFiveStarData.phone),
        dob: userFiveStarData.birthday,
      };
      reset(fiveStarLogin);
    }
  }, []);
  useEffect(() => {
    const partnerId = "";
    const personId = "";

    if (getValues("phoneNumber") && getValues("phoneNumber").length >= 10) {
      getCustomerDetails(partnerId, personId, getValues("phoneNumber"))
        .then((res) => {
          setFiveStarUserDetails(res?.data);
        })
        .catch((error) => {
          console.error(error, "Error from Server");
        });
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (fiveStarUserDetails != null) {
      let dob = fiveStarUserDetails?.account?.birthday?.split("-");
      if (dob != null) {
        setMonth(dob[1]);
        setDay(dob[2]);
      }
    } else {
      setMonth();
      setDay();
    }
  }, [fiveStarUserDetails]);
  console.log(signUp, "171");
  const onSubmit = (data) => {
    setDataReplicate(data);
    console.log(data);
    let phoneNumber = data.phoneNumber.replace(/-/g, "");
    if (isAlreadyUserChecked) {
      setSignUp(true);
    } else {
      handleCheckUser(phoneNumber);
    }
    dispatch(setUserGoogleData(""));
  };
  console.log("SignUp", signUp);
  useEffect(() => {
    let data = dataReplicate;
    if (signUp) {
      console.log("insideedge");
      setLoading(true);
      if (isSignInFromGoogle) {
        data.password = userGoogleData?.id;
        data.deviceType = 0;
        data.isKioskReg = 0;
        data.username = data?.firstName + " " + data.lastName;
        // data.dob = `${"1990"}-${Number(month) < 10 ? (months?.indexOf(month) + 1) : (months?.indexOf(month) + 1)}-${day}`;
        if (month && day) {
          data.dob = `${"1990"}-${month}-${day}`;
        } else {
          data.dob = `${"1990"}-${monthss}-${dayss}`;
        }
        data.fileName = selectedCategoryImage;
        let fd = new FormData();
        if (imageUpload) {
          fd.append("file", imageUpload.target.files[0]);
        }

        Register(fd, data)
          .then((res) => {
            if (res?.responseCode == 1) {
              setLoading(false);
              toast.success("Registration Successful", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
              dispatch(setLogin(true));
              dispatch(setUser(res.result));
              navigate(`/`);
              setSignUp(false);
            } else {
              toast.error(res.responseMessage, {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
              setLoading(false);
              setSignUp(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setSignUp(false);
          });
      } else if (isUserFiveStarData) {
        data.deviceType = 0;
        data.isKioskReg = 0;
        data.password = values.password;
        data.phoneNumber = data.phoneNumber.replace(/-/g, "");
        data.dob = `${"1990"}-${month}-${day}`;
        data.username = data?.firstName + " " + data.lastName;
        let fd = new FormData();
        // console.log(imageUpload.target.files[0])
        if (imageUpload) {
          fd.append("file", imageUpload.target.files[0]);
        }
        // setLoading(true);

        if (month && day) {
          data.dob = `${"1990"}-${month}-${day}`;
        } else {
          data.dob = `${"1990"}-${monthss}-${dayss}`;
        }
        Register(fd, data)
          .then((res) => {
            if (res?.responseCode == 1) {
              setLoading(false);
              toast.success("Registration Successful", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
              dispatch(setLogin(true));
              dispatch(setUser(res.result));
              setSignUp(false);
              dispatch(setIsUserFiveStarData(false));
              navigate(`/`);
            } else if (
              res?.response_code == 1 &&
              res?.responseMessage == "Profile Updated successfully"
            ) {
              setLoading(false);
              toast.success("Registration Successful", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
              dispatch(setLogin(true));
              dispatch(setUser(res.result));
              dispatch(setIsUserFiveStarData(false));
              navigate(`/`);
              setSignUp(false);
            } else {
              toast.error(res.responseMessage, {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
              setLoading(false);
              setSignUp(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setSignUp(false);
            setLoading(false);
          });
      } else {
        data.deviceType = 0;
        data.isKioskReg = 0;
        data.password = values.password;
        data.phoneNumber = data.phoneNumber.replace(/-/g, "");
        if (month && day) {
          data.dob = `${"1990"}-${month}-${day}`;
        } else {
          data.dob = `${"1990"}-${monthss}-${dayss}`;
        }

        data.username = data?.firstName + " " + data.lastName;
        let fd = new FormData();
        if (imageUpload) {
          fd.append("file", imageUpload.target.files[0]);
        }

        Register(fd, data)
          .then((res) => {
            if (res?.responseCode == 1) {
              setLoading(false);
              toast.success("Registration Successful", {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
              dispatch(setLogin(true));
              setSignUp(false);
              dispatch(setUser(res.result));
              navigate(`/`);
            } else {
              toast.error(res.responseMessage, {
                position: toast.POSITION.TOP_RIGHT,
                pauseOnFocusLoss: false,
                autoClose: 1500,
                pauseOnHover: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
              });
              setLoading(false);
              setSignUp(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setSignUp(false);
          });
      }
    }
  }, [signUp]);

  const handleImage = (e) => {
    setImageUpload(e);
  };

  const handleCheckUser = (phoneNumber) => {
    console.log(phoneNumber, "number");
    phoneNumber = phoneNumber.replace(/-/g, "");
    setLoading(true);
    setPhoneNo(phoneNumber);
    checkUser(phoneNumber, "+91")
      .then((res) => {
        setCheckUserError("");
        setLoading(false);
        if (res.response_code == 1 && res.result.otp == false) {
          setCheckUserError("This phone number already exists");
          setLoading(false);
          setSignUp(false);
        } else if (
          res?.response_code == "0" &&
          res?.response_message == "Membership does not exist at this business"
        ) {
          setTitle("Signup");
          setCheckUserError("");
          setshowPopup(true);
          setLoading(false);
        } else {
          setTitle("Signup");
          setCheckUserError("");
          resendOtp(phoneNumber, "+91")
            .then((resp) => {
              setshowPopup(true);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setSignUp(false);
              setLoading(false);
              errorToastMessage();
              // return false;
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setSignUp(false);
        setLoading(false);
        errorToastMessage();
        // return false;
      });
  };

  // const months = [
  //   "January",
  //   "Feburary",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];

  console.log(phoneNumber, "number");
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const handleMonth = (e) => {
    setMonth(e.target.value);
    console.log(e.target.value);
    setDay("");
    if (isLeapYear(new Date().getFullYear())) {
      setLeapyear(true);
    } else {
      setLeapyear(false);
    }
  };

  const handleMonths = (e) => {
    setMonthss(e.target.value);
    console.log(e.target.value);
    setDay("");
    if (isLeapYear(new Date().getFullYear())) {
      setLeapyear(true);
    } else {
      setLeapyear(false);
    }
  };

  function isLeapYear(year = new Date().getFullYear()) {
    return new Date(year, 1, 29).getDate() === 29;
  }
  const days1 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
  ];
  const days4 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
  ];
  const days2 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ];
  const days = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  const handleDay = (e) => {
    setDay(e.target.value);
  };
  const handleDays = (e) => {
    setDayss(e.target.value);
  };
  console.log(month, "month");
  return (
    <div
      style={{
        backgroundColor: "#E7E7E7",
        paddingBottom: "3vh",
      }}
    >
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container style={{ marginTop: "3vh" }}>
        <Card sx={{ borderRadius: "1rem" }}>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Grid container sx={{ margin: { lg: "1rem" } }}>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sm={12}
                  lg={3}
                  marginBottom="1.5rem"
                  align="center"
                >
                  <FormControl fullWidth>
                    <Controller
                      name="file"
                      render={({ field }) => (
                        <>
                          <input
                            name="upload-image"
                            type="file"
                            {...field}
                            id="file"
                            className={classes.hide}
                            accept="image/*"
                            onChange={(e) => handleImage(e)}
                          />
                          <Upload
                            imageUpload={imageUpload}
                            selectedImage={selectedCategoryImage}
                          />
                        </>
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12} sm={10} lg={6}>
                  <Box display="flex">
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        First Name
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="firstName"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validateUsername(value);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="firstName"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Enter your first name"
                            inputProps={{
                              maxLength: 30,
                              autocomplete: "firstName",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              );
                              // e.target.value = e.target.value.replace(
                              //   /(\d{3})(\d{3})(\d{4})/,
                              //   "$1-$2-$3"
                              // );
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.firstName ? (
                        <p className={classes.error}>
                          {errors.firstName.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box display="flex">
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Last Name
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="lastName"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validateUsername(value);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="lastName"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Enter your last name"
                            inputProps={{
                              maxLength: 30,
                              autocomplete: "lastName",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^a-zA-Z ]/g,
                                ""
                              );
                              // e.target.value = e.target.value.replace(
                              //   /(\d{3})(\d{3})(\d{4})/,
                              //   "$1-$2-$3"
                              // );
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.lastName ? (
                        <p className={classes.error}>
                          {errors.lastName.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box display="flex">
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Phone No.
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="phoneNumber"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (phoneNumber) => {
                              console.log(phoneNumber, "number123");
                              return validatePhoneNumber(phoneNumber);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="phoneNumber"
                            disabled={isUserFiveStarData ? true : false}
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Your contact number"
                            // value={phoneNumber} // Set the value of the input field to the state variable
                            // onChange={(e) => {
                            //   // Update the state when the input value changes
                            //  // const formattedValue = e.target.value.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
                            //   setPhoneNumber(e.target.value);
                            // }}

                            inputProps={{
                              autocomplete: "phoneNumber",
                              form: {
                                autocomplete: "off",
                              },
                              maxLength: 12,
                              inputMode: "numeric",
                            }}
                            onInput={(e) => {
                              setPhoneNumber(e.target.value);
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              e.target.value = e.target.value.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                "$1-$2-$3"
                              );
                              if (e.target.value.length < 12) {
                                setCheckUserError("");
                              }
                            }}
                            // onBlur={(e) => {
                            //   console.log(e.target.value.length)
                            //   if (e.target.value.length == 12) {
                            //     handleCheckUser(e.target.value)
                            //   }
                            // }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.phoneNumber || checkUserError ? (
                        <p className={classes.error}>
                          {errors?.phoneNumber?.message || checkUserError}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box display="flex">
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Email
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="email"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              return validateEmail(value);
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="email"
                            type="text"
                            fullWidth
                            size="small"
                            variant="standard"
                            placeholder="Enter your mail"
                            inputProps={{
                              autocomplete: "email",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.email ? (
                        <p className={classes.error}>{errors.email.message}</p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <Box sx={{ width: "12rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Birthday
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <div>
                      <div style={{ display: "flex" }}>
                        <FormControl fullWidth>
                          <Controller
                            name="dob"
                            rules={
                              ({
                                required: true,
                              },
                              {
                                validate: (value) => {
                                  if (month) {
                                    return validateDob(month);
                                  } else {
                                    return validateDob(monthss);
                                  }
                                },
                              })
                            }
                            render={({ field }) => (
                              <div>
                                <InputLabel
                                  sx={{ display: { xs: "none", sm: "block" } }}
                                >
                                  Select Month
                                </InputLabel>
                                <InputLabel
                                  sx={{ display: { xs: "block", sm: "none" } }}
                                >
                                  Month
                                </InputLabel>
                                {month ? (
                                  <Select
                                    {...field}
                                    disabled={
                                      getValues("phoneNumber") && month
                                        ? true
                                        : false
                                    }
                                    IconComponent="none"
                                    MenuProps={MenuProps}
                                    label="Select Month"
                                    id="dob"
                                    value={
                                      getValues("phoneNumber") && month
                                        ? month
                                        : ""
                                    }
                                    onChange={handleMonth}
                                    // size="small"
                                    inputProps={{
                                      autocomplete: "dob",
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                    variant="outlined"
                                    sx={{
                                      width: {
                                        lg: "11vw",
                                        md: "13vw",
                                        sm: "20vw",
                                        xs: "20vw",
                                      },
                                    }}
                                  >
                                    {months.map((month, index) => {
                                      return (
                                        <MenuItem key={index} value={month}>
                                          {month}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                ) : (
                                  <Select
                                    {...field}
                                    // disabled={getValues("phoneNumber")&&month?true:false }
                                    IconComponent="none"
                                    MenuProps={MenuProps}
                                    label="Select Month"
                                    id="dob"
                                    value={monthss}
                                    onChange={handleMonths}
                                    // size="small"
                                    inputProps={{
                                      autocomplete: "dob",
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                    variant="outlined"
                                    sx={{
                                      width: {
                                        lg: "11vw",
                                        md: "13vw",
                                        sm: "20vw",
                                        xs: "20vw",
                                      },
                                    }}
                                  >
                                    {months.map((month, index) => {
                                      return (
                                        <MenuItem key={index} value={month}>
                                          {month}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                )}
                              </div>
                            )}
                            control={control}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Controller
                            name="dob"
                            rules={
                              ({
                                required: true,
                              },
                              {
                                validate: (value) => {
                                  if (day) {
                                    return validateDob(day);
                                  } else {
                                    return validateDob(dayss);
                                  }
                                },
                              })
                            }
                            render={({ field }) => (
                              <>
                                <InputLabel
                                  sx={{ display: { xs: "none", sm: "block" } }}
                                >
                                  Select Day
                                </InputLabel>
                                <InputLabel
                                  sx={{ display: { xs: "block", sm: "none" } }}
                                >
                                  Day
                                </InputLabel>
                                {day ? (
                                  <Select
                                    {...field}
                                    disabled={
                                      getValues("phoneNumber") && day
                                        ? true
                                        : false
                                    }
                                    MenuProps={MenuProps}
                                    label="Select Day"
                                    id="dob"
                                    value={
                                      getValues("phoneNumber") && day ? day : ""
                                    }
                                    onChange={handleDay}
                                    IconComponent="none"
                                    // size="small"
                                    inputProps={{
                                      autocomplete: "dob",
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                    variant="outlined"
                                    sx={{
                                      width: {
                                        lg: "11vw",
                                        md: "13vw",
                                        sm: "20vw",
                                        xs: "20vw",
                                      },
                                      marginRight: "0.5rem",
                                    }}
                                  >
                                    {(months.indexOf(month) % 2 == 0
                                      ? days
                                      : months.indexOf(month) == 1
                                      ? leapyear
                                        ? days4
                                        : days1
                                      : months.indexOf(month) == 7
                                      ? days
                                      : days2
                                    ).map((day, index) => {
                                      return (
                                        <MenuItem key={index} value={day}>
                                          {day}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                ) : (
                                  <Select
                                    {...field}
                                    // disabled={getValues("phoneNumber")&&day?true:false}
                                    MenuProps={MenuProps}
                                    label="Select Day"
                                    id="dob"
                                    value={dayss}
                                    onChange={handleDays}
                                    IconComponent="none"
                                    // size="small"
                                    inputProps={{
                                      autocomplete: "dob",
                                      form: {
                                        autocomplete: "off",
                                      },
                                    }}
                                    variant="outlined"
                                    sx={{
                                      width: {
                                        lg: "11vw",
                                        md: "13vw",
                                        sm: "20vw",
                                        xs: "20vw",
                                      },
                                      marginRight: "0.5rem",
                                    }}
                                  >
                                    {(months.indexOf(month) % 2 == 0
                                      ? days
                                      : months.indexOf(month) == 1
                                      ? leapyear
                                        ? days4
                                        : days1
                                      : months.indexOf(month) == 7
                                      ? days
                                      : days2
                                    ).map((day, index) => {
                                      return (
                                        <MenuItem key={index} value={day}>
                                          {day}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                )}
                              </>
                            )}
                            control={control}
                          />
                        </FormControl>
                      </div>
                      {errors?.dob ? (
                        <p className={classes.error}>{errors.dob.message}</p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </div>
                  </Box>
                </Grid>
                <Container
                  sx={{
                    marginTop: "2rem",
                    display: { xs: "none", sm: "none" },
                  }}
                >
                  <Box
                    sx={{
                      marginRight: {
                        xs: "0",
                        sm: "0",
                        md: "16rem",
                        lg: "16rem",
                      },
                    }}
                    display="flex"
                  >
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Choose Password
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="password"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              setPassword(value);
                              if (!value) {
                                return validatePassword(value);
                              } else if (value.length > 0 && value.length < 8) {
                                return validatePassword(value);
                              } else if (value.length >= 8) {
                                return value.length > 16
                                  ? "More than 16 characters are not Allowed"
                                  : validatePassword(value);
                              }
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="password"
                            size="small"
                            placeholder="Enter 8-16 digit password"
                            type={values.showPassword ? "text" : "password"}
                            onInput={handlePasswordChange("password")}
                            value={values.password}
                            variant="standard"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleClickShowPassword}>
                                    {values.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              // autocomplete: "password",
                              // form: {
                              //   autocomplete: "off",
                              // },
                            }}
                            inputProps={{
                              maxLength: 16,
                              autocomplete: "password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.password ? (
                        <p className={classes.errorP}>
                          {errors.password.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box
                    sx={{
                      marginRight: {
                        xs: "0",
                        sm: "0",
                        md: "16rem",
                        lg: "16rem",
                      },
                    }}
                    display="flex"
                  >
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Confirm Password
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="confirmPassword"
                        rules={
                          ({ required: true },
                          {
                            validate: (value) => {
                              if (password) {
                                return validateConfirmPassword(value, password);
                              } else if (!value) {
                                return "Confirm password is required";
                              }
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            id="confirmPassword"
                            {...field}
                            size="small"
                            placeholder="Re-enter password"
                            type={valuesCN.showPassword ? "text" : "password"}
                            onInput={handlePasswordChangeCN("password")}
                            value={valuesCN.password}
                            variant="standard"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowPasswordCN}
                                  >
                                    {valuesCN.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              maxLength: 16,
                              autocomplete: "confirmPassword",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.confirmPassword ? (
                        <p className={classes.errorP}>
                          {errors.confirmPassword.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>
                </Container>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  sx={{ display: { xs: "block", sm: "block" } }}
                >
                  <Box
                    sx={{
                      marginRight: {
                        xs: "0",
                        sm: "0",
                        md: "16rem",
                        lg: "16rem",
                      },
                    }}
                    display="flex"
                  >
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Choose Password
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="password"
                        rules={
                          ({
                            required: true,
                          },
                          {
                            validate: (value) => {
                              setPassword(value);
                              if (!value) {
                                return validatePassword(value);
                              } else if (value.length > 0 && value.length < 8) {
                                return validatePassword(value);
                              } else if (value.length >= 8) {
                                return value.length > 16
                                  ? "More than 16 characters are not Allowed"
                                  : validatePassword(value);
                              }
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="password"
                            size="small"
                            placeholder="Enter 8-16 digit password"
                            type={values.showPassword ? "text" : "password"}
                            onInput={handlePasswordChange("password")}
                            value={values.password}
                            variant="standard"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleClickShowPassword}>
                                    {values.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            inputProps={{
                              maxLength: 16,
                              // autocomplete: "password",
                              // form: {
                              //   autocomplete: "off",
                              // },
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.password ? (
                        <p className={classes.errorP}>
                          {errors.password.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>

                  <Box
                    sx={{
                      marginRight: {
                        xs: "0",
                        sm: "0",
                        md: "16rem",
                        lg: "16rem",
                      },
                    }}
                    display="flex"
                  >
                    <Box sx={{ width: "18rem", display: "flex" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Confirm Password
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Controller
                        name="confirmPassword"
                        rules={
                          ({ required: true },
                          {
                            validate: (value) => {
                              if (password) {
                                return validateConfirmPassword(value, password);
                              } else if (!value) {
                                return "Confirm password is required";
                              }
                            },
                          })
                        }
                        render={({ field }) => (
                          <TextField
                            id="confirmPassword"
                            {...field}
                            size="small"
                            placeholder="Re-enter password"
                            type={valuesCN.showPassword ? "text" : "password"}
                            onInput={handlePasswordChangeCN("password")}
                            value={valuesCN.password}
                            variant="standard"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleClickShowPasswordCN}
                                  >
                                    {valuesCN.showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                            inputProps={{
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                              maxLength: 16,
                            }}
                          />
                        )}
                        control={control}
                      />
                      {errors?.confirmPassword ? (
                        <p className={classes.errorP}>
                          {errors.confirmPassword.message}
                        </p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </FormControl>
                  </Box>
                </Grid>

                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0",
                    marginTop: "-1rem",
                  }}
                >
                  <Checkbox
                    checked={signupCondition}
                    onChange={(e) => {
                      setsignupCondition(e.target.checked);
                    }}
                  />
                  <h6 style={{ marginTop: "0.7rem" }}>
                    By signing up, you agree to the
                    <a
                      href={"https://gongchausa.com/terms-conditions/"}
                      target="_blank"
                      style={{
                        marginLeft: "0.1rem",
                        marginRight: "0.3rem",
                        textDecoration: "none",
                      }}
                    >
                      {" "}
                      Terms & Conditions
                    </a>
                    and
                    <a
                      href={"https://gongchausa.com/privacy-policy/"}
                      target="_blank"
                      style={{ marginLeft: "0.1rem", textDecoration: "none" }}
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </h6>
                </Container>
                <Container sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    disabled={signupCondition ? false : true}
                    sx={{
                      padding: "0.5rem 2rem",
                      backgroundColor: "#B7273B",
                      fontFamily: "Lusitana",
                      borderRadius: "3rem",
                      marginBottom: "2rem",
                    }}
                    variant="contained"
                    style={{ textTransform: "none" }}
                  >
                    Sign Up
                  </Button>
                </Container>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
      {showPopup && (
        <OtpPopUp
          showPopup={showPopup}
          setshowPopup={setshowPopup}
          phoneNo={phoneNo}
          title={title}
          setSignUp={setSignUp}
          setUserRegistered={setUserRegistered}
        />
      )}
    </div>
  );
};

export default SignUp;
