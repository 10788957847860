import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  Box,
  Button,
  DialogTitle,
  TextField,
  FormControl,
  Typography,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MenuProps } from "../../Common/utils";
import SocialButton from "./SocialButton";
import { toast } from "react-toastify";
import { deleteAccount, Log_in, SocialLogin, UpdateUserProfile } from "../../Api/API";
import { checkUser } from "../../Api/API";
import { validateDob } from "../../Common/FormVailidation";
import { useForm, Controller } from "react-hook-form";
import { setIsSignInFromGoogle, setLogin, setUser, setUserFiveStarData, setUserGoogleData } from "../../Redux/UserSlice";
import {
  validatePassword,
  validatePhoneNumber,
} from "../../Common/FormVailidation";
import CancelIcon from "@mui/icons-material/Cancel";
import Login from "./Login";
import OtpPopUp from "./OtpPopUp";
import {CircularProgress} from "@mui/material";
toast.configure();
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: { borderRadius: 15 },
  noBorder: {
    border: "none",
  },
  btnLogin: {
    padding: "0.2rem 5rem",
    backgroundColor: "#B7273B",
    margin: "auto",
    fontFamily: "Lusitana",
    textTransform: "none",
  },
  btnNew: {
    padding: "0.2rem 0.5rem",
    backgroundColor: "#B7273B",
    fontFamily: "Lusitana",
    textDecoration: "none",
    color: "white",
    textTransform: "none",
  },
  error: {
    color: "#bf1650",
    " &::before": {
      display: "inline",
      content: "⚠ ",
    },
  },
}));
const SocialLogins = (props) => {
  const defaultValues = {
    phone: "",
    dob:"",
  };
  const { control, handleSubmit, formState, setValue, reset } = useForm({
    defaultValues,
  });
  const { errors } = formState;
  const { showLoginPopup, setShowLoginPopup, googleLoginData } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [leapyear, setLeapyear] = useState(false)
  const [userRegistered, setUserRegistered] = useState(false);
  const [checkUserError, setCheckUserError] = useState("");
  const [phoneNo, setPhoneNo] = useState();
  const [monthNumeric , setMonthNumeric] = useState("");
  const [title, setTitle] = useState();
  const [showPopup, setshowPopup] = useState(false);
  const [birth , setBirth] = useState("");
  const [loading , setLoading] = useState(false);
  const [showAd, setShowAd] = useState(false);
  const isLogin = useSelector((state) => state.user.isLogin);
  const userGoogleData = useSelector((state) => state.user.userGoogleData);
  const closeAd = () => {
    setShowLoginPopup(false);
    deleteAccount("", googleLoginData?.email);
  }
// console.log(googleLoginData,"kjh")
  const onSubmit = (data) => {
    let phone = data.phone;
    phone = phone.replace(/-/g, "");
    data.dob = `${"1990"}-${month}-${day}`;
    setPhoneNo(phone);
    setBirth(data.dob);
    setLoading(true);
    checkUser(phone, "+91")
    .then((res) => {
      setLoading(false);
      if (res.response_code == 1 && res.result.otp == false) {
        toast.error("Phone number already in use", {
          position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
        setLoading(false);
      }
      else if(res.response_code == 1 && res.response_message == "This phone no already exists")
      {
        toast.error("Phone number already in use", {
          position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss : false,
          autoClose : 1500,
          pauseOnHover : false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
        setLoading(false);
      }  
    else {
      setTitle("GoogleLogin")
      setshowPopup(true);
      setLoading(false);
      // setShowLoginPopup(false)
    }
  })
    // phone = phone.replace(/-/g, "");
    // if (phone) {
    //   const updateProfileGoogleLoginData = {
    //     deviceType: 2,
    //     dateOfBirth: googleLoginData.date_of_birth ? googleLoginData.date_of_birth : "1970-01-01",
    //     email: googleLoginData.email,
    //     firstName: userGoogleData.firstName,
    //     lastName: userGoogleData.lastName,
    //     phoneNumber: phone,
    //     userId: googleLoginData.customer_id,
    //     socialType: "google"
    //   };
    //   let fd = new FormData()
    //   UpdateUserProfile(fd, updateProfileGoogleLoginData)
    //     .then((res) => {
    //       console.log(res)
    //       if (res.responseCode == 1 || res.response_code == 1) {
           
    //         setShowLoginPopup(false);
    //         dispatch(setLogin(true));
    //         dispatch(setUser(res.result));
    //       }
    //       else if(res.response_code == 0 )
    //       {
    //         toast.error(res?.message, {
    //           position: toast.POSITION.TOP_RIGHT,
    //         });
    //         deleteAccount("", googleLoginData?.email);
    //         setValue("phone","")
    //         setShowLoginPopup(false);
    //         setShowAd(true);          
    //       }
    //       else
    //       {
    //         toast.error(res?.responseMessage, {
    //           position: toast.POSITION.TOP_RIGHT,
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  };

  // const months = [
  //  "January",
  //   "Feburary",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];

  const months = [
    "01",
     "02",
     "03",
     "04",
     "05",
     "06",
     "07",
     "08",
     "09",
     "10",
     "11",
     "12",
   ];

  // const monthstrial = [
    
  //   {
  //     mon:"January",
  //     num:"01"
  //   }

  //     "January",
  //    "Feburary",
  //    "March",
  //    "April",
  //    "May",
  //    "June",
  //    "July",
  //    "August",
  //    "September",
  //    "October",
  //    "November",
  //    "December",
  //  ];
  const handleMonth = (e) => {

    setMonth(e.target.value);
    // let monthIndex = months?.findIndex((item,index)=> item == e.target.value)
    setMonthNumeric(months[e.target.value])
    console.log(e, "harshit_rawat")
    setDay("")
    if (isLeapYear(new Date().getFullYear())) {
      setLeapyear(true)
    } else {
      setLeapyear(false)
    }

  };

  function isLeapYear(year = new Date().getFullYear()) {
    return new Date(year, 1, 29).getDate() === 29;
  }
  const days1 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28"
  ];
  const days4 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"
  ];
  const days2 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"
  ];
  const days = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"
  ];
  const handleDay = (e) => {
    setDay(e.target.value);
  };
  // console.log(monthNumeric , month, "HARSHIT_RAWAT")
  return (
    <>
      <Dialog
        open={showLoginPopup}
        classes={{
          paper: classes.paper,
        }}
      >
      <Backdrop
        sx={{ color: '#fff', zIndex: 1000}}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box textAlign="right" sx={{ cursor: "pointer" }}>
            <CancelIcon
              fontSize="large"
              onClick={() => {
                setShowLoginPopup(false);
                deleteAccount("", googleLoginData?.email);
              }}
            />
          </Box>
        <Box p={3}>
          <DialogTitle textAlign="center">
            <img src="./NewLogoG.png" alt="" width="70%" />
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Box dir="ltr">
              <FormControl fullWidth>
                <Controller
                  name="phone"
                  rules={
                    ({
                      required: true,
                    },
                    {
                      validate: (value) => {
                        return validatePhoneNumber(value);
                      },
                    })
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="phone"
                      type="text"
                      placeholder="Enter phone number"
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                        form: {
                          autocomplete: "off",
                        },
                      }}
                      inputProps={{ maxLength: 12 ,  inputMode: 'numeric'}}
                      sx={{ backgroundColor: "#E7E7E7" }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "")
                        e.target.value = e.target.value.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "$1-$2-$3"
                        );
                      }}
                      autoFocus
                    />
                  )}
                  control={control}
                />
              </FormControl>
              {errors?.phone || checkUserError ? (
                <p className={classes.error}>
                  {errors?.phone?.message || checkUserError}
                </p>
              ) : (
                <p>
                  <br />
                </p>
              )}
            </Box>
            <Box display="flex" alignItems="center">
                    <Box sx={{ width: "6rem", display: "flex" , marginBottom:"2.5rem" }}>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                      >
                        Birthday
                      </Typography>
                      <Typography sx={{ color: "red" }}>*</Typography>
                    </Box>
                    <div>
                      <div style={{ display: "flex" }}>
                        <FormControl fullWidth>
                          <Controller
                            name="dob"
                            rules={
                              ({
                                required: true,
                              },
                              {
                                validate: (value) => {
                                  return validateDob(month);
                                },
                              })
                            }
                            render={({ field }) => (
                              <div>
                                <InputLabel sx={{ display: { xs: 'none', sm: 'block' } }} >
                                  Month
                                </InputLabel>
                                <InputLabel sx={{ display: { xs: 'block', sm: 'none' } }} >
                                  Month
                                </InputLabel>
                                <Select
                                  {...field}
                                  IconComponent="none"
                                  MenuProps={MenuProps}
                                  label="Month"
                                  id="dob"
                                  value={month}
                                  onChange={handleMonth}
                                  inputProps={{
                                    autocomplete : "dob",
                                    form: {
                                      autocomplete: "off",
                                    },
                                  }}
                                  variant="outlined"
                                  sx={{ width: { lg: "8vw", md: "13vw", sm: "20vw", xs: "20vw", } }}
                                >
                                  {months.map((month, index) => {
                                    return (
                                      <MenuItem key={index} value={month}>
                                        {month}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </div>
                            )}
                            control={control}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Controller
                            name="dob"
                            rules={
                              ({
                                required: true,
                              },
                              {
                                validate: (value) => {
                                  console.log(value)
                                  return validateDob(day);
                                },
                              })
                            }
                            render={({ field }) => (
                              <>
                                <InputLabel sx={{ display: { xs: 'none', sm: 'block' } }} >
                                  Day
                                </InputLabel>
                                <InputLabel sx={{ display: { xs: 'block', sm: 'none' } }} >
                                  Day
                                </InputLabel>
                                <Select
                                  {...field}

                                  MenuProps={MenuProps}
                                  label="Day"
                                  id="dob"
                                  value={day}
                                  onChange={handleDay}
                                  IconComponent="none"
                                  // size="small"
                                  inputProps={{
                                    autocomplete : "dob",
                                    form: {
                                      autocomplete: "off",
                                    },
                                  }}
                                  variant="outlined"
                                  sx={{ width: { lg: "8vw", md: "13vw", sm: "20vw", xs: "20vw", }, marginRight: "0.5rem" }}
                                >
                                  {(months.indexOf(month) % 2 == 0 ? days : months.indexOf(month) == 1 ? leapyear ? days4 : days1 : months.indexOf(month) == 7 ? days : days2).map((day, index) => {
                                    return (
                                      <MenuItem key={index} value={day}>
                                        {day}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </>
                            )}
                            control={control}
                          />
                        </FormControl>
                      </div>
                      {errors?.dob ? (
                        <p className={classes.error}>{errors.dob.message}</p>
                      ) : (
                        <p><br /></p>
                      )}
                    </div>
                  </Box>
            <Box textAlign="center">
              <Button
                type="submit"
                className={classes.btnLogin}
                variant="contained"
              >
                Log in
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
      <Login showAd={showAd} setShowAd={setShowAd} setShowLoginPopup={setShowLoginPopup}/>
      {showPopup && (
        <OtpPopUp
          showPopup={showPopup}
          setshowPopup={setshowPopup}
          phoneNo={phoneNo}
          title={title}
          googleLoginData={googleLoginData}
          setShowLoginPopup={setShowLoginPopup}
          birth={birth}
        />
      )}
    </>
  );
};

export default SocialLogins;
