import React from 'react'
import { Box } from '@mui/system'
const Loader = () => {
  return (
    <div
    // className="row"
    style={{
      backgroundColor: "#E7E7E7",
    }}
  >
    <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
    <img
    src="./Spinner.gif"
    alt=""
    style={{ width: "20vw",marginTop:"15vh" }}
  />
  </Box>
  </div>
  )
}

export default Loader