import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Container,
  Button,
  Grid,
  Card,
  Divider,
} from "@mui/material";
import { Badge } from "@mui/material";
import { toast } from "react-toastify";
import { Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAdvCheck, setBestSellerCheck, setCartDetailes, setOpenPopUps, setTabValue, setUpdateCart,setTitles,setEditTitles } from "../../Redux/CartSlice";
import { setRemoveReward } from "../../Redux/UserSlice";
import { setCart } from "../../Redux/CartSlice";
import Zoom from "@mui/material/Zoom";
import { favoriteUnfavoriteMenu } from "../../Api/API";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import CoffeeIcon from '@mui/icons-material/Coffee';
import { Close } from "@mui/icons-material";
import "./categoryPopUp.css";
import {
  errorToastMessage,
  handleCartItemCheck,
  handlePriceOfSelectedModifier,
  handleSugarAndIceLevel,
} from "../../Common/utils";
import AlertPopup from "../../Common/AlertPopup";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: { borderRadius: 15 },
  cursor: { cursor: "pointer" },
  btn: {
    // backgroundColor: "#E8E8E8",
    fontFamily: "Lusitana",
    color: "black",
    width: "100%",
    height: "8vh",
    lineHeight: "0.9rem",
    justifyContent: "space-between",
    textTransform: "none",
    // padding:"0 2rem",
    // minWidth: "7.3vw",
    minHeight: "6vh",
    "&:hover": {
      //  background: "#E3E3E3",  
    },
  },
  btnCliked: {
    backgroundColor: "#E3E3E3",
    fontFamily: "Lusitana",
    justifyContent: "space-between",

    // padding:"0 2rem",
    color: "Black",
    textTransform: "none",
    width: "90%",
    height: "8vh",
    lineHeight: "0.9rem",
    // minWidth: "7.3vw",
    minHeight: "6vh",
    "&:hover": {
      background: "#E3E3E3",
      //  color: "white",
    },
  },
  cardHeader: {
    backgroundColor: "#E7E7E7",
    color: "black",
    justifyContent: "space-between",
    marginBottom: "0.2rem",
  },
  modifierGrp: {
    paddingLeft: 2,
    // paddingTop:"0.2rem",
    backgroundColor: "#C30E2F",
    color: "white",
    // height: "5vh",
  },
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  imgContainer: {
    position: "relative",
    textAlign: "center",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} timeout={500} />;
});

const Popup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    setOpenSnackBar,
    favFromCard,
    minCalories,
    maxCalories,
  } = props;

  const isLogin = useSelector((state) => state.user.isLogin);
  const bestSellerCheck = useSelector((state) => state.cart.bestSellerCheck);
  const tabValue = useSelector((state) => state.cart.tabValue);
  const advCheck = useSelector((state) => state.cart.advCheck);
  const userDetail = useSelector((state) => state.user.user);
  const cartItem = useSelector((state) => state.cart.cart);
  const menuItem = useSelector((state) => state.cart.cartDetailes);
  const customizeOption = useSelector((state) => state.cart.customizeOptions);
  const menuServingDetail = useSelector((state) => state.cart.menuServingDetails);
  const selectedCupSize = useSelector((state) => state.cart.selectedCupSizes);
  const openPopup = useSelector((state) => state.cart.openPopUps);
  const title = useSelector((state) => state.cart.titles);
  const EditTitle = useSelector((state) => state.cart.editTitles);
  const cartItemIndex = useSelector((state) => state.cart.cartItemIndexs);
  const nearestStore = useSelector((state) => state.store.nearestStore);
  const [selectedModifier, setSelectedModifier] = useState(0);
  const [toppingImage, setToppingImage] = useState([]);
  const [toppingEnable, setToppingEnable] = useState({});
  const [toppingIndex, setToppingIndex] = useState();
  const [cupSizeEnable, setCupSizeEnable] = useState(true);
  const [counter, setCounter] = useState(0);
  const [sugarLevelModifier, setSugarLevelModifier] = useState();
  const [btnModifierId, setBtnModifierId] = useState(menuItem?.btnModifierId);
  const [btnModifierName, setBtnModifierName] = useState([]);
  const [sugarLevelMarks, setSugarLevelMarks] = useState([]);
  const [sugarLevelMarksForSmall, setSugarLevelMarksForSmall] = useState([]);
  const [iceCubeLevelMarks, setIceCubeLevelMarks] = useState([]);
  const [iceCubeLevelMarksForSmall, setIceCubeLevelMarksForSmall] = useState(
    []
  );
  const abc = useRef(null);
  const [custmizationSomething, setCustmizationSomething] = useState([]);
  const [sugarLevelMarksLabel, setSugarLevelMarksLabel] = useState();
  const [iceCubeLevelMarksLabel, setIceCubeLevelMarksLabel] = useState();
  const [sliderState, setSliderState] = useState();
  const [isHotServing, setIsHotServing] = useState(false);
  const [servingDetail, setServingDetail] = useState();
  const [modifierPriceSum, setModifierPriceSum] = useState(0);
  const [cupSizeState, setCupSizeState] = useState(
    // selectedCupSize && selectedCupSize[0]?.serving_name
    // ? selectedCupSize[0]?.serving_name
    // : null
  );
  const [cartTotalPrice, setCartTotalPrice] = useState(0);
  const [iceCubeLevelModifier, setIceCubeLevelModifier] = useState([]);
  const [fav, setFav] = useState(false);
  const [modifierCounterLimits, setModifierCounterLimits] = useState([]);
  const [autoSelected, setAutoSelected] = useState([]);
  const [removeSelectedModifier, setRemoveSelectedModifier] = useState([])
  const [cupSizeChnage, setCupSizeChange] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)

  // console.log(menuItem , "#11111")
  const [changedServing, setChangedServing] = useState({
    cupSize: "",
    servingDetail: ""
  })
  useEffect(() => {
    let sugarLevel = customizeOption.filter(
      (item) =>
        item.modifier_group_name == "Sugar Level" ||
        item.modifier_group_id == 75
    );
    let iceCubeLevel = customizeOption.filter(
      (item) =>
        item.modifier_group_name == "Ice Cube Level" ||
        item.modifier_group_id == 76
    );
    let iceCubeLevelPartition = [];
    let iceCubeLevelPartitionForSmall = [];
    let length = iceCubeLevel[0]?.option.length;
    let partition = 100 / (length - 1);
    let initialPartition = 0;
    // console.log(sugarLevel, "trial of modifiers")
    iceCubeLevel[0]?.option.forEach((item, index) => {
      iceCubeLevelPartition.push({
        value: initialPartition,
        label: item?.modifier_name,
        selectedImage: item?.selectedImage,
        unSelectedImage: item?.unSelectedImage,
      });

      iceCubeLevelPartitionForSmall.push({ value: initialPartition });
      initialPartition = initialPartition + Number(partition);
    });

    let sugarLevelPartition = [];
    let sugarLevelPartitionForSmall = [];
    length = sugarLevel[0]?.option?.length;

    partition = 100 / (length - 1);
    initialPartition = 0;
    sugarLevel[0]?.option.forEach((item, index) => {
      sugarLevelPartition.push({
        value: initialPartition,
        label: item?.modifier_name,
        selectedImage: item?.selectedImage,
        unSelectedImage: item?.unSelectedImage,
      });

      sugarLevelPartitionForSmall.push({ value: initialPartition });
      initialPartition = initialPartition + Number(partition);
    });
    let hotServing = menuServingDetail.filter(
      (item) => item.serving_name == "Hot"
    );
    if (hotServing.length > 0) {
      setIsHotServing(true);
    }
    setIceCubeLevelMarksForSmall(iceCubeLevelPartitionForSmall);
    setSugarLevelMarksForSmall(sugarLevelPartitionForSmall);
    setSugarLevelMarks(sugarLevelPartition);
    setSugarLevelModifier(sugarLevel[0]);
    setIceCubeLevelModifier(iceCubeLevel[0]);
    setIceCubeLevelMarks(iceCubeLevelPartition);
  }, []);


  useEffect(() => {
    if (sliderState || sliderState === 0) {
      if (sugarLevelMarksLabel) {
        let selectedModifierByCustomer = sugarLevelModifier?.option?.filter(
          (item) =>
            item.modifier_name ==
            sugarLevelMarks.filter((item) => item.value == sliderState)[0].label
        );
        setSliderState();
        handleCustomization(sugarLevelModifier, selectedModifierByCustomer[0]);
      }
    }
  }, [sugarLevelMarksLabel]);

  useEffect(() => {
    // console.log(iceCubeLevelMarks, iceCubeLevelModifier, "manana", openPopup)
    if (sliderState || sliderState === 0) {
      if (iceCubeLevelMarksLabel) {
        let selectedModifierByCustomer = iceCubeLevelModifier?.option?.filter(
          (item) =>
            item?.modifier_name ==
            iceCubeLevelMarks.filter((item) => item.value == sliderState)[0]
              .label
        );
        setSliderState();
        handleCustomization(
          iceCubeLevelModifier,
          selectedModifierByCustomer[0]
        );
      }
    }
  }, [iceCubeLevelMarksLabel]);

  useEffect(() => {
    if (
      openPopup &&
      menuItem?.selectedCupSize &&
      menuItem?.menuServingDetail?.length > 0 &&
      !selectedCupSize
    ) {
      setCustmizationSomething(
        menuItem?.selectedOptions ? menuItem?.selectedOptions : []
      );
      setCupSizeState(
        menuItem?.selectedCupSize
          ? menuItem?.selectedCupSize[0]?.serving_name
          : ""
      );
      setCartTotalPrice(
        menuItem?.orderItemPrice ? menuItem?.orderItemPrice : 0
      );
      setSelectedModifier(
        menuItem?.selectedOptions
          ? menuItem?.selectedOptions[0]?.modifier_group_id
          : ""
      );
      setCounter(5);
      let sugarAndIceLevelObject = handleSugarAndIceLevel(
        customizeOption,
        menuItem
      );
      let sugarLevelIndicator = sugarAndIceLevelObject.sugarLevelIndicator;
      let sugarPartition = sugarAndIceLevelObject.sugarPartition;
      let partition = sugarAndIceLevelObject.partition;
      let iceCubeLevelIndicator = sugarAndIceLevelObject.iceCubeLevelIndicator;

      setServingDetail(menuItem?.selectedCupSize[0]);
      setSugarLevelMarksLabel(sugarLevelIndicator * sugarPartition);
      setIceCubeLevelMarksLabel(iceCubeLevelIndicator * partition);
    } else if (!openPopup) {
      setModifierPriceSum(0);
      setServingDetail();
      setCupSizeState(null);
    }
  }, [openPopup]);

  useEffect(() => {
    // console.log(menuItem, "#1111")

    if (
      cupSizeState &&
      custmizationSomething.length > 0 && cupSizeChnage &&
      custmizationSomething[0].option[0].selected_modifier_serving
        .serving_name != cupSizeState
    ) {
      setCustmizationSomething([]);
      setSugarLevelMarksLabel(null);
      setIceCubeLevelMarksLabel(null);
      setCounter(555555);
    }
  }, [cupSizeChnage]);

  let something = {};

  const handleCustomization = (modifierGroup, modifier) => {
    dispatch(setEditTitles(""))
    if (
      modifierCounterLimits?.findIndex(
        (item) => item?.modifier_id == modifier?.modifier_id
      ) == -1
    ) {
      let modifierCounterLimit = [];
      modifierCounterLimit.push({
        modifier_id: modifier?.modifier_id,
        counterLimit: modifier?.counterLimit,
      });
      setModifierCounterLimits([
        ...modifierCounterLimits,
        ...modifierCounterLimit,
      ]);
    }
    let modifierPrice = modifier?.modifier_group_description?.filter(
      (item) => item.serving_name == cupSizeState
    );
    let image = [];
    if (modifier?.modifier_image) {
      image.push(modifier?.modifier_image);
      setToppingImage([...toppingImage, ...image]);
    }
    setSelectedModifier(modifier?.modifier_id);
    something = {
      modifier_group_id: modifierGroup?.modifier_group_id,
      modifier_group_name: modifierGroup?.title,
      option: [
        {
          id: "",
          isSelected: false,
          modifier_id: modifier?.modifier_id,
          modifier_name: modifier?.title,
          modifier_prefix_overide_mode: "No",
          modifier_prefix_overideMode: "",
          modifier_prefix_price: 0,
          modifier_prefix_name: 0,
          modifier_price: modifierPrice[0]
            ? modifierPrice[0]?.price.toString()
            : "0",
          prefix_name: "",
          price: modifierPrice[0] ? modifierPrice[0]?.price.toString() : "0",
          quantity: 1,
          selected_modifier_serving: {
            price: modifierPrice[0] ? modifierPrice[0]?.price.toString() : "0",
            serving_name: cupSizeState,
          },
          serving_id: "",
          serving_name: "",
          serving_price: "",
          sub_modifier_id: 0,
          title: "30%",
        },
      ],
      required: modifierGroup?.is_required,
      selection_time: modifierGroup?.optionType,
      title: modifierGroup?.title,
    };

    let individual = {
      id: "",
      isSelected: false,
      modifier_id: modifier.modifier_id,
      modifier_name: modifier.title,
      modifier_prefix_overide_mode: "No",
      modifier_prefix_overideMode: "",
      modifier_prefix_price: 0,
      modifier_prefix_name: 0,
      modifier_price: modifierPrice[0]
        ? modifierPrice[0]?.price.toString()
        : "0",
      prefix_name: "",
      price: modifierPrice[0] ? modifierPrice[0]?.price.toString() : "0",
      quantity: 1,
      selected_modifier_serving: {
        price: modifierPrice[0] ? modifierPrice[0]?.price.toString() : "0",
        serving_name: cupSizeState,
      },
      serving_id: "",
      serving_name: "",
      serving_price: "",
      sub_modifier_id: 0,
      title: modifier.title,
    };

    let modified = custmizationSomething.filter(
      (element) => element.modifier_group_id == modifierGroup?.modifier_group_id
    );
    let modifiedIndex = custmizationSomething.findIndex(
      (element) => element.modifier_group_id == modifierGroup?.modifier_group_id
    );
    if (modified.length === 0) {
      // console.log("comming in this " ,"kkk")
      let x = autoSelected.filter(e => custmizationSomething.some(el => el.modifier_group_id == e.modifier_group_id))
      // setAutoSelected(x)  
      setRemoveSelectedModifier(x);
      setCustmizationSomething((previousState) => [
        ...previousState,
        something,
      ]);
      setCounter(1);
    } else {
      // console.log("not comming in this " ,"kkk")
      if (modified[0].selection_time == "Single") {
        if (modifier.counterLimit == 1) {
          let newArr = [...custmizationSomething]; // copying the old datas array
          newArr[modifiedIndex] = something;
          setCustmizationSomething((previousState) => {
            return newArr;
          });
          setCounter(1 + 6);
        } else {
          if (modified[0].option[0].quantity < modifier.counterLimit) {
            modified[0].option[0].quantity += 1;
            setCounter(modified[0].option[0].quantity);
            custmizationSomething.splice(modifiedIndex, 1, modified[0]);
          }
        }
      } else {
        let multipleModifier = modified[0]?.option.filter(
          (element) => element.modifier_id == modifier.modifier_id
        );
        let multipleModifierIndex = modified[0]?.option.findIndex(
          (element) => element.modifier_id == modifier.modifier_id
        );
        if (multipleModifier.length == 0) {
          let x = [...custmizationSomething[modifiedIndex].option, individual];
          let newState = Object.assign(
            {},
            custmizationSomething[modifiedIndex]
          );
          newState.option = x;
          let newArray = [...custmizationSomething];
          newArray[modifiedIndex] = newState;
          // console.log(newArray, "mkjdbijbci");
          setCustmizationSomething((previousState) => {
            return newArray;
          });
          setCounter(1);
        } else {
          if (multipleModifier[0].quantity < modifier.counterLimit) {
            let multipleNewState = Object.assign({}, multipleModifier[0]);
            multipleNewState.quantity++;
            multipleModifier[0] = multipleNewState;
            let newCustomizationSomething = [...custmizationSomething]
            let newObject = Object.assign(
              {},
              custmizationSomething[modifiedIndex]
            );
            let y = [...custmizationSomething[modifiedIndex].option];
            let optionObject = Object.assign(
              {},
              custmizationSomething[modifiedIndex].option[multipleModifierIndex]
            );
            optionObject = multipleModifier[0];
            y[multipleModifierIndex] = optionObject;
            newObject.option = y;
            newCustomizationSomething[modifiedIndex] = newObject
            setCustmizationSomething((previousState) => {
              return newCustomizationSomething
            });
            setCounter(modifiedIndex + multipleModifierIndex + counter);
          }
        }
      }
    }
  };
  useEffect(() => {
    if (custmizationSomething.length > 0) {
      let price = handlePriceOfSelectedModifier(
        customizeOption,
        custmizationSomething,
        cupSizeState
      );
      // console.log(price, "mananan", custmizationSomething);
      setModifierPriceSum(price);
    } else {
      setModifierPriceSum(0);
    }
  });

  const handleRemove = (
    modifierGrp,
    modifier,
    idx,
    index,
    optionType,
    modifierGroupId,
    required
  ) => {
    let modifierCounterLimit = modifierCounterLimits.filter(
      (item) => item.modifier_id != modifier.modifier_id
    );
    setModifierCounterLimits(modifierCounterLimit);
    if (toppingImage.length > 0) {
      let images = toppingImage.filter(
        (item) => item != modifier?.modifier_image
      );
      setToppingImage(images);
    }
    let modified = custmizationSomething.filter(
      (element) => element.modifier_group_id == modifierGroupId
    );
    let modifiedIndex = custmizationSomething.findIndex(
      (element) => element.modifier_group_id == modifierGroupId
    );

    if (optionType == "Single") {
      // custmizationSomething.splice(modifiedIndex, 1);
      setCustmizationSomething((previousState) => {
        return previousState.filter(
          (item) => item.modifier_group_id != modifierGroupId
        );
      });
      setCounter(idx + 2 + index);
    } else {
      let multipleModifier = modified[0]?.option.filter(
        (element) => element.modifier_id == modifier.modifier_id
      );
      let multipleModifierIndex = modified[0]?.option.findIndex(
        (element) => element.modifier_id == modifier.modifier_id
      );
      if (modified[0]?.option.length == 1) {
        setCustmizationSomething((previousState) => { return previousState.filter((item) => item.modifier_group_id != modifierGroupId) })
        setCounter(idx + index + counter);
      } else {
        let x = [...custmizationSomething[modifiedIndex].option];
        let obj = Object.assign({}, custmizationSomething[modifiedIndex]);
        let newOption = x.filter(
          (e) =>
            e.modifier_id !=
            custmizationSomething[modifiedIndex].option[multipleModifierIndex]
              .modifier_id
        );
        obj.option = newOption;
        let newArray = [...custmizationSomething];
        newArray[modifiedIndex] = obj;
        setCustmizationSomething((previousState) => {
          return newArray;
        });
        setCounter(idx + 2);
      }
    }
  };

  useEffect(()=>{
    if(menuServingDetail[0]?.serving_name==="Base Price"){
      setServingDetail(menuServingDetail[0]);
      setCupSizeState(menuServingDetail[0].serving_name);
    }
  },[menuServingDetail])
  const handleCupSize = (e, elm) => {
    if (custmizationSomething.length > 0) {
      setChangedServing({ cupSize: e.target.textContent, servingDetail: elm })
      setOpenAlert(true)
      setCupSizeChange(false)
      dispatch(setEditTitles(""));
    } else {
      setServingDetail(elm);
      setCupSizeState(e.target.textContent);
    }
  };

  const cupNotSelected = () => { 
    toast.error(`Please select cupsize for your drink`, {
      position: toast.POSITION.TOP_RIGHT,
      pauseOnFocusLoss: false,
      autoClose: 1500,
      pauseOnHover: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
  }

  const hadleAddToCart = () => {
    dispatch(setRemoveReward(""));
    let requiredModifiers = customizeOption.filter(
      (modifier) => modifier.is_required == true
    );
    let requiredModifiersStatus = requiredModifiers.map((e) => {
      let requiredArray = custmizationSomething.filter(
        (element) => element.modifier_group_id == e.modifier_group_id
      );
      if (requiredArray.length > 0) {
        return true;
      } else {
        return false;
      }
    });
    if (
      requiredModifiersStatus.indexOf(false) == -1 &&
      requiredModifiers.length == requiredModifiersStatus.length
    ) {
      let sameCustomizationObject = handleCartItemCheck(
        cartItem,
        custmizationSomething,
        menuItem,
        cupSizeState
      );
    
      let cartCheckIndex = sameCustomizationObject.cartCheckIndex;
      let sameCustomization = sameCustomizationObject.sameCustomization;

      const index = cartItem?.findIndex(
        (item) =>
          item.id === menuItem?.id && item.categoryId === menuItem?.categoryId
      );
      let arr = [];
      let servingDetails = menuServingDetail.filter(
        (e) => e.serving_name == cupSizeState
      );

      let obj = Object.assign({}, menuItem)
      obj.btnModifierId = btnModifierId;
      obj.btnModifier = btnModifierName;
      obj.customizationOpt = customizeOption;
      obj.selectedOptions =
        custmizationSomething.length > 0 ? custmizationSomething : [];
      obj.menuServingDetail = menuServingDetail;
      obj.selectedCupSize = servingDetails;
      obj.itemAliasName = menuItem?.itemAliasName
      obj.orderItemPrice = servingDetail
        ? servingDetail.price + modifierPriceSum
        : selectedCupSize
          ? selectedCupSize[0].price + modifierPriceSum
          : cartTotalPrice;
      arr.push(obj);
      console.log(arr,obj.itemAliasName,obj,"object")
      dispatch(setCartDetailes(obj))
      if (
        !sameCustomization ||
        sameCustomization?.length != custmizationSomething?.length
      ) {
        if (menuItem.quantity !== 0 && !title) {
          dispatch(setCart(arr));
          // setOpenSnackBar(true);
          toast.success(`${menuItem?.title} Added to the cart`, {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark"
          });
        } else if (menuItem.quantity !== 0 && title == "editFromCart") {
          let itemToUpdate = [...cartItem];
          itemToUpdate.splice(cartItemIndex, 1, obj);
          dispatch(setUpdateCart(itemToUpdate));
        }
      } else {
        if (!title) {
          const item = menuItem;
          const updatedCart = [...cartItem];
          let obj = Object.assign({}, updatedCart[cartCheckIndex]);
          obj.quantity += menuItem.quantity;
          updatedCart?.splice(cartCheckIndex, 1, obj);
          dispatch(setUpdateCart(updatedCart));
          // setOpenSnackBar(true);
          toast.success(`${menuItem?.title} Added to the cart`, {
            position: "bottom-left",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "dark"
          });
        } else if (title == "editFromCart") {
          let itemToUpdate = [...cartItem];
          itemToUpdate.splice(cartItemIndex, 1, obj);
          dispatch(setUpdateCart(itemToUpdate));
        }
      }
      dispatch(setOpenPopUps(false))
      if (title == "editFromCart") {
        navigate(`/cart`);
      } else {
        dispatch(setTabValue(2))
        navigate(tabValue == 2 ? `/menu` : "/");
      }
    } else {
      if (!cupSizeState) {
        toast.error(`Please select cupsize for your drink`, {
          position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss: false,
          autoClose: 1500,
          pauseOnHover: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      } else {
        toast.error(
          `Please select at least one modifier from ${requiredModifiers.map((e) => e.modifier_group_name)[
          requiredModifiersStatus.findIndex((e) => e == false)
          ]
          }`,
          {
            position: toast.POSITION.TOP_RIGHT,
            pauseOnFocusLoss: false,
            autoClose: 1500,
            pauseOnHover: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    }
  };
 
  useEffect(() => {
    if(menuItem?.is_favourite == 1){
      setFav(true)
    }
  },[])
  

  const handleFavUnfav = (val) => {
    let selectedServingDetails = menuServingDetail?.filter(
      (e) => e.serving_name == cupSizeState
    );
    let requiredModifiers = customizeOption.filter(
      (modifier) => modifier.is_required == true
    );
    let requiredModifiersStatus = requiredModifiers.map((e) => {
      let requiredArray = custmizationSomething.filter(
        (element) => element.modifier_group_id == e.modifier_group_id
      );
      if (requiredArray.length > 0) {
        return true;
      } else {
        return false;
      }
    });
    if (
     ( requiredModifiersStatus.indexOf(false) == -1 &&
      requiredModifiers.length == requiredModifiersStatus.length) || (val == false)
    ) 
    {
      setFav(val);
      let favorite = val ? 1 : 0;
      let custom = [];
      custmizationSomething.forEach((item) => {
        let option = [];
        item?.option?.forEach((element) => {
          let counterLimit = modifierCounterLimits.filter(
            (e) => e.modifier_id == element.modifier_id
          );

          let selectedOption = {
            counterLimit:
              counterLimit.length > 0 ? counterLimit[0].counterLimit : 0,
            id: element.modifier_id.toString(),
            modifier_description: "",
            selected: val,
            modifier_group_description: [
              {
                modifier_price: element.selected_modifier_serving.price,
                prefixData: [],
                price: Number(element.selected_modifier_serving.price),
                serving_id: servingDetail?.serving_id.toString(),
                serving_name: servingDetail?.serving_name,
              },
            ],
            modifier_id: element?.modifier_id,
            modifier_name: element?.modifier_name,
            modifier_price:element.selected_modifier_serving.price,
            modifier_sort: 0,
            status: "Active",
            title: element?.modifier_name,
          };
          option.push(selectedOption);
        });

        let selectedFavUnfav = {
          bar: 0,
          is_required: item.required,
          modifier_group_description: "",
          modifier_group_id: item.modifier_group_id,
          modifier_group_name: item.modifier_group_name,
          option: option,
          optionType: item.selection_time,
          selection_time: item.selection_time,
          title: item.title,
        };
        custom.push(selectedFavUnfav);
      });
      const favUnfavData = {
        cart_id: 0,
        category_id: menuItem?.categoryId,
        customer_id: userDetail?.customer_id
          ? userDetail?.customer_id
          : userDetail?.person_id,
        fav_id: 0,
        fav_type: "menujson",
        is_favourite: favorite,
        selectedOptions: custmizationSomething,
        orderItemPrice: parseFloat(
          isNaN(
            Number(
              servingDetail
                ? servingDetail.price + modifierPriceSum
                : selectedCupSize
                  ? selectedCupSize[0]?.price + modifierPriceSum
                  : cartTotalPrice
            )
          )
            ? 0
            : Number(
              servingDetail
                ? servingDetail.price + modifierPriceSum
                : selectedCupSize
                  ? selectedCupSize[0]?.price + modifierPriceSum
                  : cartTotalPrice
            ).toFixed(2)
        ),
        menu_details: {
          cart_id: 0,
          category_id: menuItem?.categoryId,
          category_name: menuItem?.categoryName,
          created_at: new Date().toISOString(),
          customizationOpt: custom,
          description: "",
          discount_details: [],
          id: 0,
          image: menuItem?.image,
          is_active: true,
          is_deleted: "1",
          is_favourite: favorite,
          menu_serving_detail: [
            {
              created_at: new Date().toISOString(),
              price: servingDetail?.price
                ? servingDetail?.price
                : selectedCupSize
                  ? selectedCupSize[0]?.price
                  : selectedServingDetails[0]?.price,
              serving_actual_id: servingDetail?.serving_actual_id
                ? servingDetail?.serving_actual_id
                : selectedCupSize
                  ? selectedCupSize[0]?.serving_actual_id
                  : selectedServingDetails[0]?.serving_actual_id,
              serving_description: servingDetail?.serving_description
                ? servingDetail?.serving_description
                : selectedCupSize
                  ? selectedCupSize[0]?.serving_description
                  : selectedServingDetails[0]?.serving_description,
              serving_icon: servingDetail?.serving_icon
                ? servingDetail?.serving_icon
                : selectedCupSize
                  ? selectedCupSize[0]?.serving_icon
                  : selectedServingDetails[0]?.serving_icon,
              serving_id: servingDetail?.serving_id
                ? servingDetail?.serving_id
                : selectedCupSize
                  ? selectedCupSize[0]?.serving_id
                  : selectedServingDetails[0]?.serving_id,
              serving_name: servingDetail?.serving_name
                ? servingDetail?.serving_name
                : selectedCupSize
                  ? selectedCupSize[0]?.serving_name
                  : selectedServingDetails[0]?.serving_id,
              status: "Active",
              tier_id: servingDetail?.tier_id
                ? servingDetail?.tier_id
                : selectedCupSize
                  ? selectedCupSize[0]?.tier_id
                  : selectedServingDetails[0]?.tier_id,
              updated_at: new Date().toISOString(),
            },
          ],
          price: servingDetail
            ? servingDetail.price + modifierPriceSum
            : menuItem.totalPrice,
          status: "Active",
          store_id: 0,
          title: menuItem?.title,
          updated_at: new Date().toISOString(),
          min_calories: minCalories,
          max_calories: maxCalories
        },
        menu_id: menuItem.id,
        order_id: "",
      };
      favoriteUnfavoriteMenu(favUnfavData)
        .then((res) => {
          if (val) {
            toast.success("Added to your favorite", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
          } else {
            toast.error("Removed from your favorite", {
              position: toast.POSITION.TOP_RIGHT,
              pauseOnFocusLoss: false,
              autoClose: 1500,
              pauseOnHover: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            });
          }
        })
        .catch((e) => {
          errorToastMessage();
          console.log(e);
        });
    } 
    if(!cupSizeState){
      toast.error(`Please select cupsize for your drink`, {
        position: toast.POSITION.TOP_RIGHT,
        pauseOnFocusLoss: false,
        autoClose: 1500,
        pauseOnHover: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      });

    }
    else {
      toast.error(
        `Please select at least one modifier from ${requiredModifiers.map((e) => e.modifier_group_name)[
        requiredModifiersStatus.findIndex((e) => e == false)
        ]
        }`,
        {
          position: toast.POSITION.TOP_RIGHT,
          pauseOnFocusLoss: false,
          autoClose: 1500,
          pauseOnHover: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        }
      );
    }
  };
  function valueLabelFormat(value) {
    let labelObject = sugarLevelMarks?.filter(
      (element) => element.value == value
    );
    let label = labelObject[0]?.label;
    return label;
  }

  function valueLabelFormatIceCube(value) {
    let labelObject = iceCubeLevelMarks?.filter(
      (element) => element?.value == value
    );
    let label = labelObject[0]?.label;
    return label;
  }

  const handleChange = (modifier, index) => {
    console.log(modifier,index,"handleCup9999999999999999")
    setToppingEnable(modifier);
    setToppingIndex(index);
    setCupSizeEnable(false);
  };
  const handleCupEnable = () => {
    setCupSizeEnable(true);
    setToppingEnable({})
    setToppingIndex();
  };

  let navigate = useNavigate();
  console.log(bestSellerCheck,"bestSellerCheck")
  const routeChange = () => {
    if (bestSellerCheck == true) {
      dispatch(setTabValue(1))
      let path = `/`;
      navigate(path);
      dispatch(setOpenPopUps(false))
      dispatch(setBestSellerCheck(false))
    }
    else if (advCheck == true) {
      // tabValue(4)   
      dispatch(setTabValue(3))     
      let path = `/`;
      navigate(path);
      dispatch(setOpenPopUps(false))
      dispatch(setAdvCheck(false))
    }else if(title=="editFromCart"){
      dispatch(setTabValue(0))  
      let path = `/cart`;
      navigate(path);
    } else if (fav == true) { 
      dispatch(setTabValue(5))      
      let path = `/`;
      navigate(path);
      // dispatch(setOpenPopUps(false))
    }
    else {
      dispatch(setTabValue(0)) 
      let path = `/menu`;
      navigate(path);
      setFav(false);
      dispatch(setOpenPopUps(false))
      // setOpenPopup(false);
    }
  };
const handleLeftArrow=()=>{
  console.log("12345678")

  if (bestSellerCheck == true) {
    dispatch(setTabValue(1))
    let path = `/`;
    navigate(path);
    dispatch(setOpenPopUps(false))
    dispatch(setBestSellerCheck(false))
  }
}
useEffect (()=>{
  window.addEventListener('popstate', handleLeftArrow);
  return () => {
    window.removeEventListener('popstate', handleLeftArrow);
  };
},[])

  
  const scrollToBottom = () => {
    abc?.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    if(cupSizeState && custmizationSomething?.length == 0){
      setToppingEnable(customizeOption[0])
      setCupSizeEnable(false);
    }
    let selectedIndex = customizeOption.findIndex((e)=>e.modifier_group_id==toppingEnable?.modifier_group_id)
    let customizedIndex = custmizationSomething?.findIndex((e)=>e.modifier_group_id==toppingEnable?.modifier_group_id)
  let selected = [];
    if(selectedIndex==customizedIndex){
     selected = customizeOption.filter((el) => {
      return custmizationSomething.every((f) => {
        if (f.selection_time == "Single") {
          return (f.modifier_group_id != el.modifier_group_id)
        } else if (f.selection_time == "Multiple") {
          return true
        }
      });
    });
    }
    if(selectedIndex!=customizedIndex){
     selected = customizeOption.filter((el,Index) => {
      return Index>selectedIndex;
    });
  }
   // if (custmizationSomething.length > 0) {
    //   console.log(selected.filter((removeNotSelected, index) => index > selected?.findIndex((i) => i.modifier_group_id == custmizationSomething[custmizationSomething.length - 1].modifier_group_id)), "kkk")
    //   selected = selected.filter((removeNotSelected, index) => index >= customizeOption?.findIndex((i) => i.modifier_group_id == custmizationSomething[custmizationSomething.length - 1].modifier_group_id))
    // }
    // console.log(EditTitle,"EditTitle")
    setAutoSelected(selected)
    if (selected.length >= 1 && custmizationSomething?.length > 0 && EditTitle != "FromCart" && toppingEnable.selection_time !="Multiple"&&toppingEnable?.modifier_group_id!=customizeOption[customizeOption?.length-1].modifier_group_id
      // && selected[0]?.selection_time == "Multiple"
    ) {
      setToppingEnable(selected[removeSelectedModifier.length > 0 ? selected.indexOf(removeSelectedModifier[0]) + 1 : 0])
    }
    if (custmizationSomething?.length >= 2) {
      scrollToBottom()
    }
  }, [custmizationSomething, cupSizeState])
  return (
    <>
    
      <Container sx={{ marginTop: { lg: "4vh", md: "4vh", xs: "0vh" }, paddingBottom: { lg: "2vh", md: "2vh", xs: "60px" }, paddingLeft: { xs: "0rem" }, paddingRight: { xs: "0rem" } }}>
        <Card sx={{ borderRadius: { lg: "16px", xs: 0 } }} >
          <Box onClick={routeChange} sx={{ display: "flex", padding: "1rem", cursor: "pointer", position: { lg: "absolute", md: "absolute", sm: "inherit", xs: "inherit" } }}>
            <ArrowBackIosIcon style={{ fill: "#C60734", }} />
            <Typography sx={{ color: "#C60734" }} variant='h5'>Customization</Typography>
          </Box>
          <div style={{ display: "flex", justifyContent: 'center' }}>
            <Box
              component="img"
              sx={{
                height: "8rem",
                objectFit: "contain",
                marginTop: "0.5rem"
              }}
              alt="Menu Item Image"
              src={menuItem?.image ? menuItem?.image : "./logos.png"}
            />
            {/* {cupSizeState &&
                nearestStore?.id &&
                (isLogin) && (
                  <div
                    style={{ display: "flex", alignItems:"end" }}
                  >
                    <Checkbox 
                      icon={<FavoriteBorderIcon />}
                      checkedIcon={<FavoriteIcon style={{fill: "#C30E2F" }}  />}
                      checked={fav}
                      onChange={(e) => {
                        handleFavUnfav(e.target.checked);
                      }}
                    />
                  </div>
                )} */}
          </div>

          <div style={{ textAlign: "center", marginBottom: "0.1rem" }}>
            <Typography variant="subtitle" style={{ textAlign: "center" }} >{menuItem?.title}</Typography><br></br>
            {menuItem?.description&&
            <Typography variant="subtitle" fontSize={14} style={{ textAlign: "center" }} sx={{marginLeft:{lg:"1rem",md:"1rem"},marginRight:{lg:"1rem",md:"1rem"}}} >{menuItem?.description}</Typography>}
          </div>

          <div style={{
            display: "flex", justifyContent: 'center',
            marginRight: ((customizeOption?.length > 4) ? "8px" : "0px"), marginBottom: "0.3rem",marginLeft:"-2px",
          }}>
               {menuItem.cold && !nearestStore?.id &&<Box
            style={{ marginTop: "0.7rem", width:"1.2rem",height:"1.2rem",marginRight:"10px" }}
              component="img"
              alt="Menu Item Image"
              src={"./Gongcha 2.png"}
            />}
          
          {(menuItem.cold) && (nearestStore?.id) && 
          <Box
            style={{ marginTop: "0.7rem", width:"1.2rem",height:"1.2rem",marginRight:(isLogin==false)?"10px":"0px" }}
              component="img"
              alt="Menu Item Image"
              src={"./Gongcha 2.png"}
            />
          }
           
            {
              nearestStore?.id &&
              (isLogin) && (
                <Checkbox
                
                icon={(menuItem.is_favourite == 1 && fav) ? <FavoriteIcon style={{ fill: "#C30E2F" }} /> : <FavoriteBorderIcon /> }
                checkedIcon={  <FavoriteIcon style={{ fill: "#C30E2F" }} />}
                checked={fav}
                onChange={(e) => {  
                  if(menuItem?.is_favourite == 1 && fav){
                    handleFavUnfav(false);
                  }
                  else{
                  handleFavUnfav(e.target.checked);
                }
                }}
              />
              )}
           
            {/* {menuItem?.caffine && <CoffeeIcon style={{ marginTop: "0.7rem" }} />} */}
           { menuItem.caffine && <Box
            style={{ marginTop: "0.7rem", width:"1.2rem",height:"1.2rem" }}
              component="img"
              alt=""
              src={ "./newCoffee.png" }
            />}
          </div>
          <Divider />
          <Grid container >
            <Grid item md={6} xs={6} sm={6} lg={6}>
              <div style={{ overflowY: "auto", height: "39vh" }}>
                {cupSizeState !=="Base Price"&&<Grid item xs={12} lg={12}>

                  <Button size="medium" className={classes.btn} sx={{ fontWeight: "bold", padding: { lg: "0 2rem", md: "0 2rem", sm: "0 2rem", xs: "0 0.7rem" }, }}
                    onClick={() => handleCupEnable()}
                  >
                    Cup Size
                  </Button>

                </Grid>}

                <div >
                  {cupSizeState &&cupSizeState !=="Base Price"&&
                  <Typography variant="subtitle2" sx={{ padding: { lg: "0 2.6rem", md: "0 2.6rem", sm: "0 2.6rem", xs: "0 1rem" }, color: "#C60734" }} >- {cupSizeState}</Typography>}
                  {/* <Grid container spacing={0.8}> */}

                  {customizeOption?.map((element, index) => {
                    return (
                      <>
                        <Grid item xs={12} lg={12} ref={abc}>
                          <Button size="medium" className={classes.btn} sx={{
                            fontWeight: "bold", padding: { lg: "0 2rem", md: "0 2rem", sm: "0 2rem", xs: "0 0.7rem" },
                            backgroundColor: (toppingEnable?.modifier_group_id == element?.modifier_group_id) ? "#E7E6E6" : " "
                          }}
                          disabled={cupSizeState ? false : true}
                            onClick={() => handleChange(element, index)}
                          >
                            {element?.title + (element.is_required ? " *" : "")}
                          </Button>
                        </Grid>
                        {custmizationSomething?.map((ele) => {
                          return (
                            <>
                              {(ele?.modifier_group_name == element?.title) &&
                                <div>
                                  {ele?.option?.map((e) => {
                                    return (
                                      <>
                                        <Typography variant="subtitle2" sx={{ display: "flex", paddingLeft: { lg: "2.6rem", md: "2.6rem", sm: "2.6rem", xs: "1rem" }, color: "#C60734" }}
                                        >-
                                          <Typography variant="subtitle2" sx={{ marginLeft: "0.2rem" }} >{e?.modifier_name}</Typography>
                                          {e?.quantity > 1 &&
                                            <Typography variant="subtitle2" sx={{ marginLeft: "0.3rem", width: "30px" }}>x {e?.quantity}</Typography>}
                                        </Typography>
                                      </>
                                    )

                                  })}

                                </div>
                              }</>
                          )
                        })}
                      </>
                    )
                  })}
                  {/* </Grid> */}
                </div>
                <Box sx={{ display: { xs: 'block', sm: 'none', lg: "none", md: "none" } }}>
                  <h6 className="card-text" style={{ marginLeft: "0.7rem", marginTop: "40px", }}
                  >
                    {menuItem.min_calories}-{menuItem.max_calories} cal
                  </h6>
                </Box>
              </div>
              {/* </Container> */}

            </Grid>

            <Grid item md={6} xs={6} sm={6} lg={6}
              style={{
                borderStyle: 'solid',
                borderColor: 'rgba(0, 0, 0, 0.12)',
                borderWidth: '0 0 0 1px'
              }}>
              <div style={{ overflowY: "auto", height: "39vh" }}>
                <div style={{ margin: "13px" }}>
                  <Grid container spacing={0.8}>
                    {cupSizeEnable && (menuItem?.menuServingDetail?.length > 0
                      ? menuItem.menuServingDetail
                      : menuServingDetail
                    )?.map((elm) => {
                      return (
                        <Grid item xs={12} lg={12}>
                          <Button
                            size="medium"
                            className={
                              cupSizeState == elm.serving_name
                                ? classes.btnCliked
                                : classes.btn
                            }
                            onClick={(e) => handleCupSize(e, elm)}
                            sx={{ textTransform: "none", padding: { lg: "0 2rem", md: "0 2rem", sm: "0 2rem", xs: "0 0.7rem" }, }}
                          >
                            {elm.serving_name}
                          </Button>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>

                <div >
                  <Grid container spacing={1}>
                    {toppingEnable?.option?.map((e, idx) => {
                      let modified = custmizationSomething?.filter(
                        (ele) => ele?.modifier_group_id == toppingEnable?.modifier_group_id
                      );
                      let count = selectedModifier
                        ? modified[0]?.option?.filter(
                          (elx) => elx?.modifier_id == e?.modifier_id
                        )[0]
                        : null;

                      return (
                        <Grid item xs={12} lg={12}>
                          <Button
                            key={e.modifier_id}
                            size="medium"
                            name={e.title}
                            sx={{ textTransform: "none", padding: { lg: "0 2rem", md: "0 2rem", sm: "0 2rem", xs: "0 0.3rem" }, }}
                            disabled={
                              !cupSizeState ||
                                e.modifier_group_description.filter(
                                  (item) =>
                                    item.serving_name == cupSizeState
                                ).length == 0
                                ? true
                                : false
                            }
                            onClick={() => {
                              handleCustomization(toppingEnable, e);
                            }}
                            className={
                              count ? classes.btnCliked : classes.btn
                            }
                          >
                            {e.title}
                            {counter > 0 && count && count.quantity && (
                              <Badge sx={{ marginRight: "1rem" }}
                                key={e.title}
                                badgeContent={count.quantity}
                                color={"error"}
                              />
                            )}
                          </Button>
                          {counter > 0 && count && count.quantity && (
                            <Badge
                              className={classes.cursor}
                              badgeContent={"X"}
                              color={"secondary"}
                              key={idx}
                              onClick={() => {
                                handleRemove(
                                  toppingEnable?.title,
                                  e,
                                  idx,
                                  toppingIndex,
                                  toppingEnable?.optionType,
                                  toppingEnable?.modifier_group_id,
                                  toppingEnable?.is_required
                                );
                              }}
                            />
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>

              </div>
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <h6 className="card-text" style={{ marginLeft: "2rem", marginTop: "27px", marginBottom: "-44px" }}
            >
              {menuItem.min_calories}-{menuItem.max_calories} cal
            </h6>
          </Box>
          {cupSizeState ?
          <div className="d-flex justify-content-center pt-2">
            {" "}
            <h6 className="card-text" >
              Total:
            </h6>{" "}
            <h6 className="card-text" style={{ marginLeft: "0.2rem" }}>
              $
              {isNaN(
                Number(
                  servingDetail
                    ? servingDetail.price + modifierPriceSum
                    : selectedCupSize
                      ? selectedCupSize[0]?.price + modifierPriceSum
                      : cartTotalPrice
                )
              )
                ? 0
                : Number(
                  servingDetail
                    ? servingDetail.price + modifierPriceSum
                    : selectedCupSize
                      ? selectedCupSize[0]?.price + modifierPriceSum
                      : cartTotalPrice
                ).toFixed(2)}
            </h6>{" "}
          </div> : <br/>}

          <div className="text-center " style={{ paddingBottom: "0.7rem" }}>
            <Button
              // type="button"
              // className="btn  rounded-pill btn-sm"
              // disabled={cupSizeState ? false : true}
              onClick={cupSizeState ? hadleAddToCart : cupNotSelected}
              sx={{ color: "#C60734", textTransform: "none", border: "1px solid #C60734", borderRadius: "1rem", padding: "0.2rem 3.6rem", fontWeight: 550 }}
            >
              {favFromCard
                ? `Add to Cart` 
                : !selectedCupSize
                  ? `Update ${menuItem?.title}`
                  : `Add to Cart`}
            </Button>
          </div>
        </Card>
      </Container>
      <AlertPopup
        open={openAlert}
        setOpen={setOpenAlert}
        setCounter={setCupSizeChange}
        setCupSizeState={setCupSizeState}
        setState={setServingDetail}
        changedServing={changedServing}
        title={"customization"}
        buttonType1={true}
        buttonType2={true}
        displayTitle={"Changing cup size will remove selected options"}
      />
    </>
  );
};
export default Popup;
